<template>
  <div>
    <BaseModal
      v-if="units"
      id="unitmodifyform"
      @cancel="emit"
    >
      <template #popupButton>
        <v-btn
          large
          icon
          absolute
          right
          style="margin-top: 0.8em; margin-right: 4em;"
          @click="popup()"
        >
          <v-icon>open_in_new</v-icon>
          <span class="d-sr-only">{{ $t('OpenInNewWindow') }}</span>
        </v-btn>
      </template>
      <template #title>
        {{ $t('Edit units') }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card
            v-if="units.length > 0"
            elevation="0"
          >
            <v-card
              v-show="groupUpdate && units.length > 1"
              elevation="0"
            >
              <table
                class="v-datatable v-data-table v-table them--light table-overflow"
                style="margin-top: 1em; margin-bottom: 1em"
              >
                <thead>
                  <th class="column">
                    {{ $t('Group update') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <th class="column">
                      {{ $t('New values') }}
                    </th>
                    <template v-for="field in filteredFields">
                      <UnitModifyField
                        :key="field.value"
                        v-model="groupModifiedFields[field.value]"
                        :field="field"
                        :unit-usages="unitUsages"
                        :unit-rent-states="translatedRentStates"
                        :unit-apartments="unitApartments"
                        :unit-kitchens="unitKitchens"
                        :unit-balconies="unitBalconies"
                        :unit-additional-infos="unitAdditionalInfos"
                        :target-goal="targetGoal"
                        :tags="tags"
                      />
                    </template>
                  </tr>
                  <tr>
                    <th> {{ }} </th>
                    <template v-for="field in filteredFields">
                      <td
                        :key="field.value"
                        style="border: 0 !important;"
                      >
                        <v-btn
                          outlined
                          rounded
                          block
                          @click="setToAllFields(field)"
                        >
                          {{ $t('Set to all') }}
                        </v-btn>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </v-card>
            <v-card
              elevation="0"
            >
              <table class="v-datatable v-data-table v-table them--light table-overflow">
                <thead>
                  <th class="column">
                    {{ $t('Unit code') }}
                  </th>
                  <th
                    v-for="field in filteredFields"
                    :key="field.value"
                    class="column"
                    :style="field.style"
                  >
                    {{ $t(field.header) }}
                  </th>
                  <th v-if="dismantleable">
                    {{ $t('Dismantle') }}
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="unit in units"
                    :key="unit.id"
                  >
                    <th class="column">
                      {{ unit.unit_code }}
                    </th>
                    <template v-for="field in filteredFields">
                      <UnitModifyField
                        :key="field.value"
                        v-model="modifiedUnits[unit.id][field.value]"
                        :field="field"
                        :data="getCorrectValueForField(field.value, unit[field.value])"
                        :placeholder="unit[field.value]"
                        :unit-usages="unitUsages"
                        :unit-rent-states="translatedRentStates"
                        :unit-apartments="unitApartments"
                        :unit-kitchens="unitKitchens"
                        :unit-balconies="unitBalconies"
                        :unit-additional-infos="unitAdditionalInfos"
                        :target-goal="targetGoal"
                        :tags="tags"
                      />
                    </template>
                    <td
                      v-if="dismantleable"
                      class="column"
                    >
                      <v-row
                        justify="center"
                        align="center"
                      >
                        <v-checkbox
                          v-model="modifiedUnits[unit.id].dismantle"
                          :disabled="hasActiveLink(unit.id)"
                          style="padding-top: 1em; padding-left: 1em;"
                        />
                        <v-text-field
                          v-if="modifiedUnits[unit.id].dismantle"
                          v-model="modifiedUnits[unit.id].dismantle_date"
                          type="date"
                        />
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-card>
        </v-form>
      </template>
      <template #hideCancel>
        <p />
      </template>
      <template #footer>
        <columns-chooser
          v-model="visibleColumns"
          :stored-view-parent="'facilitymanagement'"
          :stored-view-level="'unit_modify_form'"
          :headers="editableFields"
          :show-save-selection="true"
          header-value="value"
          header-text="header"
          :small="false"
        />
        <v-btn
          v-if="units.length > 1"
          text
          outlined
          rounded
          :class="{ 'active': groupUpdate}"
          @click="groupUpdate = !groupUpdate"
        >
          {{ $t('Group update') }}
        </v-btn>
        <v-spacer />
        <v-btn
          rounded
          outlined
          text
          @click="emit"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid || isSaving"
          rounded
          depressed
          color="primary"
          @click="validate"
        >
          {{ $t('Save') }}
          <v-progress-circular
            v-if="isSaving"
            size="16"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import UnitModifyField from './UnitModifyField.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import humanize from '../../helpers/humanize'
import moment from 'moment'
import ColumnsChooser from '../../components/general/ColumnsChooser'
import BaseModal from '../../components/general/BaseModal.vue'

export default {
  name: 'UnitModifyForm',
  components: {
    UnitModifyField: UnitModifyField,
    ColumnsChooser: ColumnsChooser,
    BaseModal,
  },
  props: {
    disabled: { type: Boolean, default: null },
    units: { type: Array, default: null },
    unitUsages: { type: Array, default: null },
    unitRentStates: { type: Array, default: null },
    partyLinks: { type: Array, default: null },
    dismantleable: { type: Boolean, default: null },
    renting: { type: Boolean, default: null },
    parties: { type: Array, default: null },
    unitApartments: { type: Array, default: null },
    unitKitchens: { type: Array, default: null },
    unitBalconies: { type: Array, default: null },
    apartmentsActive: { type: Boolean, default: false },
    unitAdditionalInfos: { type: Array, default: null },
    unitVisibilityRights: { type: Object, default: null },
    targetGoal: { type: Array, default: null },
    inPopup: Boolean,
    rightForTiExpense: { type: Boolean, default: false },
    tags: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      groupUpdate: false,
      groupModifiedFields: {},
      modifiedUnits: {},
      valid: true,
      isSaving: false,
      childMessage: null,
      currentVisibility: false,
      rentingTabActive: false,
      visibleColumns: [],
    }
  },
  computed: {
    ...mapGetters('app', ['definitionById', 'definitionLabelById']),
    ...mapState('app', ['currentDate']),
    translatedRentStates () {
      return this.unitRentStates
        .filter(rent => rent.id !== 0)
        .map(rent => {
          return {
            label: this.getDefinitionLabel(rent.id),
            id: rent.id
          }
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })
    },
    fields () {
      return [
        {
          header: 'Customize unit code',
          value: 'unit_code',
          show: this.canEdit(this.unitVisibilityRights.unit_code),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Unit use',
          value: 'usage',
          show: this.canEdit(this.unitVisibilityRights.usage),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Dismantle',
          value: 'dismantle',
          show: false,
          style: { 'min-width': '10em' }
        },
        {
          header: 'Online marketing',
          value: 'marketing',
          show: this.rentingTabActive
        },
        {
          header: 'Apartment type',
          value: 'apartment',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Unit rental status',
          value: 'not_rentable',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Kitchen type',
          value: 'kitchen',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Apartment has sauna',
          value: 'sauna',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Apartment has balcony',
          value: 'balcony',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Additional info 1',
          value: 'additional_info_1',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Additional info 2',
          value: 'additional_info_2',
          show: this.apartmentsActive,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Clarified TI Expense',
          value: 'clarified_ti_expense',
          show: this.showClarifiedTIExpenses,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Unit identifier',
          value: 'unit_identifier',
          show: this.showUnitIdentifier,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Not rentable info',
          value: 'not_rentable_info' ,
          show: this.canEdit(this.unitVisibilityRights.not_rentable_info),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Target goal',
          value: 'target_goal',
          show: this.canEdit(this.unitVisibilityRights.target_goal),
          style: { 'min-width': '20em' }
        },
        { header: 'Area',
          value: 'area',
          show: this.canEdit(this.unitVisibilityRights.area),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Empty space contract area',
          value: 'empty_space_contract_area',
          show: this.showEmptySpaceContractArea,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Category',
          value: 'tags',
          show: this.canEdit(this.unitVisibilityRights.tags),
          style: { 'min-width': '20em' },
          placeholderProperty: 'name',
        },
        {
          header: 'Unit description',
          value: 'description' ,
          show: this.canEdit(this.unitVisibilityRights.description),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Additional info',
          value: 'additional_info',
          show: this.canEdit(this.unitVisibilityRights.additional_info),
          style: { 'min-width': '20em' },
        },
        {
          header: 'Permission validity',
          value: 'permission_validity_date',
          show: this.canEdit(this.unitVisibilityRights.permission_validity_date),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Permission definition',
          value: 'permission_definition',
          show: this.canEdit(this.unitVisibilityRights.permission_definition),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Min stocknumber',
          value: 'min_stocknumber',
          show: this.canEdit(this.unitVisibilityRights.min_stocknumber),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Max stocknumber',
          value: 'max_stocknumber',
          show: this.canEdit(this.unitVisibilityRights.max_stocknumber),
          style: { 'min-width': '20em' }
        },
        {
          header: 'Unit start date',
          value: 'version_one_start_date',
          show: true,
          style: { 'min-width': '20em' }
        },
        {
          header: 'Unit version end',
          value: 'end_date',
          show: true,
          style: { 'min-width': '20em' }
        },
      ]
    },
    showClarifiedTIExpenses () {
      const writeAccess = this.unitVisibilityRights.clarified_ti_expense?.editType == "Number"
      return this.rightForTiExpense && writeAccess
    },
    showUnitIdentifier () {
      if(this.unitVisibilityRights.unit_identifier?.editType === 'None' ?? true)
      {
        return false
      }

      return this.unitVisibilityRights.unit_identifier?.isShown === true ?? false
    },
    showEmptySpaceContractArea () {
      if(this.unitVisibilityRights.empty_space_contract_area?.editType === 'None' ?? true)
      {
        return false
      }

      return this.unitVisibilityRights.empty_space_contract_area?.isShown === true ?? false
    },
    editableFields () {
      return this.fields.filter(field => field.show)
    },
    filteredFields () {
      return this.fields.filter(field => field.show && this.visibleColumns.includes(field.value))
    },
  },
  watch: {
    units: {
      deep: true,
      immediate: true,
      async handler (oldVal) {
        this.modifiedUnits = {}
        oldVal.forEach(element => {
          this.$set(this.modifiedUnits, element.id, {
            dismantle_date: moment(this.currentDate).format(
              'YYYY-MM-DD'
            ),
            description: element.description,
            tags: element.tags
          })
        })
      }
    },
    currentVisibility: function (value) {
      if (value === false) {
        this.$emit('close')
      }
    },
    visible: function (value) {
      this.currentVisibility = value
    },
    renting: {
      handler () {
        this.rentingTabActive = this.renting
      }
    },
    unitVisibilityRights: {
      deep: true,
      immediate: true,
      handler () {
        this.setVisibilities()
      }
    }
  },
  mounted () {},
    methods: {
    ...mapActions('app', ['showConfirmDialog']),
    emit () {
      this.$emit('childToParent', false)
      this.$emit('update')
      this.close()
    },
    close () {
      this.currentVisibility = false
      this.groupModifiedFields = {}
      this.groupUpdate = false
      this.$emit('close')
    },
    getHumanreadableDate (date) {
      return humanize.date(date, 'date')
    },
    hasActiveLink (id) {
      return this.partyLinks.find(link => link.id_parent === id) !== undefined
    },
    getUnitLink (unit) {
      return this.partyLinks.find(link => link.id_parent === unit)
    },
    getDefinitionLabel (id) {
      const def = this.definitionById(id)
      if (def) {
        return this.$t(def.label)
      } else if (typeof id === 'string') {
        return this.$t(id)
      } else {
        return this.$t('Undefined')
      }
    },
    setToAllFields (field) {
      this.$nextTick(() => {
        for (const key of Object.keys(this.modifiedUnits)) {
          this.modifiedUnits[key][field.value] = JSON.parse(
            JSON.stringify(this.groupModifiedFields[field.value])
          )
        }
      })
    },
    setVisibilities () {
      this.visibleColumns = []
      this.fields.forEach(field => {
        this.visibleColumns.push(field.value)
      })
    },
    popup (){
      this.$emit('popup', this.modifiedUnits, 'unitmodify', 'unitmodifyform');
      this.close();
    },
    async validate () {
      this.isSaving = true
      await this.patch()
      this.isSaving = false
      this.$emit('saved', 'success')
    },
    async patch () {
      const time = new Date(this.currentDate).toISOString().slice(0, 10)
      // variable for picking and inspecting not_rentable label value
      let tagsByUnit = []
      let tagsUpdated = false

      let unitStatus = ''
      for (const unit in this.modifiedUnits) {
        const unitObject = this.units.find(e => {
          return e.id === parseInt(unit)
        })

        // tags are updated separately from the patch
        // process new tags into tag objects
        let tags = []
        let unitTagsUpdated = false
        if (this.canEdit(this.unitVisibilityRights.tags)) {
          const existingTagCount = unitObject.tags == null ? 0 : unitObject.tags.length
          const modifiedTagCount = this.modifiedUnits[unit].tags == null ? 0 : this.modifiedUnits[unit].tags.length

          if (existingTagCount != modifiedTagCount) {
            tagsUpdated = true
            unitTagsUpdated = true
          }

          if (this.modifiedUnits[unit].tags != null) {
            this.modifiedUnits[unit].tags.forEach(tag => {
              let updatedTag = null
              if (typeof tag === 'string') {
                updatedTag = {
                  id: null,
                  idUnit: unit,
                  name: tag,
                }
              } else {
                updatedTag = {
                  idUnit: unit,
                  ...tag,
                }
              }

              tags.push(updatedTag)
            })
          }

          if (unitTagsUpdated) {
            tagsByUnit.push({
              idUnit: unit,
              unitTags: tags,
            })
          }

          this.modifiedUnits[unit].tags = undefined
        }

        // some logic fix - if client perform unit dismantle, there's no need for other unit operations
        // other then ending active version so make dismantle a controllor condition
        if (
          this.modifiedUnits[unit].dismantle &&
          this.modifiedUnits[unit].dismantle === true
        ) {
          await this.$rambollfmapi.units.dismantle(
            unit,
            this.modifiedUnits[unit].dismantle_date
          )
        }
        else
        {
          const unitCopy = JSON.parse(JSON.stringify(unitObject))
          const unitObserver = this.$jsonpatch.observe(unitCopy)
          Object.keys(unitCopy).forEach(key => {
            if (this.modifiedUnits[unit][key] !== undefined) {
              unitCopy[key] =
                key === 'not_rentable' && this.modifiedUnits[unit][key] === 0
                  ? null
                  : this.modifiedUnits[unit][key]
            }
            // We pick the not_rentable lable value
            if (key === 'not_rentable') {
              unitStatus = this.getDefinitionLabel(unitCopy[key])
            }
          })
          const unitPatch = this.$jsonpatch.generate(unitObserver)
          let notRentableOperation = unitPatch.filter(u => u.path === '/not_rentable')
          let alvOperation = unitPatch.filter(u => u.path === '/alv')

          // If picked not_rentable label value is other then Vuokrattava tila or KOY, Vuokrattava tila,
          // initiate partylink patch
          if (
            unitStatus !== '' &&
            unitStatus !== this.$t('rentable') &&
            unitStatus !== this.$t('rentable.koy') &&
            unitStatus !== this.$t('notrentable.other')
          ) {
            const linkPatch = []

            const link = this.getUnitLink(parseInt(unit))

            if (notRentableOperation.length > 0 || alvOperation.length > 0) {
              if ( unitCopy.alv === true) {
                unitPatch.push({
                  op: 'replace',
                  path: '/alv',
                  value: false
                })
              }
            }

            if (
              link !== undefined &&
              (link.end_date === null ||
                moment(new Date(link.end_date)).format('YYYY-MM-DD') >
                  moment(new Date(time)).format('YYYY-MM-DD'))
            ) {
              const ok = await this.showConfirmDialog({
                title: 'Changing unit status to non-rentable state will end the contract to this date.',
                subtitle: 'Do you really want to end rental contract?',
                positive: 'Yes',
              })
              if (ok === true) {
                linkPatch.push({
                  op: 'replace',
                  path: '/end_date',
                  value: moment().add(-1, 'days')
                })

                await this.$rambollfmapi.units
                  .partyLinks(parseInt(unit))
                  .purePatch(parseInt(unit), link.id, 0, linkPatch)
              } else {
                this.isSaving = false
                return
              }
            }
          } else if (
            unitObject.not_rentable &&
            unitObject.not_rentable.includes('notrentable')
          ) {
            // When changing from notrentable to rentable, alv-status gets default value of true
            unitPatch.push({
              op: 'replace',
              path: '/alv',
              value: true
            })
          }
          if (unitPatch.length) {
            await this.$rambollfmapi.units.patch(unit, time, unitPatch)
          }
        }
      }

      if (tagsUpdated) {
        await this.$rambollfmapi.units.tags.update(tagsByUnit)
      }

      this.$emit('update')
      this.close()
    },
    canEdit (object) {
      return (
        object !== undefined && object.isShown && object.editType !== 'None'
      )
    },
    getCorrectValueForField (header, value) {
      if(header === 'usage'){
        var result = this.unitUsages.find(u => u.label == value)
          if (result === undefined) {
            return null
          }
          return result.id
      }
      if(header === 'not_rentable'){
        result = this.unitRentStates.find(u => u.label == value)
          if (result === undefined) {
            return null
          }
          return result.id
      }
      return value
    }
  }
}
</script>
<style scoped>
.active {
  background: var(--c-color-accent) !important;
  color: white !important;
}
.v-datatable tbody tr td v-text-field {
  width: 100px;
}
.v-datatable tbody tr td {
  vertical-align: 0%;
}
</style>
