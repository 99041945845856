<template>
  <v-app
    id="ramboll-dashboard"
    :class="printingClass"
  >
    <v-container
      v-if="!inPopup"
      :class="printingClassForAppContainer"
      fluid
    >
      <v-dialog
        v-model="authorizationError"
        max-width="550"
        persistent
      >
        <Forbidden
          @logout="logout()"
          @dismiss="clearAuthorizationErrors()"
        />
      </v-dialog>
      <!-- <v-app-bar app> -->
      <header>
        <AriaLive />
        <ErrorTip />
        <div
          class="toolbar"
        >
          <v-dialog
            v-model="showServiceRequestDialog"
            persistent
            max-width="550"
          >
            <v-card>
              <ServiceRequestForm
                :request-type="requestType"
                :is-shown="showServiceRequestDialog"
                @close="showServiceRequestDialog = false"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="showTableDataDialog"
            persistent
            max-width="1200"
          >
            <SiteSelect
              v-if="showTableDataDialog"
              :sites="availableSites"
              :portfolios="sitePortfolios"
              @site-select="siteSelection"
              @close="showTableDataDialog = false"
            />
          </v-dialog>
          <v-dialog
            v-model="showFilters"
            max-width="1300"
            persistent
          >
            <AppFilters
              v-if="showFilters"
              @close="showFilters = false"
              @apply="applyFilters"
            />
          </v-dialog>
          <v-dialog
            v-model="showPasswordChangeDialog"
            persistent
            max-width="550"
          >
            <v-card>
              <PasswordForm
                v-if="showPasswordChangeDialog"
                :edit-user="userInfo"
                @childToParent="onChildClick"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="showLanguageChangeDialog"
            persistent
            max-width="550"
          >
            <v-card>
              <LanguageForm
                v-if="showLanguageChangeDialog"
                :languages="languages"
                @closeLanguageForm="closeLanguageForm"
                @changeLanguage="changeLanguage"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="hasApplicationPermissionByName('LEASING') && shownRentalProcess"
            v-model="showModifyRentalProspect"
            persistent
            max-width="1600"
          >
            <RentalProcessModal
              :visible="showModifyRentalProspect"
              :rental-process="shownRentalProcess"
              @close="showModifyRentalProspect = false"
            />
          </v-dialog>
          <v-dialog
            v-model="showAdoptUsergroupDialog"
            persistent
            max-width="600"
          >
            <AdoptUsergroupForm
              v-if="showAdoptUsergroupDialog"
              @close="showAdoptUsergroupDialog = false"
            />
          </v-dialog>
          <v-dialog
            v-if="hasApplicationPermissionByName('KOHTEIDEN_LISAYS')"
            v-model="showAddSiteDialog"
            persistent
            max-width="550"
          >
            <AddSite
              v-if="showAddSiteDialog"
              :portfolios="sitePortfolios"
              @close="showAddSiteDialog = false"
            />
          </v-dialog>
          <v-dialog
            v-if="hasApplicationPermissionByName('HALYTYKSET')"
            v-model="showAddAlertDialog"
            persistent
            max-width="550"
          >
            <add-alert
              v-if="showAddAlertDialog"
              @close="showAddAlertDialog = false"
            />
          </v-dialog>
          <v-dialog
            v-model="showNewRentalProcessModal"
            persistent
            max-width="550"
          >
            <NewRentalProcessModal
              v-if="hasApplicationPermissionByName('LEASING')"
              :visible="showNewRentalProcessModal"
              @close="showNewRentalProcessModal = false"
              @openRentalProcessModal="showModifyRentalProspect = true"
            />
          </v-dialog>
          <ConfirmDialog 
            v-if="showConfirmDialog"
            :options="confirmDialogOptions"
            @close="hideConfirmDialog"
          />
          <SkipTo
            :label="$t('skip-to-page-content')"
            to="#content"
          />
          <a
            v-if="showLogo && wideScreen && customerLogo"
            :aria-label="$t('Circle main page')"
            style="padding-top: 4px;"
            class="mr-3"
            href="/dashboard"
          >
            <v-img
              :src="'data:image/jpeg;base64,' + customerLogo"
              contain
              height="40px"
              max-width="100px"
            />
          </a>

          <v-btn
            :icon="!wideScreen"
            :disabled="filteringDisabled"
            rounded
            depressed
            :outlined="siteFiltersLength === 0"
            :class="!filteringDisabled && siteFiltersLength > 0 ? 'secondary' : ''"
            @click="openFilters"
          >
            <template
              v-if="!filtersLoading"
            >
              <v-icon
                :left="wideScreen"
                :small="!wideScreen"
              >
                filter_list
              </v-icon>
              <v-badge
                v-if="siteFiltersLength && !filteringDisabled"
                color="accent"
                :content="siteFiltersLength"
              >
                <span
                  :class="{'d-sr-only': !wideScreen}"
                  class="mr-2"
                >
                  {{ $t('All filters') }}
                </span>
              </v-badge>
              <span
                v-else
                :class="{'d-sr-only': !wideScreen}"
              >
                {{ $t('All filters') }}
              </span>
            </template>
            <v-row
              v-else
              justify="center"
              align="center"
              no-gutters
            >
              <v-progress-circular
                :size="20"
                :width="3"
                indeterminate
              />
            </v-row>
          </v-btn>
          <DatePicker
            class="datePicker"
            :initial-date="currentDate"
            :disabled="currentRouteName.startsWith('internalrent')"
            :is-date-different="dateIsDifferent"
            :header-version="true"
            :hide-details="true"
            :aria-label="$t('date_format')"
            :clearable="false"
            @dateupdated="currentDateUpdate"
            @setToday="setToToday"
          />
          <v-btn
            v-if="hasApplicationPermissionByName('KARTTAKAYTTOLIITTYMA')"
            :icon="!wideScreen"
            rounded
            outlined
            @click="handleGisClick"
          >
            <v-icon

              :left="wideScreen"
              :small="!wideScreen"
            >
              map
            </v-icon>
            <span :class="{'d-sr-only': !wideScreen}">
              {{ $t('Gis map') }}
            </span>
          </v-btn>
          <CircleSearch
            @select="onSearchSelect"
          />
          <AddMenu
            @open="openModal"
          />
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                v-show="
                  hasApplicationPermissionByName('RAMBOLL_TILAUS') ||
                    hasApplicationPermissionByName('ZOAN_TILAUS')
                "
                slot="activator"
                v-bind="attrs"
                outlined
                :icon="!wideScreen"
                rounded
                v-on="on"
              >
                <v-icon
                  :left="wideScreen"
                  :small="!wideScreen"
                >
                  announcement
                </v-icon>
                <span :class="{ 'd-sr-only': !wideScreen }">{{ $t('Service request') }}</span>
              </v-btn>
            </template>
            <v-list
              nav
              dense
            >
              <v-list-item
                v-for="filteredRequestType in filteredRequestTypes"
                :key="filteredRequestType.id"
                @click="requestType = filteredRequestType.id, showServiceRequestDialog = true"
              >
                <v-list-item-icon>
                  <v-icon>{{ filteredRequestType.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  {{ $t(filteredRequestType.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <SettingsMenu />

          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <div>
                <v-btn
                  v-if="!wideScreen"
                  icon
                  depressed
                  rounded
                  :class="hasAdoptedUsergroup ? 'heath' : 'primary'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    v-if="hasAdoptedUsergroup"
                    color="white"
                  >
                    supervised_user_circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="white"
                  >
                    account_circle
                  </v-icon>
                  <span class="d-sr-only">{{ userInfo !== null ? `${userInfo.name} (${userInfo.customer})` : '' }}</span>
                </v-btn>
                <v-btn
                  v-else
                  depressed
                  color="transparent"
                  rounded
                  v-bind="attrs"
                  class="userButton"
                  v-on="on"
                >
                  <v-avatar
                    size="36"
                    class="mr-2"
                    :color="hasAdoptedUsergroup ? 'heath' : 'accent'"
                  >
                    <v-icon
                      v-if="hasAdoptedUsergroup"
                      color="white"
                    >
                      supervised_user_circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="white"
                    >
                      account_circle
                    </v-icon>
                  </v-avatar>
                  <div class="d-flex flex-column align-start">
                    <div class="userButton__title">
                      {{ userInfo !== null ? `${userInfo.name}` : '' }}
                    </div>
                    <div class="userButton__subtitle">
                      {{ userInfo !== null ? `${userInfo.customer}` : '' }}
                    </div>
                  </div>
                  <v-icon>keyboard_arrow_down</v-icon>
                </v-btn>
              </div>
            </template>
            <v-list
              nav
              dense
            >
              <v-list-item
                v-if="isDraggableView"
                @click="sortWidgets"
              >
                <v-list-item-icon>
                  <v-icon>reorder</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Default view') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeUsergroupAdoption()">
                <v-list-item-icon>
                  <v-icon>supervisor_account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ hasAdoptedUsergroup ? $t('Restore user') : $t('Adopt user') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="languageChangeAllowed"
                @click="showLanguageChangeDialog = true"
              >
                <v-list-item-icon>
                  <v-icon>language</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Change language') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="passwordChangeAllowed"
                @click="showPasswordChangeDialog = true"
              >
                <v-list-item-icon>
                  <v-icon>create</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  {{
                    $t('Change password')
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!hasAdoptedUsergroup"
                @click="logout"
              >
                <v-list-item-icon>
                  <v-icon>exit_to_app</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{
                    $t('Log out')
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </header>
      <!--  </v-app-bar> -->
      <nav :aria-label="$t('MainMenu')">
        <v-row
          justify="space-between"
          class="navigation text-no-wrap"
          no-gutters
        >
          <v-col class="shrink">
            <!-- all sites -->
            <v-btn
              :class="{ active: currentRouteName.startsWith('dashboard') }"
              :to="{ name: 'dashboard' }"
              large
              text
              router
              @click="
              ;(dashboard = true), (selectedSite = null), (siteItem = null)
              "
            >
              <v-icon>work</v-icon>
            &nbsp;{{ $t('Sites') }}
            </v-btn>
            <!-- PTS Tab -->
            <v-btn
              v-if="hasApplicationPermissionByName('PTS')"
              :class="{ active: currentRouteName.startsWith('planning') }"
              :to="{ name: 'planning' }"
              large
              text
              router
            >
              &nbsp;{{ $t('LTP') }}
            </v-btn>
            <!--  end of PTS Tab -->
            <!--Internal Rent Tab -->
            <v-btn
              v-if="hasApplicationPermissionByName('SISAINENVUOKRAUS')"
              :class="{
                active: currentRouteName.startsWith('internalrent'),
              }"
              :to="{ name: 'internalrent' }"
              large
              text
              router
            >
              &nbsp;{{ $t('Internal Rent') }}
            </v-btn>
            <!--  end of Internal Rent Tab -->
            <!-- Leasing Tab -->
            <v-btn
              v-if="hasApplicationPermissionByName('LEASING')"
              :class="{ active: currentRouteName.startsWith('renting') }"
              :to="{ name: 'renting' }"
              large
              text
              router
            >
              &nbsp;{{ $t('Renting') }}
            </v-btn>
            <!--  end of Leasing Tab -->
            <!-- Contractlayers Tab -->
            <v-btn
              v-if="hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') ||
                hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
              id="contracts_tab"
              :class="{ active: currentRouteName.startsWith('contractlayers') }"
              :to="{ name: 'contractlayers' }"
              large
              text
              router
            >
              &nbsp;{{ $t('Contracts') }}
            </v-btn>
            <!--  end of Contractlayers Tab -->
            <!-- Billing Tab -->
            <v-btn
              v-if="hasApplicationPermissionByName('LASKUTUS')"
              :class="{ active: currentRouteName.startsWith('billing') }"
              :to="{ name: 'billing' }"
              large
              text
              router
            >
              &nbsp;{{ $t('Billing') }}
            </v-btn>
            <!--  end of Billing Tab -->
            <v-btn
              v-if="currentRouteName.startsWith('settings')"
              :to="{ name: 'settings' }"
              large
              text
            >
              <v-icon>settings</v-icon>
              &nbsp;{{ $t('Settings') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="
              openedSites &&
                openedSites.length > 0 &&
                sitesBar.scrollWidth >= sitesBar.clientWidth &&
                sitesBar.scrollLeft > 0
            "
            class="shrink"
            style="
            position: ablsolute;
            left: 0;
            padding-left: 0.5em;
            padding-right: 0.5em;
          "
          >
            <v-chip
              text
              align-center
              justify-center
              @click="scrollTo('left')"
            >
              <v-icon>keyboard_arrow_left</v-icon>
            </v-chip>
          </v-col>
          <v-col
            id="sites-bar"
            ref="sitesbar"
            class="sitesbar"
          >
            <v-row
              justify="start"
              no-gutters
            >
              <v-row
                justify="end"
                class="flex-nowrap text-right mr-4"
                no-gutters
              >
                <!-- opened sites on the right -->
                <v-col
                  v-for="item in openedSites"
                  :key="item.id_site"
                  class="shrink"
                >
                  <v-btn
                    :to="{ name: item.location, params: { id_site: item.id_site } }"
                    large
                    text
                    route
                  >
                    <v-icon
                      v-if="item.location === 'sites.info'"
                    >
                      location_on
                    </v-icon>
                    <v-icon
                      v-else-if="item.location === 'sites.facilitymanagement'"
                    >
                      border_style
                    </v-icon>
                    <v-icon
                      v-else-if="item.location === 'sites.graveyardmap'"
                    >
                      fence
                    </v-icon>
                    <v-icon
                      v-else-if="item.location === 'sites.documents'"
                    >
                      file_copy
                    </v-icon>
                    <v-icon
                      v-else-if="item.location === 'sites.photos'"
                    >
                      insert_photo
                    </v-icon>
                    {{ getTabHeader(item.id_site) }}
                  </v-btn>
                  <v-btn
                    large
                    text
                    icon
                    @click.stop="deleteColumn(item)"
                  >
                    <v-icon>close</v-icon>
                    <span class="d-sr-only">{{ $t('Close') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-col
            v-if="openedSites && openedSites.length > 2"
            class="shrink mr-4 ml-0"
            style="
            position: ablsolute;
            right: 0;
          "
          >
            <v-chip
              align-center
              justify-center
              @click="scrollTo('right')"
            >
              <v-icon>keyboard_arrow_right</v-icon>
            </v-chip>
          </v-col>
        </v-row>
      </nav>

      <!-- content -->
      <v-main id="content">
        <nav
          v-if="dashboardButtonsVisible"
          :aria-label="$t('SubMenu')"
        >
          <v-row no-gutters>
            <v-col>
              <v-tabs
                class="pl-5"
                show-arrows
              >
                <v-tab
                  :to="{ name: 'dashboard' }"
                  exact
                >
                  {{ $t('Dashboard') }}
                </v-tab>
                <v-tab
                  :to="{ name: 'reports' }"
                  exact
                  @click="
                    portfolioSelection = null
                    portfolioGroupSelection = null
                  "
                >
                  {{ $t('Reporting') }}
                </v-tab>
                <v-tab
                  v-if="!hasApplicationPermissionByName('MUUT_SOPIMUKSET_ULOSVUOKRAUS') && !hasApplicationPermissionByName('MUUT_SOPIMUKSET_SISAANVUOKRAUS')"
                  :disabled="!hasAppRightToOtherContracts"
                  :to="{ name: 'contracts' }"
                  exact
                >
                  {{ $t('Contracts') }}
                </v-tab>
                <v-tab
                  v-if="hasApplicationPermissionByName('SIS_HLOMAARAKOHDISTUS')"
                  :to="{ name: 'personnel_allocations' }"
                  exact
                >
                  {{ $t('Numbers of personnel') }}
                </v-tab>
                <v-tab
                  v-if="hasApplicationPermissionByName('MARKKINOINTI')"
                  :to="{ name: 'marketing_information' }"
                  exact
                >
                  {{ $t('Marketing information') }}
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col
              v-if="siteFiltersByTypes('all').length > 0"
              v-show="!filteringDisabled"
              class="text-right"
            >
              <FilteringStatusChip />
            </v-col>
          </v-row>
          <v-divider />
        </nav>
        <keep-alive include="Dashboard">
          <router-view />
        </keep-alive>
        <Alert
          v-if="showTableDataDialog === false && showFilters === false"
          :show="showIndicator"
          :result="operationResult"
          :message="indicatorMessage"
          :y="'bottom'"
          :x="'left'"
          :timeout="-1"
          :has-button="true"
        />
      </v-main>
      <AppFooter class="py-5">
        <router-link
          v-if="hasApplicationPermissionByName('SAAVUTETTAVUUSSELOSTE')"
          to="/accessibilitystatement"
        >
          {{ $t('Saavutettavuusseloste') }}
        </router-link>
        <router-link
          to="/releases"
        >
          {{ $t('Releases') }}
        </router-link>
      </AppFooter>
    </v-container>
    <router-view v-else />
  </v-app>
</template>

<script>
import AddAlert from './components/AddAlert.vue'
import AddSite from './components/AddSite.vue'
import AppFooter from './components/general/AppFooter.vue'
import SkipTo from './components/general/SkipTo.vue'
import Forbidden from './components/Forbidden.vue'
import ErrorTip from './components/ErrorTip.vue'
import AriaLive from './components/AriaLive.vue'
import helpers from './helpers'
import AdoptUsergroupForm from './components/AdoptUsergroupForm.vue'
import ServiceRequestForm from './components/ServiceRequestForm.vue'
import SiteSelect from './components/SiteSelect.vue'
import AppFilters from './components/AppFilters.vue'
import CircleSearch from './components/CircleSearch'
import PasswordForm from './components/PasswordForm.vue'
import LanguageForm from './components/LanguageForm.vue'
import Alert from './components/Alert.vue'
import AddMenu from './components/AddMenu.vue'
import DatePicker from './components/general/DatePicker.vue'
import SettingsMenu from './components/SettingsMenu.vue'
import NewRentalProcessModal from './components/Leasing/Modals/NewRentalProcessModal.vue'
import { loadLanguageAsync } from './plugins/i18n'
import './theme.css'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import FilteringStatusChip from './components/general/FilteringStatusChip.vue'
import RentalProcessModal from './components/Leasing/RentalProcessModal.vue'
import ConfirmDialog from './components/general/ConfirmDialog.vue'
export default {
  name: 'App',
  components: {
    AddAlert,
    AddSite,
    AppFooter,
    SkipTo,
    ServiceRequestForm,
    AdoptUsergroupForm,
    SiteSelect,
    Forbidden,
    ErrorTip,
    AriaLive,
    AppFilters,
    PasswordForm,
    LanguageForm,
    Alert,
    AddMenu,
    DatePicker,
    SettingsMenu,
    FilteringStatusChip,
    NewRentalProcessModal,
    RentalProcessModal,
    CircleSearch,
    ConfirmDialog,
  },
  metaInfo () {
    return {
      titleTemplate: '%s Ramboll Circle',
      htmlAttrs: {
        lang: this.$i18n.locale
      },
    }
  },
  data () {
    return {
      setPortfolio: { name: 'Kaikki kohteet', multiple: true },
      site: false,
      selectedSite: null,
      siteItem: null,
      select2: null,
      dashboard: true,
      items: [],
      showFilters: false,
      selection: [],
      allSites: [],
      select: { name: 'Kaikki kohteet', multiple: true },
      favourites: [{ name: 'Suosikit' }],
      active: null,
      showLogo: false,
      showMenu: false,
      showTableDataDialog: false,
      showServiceRequestDialog: false,
      showAddSiteDialog: false,
      showAddAlertDialog: false,
      showAdoptUsergroupDialog: false,
      showPasswordChangeDialog: false,
      showLanguageChangeDialog: false,
      showAddProspectDialog: false,
      showNewRentalProcessModal: false,
      showModifyRentalProspect: false,
      requestType: 0,
      orderWidgets: false,
      tabs: [
        { title: 'Dashboard', route: 'dashboard', class: 'true' },
        { title: 'Dokumentit', route: 'documents', class: 'true' },
        { title: 'Raportit', route: 'reports', class: 'true' }
      ],
      helpVisible: false,
      settingsVisible: false,
      previousSelections: [
        { name: '', multiple: true },
        { name: '', multiple: true },
        { name: '', multiple: true }
      ],
      portfolioGroupSelections: [{ name: '', multiple: false }],
      portfolioGroupSelection: null,
      portfolioSelection: null,
      currentDate: null,
      selectedFilters: [],
      loadingSiteData: false,
      hierarchyIcons: {
        Site: 'location_on',
        Building: 'home'
      },
      allPortfoliosSelection: { label: 'Kaikki kohteet', id: -1 },
      sitesBar: {
        scrollLeft: 0,
        clientWidth: 0,
        scrollWidth: 0
      },

      // Alert component variables
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',

      dateIsDifferent: false,
      filtersLoading: false,
      languages: [],
    }
  },
  computed: {
    ...mapState('app', [
      'authorizationError',
      'portfolios',
      'sites',
      'userInfo',
      'availableSites',
      'printingClass',
      'siteMetadata',
      'defaultFiltersLoaded',
      'recursionLimit',
      'siteIds',
      'siteRecursionLoadingState',
      'siteLimitForFetchingAllSites',
      'cemeteries',
      'settings',
      'showConfirmDialog', 
      'confirmDialogOptions',
    ]),
    ...mapState('internalRent', [
      'selectedView'
    ]),
    ...mapGetters('app', [
      'definitionById',
      'sitePermission',
      'siteFiltersByTypes',
      'applicationPermissions',
      'hasApplicationPermissionByName',
      'siteName',
      'customerLogo',
    ]),
    ...mapState('rentalProcess', [
      'shownRentalProcess'
    ]),
    hasAdoptedUsergroup () {
      return VueCookies.get('realToken') !== null
    },
    currentRouteName () {
      return this.$route.name ?? ''
    },
    openedSites () {
      return this.$store.state.sites.siteTabs
    },
    sitePortfolios () {
      return this.portfolios
        .filter((val, idx, arr) => arr.indexOf(val) === idx) // unique
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1
        })
    },
    selectedPortfolio () {
      return this.allPortfoliosSelection
    },
    currentDateAsString () {
      const date = this.$store.state.app.currentDate

      return helpers.humanize.date(date.toISOString(), 'date')
    },
    previous () {
      const src = this.previousSelections
      return src
    },
    favouriteSelections () {
      var fav = this.$store.state.app.favourites
      return this.$store.state.app.sites.filter(s =>
        fav.find(f => f === s.id_site)
      )
    },
    inPopup () {
      return this.$store.state.app.popupOpen
    },
    dashboardButtonsVisible () {
      return (
        this.dashboard === true &&
        !this.$route.params.id_site &&
        !this.currentRouteName.startsWith('settings') &&
        !this.currentRouteName.startsWith('internalrent') &&
        !this.currentRouteName.startsWith('planning') &&
        !this.currentRouteName.startsWith('contractlayers') &&
        !this.currentRouteName.startsWith('billing') &&
        !this.currentRouteName.startsWith('accessibilityStatement') &&
        !this.currentRouteName.startsWith('releases') &&
        !this.currentRouteName.startsWith('renting')
      )
    },
    wideScreen () {
      return this.$vuetify.breakpoint.lgAndUp
    },
    xlScreen () {
      return this.$vuetify.breakpoint.xl
    },
    webScreen () {
      return this.$vuetify.breakpoint.smAndUp
    },
    filteredRequestTypes () {
      // id 2 (lost password) is not shown in menu selection
      return helpers.requestTypes
        .types()
        .filter(type => type.id !== 2)
        .filter(type => this.hasApplicationPermissionByName(type.addPermission))
    },
    availableFilters () {
      const newFilters = Object.keys(this.siteMetadata).filter(metadata => this.siteMetadata[metadata].showFilter) || []
      newFilters.push("partyFilters")
      newFilters.push("sites")
      return newFilters
    },
    siteFiltersLength () {
      return this.siteFiltersByTypes(this.availableFilters).length
    },
    hasAppRightToOtherContracts () {
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET')
    },
    printingClassForAppContainer () {
      return this.printingClass ? this.printingClass + '-app-container' : null
    },
    isDraggableView () {
      return !this.$store.state.app.sortableWidgets && (
        (
          this.dashboard === true &&
          this.currentRouteName.startsWith('dashboard') &&
          !this.$route.params.id_site
        ) ||
        (
          this.currentRouteName === 'sites.info' &&
          this.$route.params.id_site
        ) ||
          this.currentRouteName === 'internalrent' &&
          this.currentRouteName === 'internalrent.dashboard' ||
          this.currentRouteName === 'internalrent.budgeting_and_realisation'
          ||
        (
          this.currentRouteName === 'sites.additionalInfo' &&
          this.$route.params.id_site
        ) ||
        (
          this.currentRouteName === 'sites.renting' &&
          this.$route.params.id_site
        ) ||
        (
          this.currentRouteName === 'sites.rentingprocesses' &&
          this.$route.params.id_site
        )
      )
    },
    filteringDisabled () {
      return !(this.currentRouteName.startsWith('dashboard') ||
      this.currentRouteName.startsWith('contracts') ||
      this.currentRouteName.startsWith('renting') ||
      this.currentRouteName.startsWith('planning') ||
      this.currentRouteName.startsWith('personnel_allocation') ||
      this.currentRouteName.startsWith('marketing_information'))
    },
    passwordChangeAllowed () {
      const status = this.$store.state.app.userInfo != null ? !this.$store.state.app.userInfo.ssoAccount : false
      return status
    },
    languageChangeAllowed () {
      return this.languages?.length > 1
    },
  },
  watch: {
    currentDate: function (newDate) {
      const date = this.$store.state.app.currentDate
      // Make sure the currentDate actually changed...
      if (
        new Date(newDate).toISOString().substr(0, 10) !==
        date.toISOString().substr(0, 10)
      ) {
        this.$store.dispatch('app/setCurrentDate', newDate)
      }
      this.showDateMessage()
    },
    currentRouteName: function () {
      if (this.dateIsDifferent && this.currentRouteName.startsWith('internalrent')) {
        this.setToToday()
      }
    },
    openedSites: function () {
      this.getSitesBarData()
    },
    siteRecursionLoadingState: function (bool) {
      if (!bool) {
        this.clearWidgetData()
        this.loadSitesEstatesAndBuildings()
      }
    },
    // Open modal if it's not yet open.
    shownRentalProcess () {
      if(!this.showModifyRentalProspect){
        this.showModifyRentalProspect = true
      }
    }
  },
  async mounted () {
    await this.getSettings()
    await this.getCurrencies()
    await this.getCountries()
    this.setLanguages(this.$i18n.locale)
    // get selection
    await this.$store.dispatch('app/getSiteMetadata')
    await this.$store.dispatch('app/getCustomerLogo')
    await this.$store.dispatch('app/checkExternalOrgs')
    this.$store.dispatch('app/getPortfolios')
    this.$store.dispatch('app/getDefinitions')
    this.$store.dispatch('app/getTags')
    this.$store.dispatch('app/getFavourites')
    this.$store.dispatch('rentContracts/initContractTemplates')
    if (!this.defaultFiltersLoaded) {
      await this.loadDefaultFilters()
      this.setDefaultFiltersLoaded()
    }
    await this.$store.dispatch('app/getRecursiveSites')
    await this.getCemeteries()
    await this.$store.dispatch('app/initializeParties')
    this.init()
    //await this.getSites() This might be needed if recursion is used again at some point
    this.getSitesBarData()
    if (this.hasApplicationPermissionByName('HALYTYKSET')) {
      await this.getAlertUsers()
    }
  },
  methods: {
    ...mapMutations('app', [
      'clearAuthorizationErrors',
      'setSiteFilters',
      'setMassSiteFilters',
      'clearSiteFilters',
      'filterSites',
      'setDefaultFiltersLoaded',
      'getFilteredSites'
    ]),
    ...mapMutations('dashboard', ['clearWidgetData']),
    ...mapMutations('app', ['pushCemeteries', 'clearCemeteries']),
    ...mapActions('app', [
      'getSites',
      'getBuildings',
      'getEstates',
      'getCemeteries',
      'getUserOrganizations',
      'getFilterValues',
      'getSettings',
      'getAlertUsers',
      'getCurrencies',
      'getCountries',
      'hideConfirmDialog',
      ]),
    ...mapActions('rentalProcess', ['openRentalProcess']),
    async changeUsergroupAdoption () {
      if (!this.hasAdoptedUsergroup) {
        this.showAdoptUsergroupDialog = true
      } else {
        // Restore old session data
        await this.$rambollfmapi.accounts.refresh(VueCookies.get('refreshToken')).delete()
        await this.$rambollfmapi.accounts.removeAdopted()
        VueCookies.set('token', VueCookies.get('realToken'), new Date(new Date().getTime() + 60 * 60 * 1000), null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
        VueCookies.set('refreshToken', VueCookies.get('realRefreshToken'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
        VueCookies.set('tokenExpires', VueCookies.get('realExpires'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
        VueCookies.set('v3ViewerToken', VueCookies.get('realv3ViewerToken'), '1m', null, null, process.env.NODE_ENV === 'development' ? false : true, 'Strict')
        if (VueCookies.isKey('realToken')) {
          VueCookies.remove('realToken')
        }
        if (VueCookies.isKey('realRefreshToken')) {
          VueCookies.remove('realRefreshToken')
        }
        if (VueCookies.isKey('realExpires')) {
          VueCookies.remove('realExpires')
        }
        if (VueCookies.isKey('v3ViewerToken')) {
          VueCookies.remove('v3ViewerToken')
        }
        window.location = '/dashboard'
      }
    },
    currentDateUpdate (value) {
      this.currentDate = value
    },
    showDateMessage () {
      const date = this.$store.state.app.currentDate
      const today = new Date()

      if (!moment(date, 'day').isSame(today, 'day')) {
        this.dateIsDifferent = true
        this.showIndicator = true
        this.operationResult = 'info'
        this.indicatorMessage = this.$t('Your viewing past or future date')
      } else {
        this.dateIsDifferent = false
        this.showIndicator = false
        this.operationResult = ''
        this.indicatorMessage = ''
      }
    },
    selectPortfolio (selectedPortfolio) {
      this.$log.info(selectedPortfolio)

      this.clearSiteFilters()
      if (selectedPortfolio.id >= 0) {
        this.setSiteFilters({
          type: 'portfolios',
          value: [selectedPortfolio.id]
        })
      }
      // Retrieve sites. Uses filters from store
      this.$store.dispatch('app/getRecursiveSites')
    },
    siteSelection (sites) {
      // Hide dialog
      this.showTableDataDialog = false
      // If only one site selected then redirect
      if (sites.length === 1) {
        this.$router.push({
          name: 'sites.info',
          params: { id_site: sites[0].id_site }
        })
      } else {
        this.clearSiteFilters()
        this.setSiteFilters({
          type: 'id_site',
          value: sites.map(f => f.id_site)
        })

        // Retrieve sites. Uses filters from store
        this.getSites()
        this.getBuildings()
      }
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    async getSiteData () {
      const idSite = this.$route.params.id_site
      const site = await this.$rambollfmapi.sites.get(idSite)
      this.addColumn(site)
      if (this.$route.name.startsWith('sites')) {
        this.site = true
      }
      return site
    },
    async logout () {
      await this.deleteVektorioToken()
      await this.$rambollfmapi.accounts.refresh(VueCookies.get('refreshToken')).delete()
      if (VueCookies.isKey('token')) {
        VueCookies.remove('token')
      }
      if (VueCookies.isKey('tokenExpires')) {
        VueCookies.remove('tokenExpires')
      }
      if (VueCookies.isKey('refreshToken')) {
        VueCookies.remove('refreshToken')
      }
      if (VueCookies.isKey('realToken')) {
        VueCookies.remove('realToken')
      }
      if (VueCookies.isKey('realRefreshToken')) {
        VueCookies.remove('realRefreshToken')
      }
      if (VueCookies.isKey('realExpires')) {
        VueCookies.remove('realExpires')
      }
      if (VueCookies.isKey('realv3ViewerToken')) {
        VueCookies.remove('realv3ViewerToken')
      }
      window.localStorage.removeItem('refreshAttempt')
      window.location = '/login.html'
    },
    init () {
      if (this.settings.length === 0) {
      this.showLogo = false;
    } else {
      this.showLogo = this.settings.showMainPageLogo ?? false
    }
      this.currentDate = new Date(
        this.$store.state.app.currentDate
      ).toISOString()
      if (this.$route.params.id_site) {
        const site = this.sites.find(
          s => s.id_site === Number(this.$route.params.id_site)
        )
        this.items.push({ name: site?.name, id_site: site?.id_site })
      }
    },
    async applyFilters (filters, defaultFilters = false) {
      this.showFilters = false // close the filter dialog if visible
      this.setFiltersToStore(filters)
      // perform cemetery filtering if necessary
      this.filterCemeteries(filters)

      // filter sites
      if(!defaultFilters)
      {
        await this.$store.dispatch('app/getRecursiveSites')
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.filters_saved_and_closed'))
      }
    },
    setFiltersToStore (filters) {
      const partyFilters = {}
      const updateObjects = []

      Object.keys(filters).forEach(type => {
        if(this.availableFilters.includes(type)) {
         updateObjects.push({
            type: type,
            value: filters[type].map(f => f.id)
          })
        } else if(filters[type].length > 0) {
          partyFilters[type] = filters[type].map(f => f.id)
        }
      })

      // Retrieve sites. Uses filters from store
      updateObjects.push(
      {
        type: "partyFilters",
        value: partyFilters
      })
      this.setMassSiteFilters(updateObjects)
    },
    async filterCemeteries (filters) {
      // if we have cemeteries and site filtering is used...
      if (this.cemeteries.length > 0 && filters.sites.length > 0) {
        this.clearCemeteries()
        // ...begin filtering cemeteries based on siteIds
        for (var i = 0; i < filters.sites.length; i++) {
          const cemetery = await this.$rambollfmapi.graveyards.cemeteries.get({query:{siteId: filters.sites[i].id}})
          this.pushCemeteries(cemetery)
        }
      }
    },
    directToSite (selection, query) {
      this.$log.info('directing to', selection, query)
      if (!Array.isArray(selection))
      {
        if (selection.id_site) {
          this.$router.push({
            name: 'sites.info',
            params: { id_site: selection.id_site }
          })
          this.dashboard = false
          this.site = true
          this.selectedSite = selection.name
          this.addColumn(selection)
        }
      }
    },
    directToRentalProcess (id) {
      this.openRentalProcess({id: id})
      this.showModifyRentalProspect = true
    },
    onPropertySelect (result) {
      const selection = result.results
      selection.id_site = selection.siteId
      const query = result.query
      this.directToSite(selection, query)
    },
    onSearchSelect (result) {
      const selection = result.results
      if (selection.category === this.$t('rentalprocesses')) {
        this.directToRentalProcess(selection.identifier)
      } else {
        if (selection.linkIdentifier == null) {
          selection.id_site = selection.identifier
        } else {
          selection.id_site = selection.linkIdentifier
        }
        const query = result.query
        this.directToSite(selection, query)
      }
    },
    onMovePrevious (evt) {
      const favourites = this.favouriteSelections
      const previous = this.previousSelections
      favourites.filter(elem => {
        if (elem.name === '' && favourites.length === 4) {
          favourites.splice(elem, 1)
          previous.push({ name: '', multiple: true })
        } else {
          return false
        }
      })
      if (favourites.length > 4) {
        return false
      }
    },
    onMoveFavourites (evt) {
      const favourites = this.favouriteSelections
      const previous = this.previousSelections
      previous.filter(elem => {
        if (
          elem.name === '' &&
          previous.length === 3 &&
          evt.relatedContext.list !== favourites
        ) {
          previous.splice(elem, 1)
          favourites.push({ name: '', multiple: true })
        } else {
          return false
        }
      })
      if (previous.length > 3) {
        return false
      }
    },
    onChildClick (value) {
      this.showPasswordChangeDialog = value
    },
    closeLanguageForm (value) {
      this.showLanguageChangeDialog = value
    },
    addColumn (selection) {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].name === selection.name) {
          return
        }
      }
      this.dashboard = false
      this.selectedSite = selection.name
      this.siteItem = selection
      this.items.push({ name: selection.name, id_site: selection.id_site })
      this.selectedSite = selection.name
    },
    deleteColumn: function (selection) {
     if (this.$router.history.current.name === selection.location && Number(this.$router.history.current.params.id_site) === selection.id_site) {
        this.$router.push({ name: 'dashboard' })
        this.dashboard = true
      }
      this.items.splice(this.items.indexOf(selection), 1)
      // remove from cache
      this.$log.info('removing site ', selection)
      this.$store.commit('sites/removeSite', { siteId: selection.id_site })
    },
    setToToday () {
      const today = new Date ()
      this.currentDate = new Date(today).toISOString()
      this.showDateMessage()
    },
    sortWidgets () {
      this.$store.commit('app/setSortableWidgets', true)
    },
    scrollTo (direction) {
      const sitesBar = this.$refs.sitesbar
      const scrollLeft = Math.round(sitesBar.scrollLeft)
      const scrollWidth = sitesBar.scrollWidth
      const clientWidth = sitesBar.clientWidth
      const scrollPixels = direction === 'right' ? 300 : -300

      // Check that is scrolling needed
      if (
        !(
          (scrollLeft === 0 || scrollPixels > 0) &&
          (clientWidth + scrollLeft >= scrollWidth || scrollPixels < 0)
        )
      ) {
        this.sitesBar.scrollLeft = scrollLeft + scrollPixels
        sitesBar.scrollLeft = scrollLeft + scrollPixels
      }
    },
    getSitesBarData () {
      const sitesBar = this.$refs.sitesbar
      if (typeof sitesBar !== 'undefined') {
        this.sitesBar.scrollLeft = 0
        this.sitesBar.scrollWidth = sitesBar.scrollWidth
        this.sitesBar.clientWidth = sitesBar.clientWidth
      }
    },
    setLanguages (locale) {
      if (this.settings?.availableLanguages !== null) {
        // Use available languages
        this.languages = this.settings.availableLanguages.map(al => ({ text: this.$t(al), value: al, }))
        if (!this.languages?.some(l => l.value === locale)) {
          // Fall back to the default language
          localStorage.setItem("languageMissing", this.$t('error.language_missing', { n: this.$t(`lang.${locale}`) }))
          loadLanguageAsync(this.languages[0].value)
        } else {
          this.languageMissing()
        }
      } else if (locale !== 'fi') {
        // Fall back to the default language (fi)
        localStorage.setItem("languageMissing", this.$t('error.language_missing', { n: this.$t(`lang.${locale}`) }))
        loadLanguageAsync('fi')
      } else {
        this.languageMissing()
      }
    },
    languageMissing () {
      // Error message for missing language
      if (localStorage.getItem("languageMissing")) {
        this.$store.dispatch('error/addError', localStorage.getItem("languageMissing"))
        localStorage.removeItem("languageMissing");
      }
    },
    changeLanguage (locale) {
      loadLanguageAsync(locale)
    },
    async loadDefaultFilters () {
      const defaultFilters = await this.$rambollfmapi.siteFilters.list()
      if (defaultFilters.length > 0) {
        const filters = []
        defaultFilters.forEach(f => {
          filters[f.filter_type] = f.filter_values.map(value => { return { id: f.is_id ? parseInt(value) : value }})
        })
        this.applyFilters(filters, true)
      }
    },
    async loadSitesEstatesAndBuildings () {

      let sitesQuery = {}
      let estatesQuery = {}
      let buildingsQuery = {}
      let filteredIds = []

      if (this.currentDate) {
        sitesQuery = { time: new Date(this.currentDate) }
        estatesQuery = { time: new Date(this.currentDate) }
        buildingsQuery = { time: new Date(this.currentDate) }
      }

      if ((this.$store.state.app.filterValues && this.$store.state.app.filterValues.length > 0) || this.siteFiltersLength > 0) {
        // get filter values
        if (this.siteFiltersLength > 0 && !this.$store.state.app.filterValues) {
          await this.$store.dispatch('app/getFilterValues')
        }
        // filter sites, get ids
        await this.getFilteredSites()

        filteredIds = this.siteIds.sites
      }
      // fetch sites with filtered ids
      if (this.siteIds.sites.length > 0 && this.siteIds.sites.length < this.siteLimitForFetchingAllSites) {
        await this.getSites({ query: sitesQuery , body: filteredIds })
        await this.filterSites()
        await Promise.all([
          this.getEstates({ query: estatesQuery, body: filteredIds }),
          this.getBuildings({ query: buildingsQuery , body: filteredIds }),
        ])
      }

      await this.$store.dispatch('app/getRecursiveBuildings')
    },
    openModal (param){
      switch (param) {
        case "add_site":
          this.showAddSiteDialog = true
          break
        case "add_rental":
          this.showNewRentalProcessModal = true
          break
        case "add_alert":
          this.showAddAlertDialog = true
          break
        default:
      }
    },
    // open filter view and load filter values when the site number is large, otherwise use site data
    async openFilters ()
    {
      this.filtersLoading = true
      if (this.$store.state.app.filterValues === undefined && this.siteIds.sites.length >= this.siteLimitForFetchingAllSites)
      {
        await this.$store.dispatch('app/getFilterValues')
      }
      this.showFilters = true
      this.filtersLoading = false
    },
    getTabHeader (id) {
      const found = this.$store.state.sites.loadedSites.find(x => x.id_site === id)
      if (found === undefined) {
        return '-'
      }
      return found.name
    },
    async handleGisClick () {
      let url = await this.openGis()
      window.open(url, '_blank')
    },
    async openGis () {
      let token = await this.getUserRefreshToken()
      let link = process.env.VUE_APP_RAMBOLLFM_GIS_ENDPOINT

      return link + token
    },
    async getUserRefreshToken () {
     const result = await this.$rambollfmapi.accounts.userRefreshToken()
     const dbRefreshToken = result.token

     return dbRefreshToken
    },
    openRentalModal (type) {
      switch (type) {
        case 'new_prospect':
          this.showAddProspectDialog = true
          return
        case 'new_rental':
          this.showNewRentalProcessModal = true
          return
        default:
          return
      }
    },
    async deleteVektorioToken () {
      try {
        if (VueCookies.isKey('v3ViewerToken')) {
          const v3ViewerToken = VueCookies.get('v3ViewerToken')
          if (v3ViewerToken) {
            await this.$rambollfmapi.accounts.vektorio.session.delete(v3ViewerToken)
          }
          VueCookies.remove('v3ViewerToken')
        }
      } catch (error) {
        this.$log.error(error)
      }
    },
  }
}
</script>

<style>
.button {
  background-color: var(--c-color-accent) !important;
  color: var(--r-color-white) !important;
  border-radius: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
}

div.activeFilters {
  color: var(--c-color-accent) !important;
  z-index: 99;
}

.date-picker {
  display: flex;
  flex-direction: column;
  background-color: var(--r-color-white);
}

i.activeFilters {
  position: absolute !important;
  color: white !important;
}
button.v-btn.v-btn--outlined.activeFilters {
  background-color: var(--c-color-accent) !important;
}

.right {
  border-radius: 20px 20px 0 0 !important;
  border: 1px solid lightgrey;
  margin: 0px;
  white-space: initial;
  background-color: #f5f5f5 !important;
}

.navigation {
  background-color: var(--c-color-toolbar-bg);
  border-bottom: 1px solid var(--c-color-toolbar-border);
}

.active {
  border-bottom: 0px;
  background: white !important;
  color: black !important;
  bottom: -1px;
}

.dashboard {
  background-color: white !important;
  overflow: auto;
}

.site {
  background-color: white !important;
  justify-content: flex-end;
}

.toolbar .v-btn {
  margin: var(--r-spacing-xs) var(--r-spacing-xxs) !important;
}

.navigation .v-btn--active:before {
  background-color: white;
}

.siteButton {
  background: #ececec !important;
  color: #7a7a7a !important;
}

.siteButton:disabled {
  background: #ececec !important;
  color: #7a7a7a !important;
}

.activeButton {
  background: var(--c-color-accent) !important;
  color: white !important;
}

.upperCorner {
  padding-bottom: 29px;
}

.theme--light.v-btn.selected {
  background: var(--c-color-accent) !important;
  color: white !important;
}

/* Navigation buttons are tabs */
.navigation > .col .v-btn {
  border-radius: var(--c-radius-m) var(--c-radius-m) 0 0 !important;
  border: 1px solid var(--c-color-toolbar-border);
  border-bottom: 0;
  margin: 0px;
  background: var(--c-color-toolbar-bg) !important;
  margin-left: var(--r-spacing-xxs);
  text-transform: capitalize;
}
.navigation > .col {
  margin-left: var(--r-spacing-m);
}
.navigation > .col > .col {
  margin-left: var(--r-spacing-xxs);
}
.navigation > .col .v-btn.v-btn--active {
  border-bottom: 0px;
  background: white !important;
  color: black !important;
  bottom: -1px;
}

.navigation > .col > .row .v-btn.v-btn--active + .v-btn {
  border-bottom: 0px;
  background: white !important;
  color: black !important;
  bottom: -1px;
}

.navigation > .col > .row .v-btn:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
}

.navigation > .col > .row .v-btn:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
  margin-left: 0;
}

.button-list .col {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.sitesbar {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

.userButton {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
.userButton:hover {
  outline: 4px solid var(--r-color-pebble-60);
}
.userButton__title {
  font-size: var(--r-font-size-m);
  margin-right: var(--r-spacing-xxxs);
}
.userButton__subtitle {
  font-size: var(--r-font-size-s);
  color: var(--r-color-grey-80);
}
</style>
