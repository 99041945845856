import RambollFMAPI from '../api/rambollfm'
import store from '../store/store'
import { i18n } from '../plugins/i18n'
import lodash from 'lodash'

export default {
  namespaced: true,
  state: {
    costcenters: [],
    expenses: [],
    portfolios: [],
    portfolio: null,
    purposesOfUse: [],
    rules: [],
    apiEndpoint: null,
    apiToken: null,
    loadedReports: {},
    widgets: [],
    inspectionMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    inspectionMonthValues: ['month_1', 'month_2', 'month_3', 'month_4', 'month_5', 'month_6', 'month_7', 'month_8', 'month_9', 'month_10', 'month_11', 'month_12' ],
    allocationMonth: null,
    inspectionYear: null,
    selectedView: null,
    targetingCodes: [],
    lastReport: null,
    targetingCodesForMonths: []
  },
  getters: {
    getAllPortfolios: state => {
      return state.portfolios
    },
    getSortedPortfolios: state => {
      return state.portfolios.slice().sort((a, b) => { return (a.name < b.name) ? -1 : 1 })
    },
    getAllExpenses: state => {
      return state.expenses
    },
    getAvailablePurposesOfUse: state => {
      return state.purposesOfUse.filter(purpose => purpose.selectable)
    },
    getReport: (state) => (reportName) => {
      return state.loadedReports[reportName]
    },
    getAllTargetingCodes: state => {
      return state.targetingCodes
    }
  },
  mutations: {
    setPortfolios (state, portfolios) {
      state.portfolios = portfolios
    },
    setPortfolio (state, portfolio) {
      state.portfolio = portfolio
    },
    setNewPortfolio (state, portfolio) {
      state.portfolios = [...state.portfolios, portfolio]
    },
    setExpenses (state, expenses) {
      state.expenses = expenses
    },
    setCostcenters (state, costcenters) {
      state.costcenters = costcenters
    },
    setRules (state, rules) {
      state.rules = rules
    },
    setPurposes (state, purposes) {
      state.purposesOfUse = purposes
    },
    updatePurpose (state, params) {
      const { purposeId, data } = params
      state.purposesOfUse = state.purposesOfUse.map(purpose => {
        return purpose.id === purposeId ? { ...purpose, ...data } : purpose
      })
    },
    setReports (state, reports) {
      state.loadedReports = reports
    },
    setWidgetData (state, data) {
      state.widgets = data
    },
    setInspectionMonths (state, months) {
      state.inspectionMonths = months
    },
    setAllocationMonth (state, month) {
      state.allocationMonth = month
    },
    setInspectionYear (state, year) {
      state.inspectionYear = year
    },
    setSelectedView (state, view) {
      state.selectedView = view
    },
    setTargetingCodes (state, codes) {
      state.targetingCodes = codes
    },
    setLastReport (state, value) {
      state.lastReport = value
    },
    setTargetingCodesForSelectedMonths (state, codes) {
      state.targetingCodesForMonths = codes
    }
  },
  actions: {
    async getPortfolios ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const portfolios = await api.internalrent.portfolios().list()
      commit('setPortfolios', portfolios)
    },
    async getPortfolio ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const portfolio = await api.internalrent.portfolios().get(params)
      commit('setPortfolio', portfolio)
    },
    async saveNewPortfolio ({ commit, state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const portfolio = await api.internalrent.portfolios().post(params)
      commit('setNewPortfolio', portfolio)
    },
    async deletePortfolio ({ state }, idPortfolio) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.internalrent.portfolios().delete(idPortfolio)
      return result
    },
    async saveNewNameOrRentType ({ state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.portfolios().patch(params)
    },
    async getExpenses ({ commit, state }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const expenses = await api.internalrent.expenses().list()
      commit('setExpenses', expenses)
    },
    async saveNewExpense ({ state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.expenses().post(params)
    },
    async deleteExpense ({ state }, expenseId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const result = await api.internalrent.expenses().delete(expenseId)
      return result
    },
    async updateExpense ({ state }, params) {
      const { expenseId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.expenses().patch(expenseId, data)
    },
    async updateBudgetsForYear ({ state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.portfolios().updateBudget(params)
    },
    async setMonthExpensesForYear ({ state }, params) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.portfolios().monthExpensesForYear(params)
    },
    async getCostcenters ({ commit, state, rootState }, portfolioId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const costcenters = await api.internalrent
        .portfolios()
        .costcenters(portfolioId, rootState.app.currentDate)
      commit('setCostcenters', costcenters)
    },
    async getRules ({ commit, state }, portfolioId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const rules = await api.internalrent
        .portfolios()
        .rules(portfolioId)
        .list()
      commit('setRules', rules)
    },
    async updateRules ({ commit, state }, params) {
      const { portfolioId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent
        .portfolios()
        .rules(portfolioId)
        .put(data)
      commit('setRules', data)
    },
    async getPurposes ({ commit, state }, all) {
      const allpurposes = all !== undefined ? all : true
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const purposes = await api.internalrent.purposesofuse().list(allpurposes)
      commit('setPurposes', purposes)
    },
    async updatePurposes ({ commit, state }, params) {
      const { purposeId, data } = params
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      await api.internalrent.purposesofuse().patch(purposeId, data)
      commit('updatePurpose', params)
    },
    async getTargetingCodes ({ commit, state}, idOrganization) {
      const requestDate = new Date(Date.UTC(state.inspectionYear, state.allocationMonth, 1))
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const codes = await api.internalrent.allocations.targetingCodes.get(idOrganization, requestDate)
      commit('setTargetingCodes', codes)
    },
    async getTargetingCodesForSelectedMonths ({ commit, state }, params) {
      const requestDate = new Date(Date.UTC(state.inspectionYear + 1, state.allocationMonth, 1))
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const codes = await api.internalrent.allocations.targetingCodes.months.post(params.idOrganization, params.portfolioId, requestDate)
      commit('setTargetingCodesForSelectedMonths', codes)
    },
    async loadReports ({ commit, rootState, state }, filteredPortfolios) {
      // We need to know which report is used for which widget
      const requiredReports = {
        'internalrent_dashboard.areas_by_business_units': ['CostcentersBySpace'],
        'internalrent_dashboard.costcenters_total_by_portfolios': ['CostcentersBySpace'],
        'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses': ['CostcentersBySpace'],
        'internalrent_dashboard.domains_total_by_portfolios': ['CostcentersBySpace'],
        'internalrent_dashboard.costcenters_by_expense_type': ['CostcentersBySpace'],
        'internalrent_portfolio.expenses_by_space': ['ExpensesBySpace'],
        'internalrent_dashboard.data_integration_01': ['CostcentersBySpace', 'IntegrationData01'],
        'internalrent_dashboard.data_integration_02': ['CostcentersBySpace', 'IntegrationData02'],
        'internalrent_dashboard.data_integration_03': ['CostcentersBySpace'],
        'internalrent_dashboard.budget_per_cost_type': ['CostcentersBySpace', 'PortfoliosWithBudgets'],
        'internalrent_dashboard.costcenters_total_by_portfolios_realization': ['CostcentersRealizationBySpace'],
        'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses_realization': ['CostcentersRealizationBySpace'],
        'internalrent_dashboard.costcenters_by_expense_type_realization': ['CostcentersRealizationBySpace'],
        'internalrent_portfolio.expenses_by_space_realization': ['ExpensesRealizationBySpace'],
        'internalrent_dashboard.budget_and_realisation_per_cost_type' : ['CostcentersBySpace', 'PortfoliosWithBudgets'],
        'internalrent_dashboard.swarmed_expenses': ['SwarmedExpenses']
      }


      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = new Date(rootState.app.currentDate.getTime())
      const selectedDate = state.inspectionMonths.length > 0 ? new Date(currentDate.setFullYear(state.inspectionYear, state.inspectionMonths[state.inspectionMonths.length - 1], 0)) : currentDate

      const userWidgets = rootState.app.userWidgets.filter(w => w.name.startsWith('internalrent'))
      commit('setWidgetData', userWidgets)

      const allReports = userWidgets.map(w => w.name).reduce((acc, cur) => {
        if (requiredReports[cur]) {
          acc.push(...requiredReports[cur])
        }
        return acc
      }, []).filter((val, idx, arr) => arr.indexOf(val) === idx)

      const reportQuery = {
        time: selectedDate,
        reports: allReports,
        portfolioIds: filteredPortfolios,
        months: state.inspectionMonths,
        year: state.inspectionYear
      }

      commit('setLastReport', reportQuery)

      const reports = await api.internalrent.reports(reportQuery)

      //At this point state holds the time of most recent report
      //If it is not the time used in the returning request, the results are ignored
      if(!(state.lastReport.time == reportQuery.time)) {
        return
      }

      // If a report has null value then there is some problem with the data received frm the API.
      Object.keys(reports).forEach(key => {
        if (reports[key] === null) {
          store.dispatch('error/addError', 'err.report_no_data')
          reports[key] = []
        }
      })

      const reportsToCommit = {}
      for (const w of userWidgets) {
        // We do special handling of data here
        try {
          const report = lodash.cloneDeep(reports)
          if (w.name === 'internalrent_dashboard.areas_by_business_units') {
            const { CostcentersBySpace } = report
            reportsToCommit.BusinessUnitAreas = BusinessUnitAreas(CostcentersBySpace)
          } else if (w.name === 'internalrent_dashboard.costcenters_total_by_portfolios') {
            const { CostcentersBySpace } = report
            reportsToCommit.TotalCostcenters = TotalCostcenters(CostcentersBySpace)
          } else if (w.name === 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses') {
            const { CostcentersBySpace } = report
            reportsToCommit.TotalCostcentersWithExpenses = TotalCostcentersWithExpenses(CostcentersBySpace)
          } else if (w.name === 'internalrent_dashboard.domains_total_by_portfolios') {
            const { CostcentersBySpace } = report
            reportsToCommit.TotalDomains = TotalDomains(CostcentersBySpace)
          } else if (w.name === 'internalrent_dashboard.costcenters_by_expense_type') {
            const { CostcentersBySpace } = report
            reportsToCommit.CCByExpenseType = CCByExpenseType(CostcentersBySpace)
          } else if (w.name === 'internalrent_portfolio.expenses_by_space') {
            const { ExpensesBySpace } = report
            reportsToCommit.ExpensesBySpace = getExpensesBySpace(ExpensesBySpace)
          } else if (w.name === 'internalrent_dashboard.data_integration_01') {
            const { IntegrationData01, CostcentersBySpace } = report
            reportsToCommit.AtoIntegration = AtoIntegration(IntegrationData01, CostcentersBySpace, state.expenses, selectedDate)
          } else if (w.name === 'internalrent_dashboard.data_integration_02') {
            const { IntegrationData02 } = report
            reportsToCommit.KnaIntegration = KnaIntegration(IntegrationData02)
          } else if (w.name === 'internalrent_dashboard.data_integration_03') {
            const { CostcentersBySpace } = report
            reportsToCommit.TkuIntegration = TkuIntegration(CostcentersBySpace, state.expenses, state.inspectionYear, state.inspectionMonths)
          } else if (w.name === 'internalrent_dashboard.budget_per_cost_type' || w.name === 'internalrent_dashboard.budget_and_realisation_per_cost_type') {
            const { PortfoliosWithBudgets } = report
            reportsToCommit.budgetPerCostType = budgetPerCostType(PortfoliosWithBudgets, state.expenses.filter(e => e.selectable === true), selectedDate.getFullYear())
          } else if (w.name === 'internalrent_dashboard.costcenters_total_by_portfolios_realization') {
            const { CostcentersRealizationBySpace } = report
            reportsToCommit.TotalCostcentersRealization = TotalCostcenters(CostcentersRealizationBySpace)
          } else if (w.name === 'internalrent_dashboard.costcenters_total_by_portfolios_with_expenses_realization') {
            const { CostcentersRealizationBySpace } = report
            reportsToCommit.TotalCostcentersWithExpensesRealization= TotalCostcentersWithExpenses(CostcentersRealizationBySpace)
          } else if (w.name === 'internalrent_dashboard.costcenters_by_expense_type_realization') {
            const { CostcentersRealizationBySpace } = report
            reportsToCommit.CCByExpenseTypeRealization= CCByExpenseType(CostcentersRealizationBySpace)
          } else if (w.name === 'internalrent_portfolio.expenses_by_space_realization') {
            const { ExpensesRealizationBySpace } = report
            reportsToCommit.ExpensesRealizationBySpace = getExpensesBySpace(ExpensesRealizationBySpace)
          } else if (w.name === 'internalrent_dashboard.swarmed_expenses') {
            const { SwarmedExpenses } = report
            reportsToCommit.SwarmedExpenses = SwarmedExpenses
          }
        } catch (error) {
          store.dispatch('error/addError', 'err.report_data_handling_failed')
        }
      }
      commit('setReports', reportsToCommit)
    }
  }
}

function BusinessUnitAreas (data) {
  return data.map(x => {
    return {
      domain: x.businessUnitCode + ' - ' + x.businessUnitName,
      area: x.allocatedArea
    }
  })
}

function TotalCostcenters (data) {
  return Object.values(
    data.reduce((acc, cur) => {
      const accessor = cur.idPortfolio + ' - ' + cur.idCostcenter
      if (acc[accessor]) {
        acc[accessor].shareFromArea += cur.shareFromArea
        acc[accessor].allocatedArea += cur.allocatedArea
        acc[accessor].capitalRent += cur.capitalRent
        acc[accessor].maintenanceRent += cur.maintenanceRent
        acc[accessor].totalRent += cur.totalRent
      } else {
        acc[accessor] = JSON.parse(JSON.stringify(cur))
        acc[accessor].id = accessor
        acc[accessor].costcenterName =
          acc[accessor].costcenterCode +
          ' - ' +
          acc[accessor].costcenterName
      }
      return acc
    }, {})
  )
}

function TotalCostcentersWithExpenses (data) {
  var totalAllocatedAreaForPortfolios = data.reduce((acc, cur) => {
    const accessor = cur.idPortfolio
    if (acc[accessor]) {
      acc[accessor].area += cur.allocatedArea
    } else {
      acc[accessor] = {}
      acc[accessor].area = cur.allocatedArea
    }
    return acc}, {}
  )
  return Object.values(
    data.reduce((acc, cur) => {
      const accessor = cur.idPortfolio + ' - ' + cur.idCostcenter
      if (acc[accessor]) {
        acc[accessor].shareFromArea += cur.shareFromArea
        acc[accessor].allocatedArea += cur.allocatedArea
        acc[accessor].capitalRent += cur.capitalRent
        acc[accessor].maintenanceRent += cur.maintenanceRent
        acc[accessor].share_percentage = (acc[accessor].allocatedArea / totalAllocatedAreaForPortfolios[acc[accessor].idPortfolio].area) * 100
        acc[accessor].totalRent += cur.totalRent
        for (const expense of cur.expenses) {
          acc[accessor][String(expense.idExpense) + 'Rent'] += expense.amount
        }
      } else {
        acc[accessor] = JSON.parse(JSON.stringify(cur))
        acc[accessor].id = accessor
        acc[accessor].costcenterName =
          acc[accessor].costcenterCode +
          ' - ' +
          acc[accessor].costcenterName
        acc[accessor].share_percentage = (acc[accessor].allocatedArea / totalAllocatedAreaForPortfolios[acc[accessor].idPortfolio].area) * 100
        for (const expense of cur.expenses) {
          if (acc[accessor][String(expense.idExpense) + 'Rent']) {
            acc[accessor][String(expense.idExpense) + 'Rent'] += expense.amount
          } else {
            acc[accessor][String(expense.idExpense) + 'Rent'] = expense.amount
          }
        }
      }
      return acc
    }, {})
  )
}

function TotalDomains (reportData) {
  const data = reportData.reduce((acc, cur) => {
    const accessor = cur.idPortfolio + '-' + cur.domainCode
    if (acc[accessor]) {
      acc[accessor].shareFromArea += cur.shareFromArea
      acc[accessor].allocatedArea += cur.allocatedArea
      acc[accessor].capitalRent += cur.capitalRent
      acc[accessor].maintenanceRent += cur.maintenanceRent
      acc[accessor].capitalRentMonth += cur.capitalRentMonth
      acc[accessor].maintenanceRentMonth += cur.maintenanceRentMonth
      acc[accessor].totalRentByMonth += cur.totalRentByMonth
    } else {
      acc[accessor] = JSON.parse(JSON.stringify(cur))
      acc[accessor].businessUnitName =
        acc[accessor].businessUnitCode +
        ' - ' +
        acc[accessor].businessUnitName
      acc[accessor].domainName =
        acc[accessor].domainCode + ' - ' + acc[accessor].domainName
    }
    return acc
  }, {})
  return Object.values(data)
}

function CCByExpenseType (data) {
  return data.map(x => {
    const expenses = x.expenses
    for (const expense of expenses) {
      if (x[String(expense.idExpense) + 'Rent']) {
        x[String(expense.idExpense) + 'Rent'] += expense.amount
      } else {
        x[String(expense.idExpense) + 'Rent'] = expense.amount
      }
    }
    x.domain = x.domainCode + ' - ' + x.domainName
    x.businessUnit = x.businessUnitCode + ' - ' + x.businessUnitName
    return x
  })
}

function getExpensesBySpace (data) {
  return data.map(x => {
    const expenses = x.expenses
    for (const expense of expenses) {
      x[String(expense.idExpense) + 'Rent'] = expense.amount
    }
    return x
  })
}

function AtoIntegration (IntegrationData, CostcentersBySpace, expenses, date) {
  const maintenanceName = 'Ylläpitovuokra'
  const capitalName = 'Pääomavuokra'
  const userServicesName = 'Käyttäjäpalvelut'
  const maintenanceId = expenses.find(
    x => x.name === maintenanceName
  )
    ? expenses.find(x => x.name === maintenanceName).id
    : 0
  const capitalId = expenses.find(x => x.name === capitalName)
    ? expenses.find(x => x.name === capitalName).id
    : 0
  const userServicesId = expenses.find(
    x => x.name === userServicesName
  )
    ? expenses.find(x => x.name === userServicesName).id
    : 0

  const costsByCostcenter = {}

  for (const cost of CostcentersBySpace) {
    let maintenanceCost = cost.expenses.find(
      x => x.idExpense === maintenanceId
    )
    let capitalCost = cost.expenses.find(x => x.idExpense === capitalId)
    maintenanceCost = maintenanceCost ? maintenanceCost.amount : 0
    capitalCost = capitalCost ? capitalCost.amount : 0

    let userServicesCost = cost.expenses.find(
      x => x.idExpense === userServicesId
    )
    userServicesCost = userServicesCost ? userServicesCost.amount : 0

    if (!costsByCostcenter[cost.idCostcenter]) {
      costsByCostcenter[cost.idCostcenter] = {}
      costsByCostcenter[cost.idCostcenter][maintenanceId] = 0
      costsByCostcenter[cost.idCostcenter][userServicesId] = 0
      costsByCostcenter[cost.idCostcenter][capitalId] = 0
      costsByCostcenter[cost.idCostcenter].purposeAdditionalCost = 0
      costsByCostcenter[cost.idCostcenter].additionalCost = 0
    }

    costsByCostcenter[cost.idCostcenter][maintenanceId] +=
      maintenanceCost

    costsByCostcenter[cost.idCostcenter][capitalId] += capitalCost

    costsByCostcenter[cost.idCostcenter][
      userServicesId
    ] += userServicesCost

    costsByCostcenter[cost.idCostcenter].purposeAdditionalCost +=
      cost.additionalCostForPurpose

    costsByCostcenter[cost.idCostcenter].additionalCost +=
      cost.additionalCost
  }

  const descriptionDate = ' ' + i18n.t(date.toLocaleString('default', { month: 'long' })) + ' ' + date.getFullYear()

  let result = IntegrationData
  for (const obj of result) {
    
    if (obj.type === 1) {
      if (costsByCostcenter[obj.id]) {
        obj.amount =
          costsByCostcenter[obj.id][maintenanceId] +
          costsByCostcenter[obj.id][capitalId] +
          costsByCostcenter[obj.id].additionalCost
      }
    } else if (obj.type === 2) {
      if (costsByCostcenter[obj.id]) {
        obj.amount =
          costsByCostcenter[obj.id][userServicesId] +
          costsByCostcenter[obj.id].purposeAdditionalCost
      }
    } else if (obj.type === 3) {
      if (costsByCostcenter[obj.id]) {
        obj.amount =
          0 -
          (costsByCostcenter[obj.id][maintenanceId] +
            costsByCostcenter[obj.id][capitalId] +
            costsByCostcenter[obj.id].additionalCost)
      }
    } else if (obj.type === 4) {
      if (costsByCostcenter[obj.id]) {
        obj.amount =
          0 -
          (costsByCostcenter[obj.id][userServicesId] +
            costsByCostcenter[obj.id].purposeAdditionalCost)
      }
    }
    obj.euroAmount = obj.amount

  }

  const typeDescriptions = {
    1: i18n.t('Rent'),
    2: i18n.t('User serv.'),
    3: i18n.t('Rent'),
    4: i18n.t('User serv.')
  }

    result = result.reduce((acc, cur) => {
    const accessor =
      cur.type +
      '-' +
      cur.account +
      '-' +
      cur.project+
      '-' +
      cur.costcenter
    if (acc[accessor]) {
      acc[accessor].amount += cur.amount
      acc[accessor].euroAmount += cur.euroAmount
    } else {
      acc[accessor] = JSON.parse(JSON.stringify(cur))
      acc[accessor].id = accessor
      acc[accessor].description =
        typeDescriptions[acc[accessor].type] + descriptionDate
    }

    return acc
  }, {})

  return Object.values(result).filter(x => x.amount !== 0)
}

function KnaIntegration (data) {
  let result = data
  for (const value of result) {

    let overallSum = value.firstSum + value.secondSum + value.upkeepExpense + value.estateUpkeepExpense

    value.euroAmountByMonth = overallSum / 12
  }
  return Object.values(result)
}

function TkuIntegration (data, expenses, inspectionYear, inspectionMonths) {
  const pattern = /\d+\s+/g
  const debitRows = data.reduce((total, d) => {
    d.expenses.map(e => {
      const expense = expenses.find(expense => expense.id === e.idExpense)

      if(expense && expense.name) {
        const match = expense.name.match(pattern)
        const accounting = match[0].trim()
        const newDebitRow = { 
          kirjanpidontili: accounting,
          tulosyksikkö: '',
          kustannuspaikka: '',
          sisäinentilaus: '',
          projekti: '',
          toimintaalue: '',
          alvkoodi: '',
          euro: 0,
          debCre: 'D',
          verokanta: '',
          rakennus: d.buildingName,
          hyväksyjä: '',
          trtyyppi: '',
          kumppani: ''
        }
        // Cost center allocation by cost centers length
        if(d.costcenterCode && d.costcenterCode.length <= 7) {
          newDebitRow.kustannuspaikka = d.costcenterCode
        }
        else if(d.costcenterCode && d.costcenterCode.length == 8) {
          newDebitRow.sisäinentilaus = d.costcenterCode
        }
        else if(d.costcenterCode && d.costcenterCode.length == 10) {
          newDebitRow.projekti = d.costcenterCode
        }

        // Set charging coster center if exists
        if(d.ccChargeCostcenter) {
          if (d.ccChargeCostcenter.length <= 7) {
            newDebitRow.kustannuspaikka = d.ccChargeCostcenter
            // clear values possibly set in previous state at "Cost center allocation by cost centers length"
            newDebitRow.sisäinentilaus = ''
            newDebitRow.projekti = ''
          } else if (d.ccChargeCostcenter.length == 8) {
            newDebitRow.sisäinentilaus = d.ccChargeCostcenter
            // clear values possibly set in previous state at "Cost center allocation by cost centers length"
            newDebitRow.kustannuspaikka = ''
            newDebitRow.projekti = ''
          } else if (d.ccChargeCostcenter.length == 10) {
            newDebitRow.projekti = d.ccChargeCostcenter
            // clear values possibly set in previous state at "Cost center allocation by cost centers length"
            newDebitRow.kustannuspaikka = ''
            newDebitRow.sisäinentilaus = ''
          }
        }
        
        // VAT code and VAT appending by expense name VAT class
        if (expense.name.includes('alv25,5')) {
          newDebitRow.alvkoodi = d.ccVatChargeAccount
          newDebitRow.euro = e.amount * 1.255
        } else if (expense.name.includes('alv24')) {
          newDebitRow.alvkoodi = d.ccVatChargeAccount
          newDebitRow.euro = e.amount * 1.24
        } else if (expense.name.includes('alv10')) {
          newDebitRow.alvkoodi = d.ccVatChargeCode
          newDebitRow.euro = e.amount * 1.10
        } else if(expense.name.includes('alv0')) {
          newDebitRow.alvkoodi = d.ccVatRefundAccount
          newDebitRow.euro = e.amount
        }

        if(newDebitRow.euro != 0) {
          total.push(newDebitRow)
        }
        
      }  
    })
    return total
  }, [])
  const combinedDebitRows = debitRows.reduce((total, row) => {
        const existing = total.find(r => r.rakennus === row.rakennus && r.kirjanpidontili === row.kirjanpidontili && r.alvkoodi === row.alvkoodi
          && r.kustannuspaikka === row.kustannuspaikka && r.sisäinentilaus === row.sisäinentilaus && r.projekti === row.projekti)
          
        if (!existing) {
          total.push(row)
        } else {
            existing.euro += row.euro
        }
    return total
  }, [])

  const creditRows = combinedDebitRows.reduce((total, row) => {
    const newCreditRow = {
      kirjanpidontili: row.kirjanpidontili,
      tulosyksikkö: '',
      kustannuspaikka: '2601510',
      sisäinentilaus: '',
      projekti: '',
      toimintaalue: '',
      euro: row.euro,
      debCre: 'K',
      verokanta: '',
      rakennus: '',
      hyväksyjä: '',
      trtyyppi: '',
      kumppani: ''
    }
    // Set VAT code
    switch(row.alvkoodi) {
      case 'L0':
      case '2A':
      case '67':
        newCreditRow.alvkoodi = 'L0'
        break
      case '20':
      case '60':
        newCreditRow.alvkoodi = '60'
        break
      default:
        newCreditRow.alvkoodi = 'LB'
        break
    }
    const monthsString = inspectionMonths.join(',')
    const creditInformation = `Vuokralasku ${monthsString}/${inspectionYear} kredit`
    newCreditRow.rakennus = creditInformation
    total.push(newCreditRow)
    return total
  }, [])
  
  const combinedCreditRows = creditRows.reduce((total, cr) => {
    const existingCreditRow = total.find(creditRow => creditRow.kirjanpidontili === cr.kirjanpidontili && creditRow.alvkoodi === cr.alvkoodi)
    
    if(existingCreditRow) {
      existingCreditRow.euro += cr.euro
    } else {
      total.push(cr)
    }
    return total
  }, [])
  const widgetRows = combinedDebitRows.concat(combinedCreditRows)

  return widgetRows
}

function budgetPerCostType (data, expenses, inspectionYear) {
  return expenses.map((expense, idx) => {
      const id = idx + 1
      const expenseId = expense.id
      const name = expense.name

      const expenseYears = {
        previousYear: inspectionYear - 2,
        lastYear: inspectionYear - 1,
        thisYear: inspectionYear,
        nextYear: inspectionYear + 1
      }

      let thisYearBudget = 0
      let thisYearRealisation = 0
      let nextYearBudget = 0

      let area = 0

      data.forEach(portfolio => {
        thisYearBudget += getValueWithIdAndYear(
          expense.id,
          portfolio.monthExpenseBudgets,
          expenseYears.thisYear
        )[0].amount

        thisYearRealisation += getValueWithIdAndYear(
          expense.id,
          portfolio.yearExpenseRealizations,
          expenseYears.thisYear
        )[0].amount

        nextYearBudget += getValueWithIdAndYear(
          expense.id,
          portfolio.monthExpenseBudgets,
          expenseYears.nextYear
        )[0].amount

        portfolio.monthExpenseBudgets.forEach(b => {
          if (b.idExpense === expenseId) {
            area += portfolio.totalPortfolioArea
          }
        })
      })
      const budgetPerArea = thisYearBudget != 0 ? parseFloat(thisYearBudget/area).toFixed(2) : 0

      return {
        id,
        expenseId,
        name,
        thisYearBudget,
        thisYearRealisation,
        nextYearBudget,
        budgetPerArea,
        inspectionYear
      }
  })
}

function getValueWithIdAndYear (id, budgets, year) {
  const expense = budgets.filter(budget => {
    if (budget.idExpense === id && budget.year === year) {
      return true
    }
    return false
  })
  return expense.length ? expense : [{ amount: 0 }]
}

