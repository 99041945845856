import RambollFMAPI from "../api/rambollfm"

export default {
  namespaced: true,
  state: {
    deviceLoading: false,
    devices: [],
    deviceToModify: null,
    showAddDeviceDialog: false,
  },
  getters: {
    getDeviceById: (state) => (id) => {
      return state.devices.find(device => device.id_device === id)
    },
  },
  mutations: {
    setDevices (state, data) {
      state.devices = data
    },
    changeDeviceLoading (state, data) {
      state.deviceLoading = data
    },
    addSiteDevice (state, data) {
      state.devices.push(data)
    },
    removeDevice (state, deviceId) {
      state.devices = state.devices.filter(d => d.id_device !== deviceId)
    },
    setAddDeviceDialog (state, data) {
      state.showAddDeviceDialog = data
    },
    setDeviceToModify (state, deviceId) {
      state.deviceToModify = state.devices.find(d => d.id_device === deviceId)
    },
  },
  actions: {
    async loadDevices ({ commit, state, rootState }, siteId, spaceId) {
      commit('changeDeviceLoading', true)
      const currentDate = rootState.app.currentDate
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)

      const devices = await api.devices.get(siteId, spaceId, currentDate)
      commit('setDevices', devices)
      commit('changeDeviceLoading', false)
    },
    async endDevice ({ commit, state, rootState }, deviceId) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      api.devices.delete(deviceId, currentDate)
        .then(res => {
          if (res.status && res.status === 200) {
            commit('removeDevice', deviceId)
          }
        })
    },
    async addDevice ({ commit, state, rootState }, device) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      await api.devices.post(device, currentDate)
        .then((res) => {
          if (!res.status) {
            commit('addSiteDevice', res)
          }
        })
    },
    async patchDevice ({ commit, state, rootState }, { deviceId, devicePatch, updatedDevice }) {
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      await api.devices.patch(deviceId, currentDate, devicePatch)
        .then(res => {
          if (updatedDevice && res.status && res.status === 204) {
            commit('setDevices', state.devices.map(d => d.id_device === deviceId ? updatedDevice : d))
          }
        })
    },
    async updateDeviceConnections ({ commit, state, rootState }, { deviceId, connections }) {
      connections = connections.map(connection => {
        return {
          id_device: deviceId,
          ...connection
        }
      })
      const api = new RambollFMAPI(state.apiEndpoint, state.apiToken)
      const currentDate = rootState.app.currentDate

      await api.devices.connections(deviceId).update(currentDate, connections)
        .then(res => {
          if (res.status && res.status === 204) {
            commit('setDevices', state.devices.map(d => d.id_device === deviceId ? { ...d, connections } : d))
          }
        })
    },
    openAddDeviceDialog ({ commit }, deviceId) {
      if (deviceId) {
        commit('setDeviceToModify', deviceId)
      }
      commit('setAddDeviceDialog', true)
    },
    closeAddDeviceDialog ({ commit }) {
      commit('setDeviceToModify', null)
      commit('setAddDeviceDialog', false)
    },
  },
}