<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('create_rent_contract.add_separate_payment') }}
    </template>
    <template #content>
      <ValidationObserver>
        <v-row>
          <v-col cols="4">
            <ValidationProvider
              name="isFullContractPayment"
            >
              <v-checkbox
                v-model="isFullContractPayment"
                :label="$t('create_rent_contract.full_contract_payment')"
                :disabled="isLocked"
                @change="onIsFullContractPaymentChanged"
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetUnitsRules"
            >
              <v-autocomplete
                v-model="targetUnits"
                multiple
                :items="unitItems"
                :disabled="isFullContractPayment"
                :error-messages="errors"
                :readonly="isLocked"
                item-text="unitCode"
                item-value="unitId"
                :label="$t('create_rent_contract.units')"
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetStructuresRules"
            >
              <v-autocomplete
                v-model="targetStructures"
                multiple
                :items="structureItems"
                :disabled="isFullContractPayment"
                :readonly="isLocked"
                :error-messages="errors"
                item-text="unitCode"
                item-value="structureId"
                :label="$t('Structures')"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="parkingSpaceItems && parkingSpaceItems.length > 0"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetParkingSpacesRules"
            >
              <v-autocomplete
                v-model="targetParkingSpaces"
                multiple
                :items="parkingSpaceItems"
                :disabled="isFullContractPayment"
                :readonly="isLocked"
                :error-messages="errors"
                item-text="unitCode"
                item-value="parkingSpaceId"
                :label="$t('create_rent_contract.parking_spaces')"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-checkbox
              v-model="isDiscountPayment"
              :label="$t('create_rent_contract.is_discount_payment')"
              :disabled="isLocked"
              @change="onIsDiscountPaymentChanged"
            />
          </v-col>
          <v-col cols="5">
            <DatePicker
              :label="$t('create_rent_contract.payment_start_data')"
              :aria-label="$t('create_rent_contract.payment_start_data') + $t('create_rent_contract.in_the_format') + $t('date_format')"
              :rules="`validStartDate:${startDate},${rentPeriodStartDate},${endDate}${isOutRent ? '|required' : ''}|startDateAfterEarliest:${startDate},${earliestPossibleStartDate}|required`"
              :hint="$t('Required')"
              :clearable="false"
              :immediate="true"
              :disabled="isLocked"
              required
              :initial-date="startDate"
              @dateupdated="updateStartDate"
            />
          </v-col>
          <v-col cols="5">
            <DatePicker
              :label="$t('create_rent_contract.payment_end_data')"
              :aria-label="$t('create_rent_contract.payment_end_data') + $t('create_rent_contract.in_the_format') + $t('date_format')"
              :rules="`validEndDate: ${startDate}, ${endDate}`"
              :hint="$t('date_format')"
              :default-hint="false"
              :initial-date="endDate"
              @dateupdated="updateEndDate"
            />
          </v-col>
        </v-row>
        <div v-if="!isDiscountPayment">
          <h3 class="form-headers">
            {{ $t('create_rent_contract.payment_rent_review') }}
          </h3>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedRentReviewType"
                :items="rentReviewTypes"
                :label="$t('create_rent_contract.payment_rent_review')"
                :readonly="isLocked"
                item-value="id"
              >
                <template #selection="{ item, index }">
                  {{ item.label ? $t(`${item.label}`) : "" }}
                </template>
                <template #item="{item, index}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- Rent review: Index SELECTED -->
          <v-row
            v-if="selectedRentReviewType == definitionByLabel('PaymentRentReviewIndex').id"
            align="center"
          >
            <v-col
              cols="6"
            >
              <DatePicker
                :label="$t('create_rent_contract.rent_review_startdate')"
                :aria-label="$t('create_rent_contract.rent_review_startdate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :rules="`${isOutRent ? 'required' : ''}`"
                :clearable="false"
                :disabled="isLocked"
                :initial-date="rentReviewStartDate"
                @dateupdated="updateRentReviewStartDate"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="indexWatch"
                :label="$t('create_rent_contract.index_affects_rent')"
                :disabled="isLocked"
              />
            </v-col>
            <v-col cols="4">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-text-field
                  v-model="basicIndexYear"
                  type="number"
                  :error-messages="errors[0]"
                  :readonly="isLocked"
                  :label="$t('create_rent_contract.base_index_year')"
                  @change="onBaseIndexChange"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="4">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-select
                  v-model="basicIndexMonth"
                  :items="monthsList"
                  :label="$t('create_rent_contract.base_index_month')"
                  :error-messages="errors[0]"
                  :readonly="isLocked"
                  item-value="id"
                  @change="onBaseIndexChange"
                >
                  <template #selection="{ item, index }">
                    {{ item.label ? $t(`${item.label}`) : "" }}
                  </template>
                  <template #item="{item, index}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="basicIndexPointNumber"
                readonly
                :label="$t('create_rent_contract.base_index_points')"
              />
            </v-col>
            <v-col cols="12">
              <v-row
                v-for="(item, index) in reviewMonths"
                :key="index"
              >
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`${isOutRent ? 'required' : ''}`"
                    :immediate="true"
                  >
                    <v-select
                      v-model="reviewMonths[index]"
                      :items="monthsList"
                      :label="$t('create_rent_contract.index_review_months')"
                      :error-messages="errors[0]"
                      item-text="label"
                      item-value="id"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`${isOutRent ? 'required' : ''}`"
                    :immediate="true"
                  >
                    <v-select
                      v-model="indexMonths[index]"
                      :items="monthsList"
                      :label="$t('create_rent_contract.index_month')"
                      :error-messages="errors[0]"
                      item-text="label"
                      item-value="id"
                    />
                  </ValidationProvider> 
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-tooltip 
                bottom 
                color="rgba(255,0,0,0.6)"
                :disabled="reviewMonths.length > 0 || !isOutRent"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    text
                    rounded
                    outlined
                    depressed
                    :class="`${reviewMonths.length == 0 && isOutRent ? 'red-button' : 'blue-button'}`"
                    v-bind="attrs"
                    @click="onNewReviewMonth"
                    v-on="on"
                  >
                    {{ $t('create_rent_contract.add_new_review_month') }}
                  </v-btn>
                </template>
                <span>{{ $t('Required') }}</span>
              </v-tooltip>
              <v-btn
                text
                rounded
                outlined
                depressed
                @click="onRemoveReviewMonth"
              >
                {{ $t('create_rent_contract.remove_review_month') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="minimumIncrease"
                type="number"
                :readonly="indexWatch || isLocked"
                :label="$t('create_rent_contract.minimum_increase')"
                suffix="%"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="maxIncrease"
                type="number"
                :label="$t('create_rent_contract.maximum_increase')"
                :readonly="isLocked"
                suffix="%"
              />
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-select
                  v-model="defRentReviewBasedOn"
                  :items="rentReviewBasedOnList"
                  :label="$t('create_rent_contract.review_based_on')"
                  :error-messages="errors[0]"
                  :readonly="isLocked"
                  item-value="id"
                >
                  <template #selection="{ item, index }">
                    {{ item.label ? $t(`${item.label}`) : "" }}
                  </template>
                  <template #item="{item, index}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>
              </ValidationProvider>
            </v-col>
            <!--<v-col
                xs12
              >
                <v-checkbox
                  v-model="rentCheckByIPointNumber"
                  :label="$t('create_rent_contract.rent_review_based_on_newest_points')"
                  :disabled="isLocked"
                />
              </v-col>-->
            <v-row v-if="defRentReviewBasedOn == definitionByLabel('CurrentRentAndLatestIndex').id">
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="latestYearForIndex"
                  type="number"
                  :label="$t('create_rent_contract.latest_index_year')"
                  :readonly="isLocked"
                  @change="onLatestIndexChange"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="latestMonthForIndex"
                  :items="monthsList"
                  :label="$t('create_rent_contract.latest_index_month')"
                  :readonly="isLocked"
                  item-value="id"
                  @change="onLatestIndexChange"
                >
                  <template #selection="{ item, index }">
                    {{ item.label ? $t(`${item.label}`) : "" }}
                  </template>
                  <template #item="{item, index}">
                    {{ $t(`${item.label}`) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="latestIndexPointNumber"
                  readonly
                  :label="$t('create_rent_contract.latest_points')"
                />
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                v-model="reviewAdditionalInfo"
                :label="$t('create_rent_contract.contract_payment_review_additional_info')"
                :readonly="isLocked"
              />
            </v-col>
          </v-row>
          <!-- END OF Rent review: Index SELECTED -->
          <!-- Rent review: Cost Based / Other SELECTED -->
          <v-row
            v-if="selectedRentReviewType == definitionByLabel('PaymentRentReviewCostBased').id || selectedRentReviewType == definitionByLabel('PaymentRentReviewOther').id"
            align="center"
          >
            <v-col
              cols="12"
            >
              <DatePicker
                :label="$t('create_rent_contract.rent_review_startdate')"
                :aria-label="$t('create_rent_contract.rent_review_startdate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :rules="`${isOutRent ? 'required' : ''}`"
                :clearable="false"
                :initial-date="rentReviewStartDate"
                @dateupdated="updateRentReviewStartDate"
              />
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${selectedRentReviewType != definitionByLabel('PaymentRentReviewOther').id && isOutRent ? 'required' : ''}`"
              >
                <v-select
                  v-model="reviewMonths"
                  :items="monthsList"
                  :label="$t('create_rent_contract.index_review_months')"
                  :error-messages="errors[0]"
                  item-text="label"
                  item-value="id"
                  multiple
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- END OF Rent review: Cost Based / Other SELECTED -->
          <!-- Rent review: Revenue Based SELECTED -->
          <v-row
            v-if="selectedRentReviewType == definitionByLabel('PaymentRentReviewRevenueBased').id"
            align="center"
          >
            <v-col
              cols="6"
            >
              <DatePicker
                :label="$t('create_rent_contract.review_period_startdate')"
                :aria-label="$t('create_rent_contract.review_period_startdate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :rules="`${isOutRent ? 'required' : ''}`"
                :clearable="false"
                :disabled="isLocked"
                :initial-date="reviewPeriodStart"
                @dateupdated="updateReviewPeriodStart"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <DatePicker
                :label="$t('create_rent_contract.review_period_enddate')"
                :aria-label="$t('create_rent_contract.review_period_enddate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :clearable="false"
                :disabled="isLocked"
                :initial-date="reviewPeriodEnd"
                @dateupdated="updateReviewPeriodEnd"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <DatePicker
                :label="$t('create_rent_contract.rent_review_startdate')"
                :aria-label="$t('create_rent_contract.rent_review_startdate') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :clearable="false"
                :disabled="isLocked"
                :initial-date="rentReviewStartDate"
                @dateupdated="updateRentReviewStartDate"
              />
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-select
                  v-model="turnoverPeriod"
                  :items="monthsListwithSuffix"
                  :label="$t('create_rent_contract.turnover_period')"
                  :error-messages="errors[0]"
                  :readonly="isLocked"
                  item-text="label"
                  item-value="id"
                  @change="calculateMinimumRent"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <CurrencyField 
                :value="calculatedMinimumRent"
                :currency="selectedCurrency"
                :label="$t('create_rent_contract.calculated_minimum_rent')"
                type="number"
                readonly
                @change="calculateInvoicedAdditionalRent"
              />
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${isOutRent ? 'required' : ''}`"
              >
                <v-text-field
                  v-model="rentPercentTurnover"
                  type="number"
                  suffix="%"
                  :label="$t('create_rent_contract.rent_percent_turnover')"
                  :error-messages="errors[0]"
                  :readonly="isLocked"                    
                  @change="calculateInvoicedAdditionalRent"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="turnoverRelatednessInfo"
                :label="$t('create_rent_contract.turnover_relatedness_info')"
                :readonly="isLocked"
              />
            </v-col>
            <h3
              class="form-headers"
            >
              {{ $t('create_rent_contract.realization') }}
            </h3>
            <v-row>
              <v-col cols="4">
                <CurrencyField 
                  :value="turnover"
                  :currency="selectedCurrency"
                  :label="$t('create_rent_contract.turnover')"
                  :readonly="isLocked"
                  type="number"
                  @change="calculateInvoicedAdditionalRent"
                />
              </v-col>
              <v-col cols="4">
                <CurrencyField
                  :value="invoicedAdditionalRent"
                  :label="$t('create_rent_contract.invoiced_additional_rent')"
                  :currency="selectedCurrency"
                  type="number"
                  readonly
                />
              </v-col>
              <v-col cols="4">
                <CurrencyField
                  :value="additionalRentInvoiced"
                  :label="$t('create_rent_contract.additional_rent_invoiced')"
                  :currency="selectedCurrency"
                  type="number"
                  readonly
                />
              </v-col>
            </v-row>
            <!-- END OF Rent review: Revenue Based SELECTED -->
          </v-row>
        </div>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.payment_domain_header') }}
        </h3>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :immediate="true"
              rules="required"
            >
              <v-autocomplete
                v-model="selectedPaymentDomain"
                :items="rentPaymentDomains"
                :label="$t('create_rent_contract.payment_domain')"
                :hint="$t('Required')"
                persistent-hint
                required
                :error-messages="errors[0]"
                :readonly="isLocked"
                :item-text="item => `${item.name + ' ALV ' + item.vatPercent + ' %'}`"
                item-value="id"
                @change="onPaymentDomainChanged"
              >
                <template #selection="{ item }">
                  {{ item.name ? $t(`${item.name}`): "" }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                </template>
                <template #item="{ item }">
                  <v-list-item-avatar color="blue lighten-5">
                    <v-icon 
                      v-if="selectedCurrency.icon"
                      color="blue darken-1"
                    >
                      {{ selectedCurrency.icon }}
                    </v-icon>
                    <span
                      v-else
                      style="font-size:90%; color:#1E88E5; font-weight:bold;"
                    >  
                      {{ selectedCurrency.symbol }} 
                    </span> 
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }} {{ item.group ? `· ${item.group}` : '' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${isOutRent ? 'required' : ''}`"
            >
              <v-select
                v-model="selectedInvoicingBasis"
                :items="invoicingBases"
                :label="$t('create_rent_contract.payment_invoicing_basis')"
                :error-messages="errors[0]"
                :readonly="isLocked"
                item-value="id"
                @change="onInvoicingBasisChanged"
              >
                <template #selection="{ item, index }">
                  {{ item.label ? $t(`${item.label}`) : "" }}
                </template>
                <template #item="{item, index}">
                  {{ $t(`${item.label}`) }}
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${selectedInvoicingBasis == definitionByLabel('InvoicingBasisPcs').id && isOutRent ? 'required' : ''}`"
            >
              <v-select
                v-model="selectedProcessingMode"
                :items="rentPaymentsProcessingModes"
                :label="$t('create_rent_contract.payment_processing_mode')"
                :disabled="isProcessingModeDisabled"
                :error-messages="errors[0]"
                :readonly="isLocked"
                item-value="id"
              >
                <template #selection="{ item, index }">
                  {{ item.label ? $t(item.label, {currency: selectedCurrency.symbol}) : "" }}
                </template>
                <template #item="{item, index}">
                  {{ $t(item.label, {currency: selectedCurrency.symbol}) }}
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="totalArea"
              readonly
              :label="$t('create_rent_contract.payment_rent_area')"
            />
          </v-col>
          <v-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${selectedInvoicingBasis == definitionByLabel('InvoicingBasisPcs').id && isOutRent ? 'required' : ''}`"
            >
              <v-text-field
                v-model="pcsCount"
                :disabled="isProcessingModeDisabled"
                :readonly="isLocked || targetParkingSpaces.length > 0"
                type="number"
                :label="$t('create_rent_contract.payment_pcs')"
                :error-messages="errors[0]"
                @change="onPcsCountChanged"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.single_payment_prices') }}
        </h3>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="squareBased"
              :label="$t('create_rent_contract.payment_pcs_based')"
              :disabled="isLocked"
              @change="onIsSquareBasedChanged"
            />
          </v-col>
          <v-col cols="6">
            <CurrencyField
              :value="taxSumText"
              readonly
              type="text"
              :label="$t('create_rent_contract.tax')"
              :currency="selectedCurrency"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col 
            cols="6" 
          >
            <ValidationProvider 
              v-slot="{ errors }"
              :rules="{ requiredIfOtherFieldNotFilled: { otherFieldName : $t('create_rent_contract.payment_square_rent_tax_free'), otherField : netSquareRent } }"
            >
              <CurrencyField
                :value="netSum"
                type="number"
                :label="$t('create_rent_contract.net_sum')"
                :readonly="squareBased || isLocked"
                :error-messages="errors"
                :currency="selectedCurrency"
                @change="onNetSumChanged"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <CurrencyField
              :value="grossSumText"
              readonly
              type="text"
              :label="$t('create_rent_contract.gross_sum')"
              :currency="selectedCurrency"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col 
            cols="6" 
          >
            <ValidationProvider 
              v-slot="{ errors }"
              :rules="{ requiredIfOtherFieldNotFilled: { otherFieldName : $t('create_rent_contract.net_sum'), otherField : netSum } }"
            >
              <CurrencyField
                :value="netSquareRent"
                type="number"
                :readonly="!squareBased || isLocked"
                :label="$t('create_rent_contract.payment_square_rent_tax_free')"
                :per-unit="perUnit"
                :currency="selectedCurrency"
                :error-messages="errors"
                @change="onSquareRentChanged"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <CurrencyField
              :value="grossSquareRentText"
              readonly
              type="text"
              :label="$t('create_rent_contract.payment_square_rent')"
              :per-unit="perUnit"
              :currency="selectedCurrency"
            />
          </v-col>
        </v-row>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.payment_info') }}
        </h3>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedPaymentBasis"
              :items="rentPaymentsBases"
              :label="$t('create_rent_contract.payment_basis_basis')"
              :readonly="isLocked"
              item-value="id"
            >
              <template #selection="{ item, index }">
                {{ item.label ? $t(`${item.label}`) : $t('create_rent_contract.payment_basis_basis') }}
              </template>
              <template #item="{item, index}">
                {{ $t(`${item.label}`) }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="legend"
              type="text"
              :label="$t('create_rent_contract.payment_legend')"
            />
          </v-col>
        </v-row>
        <div v-if="!isDiscountPayment">
          <h3 class="form-headers">
            {{ $t('create_rent_contract.contract_payment_latest_review') }}
          </h3>
          <v-row>
            <v-col cols="12">
              <DatePicker
                :label="$t('create_rent_contract.contract_payment_latest_review_date')"
                :aria-label="$t('create_rent_contract.contract_payment_latest_review_date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
                :hint="$t('date_format')"
                :default-hint="false"
                :initial-date="latestReviewDate"
                @dateupdated="updateLatestReviewDate"
              />
            </v-col>
          </v-row>
        </div>
      </ValidationObserver>
      <v-dialog
        v-model="showCalculatorModal"
        persistent
        eager
        width="65%"
      >
        <Calculator
          :selected-buildings="selectedBuildings"
          @close="closeCalculatorModal()"
        />
      </v-dialog>
    </template>
    <template #footer>
      <v-btn
        v-if="hasApplicationPermissionByName('SISVUOKRA_LASKIN')"
        class="blue-button"
        rounded
        depressed
        absolute
        left
        @click="openCalculatorModal()"
      >
        {{ $t('Calculator') }}
      </v-btn>
      <v-btn
        :disabled="saveButtonDisabled"
        text
        rounded
        outlined
        depressed
        class="blue-button"
        @click="onAddPayment"
      >
        {{ addButtonText }}
      </v-btn>
    </template>
  </Basemodal>
</template>

<script>

import BaseModal from '../../../../general/BaseModal'
import DatePicker from '../../../../general/DatePicker.vue'
import helpers from './../../../../../helpers'
import { i18n } from "../../../../../plugins/i18n";
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import services from './Services'
import Calculator from '../../../../Calculator.vue'
import CurrencyField from '../../../../CurrencyField'
import { calculatePcsCountSum } from './../../../../../helpers/contract'

extend('validStartDate', {
  message: i18n.t("create_rent_contract.invalid_start_date"),
  params: ['startDate', 'rentPeriodStartDate', 'endDate'],
  validate: (_, { startDate, rentPeriodStartDate, endDate }) => {

    const date = Date.parse(startDate?.trim().split("T")[0])
    if (isNaN(date)) {
      return true
    }

    const dateEnd = Date.parse(endDate?.trim().split("T")[0])
    if (!isNaN(dateEnd)) {
      if (date > dateEnd) {
        return false
      }
    }

    const paymentStartDate = Date.parse(rentPeriodStartDate.trim().split("T")[0])
    if (isNaN(paymentStartDate)) {
      return true
    }
    return date >= paymentStartDate
  }
});
extend('validEndDate', {
  message: i18n.t("create_rent_contract.invalid_end_date"),
  params: ['startDate', 'endDate'],
  validate: (_, { startDate, endDate }) => {
    const date = Date.parse(startDate?.trim().split("T")[0])
    if (isNaN(date)) {
      return true
    }

    const dateEnd = Date.parse(endDate?.trim().split("T")[0])
    if(isNaN(dateEnd)){
      return true
    }
    if(dateEnd < date){
      return false
    }
    return true
  }
});
extend('startDateAfterEarliest', {
  message: i18n.t("create_rent_contract.target_starts_after_payment"),
  params: ['startDate', 'earliestPossibleStartDate'],
  validate: (_, { startDate, earliestPossibleStartDate }) => {

    const date = Date.parse(startDate?.trim().split("T")[0])
    if (isNaN(date)) {
      return true
    }
    const earliestDate = Date.parse(earliestPossibleStartDate.trim())

    if (isNaN(earliestDate)) {
      return true
    }

    return date >= earliestDate
  }
});

// below extend works if type of value and otherField are either number or string
extend('requiredIfOtherFieldNotFilled', {
  params: ['otherFieldName', 'otherField'],
  validate: (value, { otherFieldName, otherField }) => {
    let retVal = null;
    if (otherField === undefined || value === undefined ||
       otherField === null || value === null) {
       if ((otherField === undefined && value === undefined) ||
         (otherField === null && value === null)) retVal = false
       if (retVal === null && value === undefined) {
         if (typeof(otherField) === "number") 
          retVal = (otherField !== undefined) && (otherField !== null)
         if (retVal === null) retVal = otherField.trim() != ''
       }
       if (retVal === null) {
         if (value === null || value === undefined)
           retVal = false
         if (typeof(value) === "number") 
           retVal = value !== undefined && value !== null
         if (retVal === null) retVal = value.trim() != ''
       }
    }
    if (retVal === null) {
      if (typeof(value) === "number" && typeof(otherField) === "number")
        retVal = (value !== undefined && value !== null) || (otherField !== undefined && otherField !== null)
      if (retVal === null && typeof(value) !== "number" && typeof(otherField) !== "number")
         retVal = otherField.trim() != '' || value.trim() != '';
      if (retVal === null && typeof(value) !== "number")
        retVal = value !== null && (otherField !== undefined && otherField !== null)
      if (retVal === null)
        retVal = otherField.trim() !== null && (value !== undefined && value !== null)
    }
    if (!retVal) return i18n.t('Either this field or field') + otherFieldName + i18n.t('must be given') + "."
    return true;
  }, 
  computesRequired: true
})

export default {
  name: "SeparatePaymentModal",
  components: {
      BaseModal,
      DatePicker,
      ValidationProvider,
      ValidationObserver,
      Calculator,
      CurrencyField,
  },
  props: {
    rentPeriodStartDate: {
      type: String,
      default: () => undefined
    },
    contractId: {
      type: Number,
      default: () => undefined
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    payment: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    rentPaymentDomains: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    isInternalContract: {
      type: Boolean,
      default: () => false
    },
    selectedBuildings: {
      type: Array,
      default: () => []
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
        isFullContractPayment: false,
        targetUnits: [],
        targetStructures: [],
        targetParkingSpaces: [],
        isDiscountPayment: false,
        latestReviewDate: undefined,
        startDate: undefined,
        endDate: undefined,
        startDateFormatted: undefined,
        endDateFormatted: undefined,
        latestReviewDateFormatted: undefined,
        selectedPaymentDomain: undefined,
        selectedInvoicingBasis: undefined,
        selectedProcessingMode: undefined,
        selectedRentReviewType: undefined,
        rentedTotalArea: undefined,
        pcsCount: undefined,
        squareBased: false,
        netSquareRent: undefined,
        grossSquareRent: undefined,
        paymentBases: [],
        totalArea: undefined,
        selectedPaymentBasis: undefined,
        netSum: undefined,
        grossSum: undefined,
        taxSum: undefined,
        legend: undefined,
        rentReviewStartDate: undefined,
        indexWatch: false,
        basicIndexYear: undefined,
        basicIndexMonth: undefined,
        basicIndexPointNumber: undefined,
        minimumIncrease: undefined,
        maxIncrease: undefined,
        defRentReviewBasedOn: undefined,
        rentCheckByIPointNumber: false,
        latestYearForIndex: undefined,
        latestMonthForIndex: undefined,
        latestIndexPointNumber: undefined,
        reviewMonths: [],
        indexMonths: [],
        reviewAdditionalInfo: undefined,
        reviewPeriodStart: undefined,
        reviewPeriodEnd: undefined,
        turnoverPeriod: undefined,
        calculatedMinimumRent: undefined,
        rentPercentTurnover: undefined,
        turnoverRelatednessInfo: undefined,
        turnover: undefined,
        invoicedAdditionalRent: undefined,
        additionalRentInvoiced: undefined,
        showCalculatorModal: false,
        validRentReviewStartDate: true,
        dateString: null,
        isStartDateValidAfterEarliest: true,
        rentReviewTypes: []
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById', 'definitionByLabel', 'hasApplicationPermissionByName']),
    rentReviewBasedOnList () {
      return this.definitionsByGroupLabel('contract.rent_review_based_on')
    },
    monthsList () {
      return helpers.variableLists.monthItems()
    },
    monthsListwithSuffix () {
      return helpers.variableLists.monthItemswithSuffix()
    },
    rentPaymentsBases () {
      return this.definitionsByGroupLabel('rent_payments.basis')
    },
    rentPaymentsProcessingModes () {
      return this.definitionsByGroupLabel('rent_payments.processing_mode')
    },
    invoicingBases () {
      return this.definitionsByGroupLabel('rent_payments.invoicing_basis')
    },
    grossSumText () {

      return (this.grossSum !== null && this.grossSum !== undefined) ? helpers.humanize.amount_long(this.grossSum) : ""
    },
    grossSquareRentText () {
      return (this.grossSquareRent !== null && this.grossSquareRent !== undefined) ? helpers.humanize.amount_long(this.grossSquareRent) : ""
    },
    taxSumText () {
      return (this.taxSum !== null && this.taxSum !== undefined) ? helpers.humanize.amount_long(this.taxSum) : ""
    },
    addButtonText () {
      return this.payment ? this.$t('create_rent_contract.payment_save') : this.$t('create_rent_contract.payment_add')
    },
    isProcessingModeDisabled () {
      return this.selectedInvoicingBasis !== this.definitionByLabel("InvoicingBasisPcs").id
    },
    isLocked () {
      return this.lockedConditions()
    },
    saveButtonDisabled () {
      if (!this.isStartDateValidAfterEarliest) return true
      if (this.selectedPaymentDomain === undefined) return true
      if (!this.isOutRent) return false
      if (this.selectedRentReviewType == this.definitionByLabel('PaymentRentReviewIndex').id) {
        if (this.reviewMonths.length == 0) return true
        if (this.indexMonths.length == 0) return true
        for(let i=0; i < this.reviewMonths.length; i++)
          if (this.reviewMonths[i] === undefined || this.reviewMonths[i] === null || 
              this.reviewMonths[i] < 1 || this.reviewMonths[i] > 12)
              return true
        for(let i=0; i < this.indexMonths.length; i++)
          if (this.indexMonths[i] === undefined || this.indexMonths[i] === null || 
              this.indexMonths[i] < 1 || this.indexMonths[i] > 12)
              return true
      }
      if (this.selectedRentReviewType == this.definitionByLabel('PaymentRentReviewCostBased').id) {
      if (this.reviewMonths.length === 0 || this.validRentReviewStartDate === false) {
        return true;
      } else {
        return false;
      }
    }
      return false
    },
    targetUnitsRules () {
      if (this.isFullContractPayment || 
        (this.targetStructures && this.targetStructures.length > 0) || 
        (this.targetParkingSpaces && this.targetParkingSpaces.length > 0)) {
        return '';
      }
      return 'required';
    },
    targetStructuresRules () {
      if (this.isFullContractPayment || 
        (this.targetUnits && this.targetUnits.length > 0) || 
        (this.targetParkingSpaces && this.targetParkingSpaces.length > 0)) {
        return '';
      }
      return 'required';
    },
    targetParkingSpacesRules () {
      if (this.isFullContractPayment || 
        (this.targetUnits && this.targetUnits.length > 0) || 
        (this.targetStructures && this.targetStructures.length > 0)) {
        return '';
      }
      return 'required';
    },
    earliestPossibleStartDate () {
      if (!Array.isArray(this.selectedUnits) || !Array.isArray(this.targetUnits) || !Array.isArray(this.targetStructures)) {
        return null
      }

      if (this.isFullContractPayment && Array.isArray(this.selectedUnits)) {
        const startDates = this.selectedUnits
          .filter(selectedItem => selectedItem.startDate)
          .map(selectedItem => new Date(selectedItem.startDate))

        const earliestStartDate = startDates.length > 0 ? new Date(Math.max(...startDates)) : null
        return earliestStartDate
      }

      const startDates = []
      if (!this.isFullContractPayment){
        for (const targetId of [...this.targetUnits, ...this.targetStructures, ...this.targetParkingSpaces]) {
          const selectedItem = this.selectedUnits.find(item => item.id === targetId)
          if (selectedItem && selectedItem.startDate) {
            startDates.push(new Date(selectedItem.startDate))
          }
        }
      }
        

      const earliestStartDate = startDates.length > 0 ? new Date(Math.max(...startDates)) : null

      return earliestStartDate
    },
    perUnit () {
      return this.selectedInvoicingBasis === this.definitionByLabel("InvoicingBasisPcs").id ? `/${this.$t('pcs')}` : '/m\u00B2'
    },
    parkingSpaceItems () {
      return this.selectedUnits.filter(u => !!u.parkingSpaceId)
    },
    structureItems () {
      return this.selectedUnits.filter(u => !!u.structureId)
    },
    unitItems () {
      return this.selectedUnits.filter(u => !!u.unitId)
    },
  },
  watch: {
    rentReviewStartDate () {
      this.dateString = this.formatDate(this.rentReviewStartDate)
      if(this.rentReviewStartDate !== undefined || this.rentReviewStartDate !== null){
        this.validRentReviewStartDate = true
      }
    },
    showCalculatorModal () {
      if (this.showCalculatorModal == true) {
        this.setupCalculatorStartingValues();
      }
    },
    visible () {
      if (this.visible) {
        this.copyDataFromPayment()
        this.onBaseIndexChange()
        this.onLatestIndexChange()
      }
    },
    payment () {
      this.copyDataFromPayment()
    },
    async targetUnits () {
      this.updateTargets()
    },
    async targetStructures () {
      this.updateTargets()
    },
    async targetParkingSpaces () {
      if (this.targetParkingSpaces.length > 0 && this.selectedInvoicingBasis !== this.definitionByLabel("InvoicingBasisPcs").id) {
        this.selectedInvoicingBasis = this.definitionByLabel("InvoicingBasisPcs").id
      }
      this.pcsCount = this.targetParkingSpaces.length > 0 ? calculatePcsCountSum(this.selectedUnits.filter(u => !!u.parkingSpaceId && this.targetParkingSpaces.includes(u.id))) : undefined
      this.updateTargets()
    },
    startDate () {
      if(this.startDate || (this.targetStructures.length > 0 || this.targetUnits.length > 0 || this.targetParkingSpaces.length > 0)){
        this.checkTargetStartDate()
      }
      if (!this.startDate) {
        this.startDate = this.rentPeriodStartDate
      }
      this.startDateFormatted = this.formatDate(this.startDate)
    },
    endDate () {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    latestReviewDate () {
      this.latestReviewDateFormatted = this.formatDate(this.latestReviewDate)
    },
    basicIndexMonth () {
      if(this.basicIndexMonth != null || this.basicIndexMonth != undefined){
        const monthIndex = parseInt(this.basicIndexMonth)
        this.basicIndexMonth = monthIndex
      }
    },
    latestMonthForIndex () {
      if(this.latestMonthForIndex != null || this.latestMonthForIndex != undefined){
        const monthIndex = parseInt(this.latestMonthForIndex)
        this.latestMonthForIndex = monthIndex
      }
    },
    selectedRentReviewType () {
      if(this.selectedRentReviewType === this.definitionByLabel("PaymentRentReviewRevenueBased").id){
        this.calculateMinimumRent()
        this.calculateInvoicedAdditionalRent()
      }
    },
    async netSquareRent () {
      if(this.netSum && this.netSquareRent === 0){
        await this.calculateSums()
      }
    },
    async netSum () {
      if(this.netSquareRent && this.netSum === 0 ){
        await this.calculateSums()
      }
    },
    isInternalContract: {
      handler: function () {
        this.gatherRentReviewTypes()
        if (this.isInternalContract) {
          this.selectedRentReviewType = this.definitionByLabel('PaymentRentReviewInternal').id
        }
      },
      immediate: true 
    }
  },
  mounted () {
    if (this.rentPeriodStartDate !== undefined)
      this.startDate = this.rentPeriodStartDate
    this.gatherRentReviewTypes()
  },
  methods: {
    ...mapActions("calculator", ["setupCalculatorStartingValues"]),
    gatherRentReviewTypes () {
      this.rentReviewTypes = this.definitionsByGroupLabel('rent_payments.rent_review')
      if (!this.isOutRent) {
        this.rentReviewTypes = this.rentReviewTypes.filter(reviewType => reviewType.label != 'PaymentRentReviewInternal')
      }
    },
    updateStartDate (value) {
      this.startDate = value
    },
    updateEndDate (value) {
      this.endDate = value
    },
    updateRentReviewStartDate (value) {
      this.rentReviewStartDate = value
    },
    updateReviewPeriodStart (value) {
      this.reviewPeriodStart = value
    },
    updateReviewPeriodEnd (value) {
      this.reviewPeriodEnd = value
    },
    updateLatestReviewDate (value) {
      this.latestReviewDate = value
    },
    openCalculatorModal () {
      this.showCalculatorModal = true
    },
    closeCalculatorModal () {
      this.showCalculatorModal = false
    },
    onNewReviewMonth () {
      this.reviewMonths.push(undefined)
      this.indexMonths.push(undefined)
    },
    onRemoveReviewMonth () {
      this.reviewMonths.pop()
      this.indexMonths.pop()
    },
    onIsDiscountPaymentChanged () {
      if (this.isDiscountPayment) {
        this.reviewAdditionalInfo = undefined,
        this.latestReviewDate = undefined
      }
      this.calculateSums()
    },
     onIsSquareBasedChanged () {
       this.calculateSums()
    },
    onInvoicingBasisChanged () {
      if (this.isProcessingModeDisabled) {
        this.selectedProcessingMode = null
        this.pcsCount = null
      }
        this.calculateSums()
    },
    onPcsCountChanged () {
      this.calculateSums()
    },
    copyDataFromPayment () {
      this.isFullContractPayment = this.payment?.wholeContractPayment === undefined ? !this.isOutRent : this.payment?.wholeContractPayment
      this.startDate = this.payment?.startDate
      this.endDate = this.payment?.endDate
      this.selectedPaymentDomain = this.payment?.paymentDomainId
      this.selectedPaymentBasis = this.payment?.defPaymentBasis
      this.selectedProcessingMode = this.payment?.defProcessingMode
      this.selectedInvoicingBasis = this.payment?.defInvoicingBasis ?? this.definitionByLabel("InvoicingBasisArea").id
      this.netSum = this.payment?.rent
      this.legend = this.payment?.legend
      this.ownBill = this.payment?.ownBill
      this.squareBased = this.payment?.squareBased
      this.netSquareRent = this.payment?.rent
      this.isDiscountPayment = this.payment?.discountPayment
      this.pcsCount = this.payment?.pcsCount
      if (!this.isInternalContract || this.payment !== null) {
        this.selectedRentReviewType = this.payment?.defRentReviewType
      } else if (this.isInternalContract && this.payment === null) {
        this.selectedRentReviewType = this.definitionByLabel('PaymentRentReviewInternal').id
      }
      this.rentReviewStartDate = this.payment?.rentReviewStartDate
      this.indexWatch = this.payment?.indexWatch
      this.basicIndexYear = this.payment?.basicIndexYear
      this.basicIndexMonth = this.payment?.basicIndexMonth
      this.basicIndexPointNumber = this.payment?.basicIndexPointNumber
      this.minimumIncrease = this.payment?.minimumIncrease
      this.maxIncrease = this.payment?.maxIncrease
      this.defRentReviewBasedOn = this.payment?.defRentReviewBasedOn
      this.rentCheckByIPointNumber = this.payment?.rentCheckByIPointNumber
      this.latestYearForIndex = this.payment?.latestYearForIndex
      this.latestMonthForIndex = this.payment?.latestMonthForIndex
      this.latestIndexPointNumber = this.payment?.latestIndexPointNumber
      this.latestReviewDate = this.payment?.latestReviewDate
      this.reviewMonths = this.payment?.reviewMonths ?? []
      this.indexMonths = this.payment?.indexMonths ?? []
      this.reviewAdditionalInfo = this.payment?.reviewAdditionalInfo
      this.reviewPeriodStart = this.payment?.reviewPeriodStart
      this.reviewPeriodEnd = this.payment?.reviewPeriodEnd
      this.turnoverPeriod = this.payment?.turnoverPeriod
      this.calculatedMinimumRent = this.payment?.calculatedMinimumRent
      this.rentPercentTurnover = this.payment?.rentPercentTurnover
      this.turnoverRelatednessInfo = this.payment?.turnoverRelatednessInfo
      this.turnover = this.payment?.turnover
      this.invoicedAdditionalRent = this.payment?.invoicedAdditionalRent
      this.additionalRentInvoiced = this.payment?.additionalRentInvoiced  
      this.grossSquareRent = this.payment?.grossSquareRent
      this.totalArea = this.payment?.totalArea

      // Populate target units
      let targetUnits = []
      if (this.payment !== null && this.payment.targetUnits.length > 0) {
        targetUnits = [...targetUnits, ...this.payment.targetUnits]
      }

      this.targetUnits = targetUnits;

      let targetStructures = []
      if (this.payment !== null && this.payment.targetStructures.length > 0) {
        targetStructures = [...targetStructures, ...this.payment.targetStructures]
      }
      this.targetStructures = targetStructures

      let targetParkingSpaces = []
      if (this.payment !== null && this.payment.targetParkingSpaces.length > 0) {
        targetParkingSpaces = [...targetParkingSpaces, ...this.payment.targetParkingSpaces]
      }
      this.targetParkingSpaces = targetParkingSpaces

      this.calculateSums()
    },
    onIsFullContractPaymentChanged () {
      if (this.isFullContractPayment) {
        this.targetUnits = []
        this.targetStructures = []
      }else{
        this.totalArea = undefined
      }
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
      }
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate (date) {
      if (!date) return null

      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    onBaseIndexChange () {
      this.calculateIndex(1)
    },
    onLatestIndexChange () {
      this.calculateIndex(2)
    },
    onNetSumChanged (value) {
      this.netSum = value
      this.calculateSums()
      this.calculateMinimumRent()
      this.calculateInvoicedAdditionalRent()
    },
    onSquareRentChanged (value) {
      this.netSquareRent = value
      this.calculateSums()
    },
    onPaymentDomainChanged () {
      this.calculateSums()
    },
    calculateMinimumRent () {
      if (this.turnoverPeriod && this.netSum) {
        this.calculatedMinimumRent = this.turnoverPeriod * this.netSum
      }
      if(this.calculatedMinimumRent != undefined){
        this.calculatedMinimumRent = parseFloat(this.calculatedMinimumRent).toFixed(2);
      }
    },
    calculateInvoicedAdditionalRent () {
      if (this.turnover && this.rentPercentTurnover && this.calculatedMinimumRent) {
        this.invoicedAdditionalRent = 0
        this.invoicedAdditionalRent = (this.turnover * (this.rentPercentTurnover / 100)) - this.calculatedMinimumRent
      }
      if(this.invoicedAdditionalRent != undefined){
        this.invoicedAdditionalRent = parseFloat(this.invoicedAdditionalRent).toFixed(2);
      }
    },
    async calculateIndex (calcVer) {
      if (calcVer === 1) {
        if (this.basicIndexYear === undefined || this.basicIndexMonth === undefined) {
          this.basicIndexPointNumber = undefined
          return
        }

        const index = await this.$rambollfmapi.rentpayments.indexnumbers(this.basicIndexYear, this.basicIndexMonth)
        if (index[0] === undefined) {
          this.basicIndexPointNumber = undefined
        } else {
          this.basicIndexPointNumber = index[0].costOfLivingIndex
        }
      } else if (calcVer === 2) {
        if (this.latestYearForIndex === undefined || this.latestMonthForIndex === undefined) {
          this.latestIndexPointNumber = undefined
          return
        }

        const index = await this.$rambollfmapi.rentpayments.indexnumbers(this.latestYearForIndex, this.latestMonthForIndex)
        if (index[0] === undefined) {
          this.latestIndexPointNumber = undefined
        } else {
          this.latestIndexPointNumber = index[0].costOfLivingIndex
        }
      }
    },
    async selectedRentArea () {
      let paymentData = {
        wholeContractPayment: this.isFullContractPayment,
        targetUnits: this.targetUnits,
        targetStructures: this.targetStructures,
        targetParkingSpaces: this.targetParkingSpaces
        }
        this.totalArea = await services.tableColumnsService.getTotalArea(paymentData, this.selectedUnits)
    },
    async calculateSums () {
      if (this.selectedPaymentDomain === undefined ||
          (this.netSum === undefined && this.netSquareRent === undefined)) {
        this.grossSum = undefined
        this.taxSum = undefined
        return
      }

      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === this.selectedPaymentDomain)
      if (paymentDomain[0]) {

        if (this.netSum != undefined && this.netSum.toString().includes(",")) {
          this.netSum = parseFloat(
          this.netSum.replace(",", ".")
          ).toFixed(2);
        }

        if (this.netSquareRent != undefined && this.netSquareRent.toString().includes(",")) {
          this.netSquareRent = parseFloat(
          this.netSquareRent.replace(",", ".")
          ).toFixed(2);
        }

        const sum = this.squareBased ? this.netSquareRent : this.netSum
        
        const convertedSum = this.isDiscountPayment ? -Math.abs(sum) : sum
        
        const agreedUnitAreas = []
        if(this.totalArea != null){
          agreedUnitAreas.push(this.totalArea)
        }

        if (convertedSum) {
          const sums = await this.$rambollfmapi.rentpayments.payments.calculateSums(paymentDomain[0], convertedSum, agreedUnitAreas, this.squareBased)
          this.grossSum = sums.grossSum
          this.taxSum = sums.taxSum
          this.totalAgreedArea = sums.totalAgreedArea
          this.grossSquareRent = sums.grossSquareRent
          this.netSum = sums.netSum
          this.netSquareRent = sums.netSquareRent
          this.taxSum = sums.tax
        if(convertedSum && this.pcsCount > 0){
          const sums = await this.$rambollfmapi.rentpayments.payments.calculateSumsPcs(paymentDomain[0], convertedSum, this.pcsCount, this.squareBased)
          this.grossSum = sums.grossSum
          this.taxSum = sums.taxSum
          this.totalAgreedArea = sums.totalAgreedArea
          this.grossSquareRent = sums.grossSquareRent
          this.netSum = sums.netSum
          this.netSquareRent = sums.netSquareRent
          this.taxSum = sums.tax
          this.pcsCount = sums.pcs
          }
        } else {
          this.grossSum = 0
          this.taxSum = 0
          this.totalAgreedArea = 0
          this.grossSquareRent = 0
          this.netSum = 0
          this.netSquareRent = 0
          this.taxSum = 0
          this.pcsCount = 0
        }
      }
    },
    onAddPayment () {     
      const modifiedPayment = {
          id: this.payment?.id,
          contractId: this.contractId ?? undefined,
          paymentDomainId: this.selectedPaymentDomain,
          defPaymentBasis: this.selectedPaymentBasis,
          defInvoicingBasis: this.selectedInvoicingBasis,
          defProcessingMode: this.selectedProcessingMode,
          defRentReviewType: this.selectedRentReviewType,
          legend: this.legend,
          wholeContractPayment: this.isFullContractPayment ?? false,
          startDate: this.startDate,
          endDate: this.endDate,
          targetUnits: this.targetUnits,
          targetStructures: this.targetStructures,
          targetParkingSpaces: this.targetParkingSpaces,
          discountPayment: this.isDiscountPayment,
          squareBased: this.squareBased,
          rent: this.squareBased ? this.netSquareRent : this.netSum,
          netSum: this.netSum !== undefined ? this.netSum : null,
          pcsCount: this.pcsCount,
          rentReviewStartDate: this.rentReviewStartDate,
          indexWatch: this.indexWatch,
          basicIndexYear: this.basicIndexYear,
          basicIndexMonth: this.basicIndexMonth,
          basicIndexPointNumber: this.basicIndexPointNumber,
          minimumIncrease: this.minimumIncrease,
          maxIncrease: this.maxIncrease,
          defRentReviewBasedOn: this.defRentReviewBasedOn,
          rentCheckByIPointNumber: this.rentCheckByIPointNumber,
          latestYearForIndex: this.latestYearForIndex,
          latestMonthForIndex: this.latestMonthForIndex,
          latestIndexPointNumber: this.latestIndexPointNumber,
          latestReviewDate: this.latestReviewDate,
          reviewMonths: this.reviewMonths ?? [],
          indexMonths: this.indexMonths ?? [],
          reviewAdditionalInfo: this.reviewAdditionalInfo,
          reviewPeriodStart: this.reviewPeriodStart,
          reviewPeriodEnd: this.reviewPeriodEnd,
          turnoverPeriod: this.turnoverPeriod,
          calculatedMinimumRent: this.calculatedMinimumRent,
          rentPercentTurnover: this.rentPercentTurnover,
          turnoverRelatednessInfo: this.turnoverRelatednessInfo,
          turnover: this.turnover,
          invoicedAdditionalRent: this.invoicedAdditionalRent,
          additionalRentInvoiced: this.additionalRentInvoiced,
          grossSquareRent: this.grossSquareRent,
          billedUntilDate: this.payment?.billedUntilDate ?? null,
      }

      if (this.payment) {
        this.$emit('paymentUpdated', modifiedPayment)
      } else {
        this.$emit('paymentAdded', modifiedPayment)
      }
    },
    rentReviewStartDateChanged (date) {
      try {
          let inputDate = date
          if(inputDate !== ''){
            this.rentReviewStartDate = new Date(moment.utc(date, 'DD.MM.YYYYThh:mm:ss').toDate().setUTCHours(0, 0, 0, 0)).toISOString().split('T')[0]
            this.validRentReviewStartDate = true
          }
          else{
            this.validRentReviewStartDate = false
          }

      }
      catch (error) {
        this.validRentReviewStartDate = false
        this.dateString = null
        this.$log.error('Rent review start date has invalid time value')
      }
    },
    lockedConditions () {
      return this.payment?.id > 0 && 
        (!this.contract?.isDraft || 
         (this.payment?.latestBillingDate !== null && this.payment?.latestBillingDate !== undefined))
    },
    checkTargetStartDate () {
      let startDateTimestamp = null
      let earliestStartDateTimestamp = null

      if(this.startDate){
        startDateTimestamp = new Date(this.startDate.split('T')[0]).getTime()
      }
      if(this.earliestPossibleStartDate){
        earliestStartDateTimestamp = this.earliestPossibleStartDate.getTime()
      }

      if (startDateTimestamp >= earliestStartDateTimestamp) {
        this.isStartDateValidAfterEarliest = true
      } else {
        this.isStartDateValidAfterEarliest = false
      }
    },
    async updateTargets () {
      await this.selectedRentArea()
      await this.calculateSums()
      if (this.targetUnits.length > 0 || this.targetStructures.length > 0 || this.targetParkingSpaces.length > 0){
        this.checkTargetStartDate()
      }
    },
  }
}

</script>

<style scoped>
.form-headers {
  margin: 24px 0 16px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
.v-input--checkbox {
  display: inline-flex;
}
</style>
