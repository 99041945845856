<template>
  <v-row
    id="unitmodify"
    class="flex-column"
  >
    <v-dialog 
      v-model="unitModifyDialog"
      persistent
      max-width="1200"
    >
      <v-card>
        <UnitModifyForm        
          :units="units"
          :unit-usages="unitUsages"
          :unit-rent-states="definitionsByGroupLabel('unit.notrentable')"
          :party-links="unitPartyLinks"
          :dismantleable="false"
          :disabled="false"
          :renting="false"
          :apartments-active="unitApartmentsActive"
          :unit-apartments="definitionsByGroupLabel('unit.apartmenttype')"
          :unit-kitchens="definitionsByGroupLabel('unit.kitchentype')"
          :unit-balconies="definitionsByGroupLabel('unit.balcony')"
          :unit-additional-infos="definitionsByGroupLabel('unit.additional_info')"
          :target-goal="definitionsByGroupLabel('unit.goal')"
          :unit-visibility-rights="unitVisibilityRights"
          :right-for-ti-expense="hasApplicationPermissionByName('ULKOINENVUOKRAUS')"
          :tags="availableTags"
          @update="$emit('update')"
          @close="$emit('close')"
          @saved="saveIndicator"
          @popup="onPopup"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UnitModifyForm from '../modules/v3viewer/UnitModifyForm'
import definition from '../helpers/definition'
import clone from '../helpers/clone'

export default {
  name: 'UnitModify',
  components: {
    UnitModifyForm
  },
  props: {
    units: {
      type: Array,
      default: () => []
    },
    customizableFields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      unitModifyDialog: true,
      isWidgetFields: true,
      unitVisibilityRights: {},
      unitPartyLinks: [],
      partyInSomeLink: [],
      parties: [],
      cleaningAreas: [],
      fireAreas: [],
      costcenters: [],
      readonly: false,
      unitShares: null,
      filteredUnits: [],
      contractNumberSap: [],
      availableTags: []
    }
  },
  computed: {
    ...mapState('sites', ['loadedSites', 'currentSiteId']),
    ...mapGetters('sites', ['buildingsForCurrentSite']),
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById', 'hasApplicationPermissionByName', 'definitionsByGroupLabel']),
    ...mapState('app', ['purposeZones', 'purposesOfUse']),
    unitApartmentsActive () {
      return this.unitVisibilityRights.apartment?.isShown ?? false
    },
    unitUsages () {
      return definition.translateAndSortLabels(clone.cloneDeep(this.definitionsByGroupLabel('unit.usage')))
    },
  },
  watch: {
    units: function (units) {
      this.filteredUnits = units.filter(s => s.has_dwg === false)
      this.updatePopupData()
    }
  },
  async mounted () {
    this.getVisibilityRights()
    this.filteredUnits = this.units.filter(s => s.has_dwg === false)
    this.getPurposeZones()
    this.getPurposes()
    this.getCostcenters()
    this.getPartyLinks()
    this.initialize()
  },
  methods: {
    ...mapActions('sites', ['loadSite', 'loadSiteParties']),
    ...mapActions('app', ['getPurposeZones', 'getPurposes']),
    async getVisibilityRights () {
      const visibilityRights = await this.$rambollfmapi.units.extra().metadata()
      this.unitVisibilityRights = visibilityRights

      this.getTags()
    },
    async getPartyLinks () {
      this.unitPartyLinks = await this.$rambollfmapi.sites.unitsExtra(this.currentSiteId, {query: { time: this.currentDate}}).partyLinks()
    },
    async initialize () {
      this.$rambollfmapi.definitions
        .cleaningarea()
        .then(res => {
          this.cleaningAreas = res
        })
        .catch(error => this.$log.info(error))

      this.$rambollfmapi.definitions
        .firearea()
        .all()
        .then(res => {
          this.fireAreas = res
        })
        .catch(error => this.$log.info(error))
    },
    saveIndicator (result) {
      this.operationResult = result
      if (result === 'error') {
        this.$emit('saveError')
      }
      if (result === 'success') {
        this.$emit('saveSuccess')
      }
    },
    onPopup (modifiedUnits){
      this.modifiedUnits = modifiedUnits
      
      const w = document.getElementById('unitmodifyform').clientWidth
      const h = document.getElementById('unitmodifyform').clientHeight + 1

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom 
      
      const baseURL = window.location.href.replace(this.$route.path, '')
      window.addEventListener('message', this.receivePopupMessage)
      this.popupWindow = window.open(
        `${baseURL}/unitmodify/`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    receivePopupMessage (event){
      if (event.data == 'READY') {
        this.windowReady = true
        this.updatePopupData(true)
      } else if (event.data == 'UPDATE'){
        this.$emit('update')
      }
    },
    updatePopupData (sendPreModifiedData) {
      // let unitData = {
      //   isWidgetFields: this.isWidgetFields,
      //   unitPartyLinks: this.unitPartyLinks,
      //   partyInSomeLink: this.partyInSomeLink,
      //   units: this.filteredUnits,
      //   unitShares: this.unitShares,
      //   parties: this.parties,
      //   purposesOfUse: this.purposesOfUse,
      //   cleaningAreas: this.cleaningAreas,
      //   fireAreas: this.fireAreas,
      //   costcenters: this.costcenters,
      //   unitVisibilityRights: this.unitVisibilityRights,
      //   modifiedUnits: sendPreModifiedData ? this.modifiedUnits : {},
      //   capacityQuantity: this.capacityQuantity,
      //   capacityUnit: this.capacityUnit,
      //   readonly: this.readonly,
      //   contractNumberSap: this.contractNumberSap,
      //   customizableFields: this.customizableFields
      // }
      
      // this.popupWindow.postMessage(unitData)
    },
    getCostcenters () {
      this.$rambollfmapi.organizations
        .costCenters()
        .list()
        .then(res => {
          this.costcenters = res.map(cc => {
            return {
              id: cc.id,
              name: cc.code + ' - ' + cc.name,
              allocation_rule: cc.allocation_rule,
              code: cc.code
            }
          })
        })
    },
    close () {
      this.unitModifyDialog = false
      this.$emit('close')
    },
    async getTags () {
      if (this.unitVisibilityRights?.tags) {
        this.availableTags = await this.$rambollfmapi.units.tags.get()
      }
    },
  }
}
</script>