<template>
  <div>
    <v-dialog
      v-model="changeDraftBuildingDialog"
      persistent
      max-width="550"
    >
      <BaseModal
        @cancel="cancel()"
      >
        <template #title>
          {{ $t('Building draft to be valid') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model.trim="newBuilding.building_name"
              :rules="[
                (v) => !!v || $t('Required'),
                indicatorMessage !== 'Building name is already in use.' || $t(indicatorMessage),
              ]"
              :label="$t('Building name') + ' *'"
              required
              class="form-field"
              @change="resetValidation"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_class"
              :label="$t('Building class')"
              disabled
              class="form-field"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_sub_class"
              :label="$t('Building sub class')"
              disabled
              class="form-field"
            />
            <v-text-field
              v-if="facta_building && !factaDisabled"
              v-model="factaBuilding.building_main_class"
              :label="$t('Building main class')"
              disabled
              class="form-field"
            />
            <v-text-field
              v-model.trim="newBuilding.building_code"
              :rules="[
                (v) => !!v || $t('Required'),
                indicatorMessage !== 'Building code is already in use.' || $t(indicatorMessage),
              ]"
              :label="$t('Building code') + ' *'"
              required
              class="form-field"
              @change="resetValidation"
            />
            <v-combobox
              v-model="newBuilding.building_usage"
              :value="newBuilding.building_usage"
              :items="usageDefs"
              :label="$t('Building usage')"
              item-text="label"
              item-value="id"
              class="form-field"
            />
            <template v-if="!factaDisabled">
              <v-row
                class="mt-6"
                no-gutters
              >
                <v-text-field
                  v-model.trim="factaBuilding.building_tunniste"
                  :label="$t('Building tunniste')"
                />
                <v-btn
                  rounded
                  depressed
                  class="primary"
                  @click="getBuildingData(factaBuilding.building_tunniste, 'sisainen_rakennusnumero')"
                >
                  {{ $t('Get from facta') }}
                </v-btn>
              </v-row>
              <v-row
                class="mt-2"
                no-gutters
              >
                <v-text-field
                  v-model="factaBuilding.building_identifier"
                  :label="$t('Building identifier')"
                />
                <v-btn
                  rounded
                  depressed
                  class="primary"
                  @click="getBuildingData(factaBuilding.building_identifier, 'pysyva_rakennustunnus')"
                >
                  {{ $t('Get from facta') }}
                </v-btn>
              </v-row>
            </template>
            <template v-if="facta_building && !factaDisabled">
              <v-text-field
                v-model="factaBuilding.completed_year"
                :label="$t('Completed year')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.address"
                :label="$t('Address')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.zip_code"
                :label="$t('Zip code')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.volume"
                :label="$t('Volume')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.total_floor_area"
                :label="$t('Total floor area')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.official_gross_area"
                :label="$t('Gross area')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.energy_class"
                :label="$t('Energy class')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="temporary"
                :label="$t('Temporary')"
                disabled
                class="form-field"
              />
              <v-text-field
                v-model="factaBuilding.temporary_until"
                :label="$t('Temporary until')"
                disabled
                class="form-field"
              />
            </template>
          </v-form>
        </template>
        <template #footer>
          <Alert
            :show="showIndicator"
            :result="operationResult"
            :message="$t(indicatorMessage)"
          />
          <v-btn
            :disabled="!valid || saving"
            depressed
            rounded
            color="primary"
            @click="draftToValid"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="white"
            />
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>
    <v-dialog
      v-model="addFloorDialog"
      persistent
      max-width="550"
    >
      <AriaLive />
      <BaseModal
        @cancel="cancel()"
      >
        <template #title>
          {{ $t('Add and delete floor') }}
        </template>
        <template #content>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-list>
              <v-row
                v-if="loading"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  :size="100"
                  :width="3"
                  indeterminate
                  color="primary"
                />
              </v-row>
              <draggable
                v-else
                v-model="floorData"
                :move="checkMove"
                group="floors"
                style="min-height: 10px"
              >
                <template v-for="floor in floorData">
                  <v-list-item
                    v-if="floor.id !== undefined || isButtonPushed"
                    :key="floor.id"
                    :class="{ activeButton: floor.id === undefined }"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ floor.floor_name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        align="right"
                        text="floor.floor_number"
                      />
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-row
                        justify="end"
                        no-gutters
                      >
                        <v-btn
                          v-if="floor.id !== undefined"
                          icon
                          right
                          @click="remove(floor)"
                        >
                          <v-icon>
                            delete
                          </v-icon>
                          <span class="d-sr-only">{{ $t('Delete') }}</span>
                        </v-btn>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </draggable>
            </v-list>
            <v-card
              v-if="isButtonPushed"
              elevation="0"
            >
              <v-combobox
                v-model="newFloor.floor_name"
                :rules="[v => !!v || $t('Required')]"
                :value="newFloor.floor_name"
                :items="floorNames"
                :placeholder="$t('Floor name')"
                required
                item-text="label"
                item-value="id"
                class="form-field"
              />
              <v-text-field
                v-model="newFloor.shortname"
                :placeholder="$t('Shortname')"
                maxlength="4"
                class="form-field"
              />
              <v-menu
                :close-on-content-click="false"
                class="form-field"
                min-width="0"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="startDateFormatted"
                    :label="$t('Floor start date')"
                    :rules="[v => !!v || $t('Required')]"
                    :hint="$t('Required') + ' ' + $t('Add_Floor.earliest') + ' ' + formatDate(buildingStartDate)"
                    required
                    persistent-hint
                    class="form-field"
                    prepend-icon="calendar_today"
                    v-on="on"
                    @blur="startDate = parseDate(startDateFormatted)"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="buildingStartDate"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  :locale="$i18n.locale"
                />
              </v-menu>
            </v-card>
          </v-form>
        </template>
        <template #footer>
          <v-btn
            text
            rounded
            outlined
            color="blue"
            @click="showFloorAdd()"
          >
            {{ $t('Add floor') }}
          </v-btn>
          <v-btn
            :disabled="!valid"
            rounded
            depressed
            class="primary"
            @click="save"
          >
            <span v-if="!saving">{{ $t('Save') }}</span>
            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
              color="primary"
            />
          </v-btn>
        </template>
        <Alert
          :show="showIndicator"
          :result="operationResult"
          :message="indicatorMessage"
          :y="yPosition"
        />
      </BaseModal>
    </v-dialog>
    <v-spacer />
    <v-btn
      v-if="selectedBuilding.is_draft && hasApplicationPermissionByName('RAKENNUKSEN_LISAYS_LUONNOS')"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="showDraftBuildingDialog()"
    >
      <span>{{ $t('Draft to valid') }}</span>
    </v-btn>
    <v-btn
      v-if="selectedBuilding.is_draft && hasApplicationPermissionByName('RAKENNUKSEN_LISAYS_LUONNOS')"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="deleteDraftBuilding(selectedBuilding.id_building)"
    >
      <span>{{ $t('Delete building') }}</span>
    </v-btn>
    <div class="block">
      <EndHierarchyItemDialog
        v-if="!selectedBuilding.is_draft"
        level="building"
        :id-from-parent="buildingId"
      />
    </div>
    <v-btn
      v-if="!selectedBuilding.is_draft && hasApplicationPermissionByName('SISVUOKRA_LASKIN')"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="openCalculatorModal()"
    >
      <span>{{ $t('Calculator') }}</span>
    </v-btn>
    <v-btn
      v-if="!selectedBuilding.is_draft"
      :disabled="disabled"
      small
      outlined
      rounded
      block
      class="ma-0 mt-2"
      @click="showAddFloorDialog()"
    >
      <span>{{ $t('Add or delete floor') }}</span>
    </v-btn>
    <v-dialog
      v-model="showCalculatorModal"
      persistent
      eager
      width="65%"
    >
      <Calculator
        @close="closeCalculatorModal()"
      />
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Alert from '../components/Alert'
import AriaLive from './AriaLive.vue'
import BaseModal from './general/BaseModal'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EndHierarchyItemDialog from './EndHierarchyItemDialog'
import Calculator from './Calculator.vue'

export default {
  components: {
    Alert,
    BaseModal,
    AriaLive,
    draggable,
    EndHierarchyItemDialog,
    Calculator,
  },
  props: ['buildingCode', 'buildingId', 'siteId'],
  data () {
    return {
      selectedBuilding: {
        is_draft: false
      },
      facta_building: false,
      factaDisabled: true,
      loading: false,
      saving: false,
      valid: true,
      startDate: undefined,
      startDateFormatted: undefined,
      buildingStartDate: undefined,
      isButtonPushed: false,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      yPosition: 'top',
      floorData: [],
      disabled: true,
      estate: null,
      wholeBuilding: null,
      floorNames: [
        'AUTOKATOS',
        'VESIKATTO',
        'ULLAKKO',
        '20.KERROS',
        '19.KERROS',
        '18.KERROS',
        '17.KERROS',
        '16.KERROS',
        '15.KERROS',
        '14.KERROS',
        '13.KERROS',
        '12.KERROS',
        '11.KERROS',
        '10.KERROS',
        '9.KERROS',
        '8.KERROS',
        '7.KERROS',
        '6.KERROS',
        '5.KERROS',
        '3.KERROS',
        '4.KERROS',
        '2.KERROS',
        '1,5.KERROS',
        '1.KERROS',
        '0,5.KERROS',
        'POHJAKERROS',
        'KELLARI',
        '1.KELLARI',
        'YLÄKELLARI',
        'ALAKELLARI',
        '2.KELLARI',
        '3.KELLARI',
        '4.KELLARI',
        '5.KELLARI'
      ],
      startDateCalendarVisible: false,
      addFloorDialog: false,
      changeDraftBuildingDialog: false,
      newFloor: {
        floor_name: null,
        shortname: null,
        floor_number: null,
        start_date: new Date().toISOString().substr(0, 10)
      },
      showCalculatorModal: false,
      factaBuilding: {
          building_identifier: null,
          building_tunniste: null,
          completed_year: null,
          address: null,
          zip_code: null,
          volume: null,
          total_floor_area: null,
          official_gross_area: null,
          energy_class: null,
          temporary: null,
        },
        newBuilding: {
          building_code: null,
          building_name: null,
          building_usage: null,
          coordinate_y: 0,
          coordinate_x: 0,
          latitude: 0,
          longitude: 0,
          managed_by_intg: false,
          start_date: new Date().toISOString().split('T')[0],
          is_draft: false,
        },
        changedNumbers: {
          coordinate_x: null,
          coordinate_y: null
        },
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName','definitionsByGroupLabel']),

    computedDateFormatted () {
      return this.formatDate(this.newFloor.start_date)
    }
  },
  watch: {
    addFloorDialog (value) {
      this.addFloorDialogFunc(value);
    },
    startDate () {
      this.startDateFormatted = this.formatDate(this.startDate)
    },
    floorData (value) {
      this.newFloor.floor_number = this.getNewFloorNumber()
    },
    'newFloor.floor_name' (value) {
      this.newFloor.floor_number = this.getNewFloorNumber()
    },
    isButtonPushed (value) {
      this.showFloorAdd(value)
    },
    changeDraftBuildingDialog (value) {
      this.loading = true
      if (value === true) {
        this.usageDefs = this.definitionsByGroupLabel('building.purposeofuse')
        this.loading = false
      }
      if(this.addStructureDialog === false){
        this.$refs.form?.resetValidation()
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    showCalculatorModal () {
      if (this.showCalculatorModal == true) {
        this.setupCalculatorStartingValues();
      }
    },
    async factaDisabled () {
      if (!this.factaDisabled) {
        this.wholeBuilding = await this.$rambollfmapi.buildings.get(this.buildingCode)
        if (this.wholeBuilding ) {
          this.estate = await this.$rambollfmapi.estates.get(this.wholeBuilding.id_estate)
          this.estateIdentifier = this.estate.estate_identifier
        }
      }
    }
  },
  async mounted () {
    this.selectedBuilding = await this.$rambollfmapi.buildings.getDraftVersion(this.buildingId);
    this.buildingStartDate = this.selectedBuilding.start_date;
    this.$rambollfmapi.accounts.currentgroups().then(res => {
      res.forEach(group => {
        if (
          group.applicationPermissions.find(app => {
            return app.id === '04.08.01'
          })
        ) {
          this.disabled = false
        }
        if (
          group.applicationPermissions.find(app => {
            return app.id === '9011'
          })
        ) {
          this.factaDisabled = false
        }
      })
    })
  },
  methods: {
    ...mapActions('app', ['getSpaces', 'getUnits', 'showConfirmDialog']),
    ...mapActions("calculator", ["setupCalculatorStartingValues", "setSelectedBuildings", "setDataFromSingleBuilding", "resetCalculatorData"]),
    checkMove (evt) {
      return evt.draggedContext.element.id === undefined
    },
    async deleteDraftBuilding (value) {
      const ok = await this.showConfirmDialog({
        title: 'Delete draft building',
        negative: 'Yes',
      })
      if (ok === false) {
        this.$rambollfmapi.buildings.deleteDraft(value).then(res => {
          if (res.status === 200) {
            this.indicatorMessage = this.$t('Succesfull delete')
            this.operationResult = 'success'
            this.showIndicator = true
            this.$emit('delete', { message: this.indicatorMessage, operationResult: this.operationResult })
          } else {
            this.indicatorMessage = this.$t('Delete building failed')
            this.operationResult = 'error'
            this.showIndicator = true
          }
        })
      }
    },
    async getBuildingData (property, propertyName) {
      if ( property === null) {
        return
      }
      this.loading = true;
      const building = await this.$rambollfmapi.buildings.facta(propertyName, property, this.estateIdentifier)
      this.facta_building = false
      Object.keys(this.factaBuilding).forEach(key => {
        delete this.factaBuilding[key];
      })
      if (building.building_identifier) {
        this.newBuilding.building_usage = this.usageDefs.id
        this.newBuilding.managed_by_intg = true
        this.changedNumbers.coordinate_x = building.coordinate_x
        this.changedNumbers.coordinate_y = building.coordinate_y
        this.factaBuilding ={
          building_identifier: building.building_identifier,
          building_tunniste: building.building_tunniste,
          completed_year: building.completed_year,
          address: building.address,
          zip_code: building.zip_code,
          volume: building.volume,
          total_floor_area: building.total_floor_area,
          official_gross_area: building.official_gross_area,
          energy_class: building.energy_class,
          temporary: building.temporary,
          building_class: building.building_class,
          building_class_id: building.building_class_id,
          building_main_class: building.building_main_class,
          building_main_class_id: building.building_main_class_id,
          building_sub_class: building.building_sub_class,
          building_sub_class_id: building.building_sub_class_id
        }
        this.temporary = this.factaBuilding.temporary ? this.$t('true') : this.$t('false')
        this.facta_building = true;
      }
      else {
        Object.keys(this.newBuilding).forEach(key => {
        delete this.newBuilding[key];
      })
        this.newBuilding.managed_by_intg = false
        this.newBuilding.start_date = new Date().toISOString().split('T')[0]
        this.changedNumbers.coordinate_x = null
        this.changedNumbers.coordinate_y = null
        this.showIndicator = true
        this.operationResult = 'error'
        this.indicatorMessage = building
      }
      this.loading = false;
    },
    resetValidation (){
      this.$refs.form.resetValidation()
      this.showIndicator = false
      this.operationResult = ''
      this.indicatorMessage = ''
    },
    async draftToValid () {
      const time = new Date().toISOString().split('T')[0]
      if (this.saving) {
        return;
      }
      // Check if factaBuilding is empty or all its values are null
      const isFactaBuildingEmpty = !Object.values(this.factaBuilding).some(value => value !== null);
      const newCode = this.newBuilding.building_code;
      const newName = this.newBuilding.building_name;
      const newUsage = this.newBuilding.building_usage;
      if (isFactaBuildingEmpty) {
        // If factaBuilding is empty, map the properties from selectedBuilding to newBuilding
        this.newBuilding = {
          building_code: newCode,
          building_usage: newUsage,
          building_name: newName,
          coordinate_y: this.selectedBuilding.coordinate_y,
          coordinate_x: this.selectedBuilding.coordinate_x,
          latitude: this.selectedBuilding.latitude,
          longitude: this.selectedBuilding.longitude,
          managed_by_intg: this.selectedBuilding.managed_by_intg,
          start_date: this.selectedBuilding.start_date,
          is_draft: false,
        };
      }
      if (this.$refs.form.validate()) {
        this.saving = true;
        let obj = {};
        Object.keys(this.newBuilding).forEach(key => {
          obj[key] = this.newBuilding[key];
        });
        Object.keys(this.factaBuilding).forEach(key => {
          obj[key] = this.factaBuilding[key];
        });
        const toSave = JSON.parse(JSON.stringify(obj));
        const buildings = [toSave]; // Create an array with the new building
        for (let i = 0; i < buildings.length; i++) {
          const building = buildings[i];
          building.patch = []; // Initialize patch array

          // Add all properties of newBuilding and factaBuilding to the patch array
          Object.keys(this.newBuilding).forEach(key => {
              if (key === 'building_usage' && this.newBuilding[key] && this.newBuilding[key].id) {
                  building.patch.push({
                      op: 'replace',
                      path: `/${key}`,
                      value: this.newBuilding[key].id
                  });
              } else {
                  building.patch.push({
                      op: 'replace',
                      path: `/${key}`,
                      value: this.newBuilding[key]
                  });
              }
          });

          Object.keys(this.factaBuilding).
            filter(key =>
              key !== 'building_class' &&
              key !== 'building_main_class' && key !== 'building_main_class_id' &&
              key !== 'building_sub_class' && key !== 'building_sub_class_id').
            forEach(key => {
              if (key === 'building_usage' && this.factaBuilding[key] && this.factaBuilding[key].id) {
                  building.patch.push({
                      op: 'replace',
                      path: `/${key}`,
                      value: this.factaBuilding[key].id
                  });
              } else {
                  building.patch.push({
                      op: 'replace',
                      path: `/${key}`,
                      value: this.factaBuilding[key]
                  });
              }
          });
          const buildingsResult = await this.$rambollfmapi.buildings.draftToValid(
            this.selectedBuilding.building_code,
            time,
            building.patch,
            building.building_code,
            building.building_name,
            this.selectedBuilding.id_building
          );
          if (buildingsResult.name === 'Error') {
            this.indicatorMessage = this.$t("Error while saving");
            this.showIndicator = true;
            this.operationResult = "error";
          }
          else {
            this.indicatorMessage = this.$t("Succesfull save");
            this.showIndicator = true;
            this.operationResult = "success";
          }
          building.patch = null;
          building.original = JSON.parse(JSON.stringify(building));
        }
        this.saving = false;
        this.changeDraftBuildingDialog = false;
        this.$emit('update', { message: this.indicatorMessage, operationResult: this.operationResult });
      }
    },
    clearForm () {
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.resetValidation()
      }
    });
      this.newFloor.floor_name = null
      this.newFloor.shortname = null
      this.newFloor.start_date = new Date().toISOString().substr(0, 10)
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      if (day && month && year) {
      const parsedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      return parsedDate < this.buildingStartDate ? this.buildingStartDate : parsedDate
      }
      return new Date()
    },
    formatDate (date) {
      if (!date) return null
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : null
    },
    showAddFloorDialog () {
      this.addFloorDialog = true
    },
    showDraftBuildingDialog () {
      this.changeDraftBuildingDialog = true
    },
    openCalculatorModal () {
      if (this.siteId !== undefined && this.buildingId !== undefined) {
        const buildingData = {
          siteId: this.siteId,
          buildingId: this.buildingId
        };
        this.setDataFromSingleBuilding(buildingData);
      }
      this.showCalculatorModal = true
    },
    closeCalculatorModal () {
      this.resetCalculatorData()
      this.showCalculatorModal = false
    },
    cancel () {
      this.changeDraftBuildingDialog = false
      this.addFloorDialog = false
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.reset();
      }
    });
    },
    getNewFloorNumber () {
      // Extract floor number from floor name when possible
      let floorNumberString = null
      let floorNumber = null
      if (
        this.newFloor.floor_name !== null &&
        (this.newFloor.floor_name.includes('.KERROS') ||
          this.newFloor.floor_name.includes('.KELLARI'))
      ) {
        floorNumberString = this.newFloor.floor_name.split('.')[0]
        floorNumberString = floorNumberString.replace(',', '.')
        floorNumber = Number(
          (this.newFloor.floor_name.includes('KELLARI') ? '-' : '') +
            floorNumberString
        )
      }
      if (floorNumber != null) {
        return floorNumber
      }

      const newFloorIndex = this.floorData.findIndex(f => f.id === undefined)
      const floorAbove = this.floorData[newFloorIndex - 1]
        ? this.floorData[newFloorIndex - 1].floor_number
            : undefined
      const floorBelow = this.floorData[newFloorIndex + 1]
        ? this.floorData[newFloorIndex + 1].floor_number
        : undefined

      if (floorAbove - 1 >= floorBelow + 1) {
        // When there is empty space between floor above and below
        if (floorBelow >= 0) {
          return floorBelow + 1
        } else {
          return floorAbove - 1
        }
      } else if (Math.abs(floorAbove - floorBelow) <= 1) {
        // Mezzanines and other curiosities between normal floors
        if (floorAbove > floorBelow) {
          return (floorBelow + (floorAbove - floorBelow) / 2).toFixed(1)
        } else {
          return (floorBelow + (floorAbove + floorBelow) / 2).toFixed(1)
        }
      } else if (
        typeof floorAbove === 'undefined' &&
        typeof floorBelow !== 'undefined'
      ) {
        // Highest floor
        return floorBelow + 1
      } else if (
        typeof floorAbove !== 'undefined' &&
        typeof floorBelow === 'undefined'
      ) {
        // Lowest floor
        return floorAbove - 1
      } else if (typeof floorBelow !== 'undefined' && floorBelow >= 0) {
        return floorBelow + 1
      } else if (typeof floorAbove !== 'undefined' && floorAbove < 0) {
        return floorAbove - 1
      }
      return 0
    },
    save () {
      if (this.saving) {
        return
      }

      if (this.$refs.form.validate()) {
        this.saving = true

        const toSave = JSON.parse(JSON.stringify(this.newFloor))
        if (toSave.shortname === '') {
          toSave.shortname = null
        }
        toSave.start_date = this.startDate;
        this.$rambollfmapi.buildings
          .floors(this.buildingCode)
          .post(toSave)
          .then(res => {
            this.addFloorDialog = false
            this.clearForm()
            this.$store.dispatch('app/addAriaLive', this.$t('aria_live.save_succesfull'))
            this.saving = false
          })
      }
      if (this.isButtonPushed === true) {
        this.isButtonPushed = false
      }
      this.addFloorDialogFunc(true);

    },
    remove (floor) {
      if (confirm(this.$t('Delete floor info'))) {
        this.$rambollfmapi.floors.delete(floor.id)
          .then(res => {
            if (res.status === 200) {
              this.indicatorMessage = this.$t('Succesfull delete')
              this.operationResult = 'success'
              this.showIndicator = true
            } else {
              this.indicatorMessage = this.$t('Delete floor failed')
              this.operationResult = 'error'
              this.showIndicator = true
            }
            this.addFloorDialogFunc(true);
          })
      }
    },
    showFloorAdd (value) {
      if (value === false) {
        this.isButtonPushed = false
      } else {
        this.isButtonPushed = true
      }
    },
    addFloorDialogFunc (value) {
      this.$nextTick(() => {
      if (this.$refs['form']) {
        this.$refs.form.resetValidation()
      }
    });
      this.loading = true
      if (value === true) {
        this.$rambollfmapi.buildings
          .floors(this.buildingCode)
          .list()
          .then(result => {
            result.unshift(this.newFloor)
            this.floorData = result.sort((a, b) =>
              a.floor_number < b.floor_number ? 1 : -1
            )
            this.loading = false
          })
      }
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
  }
}
</script>
<style scoped>
.block >>> .v-btn {
  width: 100%;
}
</style>
