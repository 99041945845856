<template>
  <v-combobox
    ref="searchRef"
    v-model="searchField"
    :items="items"
    :search-input.sync="searchTerm"
    :label="$t('Search from Circle')"
    :item-text="item => `${item.defaultValue} ${item.defaultValue != item.foundValue ? ', ' + item.foundValue : ''}`"
    item-value="identifier"
    prepend-icon="search"
    append-icon=""
    class="search mt-0"
    :loading="isLoading"
    :menu-props="{ maxWidth: '800px', maxHeight: '50vh', }"
    return-object
    clearable
  >
    <template #prepend-item>
      <v-list-item>
        <v-list-item-content class="search__description">
          <div>
            <v-progress-circular
              v-if="isLoading"
              size="48"
              indeterminate
              color="primary"
            />
            <v-icon
              v-else
              size="48"
              color="primary"
            >
              search
            </v-icon>
          </div>
          <div class="text-h6">
            {{ $t('Search from Circle') }}
          </div>
          <p>{{ $t('search.description') }}</p>
          <div class="search__categories">
            <v-chip small>
              <v-icon small>
                star
              </v-icon>
              {{ $t('Favourites') }}
            </v-chip>
            <v-chip small>
              <v-icon small>
                location_on
              </v-icon>
              {{ $t('Sites') }}
            </v-chip>
            <v-chip small>
              <v-icon small>
                home
              </v-icon>
              {{ $t('Buildings') }}
            </v-chip>
            <v-chip
              v-if="hasApplicationPermissionByName('HANKE')"
              small
            >
              <v-icon small>
                construction
              </v-icon>
              {{ $t('Building Projects') }}
            </v-chip>
            <v-chip
              v-if="hasApplicationPermissionByName('LEASING')"
              small
            >
              <v-icon small>
                handshake
              </v-icon>
              {{ $t('rentalprocesses') }}
            </v-chip>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #no-data>
      <div class="text-center">
        <p><strong>{{ $t('search.no_results') }}</strong></p>
      </div>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar color="blue lighten-5">
        <v-icon color="primary">
          {{ item.icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ `${item.defaultValue} ${item.defaultValue != item.foundValue ? ', ' + item.foundValue : ''}` }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.category }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import _debounce from 'lodash/debounce'

export default {
  name: 'SiteSearch',
  components: {
  },
  data () {
    return {
      searchField: '',
      selectedResult: null,
      timeout: 0,
      isLoading: false,
      items: [],
      queryTerm: ''
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    searchTerm: {
      get () {
        return this.queryTerm
      },
      set (searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput
          this.debouncedSearch(searchInput)
        }
      }
    }
  },
  watch: {
    searchField (val) {
      if (typeof val === 'object') {
        this.focusToSearchResult(val)
      }
    }
  },
  mounted () {
    this.debouncedSearch()
  },
  methods: {
    clickResult (result) {
      this.focusToSearchResult(result)
    },
    getCategoryOrder (category) {
      switch (category) {
        case 'favourites': {
          return 1
        }
        case 'sites': {
          return 2
        }
        case 'buildings': {
          return 3
        }
        case 'rentalprocesses': {
          return 4
        }
        case 'buildingprojects': {
          return 5
        }
        default: {
          return 100
        }
      }
    },
    getCategoryIcon (category) {
      switch (category) {
        case 'favourites': {
          return 'star'
        }
        case 'sites': {
          return 'location_on'
        }
        case 'buildings': {
          return 'home'
        }
        case 'rentalprocesses': {
          return 'handshake'
        }
        case 'buildingprojects': {
          return 'construction'
        }
        default: {
          return 'view_list'
        }
      }
    },
    debouncedSearch: _debounce(function (val) {
        this.search(val)
    }, 500),
    async search (val) {
      this.isLoading = true

      // Cancel the previous in progress request before proceeding
      if (this.abortController) {
        await this.abortController.abort()
      }
      this.abortController = new AbortController() 
      const signal = this.abortController.signal
      const params = {
        limit: 500,
        time: this.currentDate,
        search: val
      }
      const categories = await this.$rambollfmapi.search.get(params, signal)
      const orderedCategories = Object.entries(categories)
        .filter(e => !(e[0] === 'rentalprocesses' && !this.hasApplicationPermissionByName('LEASING')))
        .map(e => ({
          ...e,
          icon: this.getCategoryIcon(e[0]),
          order: this.getCategoryOrder(e[0])
        }))
        .sort((a, b) => a.order - b.order);

      const items = orderedCategories.map(entry => {
        if (!Array.isArray(entry[1])) return
        
        const divider = { divider: true }
        const category = { header: this.$t(entry[0]) }
        const items =
          entry[1].map(item => ({
            ...item,
            defaultValue: entry[0] !== 'rentalprocesses' ? item.defaultValue : `${this.$t(item.additionalValue)}: ${item.defaultValue}`,
            foundValue: entry[0] !== 'rentalprocesses' ? item.foundValue : `${this.$t(item.additionalValue)}: ${item.defaultValue}`,
            icon: this.getCategoryIcon(entry[0]),
            category: this.$t(entry[0]),
          }))
        return [ divider, category, ...items ]
      }).flat()

      this.items = items
      this.isLoading = false
    },
    focusToSearchResult (result) {
      this.selectedResult = result
      this.searchDone()
    },
    searchDone () {
      if (this.selectedResult) {
        this.$emit('select', {
          query: this.selectedResult.defaultValue,
          results: this.selectedResult,
        })
      }
      this.$refs.searchRef.internalSearch = ''
      this.selectedResult = null
      this.searchField = null
    }
  }
}
</script>
<style scoped>
.search {
  margin: 0 4px;
  border: var(--r-color-mountain-40);
  background: var(--r-color-white);
}
.search__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0;
}
.search__categories {
  display: flex;
  gap: 8px;
  margin: 16px 0 24px;
}
>>> .v-subheader {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--r-color-white);
  text-transform: uppercase;
}
>>> .v-list-item {
  overflow: ellipsis;
  white-space: nowrap;
}
>>> .theme--light.v-chip:hover::before {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .search.v-input:has(input:focus) {
    position: absolute;
    background: var(--r-color-white);
    inset: 20px 4px;
    z-index: 1;
  }
}
</style>
