import Vue from 'vue'
import Vuex from 'vuex'

import appStore from './app'
import sitesStore from './sites'
import ltpStore from './ltp'
import dashboardStore from './dashboard'
import internalRentStore from './internalRent'
import leasingStore from './leasing'
import personnelAllocationStore from './personnelAllocation'
import errorStore from './error'
import rentingGoalStore from './rentingGoal'
import openBills from './openBills'
import haltia from './haltia'
import billingStore from './billing'
import carpark from './carpark'
import rentalProcess from './rentalProcess'
import calculatorStore from './calculator'
import contracts from "./contracts"
import costCenters from './costCenters'
import esg from './esg'
import rentContracts from './rentContracts'
import device from './device'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sites: sitesStore,
    app: appStore,
    dashboard: dashboardStore,
    internalRent: internalRentStore,
    ltp: ltpStore,
    leasing: leasingStore,
    error: errorStore,
    personnelAllocation: personnelAllocationStore,
    rentingGoal: rentingGoalStore,
    openBills,
    haltia,
    billing: billingStore,
    carpark,
    rentalProcess,
    calculator: calculatorStore,
    contracts,
    costCenters,
    esg,
    rentContracts,
    device,
  },
  actions: {
    setApiEndpoint ({ state }, endpoint) {
      state.app.apiEndpoint = endpoint
      state.sites.apiEndpoint = endpoint
      state.ltp.apiEndpoint = endpoint
      state.dashboard.apiEndpoint = endpoint
      state.internalRent.apiEndpoint = endpoint
      state.leasing.apiEndpoint = endpoint
      state.personnelAllocation.apiEndpoint = endpoint
      state.rentingGoal.apiEndpoint = endpoint
      state.billing.apiEndpoint = endpoint
      state.calculator.apiEndpoint = endpoint
      state.esg.apiEndpoint = endpoint
      state.rentContracts.apiEndpoint = endpoint
      state.device.apiEndpoint = endpoint
    },
    setApiToken ({ state }, token) {
      state.app.apiToken = token
      state.sites.apiToken = token
      state.ltp.apiToken = token
      state.dashboard.apiToken = token
      state.internalRent.apiToken = token
      state.leasing.apiToken = token
      state.personnelAllocation.apiToken = token
      state.rentingGoal.apiToken = token
      state.billing.apiToken = token
      state.calculator.apiToken = token
      state.esg.apiToken = token
      state.rentContracts.apiToken = token
      state.device.apiToken = token
    }
  }
})
