<template>
  <v-expansion-panels
    class="my-2"
    :value="[0]"
    multiple
  >
    <v-expansion-panel
      class="inner-expansion"
    >
      <v-expansion-panel-header>
        {{ $t('Devices') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list>
          <v-list-item
            v-for="device in devicesForSpaces(space.id)"
            :key="device.id_device"
          >
            <v-list-item-content>
              <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 0.5em; padding-right: 0.5em">
                <span>{{ getDeviceText(device) }}</span>
                <div>
                  <cds-button
                    :aria-label="$t('device.edit')"
                    type="primary"
                    icon-left="edit"
                    :hide-text="true"
                    @click="openAddDeviceDialog(device.id_device)"
                  />
                  <cds-button
                    :aria-label="$t('device.delete')"
                    type="danger"
                    icon-left="delete"
                    :hide-text="true"
                    :disabled="!canEdit"
                    @click="deleteDevice(device.id_device)"
                  />
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'
import CdsButton from '../../components/CircleDesignSystem/CdsButton.vue';

export default {
  name: 'SpaceDeviceInfo',
  components: {
    CdsButton,
  },
  props: {
    space: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('device', ['devices']),
    ...mapGetters('app', ['definitionLabelById'])
  },
  methods: {
    ...mapActions('app', ['showConfirmDialog']),
    ...mapActions('device', ['endDevice', 'openAddDeviceDialog', 'closeAddDeviceDialog']),
    devicesForSpaces (spaceId) {
      return this.devices.filter(device => device.id_space === spaceId)
    },
    getDeviceText (device) {
      return device.def_type ? this.$t(this.definitionLabelById(device.def_type)) : device.name
    },
    async deleteDevice (deviceId) {
      const ok = await this.showConfirmDialog({
        title: 'device.delete_device_confirm',
        subtitle: 'device.delete_subtitle',
        negative: 'Yes',
      })
      if (ok === false) {
        await this.endDevice(deviceId)
      }
    },
  }
}
</script>