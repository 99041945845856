<template>
  <ViewAndSelections
    contract-level="rentroll"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t("Choose visible columns") }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t("Export to PDF") }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t("Export to spreadsheet") }}
        </v-btn>
      </v-row>
    </template>
    <template #table="props">
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="headers"
        :footers="debouncedFooters"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="
          props.props.storedView ? props.props.storedView.name : null
        "
        :stored-views="
          props.props.storedViews.filter((sv) => sv.level === 'rentroll')
        "
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>
import { mapState } from "vuex"
import Table from "./../Table"
import ViewAndSelections from "./ViewAndSelections.vue"
import { CorporationLinker } from "../../helpers/leasing/corporations"
import { getBooleanString } from '../../helpers/mappers/booleanString'
import { getContractDurationLeft } from "../../helpers/contract"
import moment from 'moment'

export default {
  name: "CarparkRentRollLevel",
  components: {
    Table,
    ViewAndSelections,
  },
  props: {
    loading: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    corporations: { type: Array, default: () => [] },
  },
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      timer: null,
      headers: [
        // General info
        {
          text: this.$t("Site name"),
          value: "site",
        },
        {
          text: this.$t("Floor"),
          value: "floor",
        },
        {
          text: this.$t("Car park id"),
          value: "carpark",
        },
        {
          text: this.$t("Car park identifier"),
          value: "carparkIdentifier",
        },
        {
          text: this.$t("Carpark.area"),
          value: "carparkArea",
          format: "Area",
        },
        {
          text: this.$t("Rental situation"),
          value: "rental_situation",
        },
        {
          text: this.$t("Tenant"),
          value: "tenant",
        },
        {
          text: this.$t('Renter'),
          value: 'renter',
        },
        {
          value: "tenant_category",
          text: this.$t("Tenant category"),
          format: "",
          editable: false,
          ignoreHeader: true,
        },
        {
          value: "credit_rating",
          text: this.$t("Credit rating"),
          format: "",
          editable: false,
          ignoreHeader: true,
        },
        {
          text: this.$t("rentRoll.contractNum"),
          value: "contractNumber",
        },
        {
          text: this.$t("Industry"),
          value: "industry",
        },
        {
          text: this.$t("rentRoll.contractType"),
          value: "contractType",
        },
        {
          text: this.$t("Contract area"),
          value: "contractArea",
          format: "Area",
        },
        {
          text: this.$t("rentRoll.sameContractNumber"),
          value: "contractTotalArea",
          format: "Area",
        },
        // Rental period info
        {
          text: this.$t("rentRoll.durationLeft"),
          value: "contractDurationLeft",
          format: "Number",
        },
        {
          text: this.$t("rentRoll.contractStart"),
          value: "contractStartDate",
          format: "Date",
        },
        {
          text: this.$t("rentRoll.contractEnd"),
          value: "contractEndDate",
          format: "Date",
        },
        {
          text: this.$t("rentRoll.validityNotice"),
          value: "contractValidityNoticeTr",
        },
        {
          text: this.$t('leasing.additional_fixed_term_info'),
          format: '',
          hideOverflow: true,
          value: "contractDetails",
        },
        {
          text: this.$t("rentRoll.noticeTenant"),
          value: "tenantNoticePeriod",
          format: "Number",
        },
        {
          text: this.$t("rentRoll.noticeLandlord"),
          value: "landlordNoticePeriod",
          format: "Number",
        },
        {
          text: this.$t("rentRoll.firstEndDate"),
          value: "contractFirstPossibleEndDate",
          format: "Date",
        },
        {
          text: this.$t("rentRoll.tenantFirstEndDate"),
          value: "tenantFirstPossibleEndDate",
          format: "Date",
        },
        {
          text: this.$t("rentRoll.landlordFirstEndDate"),
          value: "landlordFirstPossibleEndDate",
          format: "Date",
        },
        // Capital rent
        {
          text: this.$t("rentRoll.carparkCapitalRent"),
          value: "carparkCapitalRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.capitalRentContract"),
          value: "contractCapitalRent",
          format: "Euro",
        },
        /*{
          text: this.$t('rentRoll.LV'),
          value: '',
          format: 'Number'
        },*/
        {
          text: this.$t("Annual rent"),
          value: "carparkYearlyCapitalRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.capitalRentSquare"),
          value: "carparkCapitalRentPerSquare",
          format: "Euro",
        },
        // Other payments
        {
          text: this.$t("rentRoll.carparkmaintenanceRent"),
          value: "carparkUpkeepRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.carparkmaintenanceYearly"),
          value: "carparkYearlyUpkeepRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.maintenanceSquare"),
          value: "carparkUpkeepRentPerSquare",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.changeoverCarpark"),
          value: "carparkChangeoverRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.changeoverContract"),
          value: "contractChangeoverRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.separateAllowanceCarpark"),
          value: "carparkSeperateAllowances",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.separateContract"),
          value: "contractSeperateAllowances",
          format: "Euro",
        },
        // Indexing
        {
          text: this.$t("rentRoll.reviewType"),
          value: "contractReviewType",
        },
        {
          text: this.$t("Index type"),
          value: "contractIndexType",
        },
        {
          text: this.$t("rentRoll.reviewMonth"),
          value: "contractReviewMonths",
        },
        {
          text: this.$t("rentRoll.minimumIncrease"),
          value: "contractMinimumRise",
          format: "Percentage",
        },
        // Discounts
        {
          text: this.$t("rental_status.discounts"),
          value: "carparkDiscounts",
          format: "Euro",
        },
        // VAT responsibility
        {
          text: this.$t("rentRoll.vat_responsibility"),
          value: "vat_status",
        },
        {
          text: this.$t("Carpark description"),
          value: "carparkDetails",
          hideOverflow: true,
        },
        {
          text: this.$t("Carpark version start date"),
          value: "carparkStartDate",
          format: "Date",
        },
        {
          text: this.$t("Carpark version end date"),
          value: "carparkEndDate",
          format: "Date",
        },
        // Unit commercial info
        {
          text: this.$t("rentRoll.marketRentSquare"),
          value: "carparkMarketRentPerSquare",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.marketRentUnit"),
          value: "carparkMarketRent",
          format: "Euro",
        },
        {
          text: this.$t("rentRoll.rentTarget"),
          value: "carparkRentTarget",
          format: "Euro",
        },
        {
          text: this.$t("Vacancy rate assumption"),
          value: "vacancyRateAssumption",
        },
        {
          text: this.$t("Vacancy rate assumption updated"),
          value: "vacancyRateAssumptionDate",
          format: "Date",
        },
        {
          text: this.$t("Lease plan"),
          value: "carparkRentPlan",
          hideOverflow: true,
        },
        {
          text: this.$t("rentRoll.commercialInfoUpdated"),
          value: "carparkCommercialInfoUpdated",
          format: "Date",
        },
      ],
      footerDefinitions: [
        { text: this.$t("Total") },
        { value: "carparkArea", type: "sum" },
        {
          value: "contractDurationLeft",
          type: "weightedAverage",
          weight: "carparkArea",
        },
        { value: "carparkCapitalRent", type: "sum" },
        { value: "carparkYearlyCapitalRent", type: "sum" },
        { value: "carparkCapitalRentPerSquare", type: "average" },
        { value: "carparkUpkeepRent", type: "sum" },
        { value: "carparkYearlyUpkeepRent", type: "sum" },
        { value: "carparkUpkeepRentPerSquare", type: "average" },
        { value: "carparkChangeoverRent", type: "sum" },
        { value: "carparkSeperateAllowances", type: "sum" },
        { value: "carparkDiscounts", type: "sum" },
        { value: "carparkMarketRentPerSquare", type: "average" },
        { value: "carparkMarketRent", type: "sum" },
        { value: "carparkRentTarget", type: "average" },
      ],
      pagination: {
        sortDesc: [false],
        itemsPerPage: 100,
        sortBy: ["site_name"],
      },
      exporting: {},
    }
  },
  computed: {
    ...mapState("app", ["currentDate"]),
    corporationLinker () {
      return new CorporationLinker(this.corporations)
    },
    isMobile () {
      return visualViewport.width <= 760
    },
    viewData () {
      return this.data.map((item) => {
        const corporation = this.corporationLinker.find({
          businessId: item.tenantBusinessId,
          contractNumber: item.contractNumber,
          partyId: item.tenantId,
        })

        const contractDurationLeft = getContractDurationLeft(item.contractEndDate, item.contractFirstPossibleEndDate, this.currentDate)
        const calculatedFields = this.calculatedFields(item)

        return {
          ...item,
          id_site: item.idSite,
          vat_status: getBooleanString(item.contractVatResponsibility),
          contractValidityNoticeTr: getBooleanString( item.contractValidityNotice),
          tenant_category: corporation?.tenant_category,
          credit_rating: corporation?.credit_rating,
          contractDurationLeft:
            contractDurationLeft > 0 ? contractDurationLeft : null,
          rental_situation: item.rentalSituation,
          ...calculatedFields,
        }
      })
    },
  },
  methods: {
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    calculatedFields (item) {
      /*
  -- Alennukset ** [jaetaan sopimusnumero pinta-alan suhteessa; pitää vielä spekata onko kk/vuosi-taso]
*/

      const {
        carparkArea,
        contractTotalArea,
        contractCapitalRent,
        contractUpkeepRent,
        contractChangeoverRent,
        contractSeperateAllowances,
        contractDiscounts,
        carparkMarketRent,
        carparkCount,
        contractCarparkCount,
      } = item

      let carparkAreaItems = {}
      if (carparkArea && contractTotalArea) {
        const carparkAreaRatio = carparkArea / contractTotalArea
        carparkAreaItems = {
          carparkCapitalRentPerSquare: carparkAreaRatio * contractCapitalRent / carparkArea,
          carparkUpkeepRentPerSquare: carparkAreaRatio * contractUpkeepRent / carparkArea,
          carparkMarketRentPerSquare: carparkMarketRent / carparkArea,
        }
      }

      if (!carparkCount || !contractCarparkCount) {
        return carparkAreaItems
      }

      const carparkCountRatio = carparkCount / contractCarparkCount

      const carparkCapitalRent = carparkCountRatio * contractCapitalRent
      const carparkUpkeepRent = carparkCountRatio * contractUpkeepRent

      return {
        carparkCapitalRent,
        carparkYearlyCapitalRent: carparkCapitalRent * 12,
        carparkUpkeepRent,
        carparkYearlyUpkeepRent: carparkUpkeepRent * 12,
        carparkChangeoverRent: carparkCountRatio * contractChangeoverRent,
        carparkSeperateAllowances:
          carparkCountRatio * contractSeperateAllowances,
        carparkDiscounts: carparkCountRatio * contractDiscounts,
        ...carparkAreaItems,
      }
    },
    // Footers calculation function is debounced, so it doesn't get called on every keypress
    debouncedFooters (headers, data) {
      clearTimeout(this.timer)
      const footerSums = []
      const timeout = 500
      this.timer = setTimeout(() => {
        const footerValues = this.footerDefinitions
        const calculatedFooterValues = {}
        calculatedFooterValues.site_name = this.$t("Total")
        footerValues.forEach((footer) => {
          const value = footer.value
          let result = null
          switch (footer.type) {
            case "sum": {
              result = data
                .map((r) => (isNaN(r[value]) ? 0 : Number(r[value])))
                .reduce((acc, cur) => acc + cur, 0)
                .toFixed(2)
              break
            }
            case "weightedAverage": {
              const weight = footer.weight
              const dataWeights = data.map((r) => ({
                value: isNaN(r[value]) ? 0 : Number(r[value]),
                weight: isNaN(r[weight]) ? 0 : Number(r[weight]),
              }))
              const [sum, weightSum] = dataWeights.reduce(
                (acc, curr) => {
                  if (curr.value) {
                    acc[0] += curr.value * curr.weight
                    acc[1] += curr.weight
                  }
                  return acc
                },
                [0, 0]
              )
              result = weightSum ? (sum / weightSum).toFixed(2) : null
              break
            }
            case "average": {
              const sum = data
                .map((r) => (isNaN(r[value]) ? 0 : Number(r[value])))
                .reduce((acc, cur) => acc + cur, 0)
                .toFixed(2)
              const itemCount = data.filter(
                (item) => item[value] != null
              ).length
              result = itemCount ? sum / itemCount : null
              break
            }
            default: {
              return null
            }
          }
          if (result) {
            calculatedFooterValues[value] = result
          }
        })
        footerSums.push(calculatedFooterValues)
      }, timeout)
      return footerSums
    },
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push("rent_roll")
    },
  },
}
</script>

<style></style>
