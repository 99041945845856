<template>
  <div>
    <v-dialog
      v-if="selectedSpaceForCommentModify"
      v-model="spaceCommentModify"
      max-width="800"
      persistent
    >
      <BaseModal
        @cancel="spaceCommentModify = false"
      >
        <template #title>
          {{ $t('Add comments to space') }}
        </template>
        <template #content>
          <v-textarea
            v-model="modifyComment"
            :placeholder="$t('Write comment here')"
            autofocus
            type="text"
            style="margin: 2em"
            counter="800"
            :rules="[rules.counter800]"
            clearable
          />
        </template>
        <template #footer>
          <v-btn
            text
            rounded
            class="primary"
            :disabled="isSaveCommentDisabled"
            @click="saveComment()"
          >
            {{ $t('Save') }}
          </v-btn>
        </template>
      </BaseModal>
    </v-dialog>

    <v-dialog
      v-if="selectedSpacesFromViewer.length > 0 || selectedUnitsFromViewer.length === 1"
      v-model="unitManageDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <UnitManageForm
          :unit="selectedUnitsFromViewer[0]"
          :units="units"
          :spaces="spaces"
          :selected-spaces="selectedSpacesFromViewer"
          :floor="selectedFloor"
          :party-links="unitPartyLinks"
          :parties="parties"
          :new-unit-placeholder-name="newUnitNamePlaceholder"
          @close="unitManageDialog = false"
          @updated="$emit('update')"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddDeviceDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <add-device-form
          :disabled="!appRights.editDevices"
          :selected-space="selectedSpacesFromViewer[0]"
          :selected-floor="selectedFloor"
          @close="closeAddDeviceDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedSpacesFromViewer.length > 0 || selectedUnitsFromViewer.length === 1"
      v-model="unitTransferDialog"
      persistent
    >
      <v-card>
        <UnitTransferForm
          :unit="selectedUnitsFromViewer[0]"
          :units="units"
          :spaces="spaces"
          :selected-spaces="selectedSpacesFromViewer"
          :floor="selectedFloor"
          :party-links="unitPartyLinks"
          :parties="parties"
          :edit-unit-not-new-version="editUnitNotNewVersion"
          @close="unitTransferDialog = false"
          @updated="$emit('update')"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="spaceModifyDialog"
      max-width="1200"
      persistent
    >
      <v-card>
        <SpaceModifyForm
          v-if="spaceModifyDialog"
          :space-visibility-rights="spaceVisibilityRights"
          :links="spacePartyLinks"
          :links-in-some-space="partyInSomeLink"
          :spaces="selectedSpacesFromViewer"
          :parties="parties"
          :customizable-fields="customizableFields"
          :costcenters="costcenters"
          :disabled="readonly"
          :space-categories="spaceCategories"
          :departments="departments"
          :item-areas="itemAreas"
          @update="$emit('update')"
          @close="spaceModifyDialog = false"
          @saved="saveIndicator"
          @popup="onPopup"
        />
      </v-card>
    </v-dialog>

    <!-- SINGLE SPACE BOX -->
    <v-expansion-panels
      v-if="selectedSpacesFromViewer.length"
      :value="selectedSpacesFromViewer.length ? [0] : null"
      multiple
    >
      <v-expansion-panel
        v-if="selectedSpacesFromViewer.length === 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>meeting_room</v-icon>
            {{ selectedSpacesFromViewer[0].name }}
            <v-icon
              v-if="selectedSpacesFromViewer[0].comments"
              style="color: #47ccaa"
              @click="modifySpaceComment(selectedSpacesFromViewer[0])"
              @click.stop
            >
              mode_comment
            </v-icon>
            <v-icon
              v-else
              @click="modifySpaceComment(selectedSpacesFromViewer[0])"
              @click.stop
            >
              add_comment
            </v-icon>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels
            multiple
            :value="[0]"
          >
            <v-expansion-panel
              class="inner-expansion"
            >
              <v-expansion-panel-header>
                {{ $t('Basic information') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <MetadataForm
                  :metadata="getMetadataForSpace(selectedSpacesFromViewer[0])"
                  :data="getDataForSpace(selectedSpacesFromViewer[0])"
                />
                <MetadataForm
                  :metadata="customizableFieldsMetadata(customizableFields)"
                  :data="dataForCustomizableFields(selectedSpacesFromViewer[0].id)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <SpaceDeviceInfo
            v-if="appRights.showDevices"
            :space="selectedSpacesFromViewer[0]"
            :can-edit="appRights.editDevices"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- SPACE BOX AS EXPAND -->
      <v-expansion-panel
        v-else-if="selectedSpacesFromViewer.length > 1"
      >
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            <v-icon>meeting_room</v-icon>
            {{ $t('Spaces') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            dense
            class="info-box"
          >
            <v-list-item class="tile">
              <v-list-item-content class="tile-content">
                Valinta
              </v-list-item-content>
              <v-list-item-content
                class="tile-content"
              >
                {{ selectedSpacesFromViewer.length + ' Huonetta' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="tile">
              <v-list-item-content class="tile-content">
                {{ $t('Net room area') }}
              </v-list-item-content>
              <v-list-item-content
                class="tile-content"
              >
                {{ getValueWithUnit(calculatedSpaceTotalArea, 'Area') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="tile">
              <v-list-item-content class="tile-content">
                {{ $t('Unit area') }}
              </v-list-item-content>
              <v-list-item-content
                class="tile-content"
              >
                {{ getValueWithUnit(calculatedSpaceTotalUnitArea, 'Area') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- SELECTED SPACES -->
          <div
            v-for="space in selectedSpacesFromViewer"
            :key="space.id"
            class="inner-expansion-container"
          >
            <v-expansion-panels
              class="my-2"
              @change="onChange"
            >
              <v-expansion-panel
                class="inner-expansion"
              >
                <v-expansion-panel-header>
                  <v-row
                    align="center"
                    justify="start"
                    no-gutters
                  >
                    {{ space.name }}
                    <v-icon
                      v-if="space.comments"
                      class="ml-2"
                      style="color: #47ccaa"
                      @click="modifySpaceComment(space)"
                      @click.stop
                    >
                      mode_comment
                    </v-icon>
                    <v-icon
                      v-else
                      class="ml-2"
                      @click="modifySpaceComment(space)"
                      @click.stop
                    >
                      add_comment
                    </v-icon>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels
                    multiple
                    :value="[0]"
                  >
                    <v-expansion-panel
                      class="inner-expansion"
                    >
                      <v-expansion-panel-header>
                        {{ $t('Basic information') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <MetadataForm
                          :metadata="getMetadataForSpace(space)"
                          :data="getDataForSpace(space)"
                        />
                        <MetadataForm
                          :metadata="customizableFieldsMetadata(customizableFields)"
                          :data="dataForCustomizableFields(space.id)"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <SpaceDeviceInfo
                    v-if="appRights.showDevices"
                    :space="space"
                    :can-edit="appRights.editDevices"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- EDIT MENU -->
    <v-row
      v-if="selectedSpacesFromViewer.length > 0"
      id="spaceModifyButton"
      align="end"
      justify="end"
    >
      <v-menu
        :disabled="readonly"
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            text
            outlined
            align-end
            rounded
            v-on="on"
          >
            <v-icon>edit</v-icon>
            {{ $t('Edit spaces...') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="spaceModifyDialog = true">
            <v-list-item-content>
              <v-icon>edit</v-icon>
            </v-list-item-content>
            <v-list-item-title>{{ $t('Edit spaces') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="appRights.moveSpace"
            @click="unitTransferDialog = true"
          >
            <v-list-item-content>
              <v-icon>view_compact</v-icon>
            </v-list-item-content>
            <v-list-item-title>{{ $t('Transfer spaces') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="appRights.addUnit && !editUnitNotNewVersion"
            @click="openUnitCreation()"
          >
            <v-list-item-content>
              <v-icon>view_compact</v-icon>
            </v-list-item-content>
            <v-list-item-title>{{ $t('Create new unit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="appRights.editDevices && selectedSpacesFromViewer.length === 1"
            @click="openAddDeviceDialog()"
          >
            <v-list-item-content>
              <v-icon>view_compact</v-icon>
            </v-list-item-content>
            <v-list-item-title>{{ $t('device.add') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import MetadataForm from '../../components/MetadataForm.vue'
import SpaceModifyForm from './SpaceModifyForm.vue'
import UnitManageForm from './UnitManageForm.vue'
import format from '../../helpers/format'
import BaseModal from '../../components/general/BaseModal.vue'
import Alert from '../../components/Alert.vue'
import UnitTransferForm from './UnitTransferForm.vue'
import AddDeviceForm from '../../components/general/AddDeviceForm.vue'
import SpaceDeviceInfo from './SpaceDeviceInfo.vue'

export default {
  name: 'SpaceBox',
  components: {
    MetadataForm,
    SpaceModifyForm,
    UnitManageForm,
    Alert,
    BaseModal,
    UnitTransferForm,
    AddDeviceForm,
    SpaceDeviceInfo,
  },
  props: {
    appRights: {
      type: Object,
      default: null
    },
    selectedSpacesFromViewer: {
      type: Array,
      default: null
    },
    show: {
      type: Object,
      default: null
    },
    parties: {
      type: Array,
      default: null
    },
    spacePartyLinks: {
      type: Array,
      default: null
    },
    partyInSomeLink: {
      type: Array,
      default: null
    },
    customizableFields: {
      type: Array,
      default: null
    },
    customizedValues: {
      type: Array,
      default: null
    },
    costcenters: {
      type: Array,
      default: null
    },
    selectedFloor: {
      type: Object,
      default: null
    },
    selectedUnitsFromViewer: {
      type: Array,
      default: null
    },
    spaces: {
      type: Array,
      default: null
    },
    spaceVisibilityRights: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    unitPartyLinks: {
      type: Array,
      default: null
    },
    units: {
      type: Array,
      default: null
    },
    spaceShares: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      modifyComment: '',
      selectedSpaceForCommentModify: null,
      spaceCommentModify: false,
      spaceModifyDialog: false,
      unitManageDialog: false,
      unitTransferDialog: false,
      organizationItems: [],
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      popupWindow: null,
      modifiedSpaces: {},
      windowReady: false,
      spaceCategories: [],
      departments: [],
      itemAreas: [],
      settings: undefined,
      editUnitNotNewVersion: false,
      newUnitNamePlaceholder: undefined,
      costcentersBySpaces: [],
      rules: {
        counter800: v => (!v || v && v.length <= 800) || this.$t('Maximum length {n} characters', {n: 800}),
      },
    }
  },
  computed: {
    ...mapState('app', ['purposeZones', 'currentDate']),
    ...mapState('device', ['showAddDeviceDialog']),
    ...mapGetters('app', ['definitionById', 'definitionsByGroupLabel', 'definitionLabelById']),
    calculatedSpaceTotalArea () {
      return this.selectedSpacesFromViewer
        .filter(s => s !== undefined)
        .map(s => Number(s.area))
        .reduce((acc, cur) => {
          return acc + cur
        }, 0)
    },
    calculatedSpaceTotalUnitArea () {
      return this.selectedSpacesFromViewer
        .filter(s => s !== undefined)
        .map(s => Number(s.unit_area))
        .reduce((acc, cur) => {
          return acc + cur
        }, 0)
    },
    isSaveCommentDisabled () {
      return !(!this.modifyComment || (this.modifyComment && this.modifyComment.length <= 800))
    }
  },
  watch: {
    showIndicator: function (value) {
      if (value === true) {
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    selectedSpacesFromViewer: function () {
      if(this.selectedSpacesFromViewer.length === 1) {
        this.fetchAllocatedAreas()
      }
      if (this.windowReady) {
        this.updatePopupData()
      }
    }
  },
  async mounted () {
    this.organizationItems = await this.$rambollfmapi.organizations
      .costCenters()
      .list()
    this.spaceCategories = this.definitionsByGroupLabel('space.category')
    this.departments = this.definitionsByGroupLabel('space.department')
    this.itemAreas = this.definitionsByGroupLabel('space.item_area')
    this.checkSettings()
    this.fetchAllocatedAreas()
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receivePopupMessage)
    if (this.popupWindow !== null) {
      this.popupWindow.close()
    }
  },
  methods: {
    ...mapActions('device', ['closeAddDeviceDialog', 'openAddDeviceDialog']),
    onChange (event) {
      if (event === undefined) // when panel is closed
        return

      this.fetchAllocatedAreas()
    },
    async fetchAllocatedAreas () {
      let spaceIds = this.selectedSpacesFromViewer.map(space => { return space.id })
      this.costcentersBySpaces = await this.$rambollfmapi.spaces.allocatedAreaBySpaces(spaceIds, this.currentDate)
    },
    getDataForSpace (space) {
      const data = {
        name_of_space: space.space_name,
        net_room_area: space.area,
        unit_area: space.unit_area,
        purpose_of_use: space.purpose_of_use,
        purpose_zone: space.purpose_zone,
        clarified_purpose_of_use: space.clarified_purpose_of_use,
        floor_material: space.floor_material,
        cleaning_area_desc: space.cleaning_area_desc,
        cleaning_area_low_desc: space.cleaning_area_low_desc,
        fire_area: space.fire_area,
        movement_area: space.movement_area,
        cleaning_frequency: space.cleaning_frequency,
        additional_cost: space.additional_cost,
        number_of_workstations: space.number_of_workstations,
        cost_center: this.getCorrectCostCenter(space),
        meter_number: space.meter_number,
        named_person_count: this.getSpacePersonCount(space),
        number_of_personnel: space.number_of_personnel,
        classification: space.classification,
        capacity_quantity: space.capacity_quantity === null ? '' : space.capacity_quantity + ' ' + this.getDefinitionLabelById(space.capacity_unit),
        contract_number_sap: space.contract_number_sap,
        shared_space_code: this.getDefinitionLabelById(space.shared_space_code),
        space_category: this.getDefinitionLabelById(space.space_category),
        department: this.getDefinitionLabelById(space.department),
        item_area: this.getDefinitionLabelById(space.item_area),
        main_usage: this.getDefinitionLabelById(space.main_usage),
        cleaning_details: space.cleaning_details,
        allocated_unit_area: this.getAllocatedUnitArea(space)
      }
      const zone1obj = this.purposeZones.find(
        pz => pz.id === space.id_purpose_zone1
      )
      const zone2obj = this.purposeZones.find(
        pz => pz.id === space.id_purpose_zone2
      )
      const zone3obj = this.purposeZones.find(
        pz => pz.id === space.id_purpose_zone3
      )
      data.purpose_zone1_string = this.$t(zone1obj ? zone1obj.name : '')
      data.purpose_zone2_string = this.$t(zone2obj ? zone2obj.name : '')
      data.purpose_zone3_string = this.$t(zone3obj ? zone3obj.name : '')

      if (this.showSpaceProp('attached_number_of_personnel')) {
        this.getSpaceUserPercentages(space).forEach(user => {
          data[user.user] = user.percentage
        })
      }
      return data
    },
    getMetadataForSpace (space) {
      const metadata = {
        name_of_space: {
          isShown: this.showSpaceProp('space_name'),
          category: 'None'
        },
        net_room_area: {
          isShown: this.showSpaceProp('area'),
          category: 'None',
          format: 'Area'
        },
        unit_area: {
          isShown: this.showSpaceProp('unit_area'),
          category: 'None',
          format: 'Area'
        },
        allocated_unit_area: {
          isShown: this.showSpaceProp('allocated_unit_area'),
          category: 'None',
          format: 'Area'
        },
        main_usage: {
          isShown: this.showSpaceProp('main_usage'),
          category: 'None'
        },
        purpose_of_use: {
          isShown: this.showSpaceProp('id_purpose'),
          category: 'None'
        },
        clarified_purpose_of_use: {
          isShown: this.showSpaceProp('clarified_purpose_of_use'),
          category: 'None'
        },
        purpose_zone1_string: {
          isShown: this.showSpaceProp('id_purpose_zone1'),
          category: 'None'
        },
        purpose_zone2_string: {
          isShown: this.showSpaceProp('id_purpose_zone2'),
          category: 'None'
        },
        purpose_zone3_string: {
          isShown: this.showSpaceProp('id_purpose_zone3'),
          category: 'None'
        },
        floor_material: {
          isShown: this.showSpaceProp('floor_material'),
          category: 'None',
          definitionGroupId: this.getDefinitionGroupId('floor_material')
        },
        cleaning_area_desc: {
          isShown: this.showSpaceProp('cleaning_area'),
          category: 'None'
        },
        cleaning_area_low_desc: {
          isShown: this.showSpaceProp('cleaning_area_low'),
          category: 'None'
        },
        cleaning_details: {
          isShown: this.showSpaceProp('cleaning_details'),
          category: 'None'
        },
        fire_area: {
          isShown: this.showSpaceProp('fire_area'),
          category: 'None'
        },
        movement_area: {
          isShown: this.showSpaceProp('movement_area'),
          category: 'None',
          definitionGroupId: this.getDefinitionGroupId('movement_area')
        },
        cleaning_frequency: {
          isShown: this.showSpaceProp('cleaning_frequency'),
          category: 'None',
          definitionGroupId: this.getDefinitionGroupId('cleaning_frequency')
        },
        additional_cost: {
          isShown: this.showSpaceProp('additional_cost'),
            category: 'None',
            format: 'AdditionalCost'
        },
        number_of_workstations: {
          isShown: this.showSpaceProp('number_of_workstations'),
          category: 'None'
        },
        cost_center: {
          isShown: this.showSpaceProp('cost_center'),
          category: 'None'
        },
        meter_number: {
          isShown: this.showSpaceProp('meter_number'),
          category: 'None'
        },
        classification: {
          isShown: this.showSpaceProp('classification'),
          category: 'None'
        },
        number_of_personnel: {
          isShown: this.showSpaceProp('number_of_personnel'),
          category: 'None'
        },
        contract_number_sap: {
          isShown: this.showSpaceProp('contract_number_sap'),
          category: 'None'
        },
        shared_space_code: {
          isShown: this.showSpaceProp('shared_space_code'),
          category: 'None'
        },
        // These should be at the bottom of the list
        named_person_count: {
          isShown: this.showSpaceProp('attached_number_of_personnel'),
          category: 'None'
        },
        capacity_quantity: {
          isShown: this.showSpaceProp('capacity_quantity'),
          category: 'None'
        },
        space_category: {
          isShown: this.showSpaceProp('space_category', true),
          category: 'None'
        },
        department: {
          isShown: this.showSpaceProp('department', true),
          category: 'None'
        },
        item_area: {
          isShown: this.showSpaceProp('item_area', true),
          category: 'None'
        }
      }
      if (this.showSpaceProp('attached_number_of_personnel')) {
        this.getSpaceUserPercentages(space).forEach(user => {
          metadata[user.user] = {
            isShown: true,
            category: 'None'
          }
        })
      }
      return metadata
    },
    getAllocatedUnitArea (space) {
      let allocatedUnitAreaOfSpace = 0
      this.costcentersBySpaces.filter(costcenterbyspace => costcenterbyspace.idSpace === space.id).forEach(
        costcenterbyspace => {
          allocatedUnitAreaOfSpace += costcenterbyspace.allocatedUnitArea
        }
      )
      return allocatedUnitAreaOfSpace
    },
    customizableFieldsMetadata (fields) {
      const metadata = {}
      fields.forEach((field) => {
        metadata[field.name] = {
          isShown: true,
          category: 'None'
        }
      })
      return metadata
    },
    dataForCustomizableFields (space) {
      const data = {}
      this.customizedValues.forEach(values => {
        if (values.idSpace === space) {
          const item = this.customizableFields.find(e => e.id === values.idField)
          const res = item.customizableDataFieldValues.find(e => e.id === values.idValue)
          data[item.name] = res.value
        }
      })
      return data
    },
    modifySpaceComment (space) {
      this.modifyComment = JSON.parse(JSON.stringify(space.comments))
      this.selectedSpaceForCommentModify = space
      this.spaceCommentModify = true
    },
    hideIndicator () {
      this.showIndicator = false
    },
    saveComment () {
      const observer = this.$jsonpatch.observe(
        this.selectedSpaceForCommentModify
      )
      this.selectedSpaceForCommentModify.comments = JSON.parse(
        JSON.stringify(this.modifyComment)
      )
      const patch = this.$jsonpatch.generate(observer)
      this.$rambollfmapi.spaces.purePatch(
        this.selectedSpaceForCommentModify.id,
        this.selectedSpaceForCommentModify.version,
        patch
      )
      this.spaceCommentModify = false
    },
    getSpacePersonCount (space) {
      return this.spacePartyLinks
        .filter(spl => {
          return spl.id_parent === space.id
        })
        .map(spl => spl.id_party).length
    },
    getSpaceUserPercentages (space) {
      const partyIds = this.spacePartyLinks
        .filter(spl => {
          return spl.id_parent === space.id
        })
        .map(spl => spl.id_party)
      const percentagePerUser = format.formatData(Number(100 / partyIds.length), 'Percentage')
      const parties = partyIds.reduce((acc, cur) => {
        const party = this.parties.find(p => p.id === cur)
        if (party.cost_center !== null) {
          acc.push({
            user: party.name + ' - ' + party.cost_center,
            percentage: percentagePerUser
          })
        } else {
          acc.push({
            user: party.name + " - ",
            percentage: percentagePerUser
          })
        }

        return acc
      }, [])
      parties.sort((a, b) => {
        if (a.user < b.user) return -1
        else return 1
      })
      return parties
    },
    getCorrectCostCenter (space) {
      let cc = ''
      let centers = []

      if (this.spaceShares !== null && this.spacePartyLinks.length === 0) {
        centers = []
        const spaceShares = this.spaceShares.filter(share => share.idSpace === space.id)

        var remainingSharePercentage = 100
        var countWithoutShare = 0
        spaceShares.forEach(share => {
          if (share.share === null) {
            countWithoutShare += 1
          } else {
            remainingSharePercentage -= share.share
          }
        })
        const percentagePerShare = remainingSharePercentage > 0 ? (remainingSharePercentage / countWithoutShare) : 0
        spaceShares.forEach(share => {
          const costcenter = this.costcenters.find(cc => cc.id === share.idCostcenter)
          centers.push(costcenter.name.split(' - ')[1] + ' ' + (share.share != null ? share.share : percentagePerShare).toFixed(2) + '%')
        })
      } else {
        if (space.cost_center === null) {
          this.getSpaceUserPercentages(space).forEach(user => {
            const split = user.user.split(' - ')
            this.organizationItems.forEach(o => {
              if (o.code === split[1]) {
                centers.push(o.name)
              }
            })
          })
        } else {
          centers.push(space.cost_center.name)
          this.getSpaceUserPercentages(space).forEach(user => {
            const split = user.user.split(' - ')
            this.organizationItems.forEach(o => {
              if (o.code === split[1]) {
                centers.push(o.name)
              }
            })
          })
        }
      }
      // Remove duplicates
      centers = [...new Set(centers)]

      cc = centers.join(', ')
      return cc
    },
    showSpaceProp (prop, showIfExists = false) {
      const right = this.spaceVisibilityRights[prop]
      if (right !== undefined) {
        //Some values are visible even if "isShown" is false but the right-object exists
        if(showIfExists)
        {
          return true
        }
        return right.isShown
      } else if (prop === 'cost_center') {
        // For now, space cost center is visible to all customers even if some aren't using the information
        return true
      } else {
        return false
      }
    },
    getDefinitionGroupId (prop) {
      if (this.spaceVisibilityRights[prop]) {
        return this.spaceVisibilityRights[prop].definitionGroupId
      }
      return 0
    },
    getValueWithUnit (value, formatting) {
      return format.formatData(value, formatting)
    },
    saveIndicator (result) {
      this.operationResult = result
      if (result === 'error') {
        this.indicatorMessage = this.$t('Unsuccesfull save')
      }
      if (result === 'success') {
        this.indicatorMessage = this.$t('Succesfull save')
      }
      this.showIndicator = true
    },
    onPopup (modifiedSpaces){
      this.modifiedSpaces = modifiedSpaces

      const w = document.getElementById('spacemodifyform').clientWidth
      const h = document.getElementById('spacemodifyform').clientHeight + 1

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom

      const baseURL = window.location.href.replace(this.$route.path, '')
      window.addEventListener('message', this.receivePopupMessage)
      this.popupWindow = window.open(
        `${baseURL}/spacemodify/`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    receivePopupMessage (event){
      if (event.data == 'READY') {
        this.windowReady = true
        this.updatePopupData(true)
      } else if (event.data == 'UPDATE'){
        this.$emit('update')
      }
    },
    updatePopupData (sendPreModifiedData) {
      let spaceData = {
        spacePartyLinks: this.spacePartyLinks,
        partyInSomeLink: this.partyInSomeLink,
        spaces: this.selectedSpacesFromViewer,
        parties: this.parties,
        costcenters: this.costcenters,
        spaceVisibilityRights: this.spaceVisibilityRights,
        modifiedSpaces: sendPreModifiedData ? this.modifiedSpaces : {},
        customizableFields: this.customizableFields,
        spaceCategories: this.spaceCategories,
        departments: this.departments,
        itemAreas: this.itemAreas
      }

      this.popupWindow.postMessage(spaceData)
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    async checkSettings () {
      const settings = await this.$rambollfmapi.settings.get()
      Object.keys(settings).forEach(setting => {
        if (setting == 'editUnitNotNewVersion') {
          this.editUnitNotNewVersion = settings[setting]
        }
      })
    },
    async getNextUnitName () {
      const unitName = 'F' + this.selectedFloor.floor_number
      const nextName = await this.$rambollfmapi.units.nextCodeForFloor(this.selectedFloor.id, unitName)
      return nextName
    },
    async openUnitCreation () {
      this.newUnitNamePlaceholder = await this.getNextUnitName()
      this.unitManageDialog = true

    }
  }
}
</script>
<style scoped>
</style>
