<template>
  <div>
    <v-dialog
      v-model="subUnitSelection"
      width="550"
      persistent
    >
      <v-form ref="form">
        <BaseModal
          @cancel="cancel()"
        >
          <template #title>
            {{ confirmation ? $t('master_unit.merge_units') : destructing ? $t('master_unit.dissolve_units') : $t('master_unit.select_units') }}
          </template>
          <template #content>
            <div v-if="!confirmation && !destructing">       
              <table class="mt-4 ml-12">
                <tr 
                  v-for="unit in availableSubUnits"
                  :key="unit.id"
                >
                  <td>
                    <v-switch
                      v-model="subUnits"
                      class="pa-0 ma-0"
                      :value="unit.id"
                      :label="unit.unit_code"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <strong>
                {{ destructing ? $t('master_unit.confirm_merge') : $t('master_unit.confirm_dissolve') }}
              </strong>
            </div>
          </template>
          <template #footer>
            <v-btn
              v-if="!confirmation && !destructing"
              rounded
              depressed
              color="primary"
              :disabled="!subUnits.length || subUnitsEqual()"
              @click="confirmation = true"
            >
              {{ $t('Select') }}
            </v-btn>             
            <template v-else>
              <v-btn
                depressed
                rounded
                :color="destructing ? 'error' : 'primary'"
                :disabled="saving"
                @click="setSubUnits"
              >
                <span v-if="!saving">{{ destructing ? $t('Delete') : $t('Ok') }}</span>
                <v-progress-circular
                  v-else
                  :size="20"
                  :width="3"
                  indeterminate
                  color="primary"
                />
              </v-btn>
            </template>
          </template>
        </BaseModal>
      </v-form>
    </v-dialog>
    <v-expansion-panels
      class="my-2"
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            {{ $t('master_unit.merged_unit') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="getMetadataForMasterUnit()"
            :data="getDataForMasterUnit"
            :with-status-icon="true"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row
      align="end"
      justify="end"
      no-gutters
    >
      <v-btn
        v-if="createMasterAvailable"
        outlined
        rounded
        text
        @click="setMaster"
      >
        {{ $t('master_unit.merge_units') }}
      </v-btn>
      <v-row
        v-else-if="isMasterUnit(selectedUnit)"
        no-gutters
      >
        <v-btn
          outlined
          rounded
          text
          @click="destructMaster"
        >
          {{ $t('master_unit.dissolve_units') }}
        </v-btn>
        <v-btn
          outlined
          rounded
          text
          @click="selectSubUnits"
        >
          {{ $t('master_unit.select_units') }}
        </v-btn>
      </v-row>
    </v-row>
    <v-expansion-panels
      class="my-2"
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            {{ $t('Unit marketing') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="getMetadataForUnit()"
            :data="getDataForUnit(selectedUnit)"
            :with-status-icon="true"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      class="my-2"
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            align="center"
            justify="start"
            no-gutters
          >
            {{ $t('Unit additional information') }}
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <MetadataForm
            :metadata="getAdditionalMetadataForUnit()"
            :data="getAdditionalDataForUnit(selectedUnit)"
            :with-status-icon="true"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import MetadataForm from '../MetadataForm.vue'
import BaseModal from '../general/BaseModal'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'UnitMarketingBox',
  components: {
    BaseModal,
    MetadataForm
  },
  props: {
    selectedUnit: { 
      type: Object, 
      default: function () {
        return {}
      }
    },
    selectedUnits: { 
      type: Array,
      default: function () {
        return []
      }
    },
    floorUnits: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      subUnits: [],
      subUnitSelection: false,
      saving: false,
      confirmation: false,
      destructing: false
    }
  },
  computed: {
    ...mapState('app', ['currentDate']),
    ...mapGetters('app', ['definitionsByGroupId', 'definitionLabelById']),
    createMasterAvailable () {
      return this.isRentable(this.selectedUnit) && !this.isMasterOrSubUnit(this.selectedUnit)
    },
    getDataForMasterUnit () {
      const unit = this.selectedUnit
      if (!unit) {
        return {}
      }

      let dataForUnit = {
        master_unit: unit.master_unit ? this.isMasterUnit(unit) ? unit.unit_code + this.$t('master_unit.this_unit') : this.getUnitCodeForId(unit.master_unit) : this.$t('master_unit.not_merged') ,
        sub_units: unit.sub_units?.map(id => this.getUnitCodeForId(id)).sort((a, b) => {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        }).join(", ")
      }
      return dataForUnit
    },
    availableSubUnits () {
      const subUnits = this.floorUnits.filter(unit => this.isRentable(unit) && !this.isMasterUnit(unit) && unit.id !== this.selectedUnit.id && (unit.master_unit === null || unit.master_unit === this.selectedUnit.id))
      return subUnits.sort((a, b) => {
          if (a.unit_code < b.unit_code) {
            return -1
          }
          if (a.unit_code > b.unit_code) {
            return 1
          }
          return 0
        })
    }
  },
  methods: {
    showUnitProp (prop) {
      const metadata = this.unitVisibilityRights[prop]
      if (metadata !== undefined) {
        const { isShown, category, order, format } = metadata
        return { isShown, category, order, format }
      } else {
        return { isShown: false, category: 'None', order: 999, format: 0 }
      }
    },
    getDataForUnit (unit) {
      if (!unit) {
        return {}
      }
      let dataForUnit = {
        web_visibility_status: this.getDefinitionLabel(unit.web_visibility_status),
        advertising_phrase: unit.advertising_phrase,
        introduction: unit.introduction,
        video_link: unit.video_link,
        panorama_link: unit.panorama_link,
        linked_photos: unit.linked_photos.map(photo => {
          return {
            value: photo.filename,
            type: 'Hyperlink',
            url: photo.publicId ? 'https://api3.rambollfm.fi/v3.1/photos/public/' + photo.publicId : null
          }
        })
      }
      return dataForUnit
    },
    getAdditionalDataForUnit (unit) {
      if (!unit) {
        return {}
      }
      let dataForUnit = {
        usage_types: unit.usage_types?.map(s => this.getDefinitionLabel(s)).join(", "),
        unit_extensibility: this.getDefinitionLabel(unit.unit_extensibility),
        unit_divisibility: this.getDefinitionLabel(unit.unit_divisibility),
        unit_detail_info: unit.unit_detail_info,
        renting_attributes: unit.renting_attributes?.map(s => this.getDefinitionLabel(s)).join(", "),
        technical_attributes: unit.technical_attributes?.map(s => this.getDefinitionLabel(s)).join(", "),
        unit_condition: unit.unit_condition,
        move_readiness: unit.move_readiness,
        water_meter: unit.water_meter,
        electricity_meter: unit.electricity_meter,
        other_utilities: unit.other_utilities,
        campaign_active: unit.campaign_active,
        campaign_last_date: unit.campaign_last_date,
        campaign_description: unit.campaign_description
      }

      return dataForUnit
    },
    getMetadataForUnit () {
      let unitMetadata = {
        web_visibility_status: {
          isShown: true,
          category: 'None',
          type: 'String'
        },
        advertising_phrase: {
          isShown: true,
          category: 'None',
        },
        introduction: {
          isShown: true,
          category: 'None',
        },
        video_link: {
          isShown: true,
          category: 'None',
          type: 'Hyperlink'
        },
        panorama_link: {
          isShown: true,
          category: 'None',
          type: 'Hyperlink'
        },
        linked_photos: {
          isShown: true,
          category: 'None',
          type: 'Array'
        }
      }
      return unitMetadata
    },
    getMetadataForMasterUnit () {
      let unitMetadata = {
        master_unit: {
          isShown: true,
          category: 'None',
        },
        sub_units: {
          isShown: true,
          category: 'None'
        }
      }
      return unitMetadata
    },
    getAdditionalMetadataForUnit () {
      let unitMetadata = {
        usage_types: {
          isShown: true,
          category: 'None'
        },
        unit_extensibility: {
          isShown: true,
          category: 'None'
        },
        unit_divisibility: {
          isShown: true,
          category: 'None'
        },
        unit_detail_info: {
          isShown: true,
          category: 'None'
        },
        renting_attributes: {
          isShown: true,
          category: 'None',
        },
        technical_attributes: {
          isShown: true,
          category: 'None',
        },
        unit_condition: {
          isShown: true,
          isDefinition: true,
          category: 'None',
          hasIcon: true
        },
        move_readiness: {
          isShown: true,
          isDefinition: true,
          category: 'None'
        },
        water_meter: {
          isShown: true,
          isDefinition: true,
          category: 'None'
        },
        electricity_meter: {
          isShown: true,
          isDefinition: true,
          category: 'None'
        },
        other_utilities: {
          isShown: true,
          category: 'None'
        },
        campaign_active: {
          isShown: true,
          category: 'None',
          type: 'Boolean'
        },
        campaign_last_date: {
          isShown: true,
          category: 'None',
          format: "Date"
        },
        campaign_description: {
          isShown: true,
          category: 'None'
        },
        //TODO: Linkitetyt huoneistot
      }
      return unitMetadata
    },
    getDefinitionLabel (id) {
      return this.definitionLabelById(id)
    },
    isMasterUnit (unit) {
      return unit.id === unit.master_unit
    },
    isMasterOrSubUnit (unit) {
      return unit.master_unit !== null
    },
    getUnitCodeForId (unitId) {
      return this.floorUnits.find(u => u.id === unitId).unit_code
    },
    isRentable (unit) {
      return unit.not_rentable === 'rentable' || unit.not_rentable === 'rentable.koy'
    },
    async setMaster () {
      if (this.selectedUnits.length > 1) {
        this.subUnits = this.selectedUnits.filter(unit => this.isRentable(unit) && (unit.master_unit === null || unit.master_unit === this.selectedUnit.id)).map(unit => unit.id).filter(id => id !== this.selectedUnit.id)
      } else {
        this.subUnits = []
      }
      this.selectSubUnits(false)
    },
    selectSubUnits (modifySubUnits = true) {
      if (modifySubUnits) {
        this.subUnits = this.selectedUnit.sub_units
      }
      this.subUnitSelection = true
    },
    async setSubUnits () {
      this.saving = true
      // first check which units were removed from subUnits
      for (let subUnit of this.selectedUnit.sub_units) {
        const foundUnit = this.subUnits.find(id => id === subUnit)
        if (!foundUnit) {
          // reset master unit for this unit
          await this.patchUnit(subUnit, null)
        }
      }
      this.selectedUnit.sub_units = this.subUnits      
      const masterUnitId = this.destructing ? null : this.selectedUnit.id
      await this.patchUnits(masterUnitId)
    },    
    async destructMaster () {
      this.destructing = true
      this.subUnitSelection = true   
    },
    async patchUnits (masterUnitId) {
      // patch master unit
      await this.patchUnit(this.selectedUnit.id, masterUnitId)
      // then subunits
      for (let unitId of this.selectedUnit.sub_units) {
        // reset subunits marketting info
        await this.patchUnit(unitId, masterUnitId, true)
        await this.resetUnitMarkettingInfo(unitId)
      }
      this.saving = false
      this.subUnitSelection = false
      this.$emit('update')
    },
    async patchUnit (unitId, masterUnitId, isSubUnit = false) {
      let patch = [
      {
          op: 'replace',
          path: '/master_unit',
          value: masterUnitId
        }
      ]
      const unitPatch = [
        {
          op: 'replace',
          path: '/panorama_link',
          value: null
        },
        {
          op: 'replace',
          path: '/video_link',
          value: null
        },
        {
          op: 'replace',
          path: '/introduction',
          value: null
        },
        {
          op: 'replace',
          path: '/advertising_phrase',
          value: null
        }
      ]
      if (isSubUnit) {
        patch = patch.concat(unitPatch)
      }
      await this.$rambollfmapi.units.patch(unitId, this.currentDate, patch)
    },
    cancel () {
      this.subUnitSelection = false
      this.confirmation = false
      this.destructing = false
      this.$refs.form.reset()
    },
    subUnitsEqual () {
      // compare selectedunit.sub_units and subUnits if their contents are equal
      const a = this.selectedUnit.sub_units.slice().sort()
      const b = this.subUnits.slice().sort()
      return a.length === b.length && a.every((id, i) => id === b[i])
    },
    async resetUnitMarkettingInfo (unitId) { 
      const time = this.currentDate.toISOString().slice(0, 10)
      const additionalInfoPatch = []
      Object.keys(this.getAdditionalMetadataForUnit()).forEach(key => {
        const patch = {
          op: 'replace',
          path: '/' + key,
          value: key === 'campaign_active' ? 0 : null
        }
        additionalInfoPatch.push(patch)
      })
      
      await this.$rambollfmapi.units.additionalInformation().patch(unitId, time, additionalInfoPatch)
    }
  }
}
</script>
<style scoped>
</style>
