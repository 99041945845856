<template>
  <v-col>
    <!-- Selected units -->
    <div>
      <RentingUnit
        :unit="unit"
        :unit-visibility-rights="unitVisibilityRights"
        :layer="layer"
        :contracts="contractDataForUnit(unit.id)"
        :contracts-loading="contractsLoading"
        :renting="renting"
        :parties="parties"
      />
    </div>
  </v-col>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import RentingUnit from '../../components/Leasing/RentingUnit'



export default {
  name: 'RentingBox',
  components: { RentingUnit },
  props: {
    unit: { type: Object, default: null },
    selectedUnitsFromViewer: { type: Array, default: null },
    unitVisibilityRights: { type: Object, default: null },
    buildingData: { type: Object, default: null },
    layer: { type: String, default: '' },
    renting: { type: Boolean, default: false },
    contractsLoading: { type: Boolean, default: false},
    parties:  {type: Array, default: null }
  },
  data () {
    return {
      selectedProspect: null,
      showSuccess: false,
      printType: '',
      rentalOfferOpen: false,
    }
  },
  computed: {
    ...mapGetters('leasing', ['rentalStatuses', 'futureRentalStatuses', 'pastRentalStatuses']),
    ...mapState('app', ['currentDate'])
  },
  methods: {
    contractDataForUnit (unitId) {
      const currentRentalContracts = this.rentalStatuses.filter(item => item.contractId != null)
      const futureRentalContracts = this.futureRentalStatuses.filter(item => item.contractId != null)
      const pastRentalContracts = this.pastRentalStatuses.filter(item => item.contractId != null)
      let unitContracts = []

      currentRentalContracts.filter(contract => contract.unitId === unitId).forEach(contract => {
        let actualArea = 0
        let contractUnits = ''
        currentRentalContracts.filter(c => c.contractNumber === contract.contractNumber).forEach(c => {
          actualArea += c.area
          contractUnits += c.unitName + ', '
        })
        contract.actualArea = actualArea
        contract.contractUnits = contractUnits.slice(0, -2)
        contract.vat = !!contract.vat

        unitContracts.push(contract)
      })

      futureRentalContracts.filter(contract => contract.unitId === unitId).forEach(contract => {
        let actualArea = 0
        let contractUnits = ''
        futureRentalContracts.filter(c => c.contractNumber === contract.contractNumber).forEach(c => {
          actualArea += c.area
          contractUnits += c.unitName + ', '
        })
        contract.actualArea = actualArea
        contract.contractUnits = contractUnits.slice(0, -2)
        contract.vat = !!contract.vat

        unitContracts.push(contract)
      })

      pastRentalContracts.filter(contract => contract.unitId === unitId).forEach(contract => {
        let actualArea = 0
        let contractUnits = ''
        pastRentalContracts.filter(c => c.contractNumber === contract.contractNumber).forEach(c => {
          actualArea += c.area
          contractUnits += c.unitName + ', '
        })
        contract.actualArea = actualArea
        contract.contractUnits = contractUnits.slice(0, -2)
        contract.vat = !!contract.vat

        unitContracts.push(contract)
      })

      unitContracts.sort((prev, next) => new Date(next.curPartyStartDate) - new Date(prev.curPartyStartDate))
      return unitContracts
    }
   }
}
</script>
<style scoped>
.info-panel {
  width: 100%;
}
#success-card {
  position: fixed;
  z-index: 299;
  bottom: 0.5em;
  left: 0;
  background-color: transparent;
}
.success-tile {
  background-color: #4caf50;
  border-top-right-radius: 5em;
  border-bottom-right-radius: 5em;
  margin-top: 0.5em;
  padding: 0.8em;
  color: white;
  display: block;
  padding-right: 1em;
  cursor: pointer;
}
.success-tile:hover {
  background-color: #4bce49;
}
.success-tile-content {
  display: inline-block;
}
.success-tile-icon {
  color: white;
}
</style>