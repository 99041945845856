import axios from 'axios'
import store from '../store/store'
import loglevel from 'loglevel'

class RambollFMAPI {
  constructor (endpoint, accessToken) {
    this.endpoint = endpoint.charAt(endpoint.length - 1) === '/' ? endpoint : endpoint + '/'
    this.accessToken = accessToken

    this.initializeAxios()

    this.accounts = {
      login: async (username, password, mfacode) => {
        const result = await axios.post(endpoint + 'accounts/login', {
          username: username,
          password: password,
          mfacode: mfacode
        })

        this.accessToken = result.data.token
        return result
      },
      loginAsUsergroup: async (usergroup_id) => {
        const result = await axios.post(endpoint + 'accounts/login/usergroup/' + usergroup_id, {
          usergroup_id
        })
        this.accessToken = result.data.token
        return result
      },
      groups: (userId) => {
        return this.extractData(axios.get(endpoint + 'accounts/permissions/' + userId + '/groups'))
      },
      currentgroups: () => {
        return this.extractData(axios.get(endpoint + 'accounts/permissions/groups'))
      },
      usergroups: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'accounts/usergroups'))
        }
      },
      put: (userId, data) => {
        return this.extractData(axios.put(endpoint + 'accounts/user/' + userId + '/permissions/groups', data))
      },
      post: async (data) => {
        const result = await axios.post(endpoint + 'accounts/user', data)

        return result
      },
      delete: (userId) => {
        return this.extractData(axios.delete(endpoint + 'accounts/user/' + userId))
      },
      removeAdopted: () => {
        return this.extractData(axios.delete(endpoint + 'accounts/user/adopteduser/'))
      },
      patch: (userId, data) => {
        return this.extractData(axios.patch(endpoint + 'accounts/' + userId, data))
      },
      userinfo: () => {
        return this.extractData(axios.get(endpoint + 'accounts/userinfo'))
      },
      userRefreshToken: () => {
        return this.extractData(axios.get(endpoint + 'accounts/refresh_token'))
      },
      list: () => {
        return this.extractData(axios.get(endpoint + 'accounts/users'))
      },
      refresh: (refreshToken) => {
        return {
          post: () => {
            return this.extractData(axios.post(endpoint + 'accounts/login/refresh/' + refreshToken))
          },
          delete: () => {
            return this.extractData(axios.delete(endpoint + 'accounts/login/refresh/' + refreshToken))
          }
        }
      },
      widgets: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'accounts/widgets'))
        },
        put: (data) => {
          return this.extractData(axios.put(endpoint + 'accounts/widgets', data))
        }
      },
      favourites: () => {
        return {
          get: () => {
            return this.extractData(axios.get(endpoint + 'accounts/favourites/sites'))
          },
          post: (siteId) => {
            return this.extractData(axios.post(endpoint + 'accounts/favourites/sites/' + siteId))
          },
          delete: (siteId) => {
            return this.extractData(axios.delete(endpoint + 'accounts/favourites/sites/' + siteId))
          }
        }
      },
      forgotpassword: ({ email, username }) => {
        return this.extractData(axios.post(endpoint + 'accounts/forgotpassword', { email, username }))
      },
      resetpassword: ({ username, password, password2, token }) => {
        return this.extractData(axios.post(endpoint + 'accounts/resetpassword', { username, password, password2, token }))
      },
      verifypasswordtoken: (token) => {
        return this.extractData(axios.post(endpoint + 'accounts/verifypasswordtoken', { token }))
      },
      logo: {
        get: () => {
          return axios.get(endpoint + 'accounts/logo')
        }
      },
      superAdmin: () => {
        return this.extractData(axios.get(endpoint + 'accounts/superadmin'))
      },
      permissionsForSite: (siteId) => {
        return this.extractData(axios.get(endpoint + 'accounts/sitepermissions/' + siteId))
      },
      sso: {
        list: (url) => {
          return this.extractData(axios.get(endpoint + 'accounts/ssocustomer/' + url))
        },
        get: (subdomain) => {
          return this.extractData(axios.get(endpoint + 'accounts/ssorequest/' + subdomain))
        },
        show: (url) => {
          return this.extractData(axios.get(endpoint + 'accounts/showsso/' + url))
        }
      },
      vektorio: {
        session: {
          get: () => {
            return this.extractData(axios.get(endpoint + 'accounts/vektorio/session', { timeout: 10000 }))
          },
          delete: (v3ViewerToken) => {
            return this.extractData(axios.delete(endpoint + 'accounts/vektorio/session/' + v3ViewerToken, { timeout: 10000 }))
          }
        },
        localenvironment: {
          get: () => {
            return this.extractData(axios.get(endpoint + 'accounts/vektorio/localenvironment'))
          },
          post: (environment) => {
            var env = environment ? `?environment=${environment}` : ""
            return this.extractData(axios.post(endpoint + 'accounts/vektorio/localenvironment' + env))
          }
        }
      },
      session: {
        valid: () => {
          return this.extractData(axios.get(endpoint + 'accounts/session/valid'))
        }
      }
    }

    this.userOrganizations = {
        list: () => {
          return this.extractData(axios.get(endpoint + 'userorganizations'))
        },
        user: () => {
          return this.extractData(axios.get(endpoint + 'userorganizations/user'))
        },
        getById: (orgId) => {
          return this.extractData(axios.get(endpoint + 'userorganizations/' + orgId))
        },
        getExternal: () => {
          return this.extractData(axios.get(endpoint + 'userorganizations/external'))
        },
        post: (data) => {
          return this.extractData(axios.post(endpoint + 'userorganizations/', data))
        },
        partyLinks: orgId => {
          return {
            post: (data) => {
              return this.extractData(axios.post(endpoint + 'userorganizations/' + orgId +'/partylinks', data))
            },
            patch: (orgId, linkId, data) => {
              return this.extractData(axios.patch(endpoint + 'userorganizations/' + orgId + '/partyLinks/' + linkId, data))
            },
            purePatch: (orgId, linkId, data) => {
              return this.extractData(axios.patch(endpoint + 'userorganizations/' + orgId + '/partyLinks/' + linkId + '/pure', data))
            },
            delete: (orgId, linkId, time) => {
              return this.extractData(axios.delete(endpoint + 'userorganizations/' + orgId + '/partyLinks/' + linkId, {params: {time: time}} ))
            }
          }
        },
        hasExternals: () => {
          return this.extractData(axios.get(endpoint + 'userorganizations/hasExternalOrgs'))
        }
    }

    this.documents = {
      list: () => {
        return this.extractData(axios.get(endpoint + 'documents/'))
      },
      get: (id) => {
        return this.extractData(axios.get(endpoint + 'documents/' + id))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'documents/', data))
      },
      update: (id, data) => {
        return this.extractData(axios.put(endpoint + 'documents/' + id, data))
      },
      delete: (id) => {
        return this.extractData(axios.delete(endpoint + 'documents/' + id))
      },
      versions: (id) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'documents/' + id + '/versions'))
          }
        }
      },
      upload: (id, params, onProgress) => {
        const formData = new FormData()
        formData.append('filePayload', params.fileData)
        return axios.post(endpoint + 'documents/' + id + '/upload', formData,
          {
            params: params.query,
            maxContentLength: 'Infinity',
            maxBodyLength: 'Infinity',
            onUploadProgress: (progressEvent) => {
              onProgress({
                percentage: Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              })
            }
          })
      },
      download: (id, progress) => {
        return axios.get(endpoint + 'documents/' + id + '/download', {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            progress.retrievalProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          }
        })
      },
      getFilename: (id) => {
        return this.extractData(axios.get(endpoint + 'documents/' + id + '/filename'))
      },
      downloadBuildingDocument: (instanceId, fileName) => {
        return axios.get(endpoint + 'documents/building_document/', {
          params: { instanceId: instanceId, fileName: fileName },
          responseType: 'blob'
        })
      }
    }

    this.taxes={
      putpropertytaxstatus: (id, data) => {
        return this.extractData(axios.put(endpoint + 'tax/propertytaxstatus/' + id, data))
      }
    }

    this.customizabledatafields = {
      get: () => {
        return this.extractData(axios.get(endpoint + 'customizabledatafields/'))
      },
      put: (id, data) => {
        return this.extractData(axios.put(endpoint + 'customizabledatafields/' + id, data))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'customizabledatafields/', data))
      },
      delete: (id) => {
        return this.extractData(axios.delete(endpoint + 'customizabledatafields/' + id))
      },
      spaces: (spaceIds) => {
        return this. extractData(axios.post(endpoint + 'customizabledatafields/spaces', spaceIds))
      },
      spacesByOrg: () => {
        return this. extractData(axios.get(endpoint + 'customizabledatafields/spaces'))
      }
    }

    this.photos = {
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'photos/', data))
      },
      put: (id, data) => {
        return this.extractData(axios.put(endpoint + 'photos/' + id, data))
      },
      delete: (id) => {
        return this.extractData(axios.delete(endpoint + 'photos/' + id))
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'photos/metadata'))
      },
      upload: (id, file, onProgress) => {
        const formData = new FormData()
        formData.append('filePayload', file)
        return axios.post(endpoint + 'photos/' + id + '/upload', formData,
          {
            maxContentLength: 'Infinity',
            maxBodyLength: 'Infinity',
            onUploadProgress: (progressEvent) => {
              onProgress({
                percentage: Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              })
            }
          })
      },
      download: (id, progress, thumbnail = false ) => {
        return axios.get(endpoint + 'photos/' + id + '/download', {
          params: { thumbnail },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            progress.retrievalProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          }
        })
      }
    }

    this.images = {
      get: (fileName) => {
        return axios.get(endpoint + 'images/' + fileName)
      },
    }

    this.buildingRating = {
      list: () => {
        return this.extractData(axios.get(endpoint + 'buildingRating/'))
      },
      get: (id) => {
        return this.extractData(axios.get(endpoint + 'buildingRating/' + id))
      },
      getAllByIdBuilding: (idBuilding) => {
        return this.extractData(axios.get(endpoint + 'buildingRating/building/' + idBuilding))
      },
      getInitialData: (idBuilding) => {
        return this.extractData(axios.get(endpoint + 'buildingRating/init/building/' + idBuilding))
      },
      getQuestions: () => {
        return this.extractData(axios.get(endpoint + 'buildingRating/questions/'))
      },
      getAnswers: () => {
        return this.extractData(axios.get(endpoint + 'buildingRating/answers/'))
      },
      getAnswersByBuildingRating: (buildingRating) => {
        return this.extractData(axios.get(endpoint + 'buildingRating/answers/' + buildingRating))
      },
      getNeighborhoods: () => {
        return this.extractData(axios.get(endpoint + 'buildingRating/neighborhoods/'))
      },
      getBuildingRatingClasses: () => {
        return this.extractData(axios.get(endpoint + 'buildingRating/buildingRatingClasses/'))
      },
      post: (buildingRating, time) => {
        return this.extractData(axios.post(endpoint + 'buildingRating/', buildingRating, time))
      },
      update: (id, buildingRating, answers) => {
        return this.extractData(axios.patch(endpoint + `buildingRating/${id}`, buildingRating, answers))
      },
      approve: (id, buildingRating) => {
        return this.extractData(axios.patch(endpoint + `buildingRating/${id}`, buildingRating))
      },
      calculateRating: (buildingRating, time) => {
        return this.extractData(axios.post(endpoint + 'buildingRating/calculate', buildingRating, time))
      },
    },

    this.buildings = {
      deleteDraft: (buildingId) => {
        return this.extractData(axios.delete(endpoint + 'buildings/deletedraftbuilding/' + buildingId))
      },
      documents: (buildingCode) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/documents'))
          }
        }
      },
      floors: (buildingCode) => {
        return {
          list: (time) => {
            return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/floors', { params: { time: time } }))
          },
          listWithFuture: (time, siteId) => {
            return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/floors/future_included', { params: { time: time, siteId: siteId } }))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'buildings/' + buildingCode + '/floors', data))
          }
        }
      },
      units: (buildingCode, time) => {
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/units', { params: { time: time }}))
      },
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'buildings', { params: queryParams }))
      },
      filteredList: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.post(endpoint + 'buildings/filtered', params?.body ?? [], { params: queryParams }))
      },
      listWithFuture: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'buildings/future_included', { params: queryParams }))
      },
      listBySites: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.post(endpoint + 'buildings/', params.query.siteids,  queryParams ))
      },
      get: (buildingCode) => {
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode))
      },
      getVersion: (buildingId, version) => {
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingId + '/version', { params: { version: version }}))
      },
      getDraftVersion: (buildingId) => {
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingId + '/draftversion'))
      },
      simpleview: () => {
        return this.extractData(axios.get(endpoint + 'buildings/simpleview'))
      },
      patch: (buildingCode, time, data) => {
        return this.extractData(axios.patch(endpoint + 'buildings/' + buildingCode, data, { params: { time: time } }))
      },
      draftToValid: (buildingCode, time, data, draftRnro, draftName) => {
        return this.extractData(axios.patch(endpoint + 'buildings/drafttovalid/' + buildingCode, data, {
          params: {
            time: time,
            draftRnro: draftRnro,
            draftName: draftName
          }
        }))
      },
      models: (buildingCode, params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/models', { params: queryParams }))
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'buildings/metadata'))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'buildings/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'buildings/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'buildings/report/metadata'))
          }
        }
      },
      partialbuildings: (buildingCode) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/partialbuildings'))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'buildings/' + buildingCode + '/partialbuildings', data))
          },
          update: (id, data) => {
            return this.extractData(axios.put(endpoint + 'buildings/' + buildingCode + '/partialbuildings/' + id, data))
          },
          delete: (id) => {
            return this.extractData(axios.delete(endpoint + 'buildings/' + buildingCode + '/partialbuildings/' + id))
          }
        }
      },
      buildingpermits: (buildingCode) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'buildings/' + buildingCode + '/buildingpermits'))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'buildings/' + buildingCode + '/buildingpermits', data))
          },
          update: (id, data) => {
            return this.extractData(axios.put(endpoint + 'buildings/' + buildingCode + '/buildingpermits/' + id, data))
          },
          delete: (id) => {
            return this.extractData(axios.delete(endpoint + 'buildings/' + buildingCode + '/buildingpermits/' + id))
          }
        }
      },
      organizations: () => {
        return {
          list: (time) => {
            return this.extractData(axios.get(endpoint + 'buildings/organizations', {params: {time: time} } ))
          },
          put: (buildingId, orgId) => {
            return this.extractData(axios.put(endpoint + 'buildings/' + buildingId + '/organization', {}, {params: { organization_id: orgId }}))
          }
        }
      },
      facta: (propertyName, property, estateIdentifier) => {
        return this.extractData(axios.get(endpoint + 'buildings/facta/' + propertyName, {params: {buildingProperty: property, estateIdentifier: estateIdentifier }}))
      },
      getAllBuildingsWithPermissionValidity: (time) => {
        return this.extractData(axios.get(endpoint + 'buildings/permissionvalidity', { params: { time: time } }))
      },
      getAllBuildingUnitsWithPermissionValidity: (time) => {
        return this.extractData(axios.get(endpoint + 'buildings/buildingunitpermissionvalidity', { params: { time: time } }))
      },
      endBuilding: (buildingId, time, deleteObject) => {
        return this.extractData(axios.delete(endpoint + 'buildings/' + buildingId + '/end_building', { params: {time: time }, data: deleteObject}))
      },
      checkIfEndingPossible: (buildingId, time) => {
        return this.extractData(axios.get(endpoint + 'buildings/' + buildingId + '/ending_possible', { params: {time: time } }))
      },
      hierarchyInfo: (time, buildingIds) => {
        return this.extractData(axios.post(endpoint + 'buildings/hierarchyinfo', buildingIds, { params: { time: time }}))
      },
      costCenterAreasByIdList: (buildingIds, time) => {
        return this.extractData(axios.post(endpoint + 'buildings/cost_centers_by_id_list/', buildingIds, { params: { time: time }}))
      },
      tags: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'buildings/tags'))
        },
        update: (body) => {
          return this.extractData(axios.put(endpoint + 'buildings/tags', body))
        },
      },
      buildingClassifications: (classificationLevel) => {
        return this.extractData(axios.get(endpoint + 'buildings/buildingclassifications',
          { params: { classificationLevel: classificationLevel } }))
      }
    }

    this.structures = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'structures/', { params: queryParams }))
      },
      listWithFuture: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'structures/future_included', { params: queryParams }))
      },
      patch: (id, time, data) => {
        return this.extractData(axios.patch(endpoint + 'structures/' + id, data, { params: { time: time } }))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'structures/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'structures/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'structures/report/metadata'))
          }
        }
      },
    }

    this.floors = {
      get: (floorId, time) => {
        return this.extractData(axios.get(endpoint + 'floors/' + floorId, { params: { time: time } }))
      },
      spaces: (floorId, time) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/spaces', { params: { time: time } }))
          },
          partyLinks: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/spaces/partylinks', { params: { time: time } }))
          }
        }
      },
      units: (floorId, time) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/units', { params: { time: time } }))
          },
          listWithFuture: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/units/future_included', { params: { time: time } }))
          },
          partyLinks: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/units/partylinks', { params: { time: time } }))
          },
          extra: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/units/extra', { params: { time: time } }))
          },
          contracts: () => {
            return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/units/contracts', { params: { time: time } }))
          },

        }
      },
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'floors/', { params: queryParams }))
      },
      extra: (floorId, categoryName, extraName, time, keys) => {
        return this.extractData(axios.post(endpoint + 'floors/' + floorId + '/categories/' + categoryName + '/extra/' + extraName, keys, { params: { time: time } })
        )
      },
      categories: (floorId, categoryLayer, time) => {
        return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/categories', { params: { time: time, categoryLayer: categoryLayer } }))
      },
      dwg: (id, processed, progress) => {
        return axios.get(endpoint + 'floors/' + id + '/dwg', {
          params: { processed },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            progress.retrievalProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          }
        })
      },
      xml: (id, time, progress, siteId) => {
        return axios.get(endpoint + 'floors/' + id + '/xml', {
          params: { time: time, siteId: siteId },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            progress.retrievalProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          }
        })
      },
      v3markups: () => {
        return {
          get: (storedViewId) => {
            return this.extractData(axios.get(endpoint + 'floors/v3markups/' + storedViewId))
          },
          patch: (floorId, data) => {
            return this.extractData(axios.patch(endpoint + 'floors/v3markups/' + floorId, data))
          },
          delete: (storedViewId) => {
            return this.extractData(axios.delete(endpoint + 'floors/v3markups/' + storedViewId))
          },
          storedview: (floorId) => {
            return this.extractData(axios.get(endpoint + 'floors/v3markups/' + floorId + '/storedviews'))
          }
        }
      },
      purePatch: (floorId, version, data) => {
        return this.extractData(axios.patch(endpoint + 'floors/' + floorId + '/pure', data, { params: { version: version } }))
      },
      delete: (floorId) => {
        return this.extractData(axios.delete(endpoint + 'floors/' + floorId))
      },
      contractrows: (floorId, time) => {
        return this.extractData(axios.get(endpoint + 'floors/' + floorId + '/contractrows', { params: { time: time} }))
      },
      floor_change: (floorId, time) => {
        return this.extractData(axios.post(endpoint + 'floors/' + floorId + '/floor_change', { params: {time: time} }))
      }
    }

    this.layers = {
      list: () => {
        return this.extractData(axios.get(endpoint + 'layers/'))
      }
    }

    this.rent = {
      contracts: () => {
        return {
          list: (params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'rent/contracts', { params: queryParams }))
          }
        }
      },
      rentstatus: () => {
        return {
          list: (params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'rent/rentstatus', { params: queryParams }))
          }
        }
      }
    }

    this.persons = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'persons/', { params: queryParams }))
      }
    }

    this.permissions = {
      groups: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'permissions/groups'))
          },
          patchHierarchy: (groupId, data) => {
            return this.extractData(axios.patch(endpoint + 'permissions/groups/' + groupId +'/hierarchy', data))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'permissions/groups', data))
          },
          put: (groupId, data) => {
            return this.extractData(axios.put(endpoint + 'permissions/groups/' + groupId, data))
          },
          delete: (groupId) => {
            return this.extractData(axios.delete(endpoint + 'permissions/groups/' + groupId))
          }
        }
      },
      preview: () => {
        return {
          documents: (data, params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.post(endpoint + 'permissions/preview/documents', data, { params: queryParams }))
          }
        }
      },
      applications: () => {
        return this.extractData(axios.get(endpoint + 'permissions/applications'))
      },
      post: (userId, data) => {
        return this.extractData(axios.post(endpoint + 'accounts/user/' + userId + '/permissions/groups', data))
      },
      sites: () => {
        return {
          getAll: (page = null, limit = null, search = null) => {
            return this.extractData(axios.get(endpoint + 'permissions/sites', { params: { page: page, limit: limit, search: search } }))
          },
          perPage: (groupId, page, limit, search = null) => {
            return this.extractData(axios.get(endpoint + 'permissions/sites/' + groupId, { params: { page: page, limit: limit, search: search } }))
          }
        }
      },
      buildings: () => {
        return {
          getAll: () => {
            return this.extractData(axios.get(endpoint + 'permissions/buildings'))
          },
          perPage: (groupId, page, limit) => {
            return this.extractData(axios.get(endpoint + 'permissions/buildings/' + groupId, { params: { page: page, limit: limit } }))
          },
          preview: (groupId, params) => {
            return this.extractData(axios.post(endpoint + 'permissions/preview/buildings/' + groupId, params.query.siteids))
          }
        }
      },
      structures: () => {
        return {
          getAll: () => {
            return this.extractData(axios.get(endpoint + 'permissions/structures'))
          },
          perPage: (groupId, page, limit) => {
            return this.extractData(axios.get(endpoint + 'permissions/structures/' + groupId, { params: { page: page, limit: limit } }))
          },
          preview: (groupId, params) => {
            return this.extractData(axios.post(endpoint + 'permissions/preview/structures/' + groupId, params.query.siteids))
          }
        }
      },
      cemeteries: () => {
        return {
          getAll: () => {
            return this.extractData(axios.get(endpoint + 'permissions/cemeteries'))
          },
          perPage: (groupId, page, limit) => {
            return this.extractData(axios.get(endpoint + 'permissions/cemeteries/' + groupId, { params: { page: page, limit: limit } }))
          },
          preview: (groupId, params) => {
            return this.extractData(axios.post(endpoint + 'permissions/preview/cemeteries/' + groupId, params.query.siteids))
          }
        }
      },
      widgets: () => {
        return this.extractData(axios.get(endpoint + 'permissions/widgets'))
      },
      documents: () => {
        return this.extractData(axios.get(endpoint + 'permissions/documents'))
      }
    }

    this.sites = {
      create: (site) => {
        return this.extractData(axios.post(endpoint + 'sites', site))
      },
      documents: (siteId) => {
        return {
          list: (limit, offset, search, type, sortby, filename) => {
            if (typeof limit === 'undefined') {
              limit = 10
            }
            if (typeof offset === 'undefined') {
              offset = 0
            }
            if (typeof search === 'undefined') {
              search = undefined
            }
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/documents', { params: { limit, offset, search, type, sortby, filename } }))
          }
        }
      },
      costs: () => {
        return {
          list: (siteId) => {
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/costs'))
          }
        }
      },
      photos: (siteId) => {
        return {
          list: (search, type, sortby, filename, time) => {
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/photos', { params: { search, type, sortby, filename, time } }))
          }
        }
      },
      groups: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'sites/groups', { params: { time: time } }))
          },
          perPage: (time, page, limit, search = null) => {
            return this.extractData(axios.get(endpoint + 'sites/groups', { params: { time: time, page: page, limit: limit, search: search } }))
          }
        }
      },
      documentFilters: (siteId) => {
        return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/documents/filters'))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'sites/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'sites/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'sites/report/metadata'))
          }
        }
      },
      estates: (siteId) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/estates'))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'sites/' + siteId + '/estates', data))
          }
        }
      },
      partyLinks: (siteId) => {
        return {
          list: (params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/partyLinks', { params: queryParams }))
          },
          patch: (siteId, linkId, version, data) => {
            return this.extractData(axios.patch(endpoint + 'sites/' + siteId + '/partyLinks/' + linkId, data, { params: { version: version } }))
          },
          post: (siteId, data) => {
            return this.extractData(axios.post(endpoint + 'sites/' + siteId + '/partyLinks', data))
          },
          delete: (siteId, linkId) => {
            return this.extractData(axios.delete(endpoint + 'sites/' + siteId + '/partyLinks/' + linkId))
          }
        }
      },
      parties: (siteId) => {
        return {
          list: (params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/parties', { params: queryParams }))
          }
        }
      },
      personnel: (siteId) => {
        return {
          list: (params) => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/personnel', { params: queryParams }))
          }
        }
      },
      services: (siteId) => {
        return {
          list: (time) => {
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/services', {params: { time: time }}))
          },
          put: (data, time) => {
            return this.extractData(axios.put(endpoint + 'sites/' + siteId + '/services', data, {params: { time: time }} ))
          }
        }
      },
      traffic: (siteId) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/traffic'))
          },
          put: (data, time) => {
            return this.extractData(axios.put(endpoint + 'sites/' + siteId + '/traffic', data, {params: { time: time }} ))
          }
        }
      },
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'sites/', { params: queryParams }))
      },
      filteredList: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.post(endpoint + 'sites/filtered', params?.body ?? [], { params: queryParams }))
      },
      listWithFuture: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'sites/future_included', { params: queryParams }))
      },
      get: (siteId, params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'sites/' + siteId, { params: queryParams }))
      },
      ids: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'sites/ids', { params: queryParams }))
      },
      patch: (siteId, time, version, data) => {
        return this.extractData(axios.patch(endpoint + 'sites/' + siteId, data, { params: { time: time, version: version } }))
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'sites/metadata'))
      },
      organizations: (siteId) => {
        return {
          put: (orgId) => {
            return this.extractData(axios.put(endpoint + 'sites/' + siteId + '/organization', {}, { params: {organization_id: orgId}}))
          }
        }
      },
      marketingInformation: (time) => {
        return this.extractData(axios.get(endpoint + 'sites/' + 'marketing_info', { params: { time: time } }))
      },
      certificationDocument: (paramsData) => {
        const { siteId, documentId, certificationDate } = paramsData
        return this.extractData(axios.put(endpoint + 'sites/' + siteId + '/documents/certification', {}, { params: { documentId: documentId, certificationDate: certificationDate }}))
      },
      filterValues: (time) => {
        return this.extractData(axios.get(endpoint + 'sites/' + 'filtervalues', { params: { time: time}}))
      },
      units: (siteId, params) => {
        return {
          list: () => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/units', { params: queryParams }))
          }
        }
      },
      unitsExtra: (siteId, params) => {
        return {
          partyLinks: () => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/units/partylinks', { params: queryParams }))
          },
          list: () => {
            const queryParams = typeof params !== 'undefined' ? params.query : {}
            return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/units/extra', { params: queryParams }))
          }
        }
      },
      endSite: (siteId, time, deleteObject) => {
        return this.extractData(axios.delete(endpoint + 'sites/' + siteId + '/end_site', { params: {time: time }, data: deleteObject}))
      },
      checkIfEndingPossible: (siteId, time) => {
        return this.extractData(axios.get(endpoint + 'sites/' + siteId + '/ending_possible', { params: {time: time } }))
      },
      costCenterMetadata: () => {
        return this.extractData(axios.get(endpoint + 'sites/costcenterarea/metadata'))
      },
      tags: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'sites/tags/'))
        },
        update: (body) => {
          return this.extractData(axios.put(endpoint + 'sites/tags', body))
        },
      }
    }

    this.estates = {
      buildings: (id) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'estates/' + id + '/buildings'))
          },
          post: (data) => {
            return axios.post(endpoint + 'estates/' + id + '/buildings', data)
          }
        }
      },
      structures: (id) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'estates/' + id + '/structures'))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'estates/' + id + '/structures', data))
          }
        }
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'estates/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'estates/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'estates/report/metadata'))
          }
        }
      },
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'estates/', { params: queryParams }))
      },
      get: (id, time) => {
        return this.extractData(axios.get(endpoint + 'estates/' + id, { params: { time: time } }))
      },
      patch: (id, time, data) => {
        return this.extractData(axios.patch(endpoint + 'estates/' + id, data, { params: { time: time } }))
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'estates/metadata'))
      },
      dwg: (id, processed, progress) => {
        return axios.get(endpoint + 'estates/' + id + '/dwg', {
          params: { processed },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            progress.retrievalProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          }
        })
      },
      carpark: (estateId, time) => {
        return {
          patch: (carparkId, data) => {
            return this.extractData(axios.patch(endpoint + 'estates/' + estateId + '/carparks', data, { params: { carpark_id: carparkId } }))
          },
          list: () => {
            return this.extractData(axios.get(endpoint + 'estates/' + estateId + '/carparks', { params: { time: time } }))
          }
        }
      },
      outdoors: (estateId) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'estates/' + estateId + '/outdoors'))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'outdoorareas/metadata'))
          },
          patch: (outdoorId, time, data) => {
            return this.extractData(axios.patch(endpoint + 'outdoorareas/' + outdoorId, data, { params: {time: time}}))
          }
        }
      },
      vegetation: (estateId) => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'estates/' + estateId + '/plantareas'))
          }
        }
      },
      categories: (estateId, categoryLayer, time) => {
        return this.extractData(axios.get(endpoint + 'estates/' + estateId + '/categories', { params: { categoryLayer: categoryLayer, time: time } }))
      },
      facta: (estateIdentifier) => {
        return this.extractData(axios.get(endpoint + 'estates/facta/' + estateIdentifier))
      },
      endEstate: (estateId, time, deleteObject) => {
        return this.extractData(axios.delete(endpoint + 'estates/' + estateId + '/end_estate', { params: {time: time }, data: deleteObject}))
      },
      checkIfEndingPossible: (estateId, time) => {
        return this.extractData(axios.get(endpoint + 'estates/' + estateId + '/ending_possible', { params: {time: time } }))
      }
    }

    this.widgets = {
      dataTable: (data, signal) => {
        return this.extractData(axios.post(endpoint + 'widgets/datatable', data, { signal }))
      }
    }

    this.services = {
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'sites/metadata/services'))
      }
    }

    this.traffic = {
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'sites/metadata/traffic'))
      }
    }

    this.parties = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'parties/', { params: queryParams }))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'parties/', data))
      },
      delete: (partyId) => {
        return this.extractData(axios.delete(endpoint + 'parties/' + partyId))
      },
      patch: (partyId, data) => {
        return this.extractData(axios.patch(endpoint + 'parties/' + partyId, data))
      },
      assignments: (type) => {
        return this.extractData(axios.get(endpoint + 'parties/assignments/', { params: { type } }))
      },
      links: (partyId) => {
        return this.extractData(axios.get(endpoint + 'parties/' + partyId + '/links'))
      },
      linksByDate: (partyId, time) => {
        return this.extractData(axios.get(endpoint + 'parties/' + partyId + '/links', {params: {time}}))
      },
      ytjbusinessidentifierquery: (businessIdentifierQuery) => {
        return this.extractData(axios.get(endpoint + 'parties/ytjbusinessidentifierquery',
          {params: {'businessIdentifierQuery': businessIdentifierQuery}}))
      },
      ytjnamequery: (nameQuery) => {
        return this.extractData(axios.get(endpoint + 'parties/ytjnamequery',
          {params: {'nameQuery': nameQuery}}))
      },
      tenantCorporation: {
        list: () => {
          return this.extractData(axios.get(endpoint + 'parties/tenantcorporation/extra'))
        },
        tenantCorporationByContract: (contractNumber) => {
          return this.extractData(axios.get(endpoint + 'parties/tenantcorporation/' + contractNumber))
        },
        tenantCorporationById: (corporationId) => {
          return this.extractData(axios.get(endpoint + 'parties/tenantcorporation/' + corporationId + '/extra'))
        },
        contracts: (corporationId, time) => {
          return this.extractData(axios.get(endpoint + 'parties/tenantcorporation/' + corporationId + '/contracts', { params: { time } }))
        }
      }
    }

    this.partiesLinks = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'parties/links', { params: queryParams }))
      }
    }

    this.definitions = {
      list: (params) => {
        return this.extractData(axios.get(endpoint + 'definitions/', { params: { groupLabel: params } }))
      },
      purposesofuse: () => {
        return this.extractData(axios.get(endpoint + 'definitions/purposesofuse'))
      },
      portfolios: () => {
        return this.extractData(axios.get(endpoint + 'definitions/portfolios'))
      },
      cleaningarea: () => {
        return this.extractData(axios.get(endpoint + 'definitions/cleaningarea'))
      },
      firearea: () => {
        return {
          building: (buildingId) => {
            return this.extractData(axios.get(endpoint + 'definitions/firearea/building', { params: { id_building: buildingId } }))
          },
          all: () => {
            return this.extractData(axios.get(endpoint + 'definitions/firearea/all'))
          }
        }
      },
      costcenters: () => {
        return this.extractData(axios.get(endpoint + 'definitions/costcenters'))
      },
      purposezones: () => {
        return this.extractData(axios.get(endpoint + 'definitions/purposesofusezones'))
      },
      portfolio: () => {
        return {
          patch: (data) => {
            return this.extractData(axios.patch(endpoint + 'definitions/portfolio', {}, { params: data }))
          },
          put: (data) => {
            return this.extractData(axios.put(endpoint + 'definitions/portfolio', {}, { params: data }))
          },
          delete: (id) => {
            return this.extractData(axios.delete(endpoint + 'definitions/portfolio/' + id))
          }
        }
      }
    }

    this.tags = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'tags/', { params: queryParams }))
      }
    }

    this.support = {
      servicerequest: () => {
        return {
          types: () => {
            return this.extractData(axios.get(endpoint + 'support/servicerequest/types'))
          },
          list: () => {
            return this.extractData(axios.get(endpoint + 'support/servicerequest'))
          },
          get: (requestId) => {
            return this.extractData(axios.get(endpoint + 'support/servicerequest/' + requestId))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'support/servicerequest', data))
          },
          delete: (requestId) => {
            return this.extractData(axios.delete(endpoint + 'support/servicerequest/' + requestId))
          },
          patch: (requestId, data) => {
            return this.extractData(axios.patch(endpoint + 'support/servicerequest/' + requestId, data))
          },
          postVersion: (requestId, data) => {
            return this.extractData(axios.post(endpoint + 'support/servicerequest/' + requestId, data))
          },
          links: (requestId) => {
            return this.extractData(axios.get(endpoint + 'support/servicerequest/' + requestId + '/links'))
          },
          documents: (requestId) => {
            return this.extractData(axios.get(endpoint + 'support/servicerequest/' + requestId + '/documents'))
          }
        }
      },
      invitation: () => {
        return this.extractData(axios.post(endpoint + 'support/invitation'))
      }
    }

    this.reports = {
      post: (reportName, buildingIds, currentDate, signal) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.post(`${endpoint}reports/${reportName}`,
          { buildingIds, rules: [], currentDate: new Date() },
          { params: { time }, signal })
        )
      },
      postSite: (reportName, siteIds, currentDate) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.post(endpoint + 'reports/' + reportName, { siteIds, rules: [] }, { params: { time: time } }))
      },
      list: (reportNames, buildingIds, currentDate, buildings = [], siteIds = [], bundle, getAll) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.post(endpoint + 'reports',
          { buildingIds: buildingIds, buildings: buildings, rules: [], reports: reportNames, siteIds: siteIds, bundle: bundle, getAll: getAll, currentDate: new Date() },
          { params: { time: time } })
        )
      },
      fimx: (siteIdentifiers, currentDate) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.post(endpoint + 'reports/fimx', siteIdentifiers, { params: {time: time }}))
      },
      buildingusages: (buildings) => {
        return this.extractData(axios.post(endpoint + 'reports/buildingusages', { sites: [], buildings: buildings, rules: [] }))
      },
      unitcontractareas: buildings => {
        return this.extractData(axios.post(endpoint + 'reports/GetContractAreasForBuilding', { sites: [], buildings: buildings, rules: [] }))
      },
      audit: (currentDate) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.get(endpoint + 'audit/inspections/withinthreemonths', { params: {time: time }}))
      },
      projectWise: (reportNames, buildingIds, currentDate, buildings = [], siteIds = []) => {
        const time = currentDate ?? new Date()
        return this.extractData(axios.post(endpoint + 'reports/projectwise', { buildingIds: buildingIds, buildings: buildings, rules: [], reports: reportNames, siteIds: siteIds }, { params: { time: time } }))
      }
    }

    this.spaces = {
      list: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'spaces/', { params: queryParams }))
      },
      get: spaceId => {
        return this.extractData(axios.get(endpoint + 'spaces/' + spaceId))
      },
      purePatch: (spaceId, version, data) => {
        return this.extractData(
          axios.patch(endpoint + 'spaces/' + spaceId + '/pure', data, { params: { version: version } })
        )
      },
      patch: (spaceId, time, data) => {
        return this.extractData(axios.patch(endpoint + 'spaces/' + spaceId, data, { params: { time: time } }))
      },
      partyLinks: spaceId => {
        return {
          list: () => {
            return this.extractData(
              axios.get(endpoint + 'spaces/' + spaceId + '/partyLinks')
            )
          },
          patch: (spaceId, linkId, version, data) => {
            return this.extractData(
              axios.patch(
                endpoint + 'spaces/' + spaceId + '/partyLinks/' + linkId,
                data,
                { params: { version: version } }
              )
            )
          },
          post: (spaceId, data) => {
            return this.extractData(
              axios.post(endpoint + 'spaces/' + spaceId + '/partyLinks', data)
            )
          },
          delete: (spaceId, linkId) => {
            return this.extractData(
              axios.delete(endpoint + 'spaces/' + spaceId + '/partyLinks/' + linkId)
            )
          }
        }
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'spaces/metadata'))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'spaces/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'spaces/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'spaces/report/metadata'))
          }
        }
      },
      noData: () => {
        return this.extractData(axios.get(endpoint + 'spaces/emptytable'))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'spaces', data))
      },
      customizabledatafields: (spaceId, data) => {
        return this.extractData(axios.put(endpoint + 'spaces/' + spaceId + '/customizabledatafields', data))
      },
      siteSpaces: (siteId, params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'spaces/site_spaces/' + siteId, { params: queryParams }))
      },
      allocatedAreaBySpaces: (spaceIds, time) => {
        return this.extractData(axios.post(endpoint + 'spaces/allocated_area_by_spaces/', spaceIds, { params: { time: time } }))
      },
      allocatedAreaByUnits: (unitIds, time) => {
        return this.extractData(axios.post(endpoint + 'spaces/allocated_area_by_units', unitIds, { params: { time: time } }))
      }
    }
    this.devices = {
      get: (siteId, spaceId, time) => {
        const params = { siteId: siteId, spaceId: spaceId, time: time }

        return this.extractData(axios.get(endpoint + 'devices/', { params }))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'devices/', data))
      },
      patch: (deviceId, time, data) => {
        return this.extractData(axios.patch(endpoint + 'devices/' + deviceId, data , { params: { time: time } }))
      },
      delete: (deviceId, time) => {
        return this.extractData(axios.delete(endpoint + 'devices/' + deviceId , { params: { time: time } }))
      },
      connections: (deviceId) => {
        return {
          update: (time, data) => {
            return this.extractData(axios.put(endpoint + 'devices/' + deviceId + '/connections', data , { params: { time: time } }))
          }
        }
      }
    }


    this.units = {
      get: (unitId, time) => {
        return this.extractData(axios.get(endpoint + 'units/' + unitId, {params: {time: time} }))
      },
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'units', data))
      },
      patch: (unitId, time, data) => {
        return this.extractData(axios.patch(endpoint + 'units/' + unitId, data, { params: { time: time } }))
      },
      purePatch: (unitId, version, data) => {
        return this.extractData(axios.patch(endpoint + 'units/' + unitId + '/pure', data, { params: { version: version } }))
      },
      partyLinks: unitId => {
        return {
          list: (time, history) => {
            return this.extractData(
              axios.get(endpoint + 'units/' + unitId + '/partyLinks', { params: { time: time, history: history } })
            )
          },
          patch: (unitId, linkId, version, data) => {
            return this.extractData(axios.patch(endpoint + 'units/' + unitId + '/partyLinks/' + linkId, data, { params: { version: version } }))
          },
          purePatch: (unitId, linkId, version, data) => {
            return this.extractData(axios.patch(endpoint + 'units/' + unitId + '/partyLinks/' + linkId + '/pure', data, { params: { version: version } }))
          },
          post: (unitId, data) => {
            return this.extractData(
              axios.post(endpoint + 'units/' + unitId + '/partyLinks', data)
            )
          },
          delete: (unitId, linkId) => {
            return this.extractData(
              axios.delete(endpoint + 'units/' + unitId + '/partyLinks/' + linkId)
            )
          },
          fullDelete: (unitId, linkId) => {
            return this.extractData(
              axios.delete(endpoint + 'units/' + unitId + '/partyLinks/' + linkId + '/full')
            )
          },
          getLinkIdByRentalContract: (contractId) => {
            return this.extractData(
              axios.get(endpoint + 'units/' + unitId + '/partyLinks/byRentalContract', { params: { contractId: contractId } })
            )
          },
          rentalContracts: () => {
            return {
              get: (time) => {
                return this.extractData(axios.get(endpoint + 'units/' + unitId + '/rentalContract', { params: { time: time } }))
              },
              post: (linkId, data) => {
                return this.extractData(axios.post(endpoint + 'units/' + unitId + '/partyLinks/' + linkId + '/rentalContract', data))
              },
              patch: (linkId, data) => {
                return this.extractData(axios.patch(endpoint + 'units/' + unitId + '/partyLinks/' + linkId + '/rentalContract', data))
              },
              getByLinkId: (linkId) => {
                return this.extractData(axios.get(endpoint + 'units/' + unitId + '/rentalContract/' + linkId))
              }
            }
          }
        }
      },
      assignSpaces: (unitId, data, date) => {
        return this.extractData(axios.post(endpoint + 'units/' + unitId + '/assign-spaces', data, { params: { date: date }}))
      },
      dismantle: (unitId, time) => {
        return this.extractData(axios.delete(endpoint + 'units/' + unitId + '/dismantle', { params: { time: time } }))
      },
      getSpacesInUnit: (spaceId, date) => {
        return this.extractData(axios.get(endpoint + 'units/space/' + spaceId + '/spacesInUnit', {params: { date: date}} ))
      },
      metadata: () => {
        return this.extractData(axios.get(endpoint + 'units/metadata'))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'units/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'units/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'units/report/metadata'))
          }
        }
      },
      extra: () => {
        return {
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'units/extra/metadata'))
          }
        }
      },
      rentalStatus: () => {
        return {
          put: (data, editContractNumber = false) => {
            return this.extractData(axios.put(endpoint + 'units/rental_status', data, { params: { edit: editContractNumber } }))
          },
          update: (data, editContractNumber = false) => {
            return this.extractData(axios.put(endpoint + 'units/contract_status', data, { params: { edit: editContractNumber } }))
          },
          post: (data) => {
            return this.extractData(axios.post(endpoint + 'units/rental_status', data ))
          },
          updateReportableInfo: (data, time) => {
            return this.extractData(axios.put(endpoint + 'units/rental_status/reportable_info', data, { params: { time: time } }))
          },
        }
      },
      newVersion: (unitId, data) => {
        return this.extractData(axios.post(endpoint + 'units/' + unitId + '/newVersion', data))
      },
      printLog: () => {
        return {
          post: (unitId, atype) => {
            return this.extractData(axios.post(endpoint + 'units/' + unitId + '/print_log', {}, { params: { a_type: atype }}))
          }
        }
      },
      additionalInformation: (unitId) => {
        return {
          get: (time) => {
            return this.extractData(axios.get(endpoint + 'units/' + unitId + '/additionalInfo', { params: { time: time } }))
          },
          patch: (unitId, time, data) => {
            return this.extractData(axios.patch(endpoint + 'units/' + unitId + '/additionalInfo', data, { params: { time: time } }))
          },
        }
      },
      marketingInformation: (time) => {
         return this.extractData(axios.get(endpoint + 'units/' + 'marketing_info', { params: { time: time } }))
      },
      transferSpaces: (targetUnitId, sourceAreasList, transferDate, noNewVersion, targetNewUnitArea, targetNewAllocatedUnitArea) => {
          return this.extractData(axios.post(endpoint + 'units/' + 'transfer_spaces', sourceAreasList, { params: {
          targetUnitId: targetUnitId, transferDate: transferDate, noNewVersion: noNewVersion, targetNewUnitArea: targetNewUnitArea, targetNewAllocatedUnitArea: targetNewAllocatedUnitArea }}))
      },
      nextCodeForFloor: (floorId, UnitCode) =>
      {
        return this.extractData(axios.get(endpoint + 'units/next_unit_code/floor/' + floorId + '/unit/' + UnitCode ))
      },
      futureVersion: (unitId, time) => {
        return this.extractData(axios.get(endpoint + 'units/' + unitId + '/future_version', { params: { time: time } }))
      },
      activeContracts: (unitId, time) => {
        return this.extractData(axios.get(endpoint + 'units/' + unitId + '/active_contracts', { params: { time: time } }))
      },
      endUnit: (unitId, time, deleteObject) => {
        return this.extractData(axios.delete(endpoint + 'units/' + unitId + '/end_unit', { params: {time: time }, data: deleteObject}))
      },
      checkIfEndingPossible: (unitId, time) => {
        return this.extractData(axios.get(endpoint + 'units/' + unitId + '/ending_possible', { params: {time: time } }))
      },
      tags: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'units/tags'))
        },
        update: (body) => {
          return this.extractData(axios.put(endpoint + 'units/tags', body))
        },
      }
    }

    this.organizations = {
      post: (data) => {
        return this.extractData(axios.post(endpoint + 'organizations', data))
      },
      patch: (orgId, data) => {
        return this.extractData(axios.patch(endpoint + 'organizations/' + orgId, data))
      },
      organizationCustomers: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'organizations/?level=organizationcustomers'))
          }
        }
      },
      organizations: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'organizations/?level=organizations'))
          }
        }
      },
      businessUnits: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'organizations/?level=businessunits'))
          }
        }
      },
      domains: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'organizations/?level=domains'))
          }
        }
      },
      costCenters: () => {
        return {
          list: () => {
            return this.extractData(axios.get(endpoint + 'organizations/?level=costcenters'))
          }
        }
      }
    }

    this.tasks = {
      list: () => {
        const queryParams = {}
        return this.extractData(
          axios.get(endpoint + 'longtermplans/', { params: queryParams })
        )
      },
      post: data => {
        return this.extractData(axios.post(endpoint + 'longtermplans/', data))
      },
      put: data => {
        return this.extractData(axios.put(endpoint + 'longtermplans/', data))
      },
      delete: id => {
        return this.extractData(axios.delete(endpoint + 'longtermplans/' + id))
      },
      codes: () => {
        return this.extractData(axios.get(endpoint + 'longtermplans/codes'))
      }
    }

    this.taskComments = {
      post: (taskId, data) => {
        return this.extractData(
          axios.post(endpoint + `longtermplans/${taskId}/comment`, data)
        )
      }
    }

    this.taskSchedules = {
      post: (taskId, data) => {
        return this.extractData(
          axios.post(endpoint + `longtermplans/${taskId}/schedule`, data)
        )
      }
    }

    this.taskBudgets = {
      post: (taskId, data) => {
        return this.extractData(
          axios.post(endpoint + `longtermplans/${taskId}/budget`, data)
        )
      }
    }

    this.carparks = {
      estate: (estateId, time) => {
        return this.extractData(axios.get(endpoint + 'carparks/estate/' + estateId, { params: { time: time } }))
      },
      floor: (floorId, time) => {
        return this.extractData(axios.get(endpoint + 'carparks/floor/' + floorId, { params: { time: time } }))
      },
      tenants: () => {
        return {
          floor: (floorId, time) => {
            return this.extractData(axios.get(endpoint + 'carparks/floor/' + floorId + '/tenants', { params: { time: time } }))
          },
          estate: (estateId, time) => {
            return this.extractData(axios.get(endpoint + 'carparks/estate/' + estateId + '/tenants', { params: { time: time } }))
          },
          put: (carparkId, time, data) => {
            return this.extractData(axios.put(endpoint + 'carparks/' + carparkId + '/tenants', data, { params: { time: time } }))
          },
          delete: (linkId, time) => {
            return this.extractData(axios.delete(endpoint + 'carparks/tenant/' + linkId, { params: { time: time } }))
          }
        }
      },
      patch: (carparkId, time, data) => {
        return this.extractData(axios.patch(endpoint + 'carparks/' + carparkId, data, { params: { time: time } }))
      },
      delete: (carparkId, version) => {
        return this.extractData(axios.delete(endpoint + 'carparks/' + carparkId, { params: { version: version } }))
      },
      marketRents: () => {
        return {
          patch: (time, data) => {
            return this.extractData(axios.patch(endpoint + 'carparks/market_rents', data, { params: { time: time } }))
          }
        }
      },
      reports: () => ({
        getAll: (time) => this.extractData(axios.get(`${endpoint}carparks/report`, { params: { time: time } })),
        perPage: (time, page, limit, search, sortBy, sortDesc) => this.extractData(axios.get(`${endpoint}carparks/report`, { params: { time: time, page, limit, search, sortBy, sortDesc } })),
        metadata: () => this.extractData(axios.get(`${endpoint}carparks/report/metadata`))
      }),
      contracts: {
        put: (data) => {
          return this.extractData(axios.put(endpoint + 'carparks/contracts', data))
        },
        post: (data) => {
          return this.extractData(axios.post(endpoint + 'carparks/contracts', data))
        },
        updateReportableInfo: (data, time) => {
          return this.extractData(axios.put(endpoint + 'carparks/contracts/reportable_info', data, { params: { time: time } }))
        },
      },
      buildingSummary: (buildingCode, time) => {
        return this.extractData(axios.get(endpoint + `carparks/buildingsummary/${buildingCode}`, { params: { time: time } }))
      },
      listWithFuture: (params) => {
        const queryParams = typeof params !== 'undefined' ? params.query : {}
        return this.extractData(axios.get(endpoint + 'carparks/future_included', { params: queryParams }))
      },
    }

    this.categories = {
      list: (floorId) => {
        return this.extractData(axios.get(endpoint + 'categories/' + floorId))
      },
      extra: (floorId, extraName, data) => {
        return this.extractData(axios.post(endpoint + 'categories/' + floorId + '/extra/' + extraName, data))
      }
    }

    this.internalrent = {
      portfolios: () => {
        return {
          list: () => {
            return this.extractData(
              axios.get(endpoint + 'internalrent/portfolios')
            )
          },
          get: (params) => {
            const { portfolioId } = params
            return this.extractData(
              axios.get(endpoint + 'internalrent/portfolios/' + portfolioId, {
                params
              })
            )
          },
          post: (data) => {
            return this.extractData(
              axios.post(endpoint + 'internalrent/portfolios', data)
            )
          },
          patch: (params) => {
            const { id, data } = params
            return this.extractData(
              axios.patch(endpoint + 'internalrent/portfolios/' + id, data)
            )
          },
          delete: (idPortfolio) => {
            return this.extractData(axios.delete(endpoint + 'internalrent/portfolios/' + idPortfolio))
          },
          costcenters: (portfolioId, time) => {
            return this.extractData(
              axios.get(
                endpoint +
                'internalrent/portfolios/' +
                portfolioId +
                '/costcenters',
                {
                  params: { time: time }
                }
              )
            )
          },
          rules: (portfolioId) => {
            return {
              list: () => {
                return this.extractData(
                  axios.get(
                    endpoint +
                    'internalrent/portfolios/' +
                    portfolioId +
                    '/rules'
                  )
                )
              },
              put: (data) => {
                return this.extractData(
                  axios.put(
                    endpoint +
                    'internalrent/portfolios/' +
                    portfolioId +
                    '/rules',
                    data
                  )
                )
              }
            }
          },
          updateBudget: (params) => {
            const { portfolioId, year, month, budgets } = params
            return this.extractData(
              axios.put(
                endpoint +
                'internalrent/portfolios/' +
                portfolioId +
                '/year/' +
                year +
                '/month/' +
                month +
                '/budgets',
                budgets
              )
            )
          },
          monthExpensesForYear: (params) => {
            const { portfolioId, year, month, expenses } = params
            return this.extractData(
              axios.put(
                endpoint +
                'internalrent/portfolios/' +
                portfolioId +
                '/year/' +
                year +
                '/month/' +
                month +
                '/expenses',
                expenses
              )
            )
          }
        }
      },
      purposesofuse: () => {
        return {
          list: (all) => {
            return this.extractData(
              axios.get(endpoint + 'internalrent/purposesofuse', {
                params: { all: all }
              })
            )
          },
          patch: (purposeId, data) => {
            return this.extractData(
              axios.patch(
                endpoint + 'internalrent/purposesofuse/' + purposeId,
                data
              )
            )
          }
        }
      },
      expenses: () => {
        return {
          list: () => {
            return this.extractData(
              axios.get(endpoint + 'internalrent/expenses')
            )
          },
          post: (data) => {
            return this.extractData(
              axios.post(endpoint + 'internalrent/expenses', data)
            )
          },
          delete: (expenseId) => {
            return this.extractData(
              axios.delete(endpoint + 'internalrent/expenses/' + expenseId)
            )
          },
          patch: (expenseId, data) => {
            return this.extractData(
              axios.patch(endpoint + 'internalrent/expenses/' + expenseId, data)
            )
          }
        }
      },
      reports: (queryBody) => {
        return this.extractData(
          axios.post(endpoint + 'internalrent/reports', queryBody)
        )
      },
      shares: () => {
        return {
          list: (spaceIds, time) => {
            return this.extractData(
              axios.post(endpoint + 'internalrent/shares', spaceIds, { params: { time: time } })
            )
          },
          put: (spaceId, time, data) => {
            return this.extractData(
              axios.put(endpoint + 'internalrent/shares/' + spaceId, data, { params: { time: time } })
            )
          }
        }
      },
      allocations: {
        get: (organization, time) => {
          return this.extractData(axios.get(endpoint + 'internalrent/allocations/' + organization, { params: { time: time } }))
        },
        put: (organization, data, time, overWrite) => {
          return this.extractData(axios.put(endpoint + 'internalrent/allocations/' + organization, data, { params: { time: time, overWrite: overWrite } }))
        },
        future: (organization, time) => {
          return this.extractData(axios.get(endpoint + 'internalrent/allocations/' + organization + '/future', { params: { time: time } }))
        },
        codes: {
          get: (organization, time) => {
            return this.extractData(axios.get(endpoint + 'internalrent/allocations/' + organization + '/codes', { params: { time: time } }))
          },
          put: (organization, data, time, overWrite) => {
            return this.extractData(axios.put(endpoint + 'internalrent/allocations/' + organization + '/codes', data, { params: { time: time, overWrite: overWrite } }))
          },
        },
        portfolios: {
          get: (organization, time) => {
            return this.extractData(axios.get(endpoint + 'internalrent/allocations/' + organization + '/portfolios', { params: { time: time } }))
          },
          put: (organization, data, time, overWrite) => {
            return this.extractData(axios.put(endpoint + 'internalrent/allocations/' + organization + '/portfolios', data, { params: { time: time, overWrite: overWrite } }))
          },
        },
        targetingCodes: {
          get: (organization, time) => {
            return this.extractData(axios.get(endpoint + 'internalrent/allocations/' + organization + '/targetingcode', { params: { time: time } }))
          },
          put: (data, time) => {
            return this.extractData(axios.put(endpoint + 'internalrent/allocations/targetingcodes', data, { params: { time: time } }))
          },
          delete: (costCode, time) => {
            return this.extractData(axios.delete(endpoint + 'internalrent/allocations/targetingcodes/'+ costCode, { params: { time: time } }))
          },
          months: {
            post: (organization, portfolioId, time) => {
              return this.extractData(axios.post(endpoint + 'internalrent/allocations/' + organization + '/targetingcodesformonths', { time: time, portfolioId: portfolioId } ))
            }
          }
        },
        expenseCost: {
          get: (code, time) => {
            return this.extractData(axios.get(endpoint + 'internalrent/allocations/irexpensecost', { params: { code: code, time: time} }))
          },
          put: (data, time) => {
            return this.extractData(axios.put(endpoint + 'internalrent/allocations/irexpensecost', data, { params: { time: time } }))
          },
          post: (data, time) => {
            return this.extractData(axios.post(endpoint + 'internalrent/allocations/irexpensecost', data, { params: { time: time } }))
          }
        }
      },
      personnelAllocations: {
        updateConfiguration: (data, time) => {
          return this.extractData(axios.put(endpoint + 'internalrent/personnelallocations/configuration', data, { params: { time: time } }))
        },
        updateAllocation: (data, time) => {
          return this.extractData(axios.put(endpoint + 'internalrent/personnelallocations/allocation', data, { params: { time: time } }))
        },
        getAllocationExtraInfo: (allocationCode, time) => {
          return this.extractData(axios.get(endpoint + 'internalrent/personnelallocations/allocationextrainfo/', { params: { allocation_code: allocationCode, time: time } }))
        }
      }
    }

    this.graveyards = {
      reports: {
        list: (reportNames, cemeteryIds, currentDate, cemeteries, sectionIds = []) => {
          const time = currentDate ?? new Date()
          return this.extractData(axios.post(endpoint + 'graveyards/reports', { cemeteryIds: cemeteryIds, cemeteries: cemeteries, sectionIds: sectionIds, rules: [], reports: reportNames }, { params: { time: time } }))
        }
      },
      graves: {
        list: (ids) => {
          return this.extractData(axios.post(endpoint + 'graveyards/graves/list', ids))
        },
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'graveyards/graves/metadata'))
        }
      },
      deceased: {
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'graveyards/deceased/metadata'))
        }
      },
      services: {
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'graveyards/services/metadata'))
        }
      },
      cemeteries: {
        get: (params) => {
          const queryParams = typeof params !== 'undefined' ? params.query : {}
          return this.extractData(axios.get(endpoint + 'graveyards/cemeteries/', { params: queryParams}))
        },
          sections: (cemeteryId) => {
          return this.extractData(axios.get(endpoint + 'graveyards/cemeteries/' + cemeteryId + '/sections'))
        },
          sectionsAndGraves: (cemeteryId) => {
          return this.extractData(axios.get(endpoint + 'graveyards/cemeteries/' + cemeteryId + '/linkedSectionsAndGraves'))
        },
          gravesbysection: (sectionId) => {
          return this.extractData(axios.get(endpoint + 'graveyards/cemeteries/sections/'+ sectionId + '/graves'))
        }
      },
      categories: {
        get: (category, params) => {
          return this.extractData(axios.get(endpoint + 'graveyards/categories/' + category, { params: params }))
        }
      },
      search: {
        deceased: (search, limit, cemeteryIds) => {
          return this.extractData(axios.post(endpoint + 'graveyards/search/deceased/' + search, cemeteryIds, { params: { limit }}))
        }
      },
      congregations: {
        get: (params) => {
          const queryParams = typeof params !== 'undefined' ? params.query : {}
          return this.extractData(axios.get(endpoint + 'graveyards/congregations/', {	params: queryParams	}))
        }
      }
    }

    this.leasing = {
      prospects: {
        get: (queryParams) => {
          return this.extractData(axios.get(endpoint + 'prospect', {params: queryParams	}))
        },
        post: (prospect) => {
          return this.extractData(axios.post(endpoint + 'prospect', prospect ))
        },
        postUnits: (prospectId, data) => {
          return this.extractData(axios.post(endpoint + `prospect/${prospectId}/unit`, data))
        },
        put: (prospect) => {
          return this.extractData(axios.put(endpoint + 'prospect', prospect))
        },
        delete: (prospectId) => {
          return this.extractData(axios.delete(endpoint + `prospect/${prospectId}/`))
        },
        changeState: (prospectId, data) => {
          return this.extractData(axios.put(endpoint + `prospect/${prospectId}/status`, data))
        },
        printout: (data) => {
          return this.extractData(axios.post(endpoint + 'prospect/printout', data))
        },
        changeSuccessLikelihood: (prospectId, queryParams) => {
          return this.extractData(axios.put(endpoint + `prospect/${prospectId}/success_likelihood`, {}, {	params: queryParams	} ))
        },
        getBySiteId: (siteId) => {
          return this.extractData(axios.get(endpoint + `prospect/${siteId}`))
        },
        units: {
          pass: (data) => {
            return this.extractData(axios.put(endpoint + 'prospect/unit/pass', data))
          }
        },
        allocationContracts: (prospectId, time) => {
            return this.extractData(axios.get(endpoint + `prospect/${prospectId}/allocationcontracts`, {params: {time: time}} ))
        },
        closedContracts: () => {
            return this.extractData(axios.get(endpoint + `prospect/closedcontracts`))
        },
        events: (prospectId) => {
          return this.extractData(axios.get(endpoint + `prospect/${prospectId}/events`))
        },
        allEvents: () => {
          return this.extractData(axios.get(endpoint + `prospect/events`))
        }
      },
      prospectNotes: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'prospectnotes'))
        },
        post: (note) => {
          return this.extractData(axios.post(endpoint + 'prospectnotes', note))
        },
        put: (note) => {
          return this.extractData(axios.put(endpoint + 'prospectnotes', note))
        },
        delete: (id) => {
          return this.extractData(axios.delete(endpoint + 'prospectnotes/' + id))
        }
      },
      leasingUsers: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'leasingusers'))
        }
      },
      notifications: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'prospectnotifications'))
        },
        delete: (notificationId) => {
          return this.extractData(axios.delete(endpoint + 'prospectnotifications/' + notificationId))
        }
      },
      rentalOffer: {
        post: (offer) => {
          return this.extractData(axios.post(endpoint + 'rentaloffer', offer))
        }
      },
      responsibleSites: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'responsiblesites'))
        }
      },
      newContracts: {
        get: (time) => {
          return this.extractData(axios.get(endpoint + `leasing/newcontracts`, {params: {time: time}} ))
        }
      },
      quarterlyUsage: {
        get: (time) => {
          return this.extractData(axios.get(endpoint + `quarterlyUsage`, {params: {time: time}} ))
        }
      },
      rentalProcesses: {
        post: (rentalProcess) => {
          return this.extractData(axios.post(endpoint + `leasing/rentalprocess`, rentalProcess))
        },
        get: (time, id, siteId) => {

          const params = { }

          if(time){
            params.time = time
          }

          if(id){
            params.id = id
          }

          if(siteId){
            params.siteId = siteId
          }

          return this.extractData(axios.get(endpoint + `leasing/rentalprocess`, { params }))
        },
        put: (rentalProcess) => {
          return this.extractData(axios.put(endpoint + `leasing/rentalprocess`, rentalProcess))
        },
        delete: (id) => {
          return this.extractData(axios.delete(endpoint + `leasing/rentalprocess/${id}`))
        },
        patch: (id, patch) => {
          return this.extractData(axios.patch(endpoint + `leasing/rentalprocess/${id}`, patch))
        },
        mFileIds: {
          getByRentalProcessId: (id) => {
            return this.extractData(axios.get(endpoint + `leasing/mfileids/${id}`))
          },
          post: (mFileIds) => {
            return this.extractData(axios.post(endpoint + `leasing/mfileids`, mFileIds))
          },
          put: (id, mFilesIds) => {
            return this.extractData(axios.put(endpoint + `leasing/${id}/mfileids`, mFilesIds))
          },
          checkUsed: (mFilesIds) => {
            return this.extractData(axios.post(endpoint + `leasing/mfileids/used`, mFilesIds))
          },
        },
        contractNumbers: {
          check: (contractNumbers) => {
            return this.extractData(axios.get(endpoint + `leasing/contractnumbers/check?contractNumbers=${contractNumbers}`))
          },
        },
        contactPerson: {
          put: (rentalProcessId, persons, type) => {
            return this.extractData(axios.put(endpoint + `leasing/rentalprocess/${rentalProcessId}/contactperson`, persons, {params: { type }}))
          },
          delete: (rentalProcessId, type, contactPersonId = null) => {
            return this.extractData(axios.delete(endpoint + `leasing/rentalprocess/${rentalProcessId}/contactperson/`, {params: { contactPersonId, type }}))
          }
        },
        rentroll: {
          get: (rentalProcessId, time) => {
            return this.extractData(axios.get(`${endpoint  }leasing/rentalprocess/${rentalProcessId}/rentroll`, {params: { time }}))
          }
        },
        location :{
          get: () => {
            return this.extractData(axios.get(endpoint + `leasing/rentalProcess/location`))
          }
        }
      },
      rentalParty: {
        post: (party, tenantCorporation) => {
          return this.extractData(axios.post(endpoint + `leasing/rentalparty`, party, {params: { tenantCorporation }}))
        }
      },
      reports: {
        list: (reportNames, buildingIds, siteIds, time, start, end) => {
          return this.extractData(axios.post(endpoint + `reports/leasing`, { reports: reportNames, buildingIds, siteIds, now: time, start, end }))
        }
      }
    }
    this.storedviews = {
      list: (parentLevel, level) => {
        return this.extractData(axios.get(endpoint + 'storedviews', { params: { parentLevel: parentLevel, level: level } }))
      },
      post: (data, fields) => {
        return this.extractData(axios.post(endpoint + 'storedviews', fields, { params: data }))
      },
      put: (id, fields) => {
        return this.extractData(axios.put(endpoint + 'storedviews/' + id, fields))
      },
      delete: (id) => {
        return this.extractData(axios.delete(endpoint + 'storedviews/' + id))
      }
    },
    this.rentpayments =  {
      singlepayments: {
        calculateSums: (paymentDomain, netSum) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/singlepayments/calculatesums', {
               paymentDomain,
               netSum
            })
          )
        },
      },
      payments: {
        calculateSums: (paymentDomain, sum, unitAreas, squareBased) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/calculate/calculatesums', {
              paymentDomain,
              sum,
              unitAreas,
              squareBased
            })
          )
        },
        calculateSumsPcs: (paymentDomain, sum, Pcs, squareBased) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/calculate/calculatesumspcs', {
              paymentDomain,
              sum,
              Pcs,
              squareBased
            })
          )
        }
      },
      indexnumbers: (year, month) => {
        return this.extractData(axios.get(endpoint + `rentpayments/indexnumbers`, {params: {year, month}} ))
      },
      rentReviews: {
        get: (criteria) => {
          return this.extractData(axios.get(endpoint + 'rentpayments/rentreviews', {params: { ...criteria }}))
        },
        post: (criteria, paymentIds) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/rentreviews', paymentIds, {params: { ...criteria }}))
        },
        getContracts: (contractNumbers, time) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/rentreviews/contracts', contractNumbers, { params: { time } }))
        },
        getReviewLetterId: (reviewBundleId) => {
          return this.extractData(axios.get(endpoint + 'rentpayments/rentreviews/reviewletter', {params: { reviewBundleId }}))
        },
        postReviewLetter: (idDocument, reviewBundleId) => {
          return this.extractData(axios.post(endpoint + 'rentpayments/rentreviews/reviewletter', reviewBundleId, {params: { idDocument }}))
        },
      }
    },
    this.contracts =  {
      contract: {
        createWithRows: (data) => {
          return this.extractData(axios.post(endpoint + 'contracts', data))
        },
        updateWithRows: (data) => {
          return this.extractData(axios.put(endpoint + 'contracts', data))
        },
        get: () => {
          return this.extractData(axios.get(endpoint + 'contracts'))
        },
        getAllContractsWithRows: () => {
          return this.extractData(axios.get(endpoint + 'contracts/allcontractwithrows'))
        },
        getAllContractIds: (excludeDrafts = false, onlyOutRent = false, forRefundInvoices = false) => {
          return this.extractData(axios.get(endpoint + 'contracts/allcontractids', { params: { excludeDrafts, onlyOutRent, forRefundInvoices }}))
        },
        getEndingContracts: (time, months) => {
          return this.extractData(axios.get(endpoint + 'contracts/ending', { params: { time, months }}))
        },
        patchContract: (id, patch) => {
          return this.extractData(axios.patch(`${endpoint}contracts/${id}`, patch))
        },
        addAttachment: (attachments) => {
          return this.extractData(axios.post(endpoint + 'contracts/addAttachment', attachments ))
        },
        updateAttachment: (contractId, attachments) => {
          return this.extractData(axios.post(endpoint + 'contracts/updateAttachment/' + contractId, attachments ))
        },
        getAttachments: (contractId) => {
          return this.extractData(axios.get(endpoint + 'contracts/getAttachments/' + contractId ))
        },
        getWithRows: (params) => {
          return this.extractData(axios.get(endpoint + 'contracts/contractwithrows', {params: { ...params}}))
        },
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'contracts/metadata'))
        },
        GetNewContractNumber: (isOutRent) => {
          return this.extractData(axios.get(endpoint + 'contracts/getNewContractNumber', {params: {isOutRent}}))
        },
        addAdditionalInfo: (info) => {
          return this.extractData(axios.post(endpoint + 'contracts/AddAdditionalInfo',info ))
        },
        getAdditionalInfoForContract: (contractId) => {
          return this.extractData(axios.get(endpoint + 'contracts/GetAdditionalInfoForContract',  {params: {contractId}} ))
        },
        updateAutomaticBilling: (contract) => {
          return this.extractData(axios.put(endpoint + 'contracts/updateautomaticbilling', contract))
        },
        cancelContract: (contractId) => {
          return this.extractData(axios.put(endpoint + `contractssigning/contractcanceled?documentId=${contractId}` ))
        },

        deleteContract: (contractId) => {
          return this.extractData(axios.delete(endpoint + 'contracts/' + contractId))
        },

        getAllContractStates: () => {
          return this.extractData(axios.get(endpoint + 'contracts/contractstates'))
        },
        getRentPaymentDomains: () => {
          return this.extractData(axios.get(endpoint + 'contracts/paymentdomains'))
        },
        AddContractEvent: (eventId, contractIds, comment) => {
          return this.extractData(axios.post(endpoint + 'contracts/contractevent', contractIds, {params: {eventId: eventId, comment: comment}}))
        },
        GetContractEvents: (contractId) => {
          return this.extractData(axios.get(endpoint + 'contracts/getcontractevents', {params: {contractId: contractId}}))
        },
        DeleteContractEvent: (id) => {
          return this.extractData(axios.delete(endpoint + 'contracts/deletecontractevent/' + id))
        },
        UpdateContractEvent: (Event) => {
          return this.extractData(axios.put(endpoint + 'contracts/updatecontractevent', Event))
        },
        contractreports: {
          get: (params, signal) => {
            return this.extractData(axios.get(endpoint + 'contracts/contract_reports', {params: { ...params}, signal}))
          },
          totalCount: (time, search, outRent, contractStates) => {
            return this.extractData(axios.get(endpoint + 'contracts/contract_reports/count', {params: { time: time, search: search, outRent: outRent, contractStates: contractStates }}))
          }
        },
        SetContractAsSigned: (Id) => {
          return this.extractData(axios.get(endpoint + 'contractssigning/setcontractbyidsigned', { params: { contractId: Id }}))
        },
        CheckIfContractReady: (Id) => {
          return this.extractData(axios.get(endpoint + 'contractssigning/iscontractReady', { params: { contractId: Id }}))
        },
        createSigningOrder: (contract) => {
          return this.extractData(axios.post(endpoint + 'contractssigning/createsigningorder', contract))
        },
        signers: (contractPDFId) => {
          return this.extractData(axios.get(endpoint + 'contracts/signers?contractPDFId=' + contractPDFId))
        },
        templates: () => {
          return this.extractData(axios.get(endpoint + 'contracts/templates'))
        }
      },
      contractRow: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'contracts/rows'))
        },
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'contracts/rows/metadata'))
        },
        reports: {
          get: (params, signal) => {
            return this.extractData(axios.get(endpoint + 'contracts/rows/reports', {params: { ...params }, signal}))
          },
          totalCount: (time, search, outRent, contractStates) => {
            return this.extractData(axios.get(endpoint + 'contracts/rows/reports/count', {params: { time: time, search: search, outRent: outRent, contractStates: contractStates }}))
          }
        }
      },
      contractPayments: {
        reports: {
          get: (params, signal) => {
            return this.extractData(axios.get(endpoint + 'contracts/payments/reports', {params: { ...params }, signal}))
          },
          totalCount: (time, search, outRent) => {
            return this.extractData(axios.get(endpoint + 'contracts/payments/reports/count', {params: { time: time, search: search, outRent: outRent }}))
         }
        }
      },
      usageFee: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'contracts/usage_fees'))
        },
        metadata: () => {
          return this.extractData(axios.get(endpoint + 'contracts/usage_fee/metadata'))
        }
      },
      conditionsTemplates: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'contracts/conditionstemplates'))
        }
      },
      contractTermination: {
        terminate: (termination) => {
          return this.extractData(axios.post(endpoint + 'contracts/termination', termination))
        },
        getTermination: (contractId) => {
          return this.extractData(axios.get(endpoint + 'contracts/termination/' + contractId))
        },
        cancelTermination: (contractId) => {
          return this.extractData(axios.delete(endpoint + 'contracts/termination/' + contractId))
        },
      },
      calculator: {
        getCalculationBases: () => {
          return this.extractData(axios.get(endpoint + 'contracts/calculationbases'))
        },
        getZonePrice: (buildingIdsList, siteIdsList) => {
          return this.extractData(axios.post(endpoint + 'contracts/calculator/zoneprice', { buildingIdsList, siteIdsList }));
        },
        getBalance: (buildingIdsList) => {
          return this.extractData(axios.post(endpoint + 'contracts/calculator/balancearea',buildingIdsList ));
        },
        calculate: (data) => {
          return this.extractData(axios.post(endpoint + 'contracts/calculator/calculate', data))
        }
      },
      guarantees: {
        update: (id, patch) => {
          return this.extractData(axios.patch(endpoint + 'contracts/guarantees/' + id, patch))
        }
      },
      tags: {
        get: () => {
          return this.extractData(axios.get(endpoint + 'contracts/tags'))
        },
      },
    },
    this.municipalities = {
      list: () => {
        return this.extractData(axios.get(endpoint + 'municipalities'))
      },
      managementRegions: () => {
        return this.extractData(axios.get(endpoint + 'municipalities/managementregions'))
      },
      countries: () => {
        return this.extractData(axios.get(endpoint + 'municipalities/countries'))
      },
    },
    this.siteFilters = {
      list: () => {
        return this.extractData(axios.get(endpoint + 'sitefilters'))
      },
      hideFilters: () => {
        return this.extractData(axios.get(endpoint + 'sitefilters/hidefilters'))
      },
      put: (data) => {
        return this.extractData(axios.put(endpoint + 'sitefilters', data))
      }
    },
    this.rentingGoals = {
      list: (time) => {
        return this.extractData(axios.get(endpoint + 'renting_goals', {params: {time}}))
      },
      create: (goals, time) => {
        return this.extractData(axios.post(endpoint + 'renting_goals', goals, {params: {time}}))
      },
      modify: (goals, time) => {
        return this.extractData(axios.put(endpoint + 'renting_goals', goals, {params: {time}}))
      },
      delete: (goals) => {
        return this.extractData(axios.delete(endpoint + 'renting_goals', { data: goals }))
      }
    },

    this.siteLinks = {
      list: (siteId) => {
        return this.extractData(
          axios.get(endpoint + 'sites/' + siteId + '/links')
        )
      },
      create: (siteId, siteLink) => {
        return this.extractData(axios.post(endpoint + 'sites/' + siteId + '/links', siteLink))
      }
    },

    this.marketing = {
      create: (marketing) => {
        return this.extractData(axios.post(endpoint + 'marketing', marketing))
      },
      update: (marketing) => {
        return this.extractData(axios.put(endpoint + 'marketing', marketing))
      },
      getMarketing: (unitId) => {
        return this.extractData(axios.get(endpoint + 'marketing', { params: { unitId } }))
      },
    },

    this.invoices = {
      markAsReadyForSending: (invoiceIds) => {
        return this.extractData(axios.patch(endpoint + 'invoices/readyforsending', invoiceIds))
    },
      createInvoicesFromContractList: (contractIds, selectedYearAndMonth) => {
        return this.extractData(axios.post(endpoint + 'invoices/createfromcontractlist', contractIds, { params: { selectedDate: selectedYearAndMonth }}))
      },
      getInvoiceDrafts: (contractIds, selectedYearAndMonth) => {
        return this.extractData(axios.post(endpoint + 'invoices/drafts', contractIds, { params: { selectedDate: selectedYearAndMonth }}))
      },
      createWithRows: (invoice,rows) => {
        return this.extractData(axios.post(endpoint + 'invoices/createwithrows', {invoice,rows}))
      },
      createrefundinvoice: (invoice) => {
        return this.extractData(axios.post(endpoint + 'invoices/createrefundinvoice', invoice))
      },
      getInvoicesForContract: (id, includeBlocked) => {
        return this.extractData(axios.get(endpoint + 'invoices/invoicesForContract', { params: { contractId: id, includeBlocked: includeBlocked }}))
      },
      getCreditInvoicesForContract: (id, includeBlocked) => {
        return this.extractData(axios.get(endpoint + 'invoices/creditInvoicesForContract', { params: { contractId: id, includeBlocked: includeBlocked }}))
      },
      createHandInvoice: (invoiceMaterial) => {
        return this.extractData(axios.post(endpoint + 'invoices/createhandinvoice', invoiceMaterial))
      },
      createRefundInvoice: (invoiceList) => {
        return this.extractData(axios.post(endpoint + 'invoices/createrefundinvoice', invoiceList))
      },
      blockInvoices: (invoiceIds) => {
        return this.extractData(axios.patch(endpoint + 'invoices/block', invoiceIds))
      },
      delete: (invoiceId) => {
        return this.extractData(axios.delete(endpoint + 'invoices/' + invoiceId))
      },
      invoiceMaterial: (invoiceId, date) => {
        return this.extractData(axios.get(endpoint + 'invoices/' + invoiceId + '/invoicematerial', { params: { date: date }}))
      },
      getSentInvoices: (contractId, minDate) => {
        return this.extractData(axios.get(endpoint + 'invoices/sent', { params: { contractId: contractId, minDate: minDate }}))
      },
      getInvoiceArchiveSearchKeys: (time) => {
        return this.extractData(axios.get(endpoint + 'invoices/archivesearch', { params: { time }}))
      },
      getInvoiceArchive: (query, time) => {
        return this.extractData(axios.post(endpoint + 'invoices/invoicearchive', query, { params: { time }}))
      }
    }

    this.diaries = {
      create: (siteId, diary, date) => {
        return this.extractData(axios.post(endpoint + 'diaries/' + siteId, diary, { params: { date: date }}))
      },
      update: (diaryId, patchObject, date) => {
        return this.extractData(axios.put(endpoint + 'diaries/' + diaryId, patchObject, { params: { date: date }}))
      },
      delete: (diary, date) => {
        return this.extractData(axios.delete(endpoint + 'diaries/' + diary.id, { params: { date: date }}))
      }
    }

    this.settings = {
      get: () => {
        return this.extractData(
          axios.get(endpoint + 'settings/')
        )
      },
      currencies: () => {
        return {
          get: () => {
            return this.extractData(axios.get(endpoint + 'settings/currencies'))
          }
        }
      }
    }

    this.buildingProjects = {
      get: (id, time) => {
        return this.extractData(axios.get(endpoint + 'buildingProject/' + id, { params: { time: time } }))
      },
      getBuildingProjectsBySiteId: (siteId, time) => {
        return this.extractData(axios.get(endpoint + 'buildingProject/', { params: { siteId: siteId, time: time } }))
      },
      getValueInformation: (buildingProjectId, time) => {
        return this.extractData(axios.get(endpoint + 'buildingProject/buildingprojectvalueinformation', { params: { buildingProjectId: buildingProjectId, time: time }}))
      },
      reports: () => {
        return {
          getAll: (time) => {
            return this.extractData(axios.get(endpoint + 'buildingProject/report', { params: { time: time } }))
          },
          perPage: (time, page, limit, search, sortBy, sortDesc) => {
            return this.extractData(axios.get(endpoint + 'buildingProject/report', { params: { time: time, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc } }))
          },
          metadata: () => {
            return this.extractData(axios.get(endpoint + 'buildingProject/report/metadata'))
          }
        }
      },
    },

    this.technicalValues = {
      building: {
        get: (idBuilding, selectedDate) => {
          return this.extractData(axios.get(endpoint + `technical_value/building/${idBuilding}`, { params: { selectedDate, currentDate: new Date() } }))
        },
        getRange: (idBuilding, selectedDate) => {
          return this.extractData(axios.get(endpoint + `technical_value/building/${idBuilding}/range`, { params: { selectedDate, currentDate: new Date() } }))
        },
      },
      reports: {
        getAll: (selectedDate) => {
          return this.extractData(axios.get(endpoint + 'technical_value/report', { params: { selectedDate, currentDate: new Date() } }))
        },
        perPage: (selectedDate, page, limit, search, sortBy, sortDesc) => {
          return this.extractData(axios.get(endpoint + 'technical_value/report', { params: { selectedDate, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc, currentDate: new Date() } }))
        },
      }
    }

    this.technicalValueRepairs = {
      reports: {
        getAll: (selectedDate) => {
          return this.extractData(axios.get(endpoint + 'technical_value/calculations_repairs_report', { params: { selectedDate, currentDate: new Date() } }))
        },
        perPage: (selectedDate, page, limit, search, sortBy, sortDesc) => {
          return this.extractData(axios.get(endpoint + 'technical_value/calculations_repairs_report', { params: { selectedDate, page: page, limit: limit, search: search, sortBy: sortBy, sortDesc: sortDesc, currentDate: new Date() } }))
        },
      }
    }

    this.buildingRepairs = {
      create: (idBuilding, repair) => {
        return this.extractData(axios.post(endpoint + `building/${idBuilding}/repair`, repair))
      },
      update: (idBuilding, repair) => {
        return this.extractData(axios.put(endpoint + `building/${idBuilding}/repair`, repair))
      },
    }

    this.buildingRepairs = {
      create: (idBuilding, repair) => {
        return this.extractData(axios.post(endpoint + `building/${idBuilding}/repair`, repair))
      },
      update: (idBuilding, repair) => {
        return this.extractData(axios.put(endpoint + `building/${idBuilding}/repair`, repair))
      },
    }

    this.alerts = {
      getAlertUsers: () => {
        return this.extractData(axios.get(endpoint + 'alerts/alertusers'))
      },
      get: (alertId) => {
        return this.extractData(axios.get(endpoint + 'alerts/' + alertId))
      },
      list: () => {
        return this.extractData(axios.get(endpoint + 'alerts'))
      },
      create: (newAlert) => {
        return this.extractData(axios.post(endpoint + 'alerts', newAlert))
      },
      contracts: () => {
        return this.extractData(axios.get(endpoint + 'alerts/contracts'))
      },
      delete: (alertId) => {
        return this.extractData(axios.delete(endpoint + 'alerts/' + alertId))
      },
      update: (alertId, data) => {
        return this.extractData(axios.patch(endpoint + 'alerts/' + alertId, data))
      }
    }

    this.releaseNotes = {
      get: () => {
        return this.extractData(axios.get(endpoint + 'releases/'))
      },
    }

    this.esg = {
      getData: () => {
        return {
          site: (siteId, time) => {
            return this.extractData(axios.get(endpoint + 'esg/site', { params: { siteId, time }}))
          },
          estate: (siteId, time) => {
            return this.extractData(axios.get(endpoint + 'esg/estate', { params: { siteId, time }}))
          },
          building: (siteId, time) => {
            return this.extractData(axios.get(endpoint + 'esg/building', { params: { siteId, time }}))
          },
        }
      },
      certificates: () => {
        return {
          addNewCertificate:(data, specificationsStr) => {
            return this.extractData(axios.post(endpoint + 'esg/certificate', data, { params: { specificationsStr }}))
          },
          getCertificateSpecifications: () => {
            return this.extractData(axios.get(endpoint + 'esg/specifications'))
          },
          delete: (id) => {
            return this.extractData(axios.delete(endpoint + 'esg/certificate/' + id))
          },
          updateCertificate: (id, certificate) => {
            return this.extractData(axios.put(endpoint +  'esg/certificate/' + id, certificate))
          },
          getCertificateById: (id) => {
            return this.extractData(axios.get(endpoint + 'esg/certificate/' + id))
          },
        }
      },
      metadata: () => {
        return {
          estate: () => {
            return this.extractData(axios.get(endpoint + 'esg/metadata/estate'))
          },
          building: () => {
            return this.extractData(axios.get(endpoint + 'esg/metadata/building'))
          },
          site: () => {
            return this.extractData(axios.get(endpoint + 'esg/metadata/site'))
          },
        }
      },
      patch: () => {
        return {
          site: (siteId, time, data) => {
            return this.extractData(axios.patch(endpoint + 'esg/site/' + siteId, data, { params: { time: time } }))
          },
          estate: (estateId, wasteFractions, time, data) => {
            return this.extractData(axios.patch(endpoint + 'esg/estate/' + estateId, data, { params: { wasteFractions: wasteFractions, time: time } }))
          },
          building: (buildingCode, time, data) => {
            return this.extractData(axios.patch(endpoint + 'esg/building/' + buildingCode, data, { params: { time: time } }))
          },
        }
      },
      post: () => {
        return {
          site: (siteId, time, siteEsg) => {
            return this.extractData(axios.post(endpoint + 'esg/site/' + siteId, siteEsg, { params: { time: time } }))
          },
          estate: (estateId, wasteFractions, time, data) => {
            return this.extractData(axios.post(endpoint + 'esg/estate/' + estateId, data, { params: { wasteFractions: wasteFractions, time: time } }))
          },
          building: (buildingCode, time, buildingEsg) => {
            return this.extractData(axios.post(endpoint + 'esg/building/' + buildingCode, buildingEsg, { params: { time: time } }))
          }
        }
      }
    }

    this.search = {
      get: (params, signal) => {
        return this.extractData(axios.get(endpoint + 'search/', { params: params, signal }))
      },
      getLevels: () => {
        return this.extractData(axios.get(endpoint + 'search/levels'))
      }
    }
  }

  initializeAxios () {
    // Add a request interceptor
    axios.interceptors.request.use((config) => {
      // auth
      if (typeof this.accessToken !== 'undefined' && this.accessToken !== null) {
        config.headers.Authorization = 'Bearer ' + this.accessToken
      }

      // configuration done
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })
  }

  async extractData (axiosPromise) {
      const results = await axiosPromise
      if (results.headers){
        if (typeof results.headers['x-total-count'] !== 'undefined') {
          return {
            items: results.data,
            count: results.headers['x-total-count']
          }
        }
      }

      if( results.data)
            return results.data
      else if (results.name === 'AxiosError')
      {
        // Earlier axios has returned 'Error' in results.name and error handling
        // at different parts of software has referred that value to be 'Error'
        // in return value of this function if error occured.
        // Change results.name to 'Error' if error occurs when now new version
        // of axios gives value 'AxiosError' to results.name in case of error.
        results.name = 'Error'

        var author = results.config.headers.Authorization
        var errorExtra = null
        results.config.headers.Authorization = (author !== null || author !== undefined) ? 'REDACTED' : null
        // if backend is able to respond, log the response object, otherwise plain result
        if (results.response !== null && results.response !== undefined)
        {
          loglevel.error('API error: ',  results.response)
          errorExtra= ['err.report_api_failure', results.response]
        }
        else
        {
          loglevel.error('API error: ',  results)
          errorExtra= ['err.report_api_failure', results]
        }
        store.dispatch('error/addError', errorExtra)
      }
      return results
  }
}

export default RambollFMAPI
