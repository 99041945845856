<template>
  <v-container
    class="py-10 px-5"
  >
    <v-row
      justify="center"
    >
      <v-col md="8">
        <h1>{{ $t('Releases') }}</h1>
        <p> {{ $t('releases.intro') }} </p>

        <div
          v-for="(releases, releaseVersion) in groupedReleases"
          :key="releaseVersion"
        >
          <h3> {{ `${$t('Release')} ${releaseVersion}, ${formatDate(releases[0].releaseDate)}` }}</h3>
          <div
            v-for="release in releases"
            :key="release.releaseTitle"
          >
            <h4> {{ release.releaseTitle }} </h4>
            <p v-html="sanitize(release.releaseDescription)" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from '../helpers'
export default {
  name: 'ReleasesView',
  metaInfo () {
    return {
      title: `${this.$t('Releases')} ·`,
    }
  },
  data () {
    return {
      releasenotes: [],
      groupedReleases: {}
    }
  },
  async mounted () {
    this.releasenotes = await this.$rambollfmapi.releaseNotes.get()
    this.groupReleases()
  },
  methods: {
    formatDate (date) {
      return helpers.format.formatDate(date)
    },
    sanitize (html) {
      return helpers.format.sanitize(html)
    },
    groupReleases () {
      this.groupedReleases = this.releasenotes.reduce((acc, release) => {
      if (!acc[release.releaseVersion]) {
        acc[release.releaseVersion] = []
      }
      acc[release.releaseVersion].push(release)
      return acc
      }, {})
    }
  }
}
</script>