
<template>
  <div>
    <v-tab-item
      :key="2"
    >
      <h3 class="form-headers">
        {{ $t('create_rent_contract.sites') }}
      </h3>
      <div v-if="targetsSelected">
        <CdsAlert
          size="large"
          type="tertiary"
          icon="task_alt"
          :title="$t('create_rent_contract.contract_targeted_title')"
        >
          <div
            v-for="unit in existingUnits"
            :key="unit.id"
            class="mb-2"
          >
            {{ unit.site }}: {{ unit.name }}
          </div>
          <CdsButton
            type="tertiary" 
            @click="changeTargets"
          >
            {{ $t("create_rent_contract.change_targets_button") }}
          </CdsButton>
        </CdsAlert>
      </div>
      <div v-else>
        <div v-if="canConnectToPortfolio == true">
          <v-col cols="4">
            <v-autocomplete
              v-model="contractAttachedTo"
              :items="contractAttachedToItems"
              :label="$t('contract.isAttached')"
              item-text="text"
              item-value="value"
              class="form-fields mt-4 ml-2"
              @change="onAttachmentTypeChanged()"
            />
          </v-col>
          <v-list v-if="canConnectToPortfolio == true && connectedToPortfolio == true">
            <v-list-item
              v-for="(item, index) in portfolioItems"
              :key="index"
            >
              <v-checkbox
                v-model="selectedPortfolio"
                :value="item"
              />
              <v-list-item-content>
                {{ item.name }}, {{ item.area }}m²
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        
        <v-form
          v-if="(canConnectToPortfolio == false || (canConnectToPortfolio == true && connectedToPortfolio == false))"
          ref="form1"
          class="pt-2"
        >
          <v-expansion-panels
            v-model="panels"
            class="my-2"
            multiple
          >
            <v-expansion-panel
              expand
            >
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.filters') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  align="center"
                >
                  <v-col>
                    <v-text-field
                      v-model="siteFilter"
                      :label="$t('create_rent_contract.site')"
                      class="form-fields mt-2 ml-2"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="siteIdentifierFilter"
                      :label="$t('Site identifier')"
                      class="form-fields mt-2 ml-2"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="buildingAddressFilter"
                      :label="$t('create_rent_contract.buildingAddress')"
                      class="form-fields mt-2 ml-2"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              expand
            >
              <v-expansion-panel-header>
                {{ $t('create_rent_contract.info') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div 
                  v-if="isLoadingSites"
                  class="text-center pa-8"
                >
                  <v-progress-circular
                    size="64"
                    indeterminate
                    color="primary"
                    :aria-label="$t('Loading. Please wait...')"
                  />
                  <div class="mt-4">
                    {{ $t('Loading. Please wait...') }}
                  </div>
                </div>
                <v-treeview
                  v-model="selectedUnits"
                  :items="filteredSites"
                  :load-children="fetchBuildings"
                  selectable
                  return-object
                  activatable
                  open-on-click
                  transition
                >
                  <template #append="{ item }">
                    <span v-if="!!item.isInactive">
                      {{ $t('starts') }} {{ formatDate(item.startDate ? item.startDate : item.date) }}
                    </span>
                  </template>
                </v-treeview>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </div>
    </v-tab-item>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import CdsAlert from '../../../CircleDesignSystem/CdsAlert.vue'
import CdsButton from '../../../CircleDesignSystem/CdsButton.vue'

export default {
  name: "RentContractUnitSelectionTab",
  components: {
    CdsAlert,
    CdsButton
  },
  props: {
    contractId: {
      type: Number,
      default: undefined
    },
    existingUnits: {
      type: Array,
      default: () => undefined
    },
    portfolioData: {
      type: Object,
      default: () => undefined
    },
    settings: {
      type: Object,
      default: () => undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    date: {
      type: Date,
      default: () => undefined
    },
    isConnectedToPortfolio: {
      type: Boolean,
      default: () => undefined
    },
  },
  emits: ['updated', 'isLoading', 'changedContractAttachmentType'],
  data () {
    return (
      {
        panels: [0, 1],
        filteredSites: [],
        selectedUnits: [],
        allSites: undefined,
        siteFilter: "",
        siteIdentifierFilter: "",
        buildingAddressFilter: "",
        openIds: [],
        loadCount: 0,
        contractAttachedToItems: [this.$t('contract.isAttached.portfolio'),this.$t('contract.isAttached.sites')],
        portfolioItems: [],
        selectedPortfolio: undefined,
        existingPortfolio:undefined,
        contractAttachedTo: this.$t('contract.isAttached.portfolio'),
        connectedToPortfolio: true,
        canConnectToPortfolio: undefined,
        isLoadingSites: false,
        targetsSelected: false,
      }
    )
  },
  computed: {
    ...mapState('app', ['sites', 'userInfo']),
    ...mapState('app', ['availableSites', 'buildings', 'currentDate']),
    ...mapGetters('app', ['definitionLabelById']),
    ...mapState('internalRent', ['portfolios', 'portfolio']),
    
      selectedDate () {
          if (this.date instanceof Date && !isNaN(this.date)) {
              return this.date
          }
          return this.currentDate
      },
  },
  watch: {
    async existingUnits () {
      if (this.existingUnits) {
        if (this.existingUnits.length>0 && this.isConnectedToPortfolio == false) {
          this.contractAttachedTo = this.$t('contract.isAttached.sites')
        }
        this.$log.info(this.existingUnits)

        if (this.existingUnits.length === 0) {
          this.filteredSites = await this.getAllSites()
        } else {  
          this.targetsSelected = true
        }
      }
    },
    selectedUnits () {
      //modify ids to proper unit ids
      const units = this.selectedUnits
        .filter(u => !!u.unitCode || !!u.structureId || !!u.parkingSpaceId)
        .map(unit => ({
            ...unit,
            id: unit.unitId ? unit.unitId : unit.structureId ? unit.structureId : unit.parkingSpaceId
        }))

        var matchingFirstLevelChildrenIds = [];
        var buildingId = undefined

        //Finds building from selected sites
        const findMatchingFirstLevelChildren = (node) => {
          if (node.children && node.children.length > 0) {
            // Check if any of the children's IDs match with the units
            if (node.children.some(child => units.some(unit => unit.unitId === child.unitId))) {
              if (buildingId != undefined && Number.isInteger(buildingId)) {
                matchingFirstLevelChildrenIds.push(buildingId);
              }
              
              buildingId = undefined
            }
            // Recurse into the children
            node.children.forEach(child => findMatchingFirstLevelChildren(child));
          }
        };
  
        this.filteredSites.forEach(site => {
          
          site.children.forEach(child => {
            buildingId = child.id
            findMatchingFirstLevelChildren(child);
          });
        });

        const selectedBuildings = matchingFirstLevelChildrenIds
        matchingFirstLevelChildrenIds = [];
              
        if (this.selectedUnits.length == 0) return
        this.setSelectedUnits(this.selectedUnits)

        const payload = {
          units,
          selectedBuildings,
          portfolio: undefined
        };
      this.$emit('updated', payload)
    },
    siteFilter: function (newVal, oldVal) {
      this.setFilter(newVal, oldVal)
    },
    siteIdentifierFilter: function (newVal, oldVal) {
      this.setFilter(newVal, oldVal)
    },
    buildingAddressFilter: function (newVal, oldVal) {
      this.setFilter(newVal, oldVal)
    },
    date: async function (newVal, oldVal) {
      if (newVal.toString() != oldVal.toString() && this.filteredSites.length > 0) {
        this.filteredSites = await this.getAllSites()
      }
    },
    async existingPortfolio () {
      await this.setupPortfolioItems()
      
      if (this.existingPortfolio != undefined && this.existingPortfolio.OriginalId != null) {
        this.selectedPortfolio =  this.portfolioItems.find(item => item.id === this.existingPortfolio.OriginalId)
      }

      if (this.selectedPortfolio != undefined && this.existingUnits.length==0) {
        this.contractAttachedTo = this.$t('contract.isAttached.portfolio')
        this.connectedToPortfolio = true
      }
    },
    selectedPortfolio: function (newVal) {

      const units = this.selectedUnits
        .filter(u => !!u.unitCode || !!u.structureId)
        .map(unit => ({
            ...unit,
            id: unit.unitId ?? unit.structureId,
        }))
        const selectedBuildings = this.filteredSites
        const payload = {
          units,
          selectedBuildings,
          portfolio: newVal ? {
            Id: newVal.id,
            Name: newVal.name,
            Area: newVal.area,
            AgreedArea: (this.existingPortfolio != undefined && this.existingPortfolio.AgreedArea != null && this.existingPortfolio.OriginalId == newVal.id) ? this.existingPortfolio.AgreedArea : newVal.agreedArea,
            Startdate: (this.existingPortfolio != undefined && this.existingPortfolio.Startdate != null && this.existingPortfolio.OriginalId == newVal.id) ? this.existingPortfolio.Startdate : newVal.startDate,
            Enddate: (this.existingPortfolio != undefined && this.existingPortfolio.Enddate != null && this.existingPortfolio.OriginalId == newVal.id) ? this.existingPortfolio.Enddate : newVal.endDate, 
          } : {}
        };
      this.$emit('updated', payload)
    },
    portfolioData: function (newVal) {
        this.existingPortfolio = newVal;
    },
    isConnectedToPortfolio: function (newVal) {

        this.connectedToPortfolio = newVal
        
    },
    settings: function (newVal) {
      if (this.settings.length === 0) {
        this.canConnectToPortfolio = false;
      } else {
        this.canConnectToPortfolio = this.isOutRent == true
        ? this.settings.outrentCanConnectContractToPortfolio 
        : this.settings.inrentCanConnectContractToPortfolio;      }     
    },
    async canConnectToPortfolio () {
      await this.setupPortfolioItems()
    }
  },
  async mounted () {
    await this.setupPortfolioItems()
    if (!this.contractId) {
      this.filteredSites = await this.getAllSites()
    }
  },
  methods: {
    ...mapActions("calculator", ["setSelectedUnits"]),
    ...mapActions('internalRent', [
      'getPortfolios'
    ]),
    setFilter (newVal, oldVal) {
      const newValLowerCase = newVal.toLowerCase()
      let newFilteredSites = this.allSites.filter(s =>
        s.name?.toLowerCase().includes(newValLowerCase) || 
        s.address?.toLowerCase().includes(newValLowerCase) || 
        s.buildingAddresses?.toLowerCase().includes(newValLowerCase) || 
        s.siteIdentifier?.toLowerCase().includes(newValLowerCase))
      this.filteredSites = newFilteredSites
      return this.filteredSites
    },
    onAttachmentTypeChanged () {
      if (this.canConnectToPortfolio == true) {
        if (this.contractAttachedTo ==this.$t('contract.isAttached.portfolio')) {
          this.connectedToPortfolio = true
        }
        else {
          this.connectedToPortfolio = false
        }
        this.$emit('changedContractAttachmentType', this.connectedToPortfolio)
      }
      
    },
    async changeTargets () {
      this.isLoadingSites = true
      this.targetsSelected = false
      this.filteredSites = await this.getAllSites()
      this.isLoadingSites = false
    },
    async getAllSites () {
        let date = this.selectedDate;
        if (date == null || date == undefined) date = Date.now;
        const result = await this.$rambollfmapi.sites.listWithFuture({ query: { time: date } })        
        this.allSites = result.map(s => ({
          type: "site",
          children: [],
          buildingAddresses: [],
          id: "site" + s.id_site,
          id_site: s.id_site,
          name: s.name,
          address: s.address,
          siteIdentifier: s.site_identifier,
          date: s.start_date,
          end_date: s.end_date,
          isInactive: (this.date && new Date(this.date) < new Date(s.start_date)),
        }))
        this.getBuildingAddress()
      return this.allSites;
    },
    getBuildingAddress () {
      if (this.buildings) {
        this.allSites.forEach(site => {
          const filteredBuildings = this.buildings.filter(building => building.id_site === site.id_site)
          const addressArray = new Set(filteredBuildings
            .filter(b => !!b.address)
            .map(b => `${b.address}, ${b.zip_code} ${b.municipality_name}`)
          )
          site.buildingAddresses = Array.from(addressArray).join("/")
        })
      }
      return this.allSites;
    },
    getBuildingAddressbyBuildingCode (buildingCode) {
      if (this.buildings) {
        const filteredBuildings = this.buildings.filter(building => building.building_code === buildingCode)
        const addressArray = new Set(filteredBuildings
          .filter(b => !!b.address)
          .map(b => `${b.address}, ${b.zip_code} ${b.municipality_name.toUpperCase()}`)
        )
        return Array.from(addressArray).join("\n")
      }
      return ""
    },
    async getBuildingFloors (site) {

      setTimeout(() => {
          this.loadCount++
          this.$emit("isLoading", this.loadCount > 0)
      })

      this.$emit("isLoading", true)
      
      let date = this.selectedDate;
      if (date == null || date == undefined) date = Date.now;

      const buildings = await this.$rambollfmapi.buildings.listWithFuture({ query: { siteId: site.id_site, time: date } })
      const structures = await this.$rambollfmapi.structures.listWithFuture({ query: { siteId: site.id_site, time: date } })
      const parkingSpaces = await this.$rambollfmapi.carparks.listWithFuture({ query: { siteId: site.id_site, time: date } })
      let selectedNodes = []
      const structureNode = {
        name: this.$t("create_rent_contract.structures"),
        id: "stuctures" + site.id_site
      }

      structureNode.children = structures.map(structure => ({
          name: `${structure.structure_name}, ${this.definitionLabelById(structure.structure_type)},  ${structure.structure_area} m²`,
          id: `structure${structure.structure_id}`,
          children: undefined,
          structureId: structure.structure_id,
          unitCode: structure.structure_name,
          date,
          isStructure: true,
          area: structure.structure_area,
          agreedArea: this.existingUnits?.find(loaded => loaded.structureId === structure.structure_id)?.agreedArea ?? structure.structure_area,
          rowId: this.existingUnits?.find(loaded => loaded.structureId === structure.structure_id)?.rowId,
          siteId: site.id_site,
          siteCode: site.siteIdentifier,
          rent: this.existingUnits?.find(loaded => loaded.structureId === structure.structure_id)?.rent ?? 0,
          startDate: this.existingUnits?.find(loaded => loaded.structureId === structure.structure_id)?.startDate ?? structure.start_date,
          address: this.getBuildingAddressbyBuildingCode(structure.building_code),
          usage: this.definitionLabelById(structure.structure_type),
          site: site.name,
          isInactive: (this.date && new Date(this.date) < new Date(structure.start_date)),
          parkingSpaceId: null,
          unitId: null,
        })
      )

      selectedNodes = structureNode.children.filter(structure => this.existingUnits?.some(u => u.structureId === structure.structureId))

      //Parking spaces by site
      const parkingSpaceNode = {
        name: this.$t('Car parks'),
        id: 'parkingspace' + site.id_site
      }

      parkingSpaceNode.children = parkingSpaces.map(p => ({
        name: `${p.nro}, ${this.$t(p.rental_type)}, ${p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount} ${this.$t('pcs')}`,
        id: `parking${p.id_carspace}`,
        children: undefined,
        unitCode: p.nro,
        siteId: site.id_site,
        siteCode: site.siteIdentifier,
        site: site.name,
        parkingSpaceId: p.id_carspace,
        rent: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.rent ?? p.market_rent,
        rowId: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.rowId,
        startDate: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.startDate ?? p.start_date,
        address: this.getBuildingAddressbyBuildingCode(p.building_code),
        usage: this.definitionLabelById(p.type),
        isInactive: (this.date && new Date(this.date) < new Date(p.start_date)),
        isParkingSpace: true,
        rentalType: p.rental_type,
        rentalTypeName: this.$t(p.rental_type),
        maxParkingRights: p.max_parking_rights,
        amount: p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount,
        agreedAmount: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.agreedAmount 
          ? this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.agreedAmount 
          : p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount,
        unitId: null,
        structureId: null,
      }))

      selectedNodes = selectedNodes.concat(parkingSpaceNode.children.filter(p => this.existingUnits?.some(u => u.parkingSpaceId === p.parkingSpaceId)))

      for (const building of buildings) {

        const floors = await this.$rambollfmapi.buildings.floors(building.building_code).listWithFuture(date, site.id_site)
        building.children = floors.map(floor => ({ name: `${floor.floor_name}`, floorId: floor.id, id: `floor${floor.id}`, children: [], date }))

        for (const floor of building.children) {
          const units = await this.$rambollfmapi.floors.units(floor.floorId, date).listWithFuture()
          floor.children = units.map(u => ({
            name: `${u.unit_code}, ${this.$t(this.definitionLabelById(u.usage)) || "-"}, ${Math.round(u.area * 2) / 2} m²`,
            id: `unit${u.id}`,
            unitCode: u.unit_code,
            unitId: u.id,
            site: site.name,
            rent: this.existingUnits?.find(loaded => loaded.id === u.id)?.rent ?? u.market_rent,
            area: this.existingUnits?.find(loaded => loaded.id === u.id)?.area ?? u.area,
            agreedArea: this.existingUnits?.find(loaded => loaded.id === u.id)?.agreedArea ?? u.agreedArea,
            rowId: this.existingUnits?.find(loaded => loaded.id === u.id)?.rowId,
            startDate: this.existingUnits?.find(loaded => loaded.id === u.id)?.startDate ?? u.start_date,
            address: this.getBuildingAddressbyBuildingCode(u.building_code),
            usage: this.$t(this.definitionLabelById(u.usage)),
            siteId: site.id_site,
            siteCode: site.siteIdentifier,
            isInactive: (this.date && new Date(this.date) < new Date(u.start_date)),
            parkingSpaceId: null,
            structureId: null,
          }))
          
          selectedNodes = selectedNodes.concat(floor?.children?.filter(u => this.existingUnits?.some(l => l.id === u.unitId)))

          //Parking spaces by floor
          const parkingSpaceFloorNode = {
            name: this.$t('Car parks'),
            id: 'parkingspaceFloor' + site.id_site
          }

          const parkingSpacesByFloor = await this.$rambollfmapi.carparks.floor(floor.floorId, date)
          parkingSpaceFloorNode.children = parkingSpacesByFloor.map(p => ({
            name: `${p.nro}, ${this.$t(p.rental_type)}, ${p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount} ${this.$t('pcs')}`,
            id: `parking${p.id_carspace}`,
            unitCode: p.nro,
            siteId: site.id_site,
            siteCode: site.siteIdentifier,
            site: site.name,
            parkingSpaceId: p.id_carspace,
            rent: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.rent ?? p.market_rent,
            rowId: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.rowId,
            startDate: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.startDate ?? p.start_date,
            address: this.getBuildingAddressbyBuildingCode(p.building_code),
            usage: this.$t(this.definitionLabelById(p.type)),
            isInactive: (this.date && new Date(this.date) < new Date(p.start_date)),
            isParkingSpace: true,
            rentalType: p.rental_type,
            rentalTypeName: this.$t(p.rental_type),
            maxParkingRights: p.max_parking_rights,
            amount: p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount,
            agreedAmount: this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.agreedAmount 
              ? this.existingUnits?.find(loaded => loaded.parkingSpaceId === p.id_carspace)?.agreedAmount 
              : p.rental_type === 'Privilege' ? p.max_parking_rights : p.amount,
            structureId: null,
            unitId: null,
          }))

          if (parkingSpaceFloorNode.children.length > 0) {
            floor.children.push(parkingSpaceFloorNode)
          }

        }
        building.children = building.children.filter(child => child.children?.length > 0)
      }
      site.children = buildings.filter(b => b.children?.length > 0).map(building => ({ name: `${building.building_name} ${building.address || ""}`, id: building.id_building, children: building.children }))

      if (structures.length > 0) {
        site.children.push(structureNode)
      }

      if (parkingSpaces.length > 0) {
        site.children.push(parkingSpaceNode)
      }

      if (selectedNodes?.length > 0) {
        setTimeout(() => {
            this.selectedUnits = selectedNodes.concat(this.selectedUnits.filter(u => !selectedNodes.some(n => n.unitId === u.unitId)))
        })
      }

      setTimeout(() => {
          this.loadCount--
          this.$emit("isLoading", this.loadCount > 0)
      })
    },
    async fetchBuildings (site) {
      if (site.type !== "site") {
        return
      }
      return this.getBuildingFloors(site)
    },
    async setupPortfolioItems () {
      if (this.canConnectToPortfolio == true) {
        if (this.portfolios.length==0) {
          await this.getPortfolios()
        }
        this.portfolioItems = this.portfolios.map(portfolio => ({
          id: portfolio.id,
          name: portfolio.name,
          area: portfolio.area,
          agreedArea: portfolio.area,
          startDate: undefined,
          endDate: undefined
        }));
      }
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
  }
}
</script>

<style>
</style>