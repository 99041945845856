<template>
  <v-dialog
    v-model="showDialog"
    max-width="550"
    persistent
  >
    <v-card>
      <div class="dialog-content">
        <div class="text-h5 lighten-2">
          {{ $t( options.title ) }}
        </div>
        <v-card
          elevation="0"
        >
          <br>
          <div
            class="text-h6"
          >
            {{ $t(options.subtitle) }}
          </div>
        </v-card>
      </div>
      <v-card
        elevation="0"
        class="dialog-footer"
      >
        <v-row
          justify="space-between"
          no-gutters
        >
          <slot name="hideCancel">
            <v-btn
              text
              rounded
              outlined
              @click="cancel"
            >
              {{ $t('Return') }}
            </v-btn>
          </slot>
          <v-row
            justify="end"
            no-gutters
          >
            <v-btn
              v-if="options.negative"
              depressed
              rounded
              color="error"
              @click="confirm(false)"
            >
              {{ $t( options.negative ) }}
            </v-btn>
            <v-btn
              v-if="options.positive"
              depressed
              rounded
              color="primary"
              @click="confirm(true)"
            >
              {{ $t( options.positive ) }}
            </v-btn>
          </v-row>
          <slot name="footer" />
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
/*
Example use of this component
ConfirmDialog is a placed in a App component and is used to show a confirmation dialog to the user.
The buttons are optional and can be customized.
The dialog returns a promise that resolves with true, false or undefined when the user clicks on the positive, negative or cancel button respectively.

// In your component
 Add ...mapActions('app', ['showConfirmDialog']),

  this.showConfirmDialog({
    title: 'Are you sure?',
    subtitle: 'This action cannot be undone', // optional
    positive: 'Yes', // green button -- optional
    negative: 'No' // //red button -- optional
  }).then(result => {
    if (result === undefined) {
      // promise was resolved with no value (user clicked cancel)
      return or do something
    } else if (result === true) {
      // promise was resolved with true (user clicked yes)
    } else {
      // promise was resolved with false (user clicked no)
    }
  }).catch(e => {
    // Handle error. This is optional
  }).finally(() => {
    // Do something after the dialog is closed. This is optional
  })
*/
  name: 'ConfirmDialog',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDialog: true,
    }
  },
  methods: {
    confirm (result) {
      // resolve the promise with the result true or false
      this.options.resolve(result)
      this.$emit('close')
    },
    cancel () {
      // resolve the promise with the result undefined
      this.options.resolve()
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.dialog-content {
  padding: 10%;
}
.dialog-footer {
  padding: 5% 7%;
  padding-top: 0;
  position: sticky;
}
</style>
