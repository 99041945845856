
/**
 * For customers that can create units from spaces on the floor plan
 * @returns {Object} Widget object
 */
export function unitsWithFloorPlan () {
  return {
    id: 'site.units_with_dwg',
    type: 'MetaDataTable',
    data: {
      headers: [
        { text: 'Building', value: 'building_name', alwaysVisible: true },
        { text: 'Floor', value: 'floor_name', alwaysVisible: true },
        { text: 'Unit code', value: 'unit_code' },
        { text: 'Unit use', value: 'usage', format: 'Translation' },
        { text: 'Apartment type', value: 'apartment', isDefinition: true },
        { text: 'Unit rental status', value: 'not_rentable', format: 'Translation' },
        { text: 'Not rentable info', value: 'not_rentable_info', textOverflow: true },
        { text: 'Target goal', value: 'target_goal', isDefinition: true },
        { text: 'Net floor area', value: 'area', format: 'Area' },
        { text: 'Rented unit area', value: 'rented_unit_area', format: 'Area' },
        { text: 'Free unit area', value: 'free_unit_area', format: 'Area' },
        { text: 'Empty space contract area', value: 'empty_space_contract_area', format: 'Area' },
        { text: 'Tenant', value: 'contract_tenant' },
        { text: 'Category', value: 'tags', type: 'chipField', keepDataStructure: true },
        { text: 'Unit description', value: 'description' },
        { text: 'Additional info', value: 'additional_info', hideOverflow: true },
        { text: 'Permission validity', value: 'permission_validity_date', format: "Date"},
        { text: 'Permission definition', value: 'permission_definition' },
        { text: 'Min stocknumber', value: 'min_stocknumber', format: 'Number' },
        { text: 'Max stocknumber', value: 'max_stocknumber', format: 'Number' },
        { text: 'Unit start date', value: 'version_one_start_date', format: "Date", alwaysVisible: true },
        { text: 'Unit version end', value: 'end_date', format: "Date", alwaysVisible: true }
      ],
      footers: [
        { value: 'area', format: 'Area', headerValue: 'area' },
        { value: 'empty_space_contract_area', format: 'Area', headerValue: 'empty_space_contract_area' },
      ],
      items: [],
      source: 'unitsForCurrentSiteWithSpace',
      descending: true,
      filterByCostcenter: true,
      shouldGenerateFooters: true,
    },
    loading: true,
    maximized: [false]
  }
}