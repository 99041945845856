<template>
  <BaseModal
    @cancel="close()"
  >
    <template #title>
      {{ $t('create_rent_contract.add_single_payment') }}
    </template>
    <template #content>
      <ValidationObserver>
        <v-row align="center">
          <v-col cols="4">
            <v-checkbox
              v-model="isFullContractPayment"
              :label="$t('create_rent_contract.full_contract_payment')"
              :disabled="isLocked"
              @change="onIsFullContractPaymentChanged"
            />
          </v-col>
          <v-col>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetUnitsRules"
            >
              <v-autocomplete
                v-model="targetUnits"
                multiple
                :items="unitItems"
                :disabled="isFullContractPayment"
                :error-messages="errors"
                :readonly="isLocked"
                item-text="unitCode"
                item-value="unitId"
                :label="$t('create_rent_contract.units')"
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetStructuresRules"
            >
              <v-autocomplete
                v-model="targetStructures"
                multiple
                :items="structureItems"
                :disabled="isFullContractPayment"
                :readonly="isLocked"
                :error-messages="errors"
                item-text="unitCode"
                item-value="structureId"
                :label="$t('Structures')"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="parkingSpaceItems && parkingSpaceItems.length > 0"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="targetParkingSpacesRules"
            >
              <v-autocomplete
                v-model="targetParkingSpaces"
                multiple
                :items="parkingSpaceItems"
                :disabled="isFullContractPayment"
                :readonly="isLocked"
                :error-messages="errors"
                item-text="unitCode"
                item-value="parkingSpaceId"
                :label="$t('create_rent_contract.parking_spaces')"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DatePicker
              :label="$t('create_rent_contract.single_payment_date')"
              :aria-label="$t('create_rent_contract.single_payment_date') + $t('create_rent_contract.in_the_format') + $t('date_format')"
              :rules="`${isOutRent ? 'required' : ''}|startDateAfterEarliest:${paymentDate},${earliestPossibleStartDate}|required`"
              :hint="$t('date_format')"
              :default-hint="false"
              :clearable="false"
              :immediate="true"
              :disabled="isLocked"
              :initial-date="paymentDate"
              @dateupdated="updatePaymentDate"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-if="isFullContractPayment"
              v-model="ownBill"
              :label="$t('create_rent_contract.own_bill')"
              :disabled="isLocked"
              @change="onIsFullContractPaymentChanged"
            />
          </v-col>
        </v-row>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.single_payment_basis') }}
        </h3>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              :immediate="true"
              rules="required"
            >
              <v-autocomplete
                v-model="selectedPaymentDomain"
                :items="rentPaymentDomains"
                :label="$t('create_rent_contract.single_payment_basis_domain')"
                :error-messages="errors"
                :readonly="isLocked"
                :item-text="item => `${item.name + ' ALV ' + item.vatPercent + ' %'}`"
                item-value="id"
                @change="onPaymentDomainChanged"
              >
                <template #selection="{ item }">
                  {{ item.name ? $t(`${item.name}`): "" }} {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }}
                </template>
                <template #item="{ item }">
                  <v-list-item-avatar color="blue lighten-5">
                    <v-icon 
                      v-if="selectedCurrency.icon"
                      color="blue darken-1"
                    >
                      {{ selectedCurrency.icon }}
                    </v-icon>
                    <span
                      v-else
                      style="font-size:90%; color:#1E88E5; font-weight:bold;"
                    >  
                      {{ selectedCurrency.symbol }} 
                    </span> 
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t('VAT') }} {{ item.vatPercent }} {{ "%" }} {{ item.group ? `· ${item.group}` : '' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.single_payment_prices') }}
        </h3>
        <v-row>
          <v-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${isOutRent ? 'required' : ''}`"
            >
              <CurrencyField
                :value="netSum"
                :label="$t('create_rent_contract.net_sum')"
                :error-messages="errors"
                :readonly="isLocked"
                :currency="selectedCurrency"
                type="number"
                @change="onNetSumChanged"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <CurrencyField
              :value="grossSumText"
              readonly
              type="text"
              :label="$t('create_rent_contract.gross_sum')"
              :currency="selectedCurrency"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <CurrencyField
              :value="taxSumText"
              :label="$t('create_rent_contract.tax_sum')"
              :currency="selectedCurrency"
              readonly
              type="text"
            />
          </v-col>
        </v-row>
        <h3 class="form-headers">
          {{ $t('create_rent_contract.single_payment_info') }}
        </h3>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="legend"
              type="text"
              :label="$t('create_rent_contract.payment_legend')"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedPaymentBasis"
              :items="rentPaymentsBases"
              :label="$t('create_rent_contract.single_payment_basis_basis')"
              :readonly="isLocked"
              item-value="id"
            >
              <template #selection="{ item, index }">
                {{ item.label ? $t(`${item.label}`) : $t('create_rent_contract.index') }}
              </template>
              <template #item="{item, index}">
                {{ $t(`${item.label}`) }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-dialog
        v-model="showCalculatorModal"
        persistent
        eager
        width="65%"
      >
        <Calculator
          :selected-buildings="selectedBuildings"
          @close="closeCalculatorModal()"
        />
      </v-dialog>
    </template>
    <template #footer>
      <v-btn
        v-if="hasApplicationPermissionByName('SISVUOKRA_LASKIN')"
        class="blue-button"
        rounded
        depressed
        absolute
        left
        @click="openCalculatorModal()"
      >
        {{ $t('Calculator') }}
      </v-btn>
      <v-btn
        :disabled="!isStartDateValidAfterEarliest || selectedPaymentDomain === undefined"
        text
        rounded
        outlined
        depressed
        class="blue-button"
        @click="onAddPayment"
      >
        {{ addButtonText }}
      </v-btn>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '../../../../general/BaseModal'
import DatePicker from '../../../../general/DatePicker.vue'
import helpers from './../../../../../helpers'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Calculator from '../../../../Calculator.vue'
import { i18n } from "../../../../../plugins/i18n";
import CurrencyField from '../../../../CurrencyField'

extend('startDateAfterEarliest', {
  message: i18n.t("create_rent_contract.target_starts_after_single_payment"),
  params: ['paymentDate', 'earliestPossibleStartDate'],
  validate: (_, { paymentDate, earliestPossibleStartDate }) => {

    const date = Date.parse(paymentDate?.trim().split("T")[0])
    if (isNaN(date)) {
      return true
    }
    const earliestDate = Date.parse(earliestPossibleStartDate.trim())

    if (isNaN(earliestDate)) {
      return true
    }

    return date >= earliestDate
  }
});

export default {
  name: "SinglePaymentModal",
  components: {
      BaseModal,
      DatePicker,
      ValidationProvider,
      ValidationObserver,
      Calculator,
      CurrencyField,
  },
  props: {
    contractId: {
      type: Number,
      default: () => undefined
    },
    selectedUnits: {
      type: Array,
      default: () => []
    },
    payment: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    rentPaymentDomains: {
      type: Array,
      default: () => []
    },
    isOutRent: {
      type: Boolean,
      default: () => undefined
    },
    selectedBuildings: {
      type: Array,
      default: () => []
    },
    selectedCurrency: {
      type: Object,
      default: () => {
        return {
          'id': 1,
          'name': 'currency.EUR',
          'code': 'EUR',
          'symbol': '€',
          'icon': 'euro'
        }
      },
    },
  },
  data () {
    return {
        isFullContractPayment: false,
        targetUnits: [],
        targetStructures: [],
        targetParkingSpaces: [],
        paymentDate: undefined,
        paymentDateFormatted: undefined,
        selectedPaymentDomain: undefined,
        paymentBases: [],
        selectedPaymentBasis: undefined,
        netSum: undefined,
        grossSum: undefined,
        taxSum: undefined,
        legend: undefined,
        ownBill: false,
        showCalculatorModal: false,
        isStartDateValidAfterEarliest: true,
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById', 'hasApplicationPermissionByName']),
    rentPaymentsBases () {
      return this.definitionsByGroupLabel('rent_payments.basis')
    },
    grossSumText () {
      return this.grossSum !== undefined ? helpers.humanize.amount_long(this.grossSum).replace('.',',') : ""
    },
    taxSumText () {
      return this.taxSum !== undefined ? helpers.humanize.amount_long(this.taxSum).replace('.',',') : ""
    },
    addButtonText () {
      return this.payment ? this.$t('create_rent_contract.payment_save') : this.$t('create_rent_contract.payment_add')
    },
    isLocked () {
      return this.payment?.id > 0 && 
        (!this.contract?.isDraft || 
         (this.payment?.latestBillingDate !== null && this.payment?.latestBillingDate !== undefined))
    },
    targetUnitsRules () {
      if (this.isFullContractPayment || 
        (this.targetStructures && this.targetStructures.length > 0) || 
        (this.targetParkingSpaces && this.targetParkingSpaces.length > 0)) {
        return '';
      }
      return 'required';
    },
    targetStructuresRules () {
      if (this.isFullContractPayment || 
        (this.targetUnits && this.targetUnits.length > 0) || 
        (this.targetParkingSpaces && this.targetParkingSpaces.length > 0)) {
        return '';
      }
      return 'required';
    },
    targetParkingSpacesRules () {
      if (this.isFullContractPayment || 
        (this.targetUnits && this.targetUnits.length > 0) || 
        (this.targetStructures && this.targetStructures.length > 0)) {
        return '';
      }
      return 'required';
    },
    earliestPossibleStartDate () {
      if (!Array.isArray(this.selectedUnits) || !Array.isArray(this.targetUnits) || !Array.isArray(this.targetStructures) || Array.isArray(this.targetParkingSpaces)) {
        return null
      }

      if (this.isFullContractPayment && Array.isArray(this.selectedUnits)) {
        const startDates = this.selectedUnits
          .filter(selectedItem => selectedItem.startDate)
          .map(selectedItem => new Date(selectedItem.startDate))

        const earliestStartDate = startDates.length > 0 ? new Date(Math.max(...startDates)) : null
        return earliestStartDate
      }

      const startDates = []

      if (!this.isFullContractPayment){
        for (const targetId of [...this.targetUnits, ...this.targetStructures, ...this.targetParkingSpaces]) {
          const selectedItem = this.selectedUnits.find(item => item.id === targetId)
          if (selectedItem && selectedItem.startDate) {
            startDates.push(new Date(selectedItem.startDate))
          }
        }
      }
      
      const earliestStartDate = startDates.length > 0 ? new Date(Math.max(...startDates)) : null

      return earliestStartDate
    },
    parkingSpaceItems () {
      return this.selectedUnits.filter(u => !!u.parkingSpaceId)
    },
    structureItems () {
      return this.selectedUnits.filter(u => !!u.structureId)
    },
    unitItems () {
      return this.selectedUnits.filter(u => !!u.unitId)
    },
  },
  watch: {
    showCalculatorModal () {
      if (this.showCalculatorModal == true) {
        this.setupCalculatorStartingValues();
      }
    },
    visible () {
      if (this.visible) {
        this.copyDataFromPayment()
      }
    },
    payment () {
      this.copyDataFromPayment()
    },
    paymentDate () {
      this.paymentDateFormatted = this.formatDate(this.paymentDate)
      if(this.paymentDate || (this.targetStructures.length > 0 || this.targetUnits.length > 0 || this.targetParkingSpaces.length > 0)){
        this.checkTargetStartDate()
      }
    },
    targetUnits () {
      if(this.targetUnits.length > 0){
        this.checkTargetStartDate()
      }
    },
    targetStructures () {
      if(this.targetStructures.length > 0){
        this.checkTargetStartDate()
      }
    },
    targetParkingSpaces () {
      if(this.targetParkingSpaces.length > 0){
        this.checkTargetStartDate()
      }
    },
  },
  methods: {
    ...mapActions("calculator", ["setupCalculatorStartingValues"]),

    openCalculatorModal () {
      this.showCalculatorModal = true
    },
    closeCalculatorModal () {
      this.showCalculatorModal = false
    },
    updatePaymentDate (value) {
      this.paymentDate = value
    },
    copyDataFromPayment () {
      this.isFullContractPayment = this.payment?.wholeContractPayment === undefined ? !this.isOutRent : this.payment?.wholeContractPayment
      this.paymentDate = this.payment?.paymentDate
      this.selectedPaymentDomain = this.payment?.paymentDomainId
      this.selectedPaymentBasis = this.payment?.defPaymentBasis
      this.netSum = this.payment?.netSum
      this.legend = this.payment?.legend
      this.ownBill = this.payment?.ownBill

      // Populate target units
      let targetUnits = []
      if (this.payment !== null && this.payment.targetUnits.length > 0) {
        targetUnits = [...targetUnits, ...this.payment.targetUnits]
      }
      this.targetUnits = targetUnits

      let targetStructures = []
      if (this.payment !== null && this.payment.targetStructures.length > 0) {
        targetStructures = [...targetStructures, ...this.payment.targetStructures]
      }
      this.targetStructures = targetStructures

      let targetParkingSpaces = []
      if (this.payment !== null && this.payment.targetParkingSpaces.length > 0) {
        targetParkingSpaces = [...targetParkingSpaces, ...this.payment.targetParkingSpaces]
      }
      this.targetParkingSpaces = targetParkingSpaces

      this.calculateSums()
    },
    onIsFullContractPaymentChanged () {
      if (this.isFullContractPayment) {
        this.targetUnits = []
        this.targetStructures = []
      }
    },
    close () {
      if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
        this.$emit('close')
      }
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate (date) {
      if (!date) return null

      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    onNetSumChanged (value) {
      this.netSum = value
      this.calculateSums()
    },
    onPaymentDomainChanged () {
      this.calculateSums()
    },
    async calculateSums () {

      if (this.selectedPaymentDomain === undefined || this.netSum === undefined) {
        this.grossSum = undefined
        this.taxSum = undefined
        return
      }
      let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === this.selectedPaymentDomain)
      if (paymentDomain[0]) {

        if (this.netSum != undefined && this.netSum.toString().includes(",")) {
          this.netSum = parseFloat(
          this.netSum.replace(",", ".")
          ).toFixed(2);
        }
        let netSum = this.netSum
        if (netSum === undefined) netSum = 0
        const sums = await this.$rambollfmapi.rentpayments.singlepayments.calculateSums(paymentDomain[0], netSum)
        this.grossSum = sums.grossSum
        this.taxSum = sums.taxSum
      }
    },
    onAddPayment () {
      const modifiedPayment = {
          id: this.payment?.id,
          contractId: this.contractId,
          paymentDomainId: this.selectedPaymentDomain,
          defPaymentBasis: this.selectedPaymentBasis,
          legend: this.legend,
          wholeContractPayment: this.isFullContractPayment ?? false,
          paymentDate: this.paymentDate,
          ownBill: this.ownBill,
          netSum: this.netSum,
          targetUnits: this.targetUnits,
          targetStructures: this.targetStructures,
          targetParkingSpaces: this.targetParkingSpaces,
          latestBillingDate: this.payment?.latestBillingDate ?? null,
      }

      if (this.payment) {
        this.$emit('paymentUpdated', modifiedPayment)
      } else {
        this.$emit('paymentAdded', modifiedPayment)
      }
    },
      checkTargetStartDate () {
      let paymentDateTimestamp = null
      let earliestPaymentDateTimestamp = null

      if(this.paymentDate){
        paymentDateTimestamp = new Date(this.paymentDate.split('T')[0]).getTime()
      }
      if(this.earliestPossibleStartDate){
        earliestPaymentDateTimestamp = this.earliestPossibleStartDate.getTime()
      }

      if (paymentDateTimestamp >= earliestPaymentDateTimestamp) {
        this.isStartDateValidAfterEarliest = true
      } else {
        this.isStartDateValidAfterEarliest = false
      }
    }
  }
}

</script>

<style scoped>
.form-headers {
  margin: var(--r-spacing-xl) 0 var(--r-spacing-xs) !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
.v-input--checkbox {
  display: inline-flex;
}
</style>
