<template>
  <div id="reports">
    <SkipTo
      :label="$t('skip-to-table')"
      to="#table-content"
    />
    <h1 class="d-sr-only">
      {{ $t('Reporting') }}
    </h1>
    <ViewAndSelections
      :contract-level="reportingLevel"
      :loading-as-prop="isLoading"
      parent-level="reports"
      @close-filter="closeViewAndSelectionDialogs"
    >
      <template #buttons>
        <BaseView>
          <template #selections>
            <v-select
              v-model="reportingLevel"
              :label="$t('Reporting level')"
              :items="reportingLevels"
              item-text="text"
              item-value="value"
            />
          </template>
          <template #buttons>
            <v-btn
              :disabled="isLoading"
              small
              outlined
              rounded
              @click="showDefaultSearchDialog"
            >
              {{ $t('Save default search') }}
            </v-btn>
            <v-btn
              :disabled="isLoading"
              small
              rounded
              outlined
              @click="showColumnsFilterDialog"
            >
              {{ $t('Choose visible columns') }}
            </v-btn>
            <v-btn
              :loading="exporting.documentType == 'pdf'"
              :disabled="Object.keys(exporting).length > 0 || isLoading"
              small
              rounded
              outlined
              @click="exportAs('pdf', reportingLevel)"
            >
              {{ $t('Export to PDF') }}
            </v-btn>
            <v-btn
              :loading="exporting.documentType == 'csv'"
              :disabled="Object.keys(exporting).length > 0 || isLoading"
              small
              rounded
              outlined
              @click="exportAs('csv', reportingLevel)"
            >
              {{ $t('Export to spreadsheet') }}
            </v-btn>
          </template>
        </BaseView>
      </template>
      <template
        #table="props"
      >
        <Table
          v-show="!isLoading"
          :rows="rowData"
          :headers="viewHeaders"
          :footers="createFooters"
          :hide="true"
          :show-rows-per-page-selection="true"
          :show-filter="showColumnsFilter"
          :show-default-search="showDefaultSearch"
          :document-type="exportAsDocument"
          :pagination="tablePagination"
          :show-save-selection="true"
          :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
          :stored-views="props.props.storedViews.filter(sv => sv.level === reportingLevel)"
          :show-confirm="props.props.confirmation"
          :emit-pagination="usePagination"
          :row-count="totalLevelItems"
          :rows-loading="betweenPageLoad"
          :get-export-data="getExportData"
          :multi-select-keys="multiSelectKeys"
          class="pa-4"
          @showFilterDialog="showColumnsFilterDialog"
          @showDefaultSearchDialog="showDefaultSearchDialog"
          @exported="exporting = {}"
          @select_view="props.props.selectView"
          @save-selection="props.props.saveStoredView"
          @delete-view="props.props.deleteSelectedView"
          @updatePagination="updatePage"
          @filterSearch="filterSearch"
        />
      </template>
    </ViewAndSelections>
  </div>
</template>
<script>
import Table from '../components/Table.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ViewAndSelections from '../components/Contracts/ViewAndSelections.vue'
import BaseView from '../components/general/BaseView.vue'
import SkipTo from '../components/general/SkipTo'
import helpers from '../helpers'
import { SHOW_CARPARK_AREA } from '../constants/config'
export default {
  name: 'Reports',
  components: {
    BaseView,
    SkipTo,
    Table,
    ViewAndSelections
  },
  metaInfo () {
    return {
      title: `${this.$t('Reporting')} ·`,
    }
  },
  data () {
    return {
      category: 'None',
      isLoading: true,
      betweenPageLoad: false,
      loadingReady: 0,
      loadingTotal: 0,
      reportingLevel: 'site',
      showColumnsFilter: false,
      exportAsDocument: [],
      tablePagination: {
        sortDesc: [false],
        itemsPerPage: 100,
        sortBy: ['site_name'],
        page: 1
      },
      // Arrays to hold different report level data
      carparks: [],
      spaces: [],
      units: [],
      sites: [],
      estates: [],
      buildings: [],
      structures: [],
      buildingProjects: [],
      technicalValues: [],
      technicalValueRepairs: [],
      // Objects for metadata
      carparkMetadata: {},
      spaceMetadata: {},
      customSpaceMetadata: {},
      unitMetadata: {},
      siteMetadata: {},
      estateMetadata: {},
      buildingMetadata: {},
      structureMetadata: {},
      buildingProjectMetadata: {},
      // Variable to hold total level item count
      levelItemsTotal: 0,
      searchValues: [],
      exporting: {},
      showDefaultSearch: false,
      multiSelectKeys: {},
      purposesofuse: [],
      buildingClassifications: []
    }
  },
  computed: {
    ...mapState('app', ['currentDate', 'siteIds']),
    ...mapGetters('app', ['definitionById', 'definitionsByGroupId', 'hasApplicationPermissionByName']),
    hasExternalOrgs () {
      return this.$store.state.app.hasExternalOrgs
    },
    viewHeaders () {
      if(this.siteMetadata == null){
        return []
      }
      let headers = this.getHeaders
      headers.forEach(h => {
        if (h.value === "dwg_status") {
          h.isMultiSelect = true
        }
      })
      return headers
    },
    reportingLevels () {
      const reports = [
        {
          text: this.$t('Site level'),
          value: 'site'
        },
        {
          text: this.$t('Estate level'),
          value: 'estate'
        },
        {
          text: this.$t('Building level'),
          value: 'building'
        },
        {
          text: this.$t('Unit level'),
          value: 'unit'
        },
        {
          text: this.$t('Room level'),
          value: 'space'
        },
        {
          text: this.$t('Car park level'),
          value: 'carpark'
        },
        {
          text: this.$t('Structure level'),
          value: 'structure'
        },
        {
          text: this.$t('Building project level'),
          value: 'building_project'
        },
      ].filter(report => report !== null)
      
      if (this.hasApplicationPermissionByName('TEKNISET_ARVOT')) {
        reports.push(
          {
            text: this.$t('technical_value_level'),
            value: 'technical_value'
          },
          {
            text: this.$t('technical_value_calculation_repairs_level'),
            value: 'technical_value_calculation_repairs'
          },
        )
      }
      return reports
    },
    totalLevelItems () {
      switch (this.reportingLevel) {
        case 'estate':
          return this.estates[0] !== undefined ? this.estates[0].total_count : 0
        case 'building':
          return this.buildings[0] !== undefined ? this.buildings[0].total_count : 0
        case 'unit':
          return this.units[0] !== undefined ? this.units[0].total_count : 0
        case 'space':
          return this.spaces[0] !== undefined ? this.spaces[0].total_count : 0
        case 'carpark':
          return this.carparks[0] ? this.carparks[0].total_count : 0
        case 'structure':
          return this.structures[0] ? this.structures[0].total_count : 0
        case 'building_project':
          return this.buildingProjects[0] ? this.buildingProjects[0].total_count : 0
        case 'technical_value':
          return this.technicalValues[0] ? this.technicalValues[0].total_count : 0
        case 'technical_value_calculation_repairs':
          return this.technicalValueRepairs[0] ? this.technicalValueRepairs[0].total_count : 0
        default:
          return this.sites[0] !== undefined ? this.sites[0].total_count : 0
      }
    },
    usePagination () {
      return this.hasExternalOrgs || this.siteIds.sites.length > 500
    },
    siteIdsReady () {
      return this.siteIds.sites.length > 0
    },
    getHeaders () {
      switch (this.reportingLevel) {
        case 'estate':
          return [
            { value: 'site_name', text: this.$t('Site name') },
            { value: 'site_identifier', text: this.$t('Site identifier') },
            { value: 'estate_name', text: this.$t('Estate') },
            { value: 'municipality', text: this.$t('Municipality') },
            ...this.buildHeaders(this.estateMetadata).filter(header => header.value !== 'name' && header.value !== 'municipality'),
            { value: 'buildings_count', text: this.$t('Buildings count'), format: 'Number' },
            { value: 'structures_count', text: this.$t('Structures count'), format: 'Number' },
          ]
        case 'building':
          let buildingMetadataHeaders = this.buildHeaders(this.buildingMetadata)
          buildingMetadataHeaders.filter(
             header => header.text == 'Temporary permit desc' || header.text == 'Additional info').forEach(
              (header) => { header.hideOverflow = true }
             )
          return [
            { value: 'site_name', text: this.$t('Site name') },
            { value: 'site_identifier', text: this.$t('Site identifier') },
            { value: 'estate_name', text: this.$t('Estate') },
            { value: 'estate_identifier', text: this.$t('Estate identifier') },
            ...buildingMetadataHeaders
          ]
        case 'unit':
          return this.getUnitHeaders()
        case 'space':
          return [
            { value: 'site_name', text: this.$t('Site name') },
            { value: 'building_name', text: this.$t('Building name') },
            { value: 'building_code', text: this.$t('Building code') },
            { value: 'floor', text: this.$t('Floor') },
            { value: 'name', text: this.$t('Space number') },
            ...this.buildCustomizableHeaders(this.spaceMetadata, this.customSpaceMetadata)
          ]
        case 'carpark':
          return this.getCarparkHeaders()
        case 'structure':
          return [
            { value: 'site_name', text: this.$t('Site name') },
            { value: 'site_identifier', text: this.$t('Site identifier') },
            ...this.buildHeaders(this.structureMetadata)
          ]
        case 'building_project':
          return this.getBuildingProjectHeaders()
        case 'technical_value':
          return this.getTechnicalValueHeaders()
        case 'technical_value_calculation_repairs':
          return this.getTechnicalValueRepairsHeaders()
          default: {        
          return [
            { value: 'site_name', text: this.$t('Site name') },
            { value: 'buildings_count', text: this.$t('Buildings count'), format: 'Amount', sortable: false },
            { value: 'structures_count', text: this.$t('Structures count'), format: 'Amount', sortable: false },
            ...this.buildHeaders(this.siteMetadata).filter(
              header => header.value !== 'name'
            ),
            { value: 'dwg_status', text: this.$t('Dwg status'), format: 'Array', sortable: false, isMultiSelect: true }
          ]
        }
      }
    },
    rowData () {
      switch (this.reportingLevel) {
        case 'estate':
          return this.estateData(this.estates)
        case 'building':
          return this.buildingData(this.buildings)
        case 'unit':         
          return this.unitData(this.units)
        case 'space':
          return this.spaceData(this.spaces)
        case 'carpark':
          return this.carparkData(this.carparks)
        case 'structure':
          return this.structureData(this.structures)
        case 'building_project':
          return this.buildingProjectsData(this.buildingProjects)
        case 'technical_value':
          return this.technicalValues
        case 'technical_value_calculation_repairs':
          return this.technicalValueRepairs
        default: {
          return this.siteData(this.sites)
        }
      }
    }
  },
  watch: {
    currentDate: function () {
      this.refreshData()
    },
    reportingLevel () {
      this.searchValues = []
      // Exporting to csv from different levels in a row will remember the previous sortBy and sortDesc values unless
      // they are reset to default values after each change of the reporting level
      this.tablePagination.sortBy = ['site_name']
      this.tablePagination.sortDesc = [false]

      this.loadCarparks()
    },
    siteIdsReady: function () {
      this.refreshData()
    },
  },
  mounted: async function () {
    if (!this.hasExternalOrgs) {
      await this.$store.dispatch('app/checkExternalOrgs')
    }
    this.refreshData()
  },
  methods: {
    ...mapActions('sites', ['loadMetaData']),
        estateData (estates) {
      return estates.map(estate => {
        estate.public_utility = estate.public_utility ? this.$t('Yes') : this.$t('No')
        estate.development_plan_proposal = estate.development_plan_proposal ? this.$t('Yes') : this.$t('No')
        estate.easement_duties = estate.easement_duties ? this.$t('Yes') : this.$t('No')
        estate.easement_rights = estate.easement_rights ? this.$t('Yes') : this.$t('No')
        estate.joint_ownership_contract = estate.joint_ownership_contract ? this.$t('Yes') : this.$t('No')
        estate.building_limits = estate.building_limits ? this.$t('Yes') : this.$t('No')
        estate.common_arrangements_with_neighbouring_estates = estate.common_arrangements_with_neighbouring_estates ? this.$t('Yes') : this.$t('No')
        return helpers.format.escapeHtmlFromObjectStrings(estate)
      })
    },
    buildingData (buildings) {
      return buildings.map(s => helpers.format.escapeHtmlFromObjectStrings(s))
    },
    unitData (units) {
      return units.map(s => {
        s.vat_responsibility = s.alv ? this.$t('Yes') : this.$t('No')
        s.cost_center = s.rental_status.site_identifier
        s.contract_party = s.rental_status.contract_party
        s.validity = s.rental_status.validity
        s.signatureDate = s.rental_status.signature_date
        s.curPartyStartDate = s.rental_status.curPartyStartDate
        s.contractFirstPossibleEndDate = s.rental_status.contractFirstPossibleEndDate
        s.curPartyEndDate = s.rental_status.curPartyEndDate
        s.tenantNotice = s.rental_status.tenantNotice
        s.landlordNotice = s.rental_status.landlordNotice
        s.huom = s.rental_status.huom ? this.$t('Considerations') : null
        s.contract_note = s.rental_status.contract_note
        s.tenantFirstPossibleEndDate = s.rental_status.tenantFirstPossibleEndDate
        s.landlordFirstPossibleEndDate = s.rental_status.landlordFirstPossibleEndDate
        s.capitalRent = s.rental_status.capitalRent
        s.maintenanceRent = s.rental_status.maintenanceRent
        s.maintenanceRentEqualization = s.rental_status.maintenanceRentEqualization
        s.changeoverRent = s.rental_status.changeoverRent
        s.separateAllowances = s.rental_status.separateAllowances
        s.discounts = s.rental_status.discounts
        s.openBillsTotal = s.rental_status.openBillsTotal
        s.basicRent = s.rental_status.basicRent
        s.rentReviewType = s.rental_status.rentReviewType
        s.indexType = s.rental_status.indexType
        s.basicIndexPointNumber = s.rental_status.basicIndexPointNumber
        s.reviewMonths = s.rental_status.reviewMonths
        s.latestIndexYear = s.rental_status.latestIndexYear
        s.latestIndexMonth = s.rental_status.latestIndexMonth
        s.latestIndexPointNumber = s.rental_status.latestIndexPointNumber
        s.minimumIncrease = s.rental_status.minimumIncrease
        s.unit_status = this.$t(s.unit_status)
        return helpers.format.escapeHtmlFromObjectStrings(s)
      })
    },
    spaceData (spaces) {
      return spaces.map(s => {
        s.ktv1 = this.$t(s.ktv_1)
        s.ktv2 = this.$t(s.ktv_2)
        s.ktv3 = this.$t(s.ktv_3)
        return helpers.format.escapeHtmlFromObjectStrings(s)
      })
    },
    carparkData (carparks) {
      return carparks.map(carpark => {
        // If carpark has a contract, add it's data to report
        let contractData = {}
        const { contract } = carpark
        if (contract) {
          contractData = {
            contract_area: contract.contract_area,
            contract_number: contract.contract_number,
            vat_responsibility: contract.vat_responsibility ? this.$t('Yes') : this.$t('No'),
            tenant: contract.tenant,
            contract_party: contract.contract_party,
            validity: contract.validity,
            signature_date: contract.signature_date,
            start_date: contract.start_date,
            first_possible_end_date: contract.first_possible_end_date,
            end_date: contract.end_date,
            tenant_first_possible_end_date: contract.tenant_first_possible_end_date,
            tenant_notice: contract.tenant_notice,
            landlord_first_possible_end_date: contract.landlord_first_possible_end_date,
            landlord_notice: contract.landlord_notice,
            validity_note: contract.validity_note,
            contract_type: contract.contract_type,
            industry: contract.industry,
            contract_status: contract.contract_status,
            capital_rent: contract.capital_rent,
            maintenance_rent: contract.maintenance_rent,
            maintenance_rent_equalization: contract.maintenance_rent_equalization,
            changeover_rent: contract.changeover_rent,
            separate_allowances: contract.separate_allowances,
            discounts: contract.discounts,
            basic_rent: contract.basic_rent,
            rent_review_type: contract.rent_review_type,
            index_type: contract.index_type,
            basic_index_point_number: contract.basic_index_point_number,
            review_months: contract.review_months,
            latest_index_year: contract.latest_index_year,
            latest_index_month: contract.latest_index_month,
            latest_index_point_number: contract.latest_index_point_number,
            minimum_increase: contract.minimum_increase,
          }
        }
        return {
          ...carpark,
          invalid_spot: carpark.invalid_spot ? this.$t('Yes') : this.$t('No'),
          type: this.getDefinitionLabelById(carpark.type),
          plug_in: carpark.plug_in ? this.$t('Yes') : this.$t('No'),
          charge_in: this.getDefinitionLabelById(carpark.charge_in),
          renter: this.getDefinitionLabelById(carpark.renter),
          ...contractData
        }
      })
    },
    structureData (structures) {
      return structures.map(structure => ({
        ...structure,
        identifier: structure.structure_identifier
      }))
    },
    buildingProjectsData (buildingProjects) {
      return buildingProjects.map(bp => ({
        ...bp,
        id_site: bp.id_site,
        site_name: bp.site_name,
        site_identifier: bp.site_identifier,
        building_name: bp.building_name,
        building_identifier: bp.building_identifier,
        building_code: bp.building_code,
        buildingproject_identifier: bp. buildingproject_identifier,
        buildingproject_number: bp.buildingproject_number,
        name: bp.name,
        purpose_of_use_with_denomination: bp.purpose_of_use_with_denomination,
        unit_area: bp.unit_area,
        owner: bp.landlord,
        buildingproject_ownership_relation: bp.ownership_relation,
        property_maintenance_name: bp.property_maintenance_name,
        property_maintenance_phone: bp.property_maintenance_phone,
        property_maintenance_email: bp.property_maintenance_email,
        situation_code: bp.situation_code,
        situation_description: bp.situation_description,
        financing_type: bp.financing_type,
        project_type: bp.project_type,
        project_type_description: bp.project_type_description,
        status: bp.status ? this.translateBuildingProjectStatus(bp.status) : undefined,
        customer: bp.customer,
        total_financing_provision: bp.total_financing_provision,
        acceptance_date: bp.acceptance_date,
        target_date: bp.target_date,
        completion_date: bp.completion_date,
        start_date: bp.start_date,
        end_date: bp.end_date
      }))
    },
    siteData (sites) {
      return sites.map(s => {
        // Leaving below commented section here so we can later review DWG status check logic if needed
        /* buildingsForSite.map(b => {
              this.floors.filter(floor => floor.building_code === b.building_code).map(f => {
                if (f.has_xml  && (s.dwg_status.indexOf(this.$t('OK')) === -1)) {
                  s.dwg_status = this.$t('OK')
                } else if (f.has_dwg  && (s.dwg_status.indexOf(this.$t('DWG')) === -1)) {
                  s.dwg_status = this.$t('DWG')
                }
              })
              if (b.has_ifc  && (s.dwg_status.indexOf(this.$t('IFC-model')) === -1)) {
                if (s.dwg_status === this.$t('No DWG')) {
                  s.dwg_status = this.$t('IFC-model')
                } else {
                  s.dwg_status += ', ' + this.$t('IFC-model')
                }
              }
            })
            if (s.dwg_status === this.$t('No DWG')) {
              this.estates.filter(estate => estate.id_site === s.id_site).map(e => {
                if (e.has_xml && (s.dwg_status.indexOf(this.$t('OK')) === -1)) {
                  s.dwg_status = this.$t('OK')
                } else if (e.has_dwg && (s.dwg_status.indexOf(this.$t('DWG')) === -1)) {
                  s.dwg_status = this.$t('DWG')
                }
              })
            }
            this.units.filter(unit => unit.id_site === s.id_site).map(u => {
              if(u.pdf && (s.dwg_status.indexOf(this.$t('Pdf')) === -1)) {
                if (s.dwg_status === this.$t('No DWG')) {
                  s.dwg_status = this.$t('Pdf')
                } else {
                  s.dwg_status += ', ' + this.$t('Pdf')
                }
              }
            }) */
        s.dwg_status = this.translateDwgStatus(s.has_dwg)
        return helpers.format.escapeHtmlFromObjectStrings(s)
      })
    },
    async refreshData () {
      if (!this.siteIdsReady){
        return
      }
      this.isLoading = true

      await this.loadMetadata()
      await this.loadData()
      await this.loadCarparks()

      this.isLoading = false
    },
    createFooters (headers, rows) {
      switch (this.reportingLevel) {
        case 'estate':
          if(this.usePagination) {
            return [
              {
                site_name: this.$t('Total'),
                estate_area: this.estates[0]?.total_estate_area ?? null,
                building_count: this.estates[0]?.total_buildings_count ?? null,
                structures_count: this.estates[0]?.total_structures_count ?? null
              }
            ]
          } else {
            return [
              {
                site_name: this.$t('Total'),
                area: rows
                  .map(s => Number(s.area))
                  .reduce((acc, cur) => acc + cur, 0),
                right_to_build: rows
                  .map(s => Number(s.right_to_build))
                  .reduce((acc, cur) => acc + cur, 0)
                  .toFixed(2),
                used_right_to_build: rows
                  .map(s => Number(s.used_right_to_build))
                  .reduce((acc, cur) => acc + cur, 0)
                  .toFixed(2),
                estate_area: rows
                  .map(s => Number(s.estate_area))
                  .reduce((acc, cur) => acc + cur, 0),
                buildings_count: rows
                  .map(s => Number(s.buildings_count))
                  .reduce((acc, cur) => acc + cur, 0),
                structures_count: rows
                  .map(s => Number(s.structures_count))
                  .reduce((acc, cur) => acc + cur, 0)
              }
            ]
          }
        case 'building':
          if(this.usePagination) {
            return [
              {
                site_name: this.$t('Total'),
                building_contract_netarea: this.buildings[0]?.total_building_contract_netarea ?? null,
                building_contract_netfloorarea: this.buildings[0]?.total_building_contract_netfloorarea ?? null,
                building_contract_netroomarea: this.buildings[0]?.total_building_contract_netroomarea ?? null,
                building_gross_area: this.buildings[0]?.total_building_gross_area ?? null,
                building_net_floor_area: this.buildings[0]?.total_building_net_room_area ?? null,
                rentable_floor_area: this.buildings[0]?.total_building_rentable_floor_area ?? null,
                rented_floor_area: this.buildings[0]?.total_building_rented_floor_area ?? null,
                free_rentable_area: this.buildings[0]?.total_building_free_rentable_area ?? null,
                other_floor_area: this.buildings[0]?.total_building_other_floor_area ?? null,
                empty_floor_area: this.buildings[0]?.total_building_empty_floor_area ?? null,
                official_gross_area: this.buildings[0]?.total_official_gross_area ?? null,
                official_leasable_area: this.buildings[0]?.total_official_leasable_area ?? null,
                official_floor_area: this.buildings[0]?.total_official_floor_area ?? null
              }
            ]
          } else {
            return [
              {
                site_name: this.$t('Total'),
                net_floor_area: rows
                  .map(s => Number(s.net_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                rentable_floor_area: rows
                  .map(s => Number(s.rentable_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                rented_floor_area: rows
                  .map(s => Number(s.rented_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                free_rentable_area: rows
                  .map(s => Number(s.free_rentable_area))
                  .reduce((acc ,cur) => acc + cur, 0),
                other_floor_area: rows
                  .map(s => Number(s.other_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                empty_floor_area: rows
                  .map(s => Number(s.empty_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                gross_area: rows
                  .map(s => Number(s.gross_area))
                  .reduce((acc, cur) => acc + cur, 0),
                building_net_floor_area_from_space: rows
                  .map(s => Number(s.building_net_floor_area_from_space))
                  .reduce((acc, cur) => acc + cur, 0),
                building_gross_area: rows
                  .map(s => Number(s.building_gross_area))
                  .reduce((acc, cur) => acc + cur, 0),
                building_net_room_area: rows
                  .map(s => Number(s.building_net_room_area))
                  .reduce((acc, cur) => acc + cur, 0),
                building_net_floor_area: rows
                  .map(s => Number(s.building_net_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                personnel_elevator_count: rows
                  .map(s => Number(s.personnel_elevator_count))
                  .reduce((acc, cur) => acc + cur, 0),
                freight_elevator_count: rows
                  .map(s => Number(s.freight_elevator_count))
                  .reduce((acc, cur) => acc + cur, 0),
                overhead_door_count: rows
                  .map(s => Number(s.overhead_door_count))
                  .reduce((acc, cur) => acc + cur, 0),
                official_gross_area: rows
                  .map(s => Number(s.official_gross_area))
                  .reduce((acc, cur) => acc + cur, 0),
                official_leasable_area: rows
                  .map(s => Number(s.official_leasable_area))
                  .reduce((acc, cur) => acc + cur, 0),
                official_floor_area: rows
                  .map(s => Number(s.official_floor_area))
                  .reduce((acc, cur) => acc + cur, 0)
              }
            ]
          }
        case 'unit':
          if(this.usePagination) {
            return [
              {
                site_name: this.$t('Total'),
                net_floor_area: this.units[0]?.total_unit_area ?? null,
                contract_area: this.units[0]?.total_contract_area ?? null,
                rented_unit_area: this.units[0]?.total_rented_unit_area ?? null,
                free_unit_area: this.units[0]?.total_free_unit_area ?? null,
                empty_unit_area: this.units[0]?.total_empty_unit_area ?? null
              }
            ]
          } else {
            return [
              {
                site_name: this.$t('Total'),
                net_floor_area: rows.reduce(
                  (acc, cur) => acc + Number(cur.net_floor_area),
                  0
                ),
                contract_area: rows.reduce(
                  (acc, cur) => acc + Number(cur.contract_area),
                  0
                ),
                market_rent: rows.reduce(
                  (acc, cur) => acc + Number(cur.market_rent),
                  0
                ),
                estimated_ti_expense: rows.reduce(
                  (acc, cur) => acc + Number(cur.estimated_ti_expense),
                  0
                ),
                clarified_ti_expense: rows.reduce(
                  (acc, cur) => acc + Number(cur.clarified_ti_expense),
                  0
                ),
                rented_unit_area: rows.reduce(
                  (acc, cur) => acc + Number(cur.rented_unit_area),
                  0
                ),
                free_unit_area: rows.reduce(
                  (acc, cur) => acc + Number(cur.free_unit_area),
                  0
                ),
                empty_unit_area: rows.reduce(
                   (acc, cur) => acc + Number(cur.empty_unit_area),
                   0
                 )        
              }
            ]
          }
        case 'space':
          if(this.usePagination) {
            return [
              {
                site_name: this.$t('Total'),
                net_room_area: this.spaces[0]?.total_room_area ?? null,
                unit_area: this.spaces[0]?.total_unit_area ?? null,
                number_of_workstations: this.spaces[0]?.total_work_space_count ?? null,
              }
            ]
          } else {
            return [
              {
                site_name: this.$t('Total'),
                unit_area: rows.reduce((acc, cur) => acc + Number(cur.unit_area), 0),
                net_room_area: rows.reduce((acc, cur) => acc + Number(cur.net_room_area), 0),
                number_of_personnel: rows.reduce((acc, cur) => acc + Number(cur.number_of_personnel), 0),
                number_of_workstations: rows.reduce((acc, cur) => acc + Number(cur.number_of_workstations), 0),
                additional_cost: rows.reduce((acc, cur) => acc + Number(cur.additional_cost), 0)
              }
            ]
          }
        case 'carpark':
          return [
            {
              site_name: this.$t('Total'),
              area: this.carparks[0] ? this.carparks[0].total_area : 0
            }
          ]
        case 'structure':
          return [
            {
              site_name: this.$t('Total'),
              total_count: this.structures[0] ? this.structures[0].total_count : 0
            }
          ]
        case 'building_project':
          return [
            {
              site_name: this.$t('Total'),
            }
          ]
        default:
          if(this.usePagination) {
            return [
              {
                site_name: this.$t('Total'),
                buildings_count: this.sites[0]?.total_buildings_count ?? null,
                structures_count: this.sites[0]?.total_structures_count ?? null,
                contract_grossarea: this.sites[0]?.total_contract_grossarea ?? null,
                contract_netfloorarea: this.sites[0]?.total_contract_netfloorarea ?? null,
                contract_netroomarea: this.sites[0]?.total_contract_netroomarea ?? null
              }
            ]
          } else {
            return [
              {
                site_name: this.$t('Total'),
                net_floor_area: rows
                  .map(s => Number(s.net_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                real_estate_company_floor_area: rows
                  .map(s => Number(s.real_estate_company_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                rented_floor_area: rows
                  .map(s => Number(s.rented_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                free_floor_area: rows
                  .map(s => Number(s.free_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                gross_area: rows
                  .map(s => Number(s.gross_area))
                  .reduce((acc, cur) => acc + cur, 0),
                rentable_floor_area: rows
                  .map(s => Number(s.rentable_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                ktv_undefined: rows
                  .map(s => Number(s.ktv_undefined))
                  .reduce((acc, cur) => acc + cur, 0),
                number_of_workstations: rows
                  .map(s => Number(s.number_of_workstations))
                  .reduce((acc, cur) => acc + cur, 0),
                net_floor_area_from_space: rows
                  .map(s => Number(s.net_floor_area_from_space))
                  .reduce((acc, cur) => acc + cur, 0),
                space_area: rows
                  .map(s => Number(s.space_area))
                  .reduce((acc, cur) => acc + cur, 0),
                other_floor_area: rows
                  .map(s => Number(s.other_floor_area))
                  .reduce((acc, cur) => acc + cur, 0),
                free_rentable_area: rows
                  .map(s => Number(s.free_rentable_area))
                  .reduce((acc ,cur) => acc + cur, 0),
                //volume: rows
                //  .map(s => Number(s.volume))
                //  .reduce((acc, cur) => acc + cur, 0),
                //floor_count: rows
                //  .map(s => Number(s.floor_count))
                //  .reduce((acc, cur) => acc + cur, 0),
                buildings_count: rows
                  .map(s => Number(s.buildings_count))
                  .reduce((acc, cur) => acc + cur, 0),
                structures_count: rows
                  .map(s => Number(s.structures_count))
                  .reduce((acc, cur) => acc + cur, 0)
              }
            ]
          }
      }
    },
    async updatePage (value) {
      let oldPage = this.tablePagination.page
      let oldSortBy = this.tablePagination.sortBy[0]
      let oldSortDesc = this.tablePagination.sortDesc[0]
      let oldItemsPerPage = this.tablePagination.itemsPerPage
      if (!this.isLoading) {
        if (value !== undefined) {
          this.tablePagination.page = value.page
          this.tablePagination.itemsPerPage = value.itemsPerPage
          this.tablePagination.sortBy = value.sortBy
          this.tablePagination.sortDesc = value.sortDesc
        }
        if ((oldPage != this.tablePagination.page && this.usePagination) || 
            (value.sortBy[0] != oldSortBy || value.sortDesc[0] != oldSortDesc ||
            value.itemsPerPage != oldItemsPerPage)) {
          this.betweenPageLoad = true
          await this.getDataPerPage(this.currentDate, this.tablePagination.page - 1, this.tablePagination.itemsPerPage, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
          this.betweenPageLoad = false
        } 
      }
    },
    async loadData () {
      if (this.usePagination) {
        await this.getDataPerPage(
          this.currentDate,
          this.tablePagination.page - 1,
          this.tablePagination.itemsPerPage,
          this.searchValues,
          this.tablePagination.sortBy[0],
          this.tablePagination.sortDesc[0]
        )
      } else {
        await this.getAllData(this.currentDate)
      }
    },
    async loadMetadata () {
      const [
        siteMetadata,
        estateMetadata,
        buildingMetadata,
        spaceMetadata,
        customSpaceMetadata,
        unitMetadata,
        carparkMetadata,
        structureMetadata,
        buildingProjectMetadata,
        purposesofuse,
        buildingClassifications
      ] = await Promise.all([
        this.$rambollfmapi.sites.reports().metadata(),
        this.$rambollfmapi.estates.reports().metadata(),
        this.$rambollfmapi.buildings.reports().metadata(),
        this.$rambollfmapi.spaces.reports().metadata(),
        this.$rambollfmapi.customizabledatafields.get(),
        this.$rambollfmapi.units.reports().metadata(),
        this.$rambollfmapi.carparks.reports().metadata(),
        this.$rambollfmapi.structures.reports().metadata(),
        this.$rambollfmapi.buildingProjects.reports().metadata(),
        this.$rambollfmapi.definitions.purposesofuse(),
        this.$rambollfmapi.buildings.buildingClassifications()
      ]);

      this.siteMetadata = siteMetadata
      this.estateMetadata = estateMetadata
      this.buildingMetadata = buildingMetadata
      this.spaceMetadata = spaceMetadata
      this.customSpaceMetadata = customSpaceMetadata
      this.unitMetadata = unitMetadata
      this.carparkMetadata = carparkMetadata
      this.structureMetadata = structureMetadata
      this.buildingProjectMetadata = buildingProjectMetadata
      this.purposesofuse = purposesofuse
      this.buildingClassifications = buildingClassifications 
    },
    async getDataPerPage (date, page, itemsPerPage, search, sortBy, sortDesc) {
      switch (this.reportingLevel) {
        case 'estate':
          this.estates = await this.$rambollfmapi.estates.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'building':
          this.buildings = await this.$rambollfmapi.buildings.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'unit':
          this.units = await this.$rambollfmapi.units.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'space':
          this.spacesData = await this.$rambollfmapi.spaces.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          // Append spaces custom data fields to space (row) data 
          this.spaces = this.addCustomizableDataFieldsForSpaces(this.spacesData)
          break
        case 'carpark':
          this.carparks = await this.$rambollfmapi.carparks.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'structure':
          this.structures = await this.$rambollfmapi.structures.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'building_project':
          this.buildingProjects = await this.$rambollfmapi.buildingProjects.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'technical_value':
          this.technicalValues = await this.$rambollfmapi.technicalValues.reports.perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        case 'technical_value_calculation_repairs':
          this.technicalValueRepairs = await this.$rambollfmapi.technicalValueRepairs.reports.perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
        default:
          this.sites = await this.$rambollfmapi.sites.reports().perPage(date, page, itemsPerPage, search, sortBy, sortDesc)
          break
      }
    },
    async getAllData (date) {
      this.sites = await this.$rambollfmapi.sites.reports().getAll(date)
      this.estates = await this.$rambollfmapi.estates.reports().getAll(date)
      this.buildings = await this.$rambollfmapi.buildings.reports().getAll(date)
      this.spaces = await this.$rambollfmapi.spaces.reports().getAll(date)
      this.spaces = this.addCustomizableDataFieldsForSpaces(this.spaces)
      this.units = await this.$rambollfmapi.units.reports().getAll(date)
      this.structures = await this.$rambollfmapi.structures.reports().getAll(date)
      this.buildingProjects = await this.$rambollfmapi.buildingProjects.reports().getAll(date)
      if (this.hasApplicationPermissionByName('TEKNISET_ARVOT')) {
        this.technicalValues = await this.$rambollfmapi.technicalValues.reports.getAll(date)
        this.technicalValueRepairs  = await this.$rambollfmapi.technicalValueRepairs.reports.getAll(date)
      }
    },
    translateDwgStatus (value) {
      var result = '';

      if (value !== 'none') {
        if (value.indexOf('dwg') !== -1) {
          result = this.$t('DWG')
        } 
        
        if (value.indexOf('xml') !== -1) {
          result = this.$t('OK')
        }

        if (value.indexOf('ifc') !== -1) {
          if (result === '') {
            result = this.$t('IFC-model')
          }
          else if (result !== '') {
            result += ', ' + this.$t('IFC-model')
          }
        }

        if (value.indexOf('pdf') !== -1) {
          if (result === '') {
            result = this.$t('Pdf')
          }
          else if (result !== '') {
            result += ', ' + this.$t('Pdf')
          }
        }

      } else {
        result = this.$t('No DWG')
      }

      return result
    },
    translateBuildingProjectStatus (value) {
      var result = '';
        if (value.indexOf('Proposal') !== -1) {
          result = this.$t('Proposal')
        } 
        if (value.indexOf('ProjectPlanning') !== -1) {
          result = this.$t('ProjectPlanning')
        }
        if (value.indexOf('RequirementInvestigation') !== -1) {
          result = this.$t('RequirementInvestigation')
        }
        if (value.indexOf('ImplementationPlanning') !== -1) {
          result = this.$t('ImplementationPlanning')
        }
        if (value.indexOf('Programmed') !== -1) {
          result = this.$t('Programmed')
        }
        if (value.indexOf('Construction') !== -1) {
          result = this.$t('Construction')
        }
        if (value.indexOf('WarrantyPeriod') !== -1) {
          result = this.$t('WarrantyPeriod')
        }
        if (value.indexOf('Finished') !== -1) {
          result = this.$t('Finished')
        }
        if (value.indexOf('Suspended') !== -1) {
          result = this.$t('Suspended')
        }
        if (value.indexOf('Unknown') !== -1) {
          result = this.$t('Unknown')
        }
      return result
    },
    isNotNullOrZero (data) {
       return data !== 0 && data !== null
    },
    buildHeaders (metadata) {
      if (metadata === null) {
        return []
      }
      let headers = 
        Object.keys(metadata)
        .map(key => {
          return { ...metadata[key], name: key }
        })
        .filter(v => v.isShown)
        .filter(v => v.category === this.category || v.category === 'AdditionalInfo')
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(key => {
          return {
            text: this.getFieldText(key.name),
            value: this.getFieldValue(key.name),
            format: key.format,
            sortable: key.sortable,
            type: this.getFieldType(key),
            hideOverflow: this.getFieldOverflow(key.name),
            isMultiSelect: this.isNotNullOrZero(key.definitionGroupId) || 
              (key === "dwg_status" || key.name === "building_class" || 
               key.name === "building_sub_class" || key.name === "building_main_class" || key.name === 'main_class')
          }
        })
      this.multiSelectKeys = { multiSelectOptionTypes: {}}
      if (this.reportingLevel === 'site') {
        this.multiSelectKeys = {
          dwg_status: [
            { text: this.$t('DWG'), value: 'dwg' },
            { text: this.$t('OK'), value: 'xml' },
            { text: this.$t('IFC-model'), value: 'ifc' },
            { text: this.$t('Pdf'), value: 'pdf' },
            { text: this.$t('No DWG'), value: 'none' },
          ],
          multiSelectOptionTypes: {
            dwg_status: 3
          }
        }
      }
      Object.keys(metadata).forEach(key => {
        if (this.isNotNullOrZero(metadata[key].definitionGroupId))
        {
          this.multiSelectKeys[key] = []
          this.definitionsByGroupId(metadata[key].definitionGroupId).forEach(
            definitionOfGroup => {
              this.multiSelectKeys[key].push({ text: this.$t(definitionOfGroup.label), value: definitionOfGroup.id })
            }
          )
          this.multiSelectKeys[key].push({ text: '-', value: 'NULL' })
          if (this.reportingLevel === 'estate' && key === 'development_plan_proposal') {
            this.multiSelectKeys['multiSelectOptionTypes'][key] = 2
          } else {
            this.multiSelectKeys['multiSelectOptionTypes'][key] = 1
          }
        } else if (key === "building_class" || key === "building_sub_class" || key === "building_main_class" ||
          key === "main_class") {
          this.multiSelectKeys[key] = []
          let classLevel = 0 
          switch(key) {
            case 'building_class' : classLevel = 3; break;
            case 'building_sub_class' : classLevel = 2; break;
            case 'building_main_class': 
            case 'main_class': classLevel = 1; break;
          }
          this.buildingClassifications.
            filter(bc => bc.classificationLevel === classLevel).
            forEach(bc => this.multiSelectKeys[key].push({text: bc.code + " " + bc.nimi, value: bc.id}))
          if (this.multiSelectKeys[key].length > 0) {
            this.multiSelectKeys[key].push({ text: '-', value: 'NULL' })
          }
          this.multiSelectKeys['multiSelectOptionTypes'][key] = 1
        }
      })
      return headers
    },
    buildCustomizableHeaders (metadata, customizabledata) {
      if (metadata === null) {
        return []
      }
      let customHeaders = []
      let spaceHeaders = Object.keys(metadata)
        .map(key => {
          return { ...metadata[key], name: key }
        })
        .filter(v => v.isShown)
        .filter(v => v.category === this.category)
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(key => {
          return {
            text: this.getFieldText(key.name),
            value: this.getFieldValue(key.name),
            format: key.format,
            isMultiSelect: key.name === "purpose_of_use"
          }
        })
      this.multiSelectKeys = {}
      this.multiSelectKeys['multiSelectOptionTypes'] = []
      Object.keys(metadata).forEach(key => {
        if (key === "purpose_of_use")
        {
          this.multiSelectKeys[key] = []
          this.purposesofuse.forEach(purposeofuse => { 
            this.multiSelectKeys[key].push({ text: purposeofuse.title, value: purposeofuse.id })
          })
          this.multiSelectKeys[key].push({ text: '-', value: 'NULL' })
          this.multiSelectKeys['multiSelectOptionTypes'][key] = 1
        }
      })

        // build headers for customizable data columns
        customizabledata.items.forEach(e => {
          var obj = {}
          obj['text'] = e.name
          obj['value'] = 'custom_'+e.id  // value name from id
          obj['sortable'] = true
          if(e.enabled === true)
          {
            customHeaders.push(obj)
          }
          })
        // custom headers after normal ones
        let result = [...spaceHeaders, ...customHeaders]
        return result
    },
    getFieldText (field) {
      // guess the name
      const name = field.split('_').join(' ')
      switch (field) {
        case 'ktv_undefined':
          return 'KTV - ' + this.$t('ktv.undefined')
        case 'id':
          return this.$t('Unit Id')
        case 'integration_region':
          return this.$t('Region')
        case 'region':
          return this.$t('Region')
        case 'unit_code':
          return this.$t('Unit')
        case 'alv':
          return this.$t('VAT-responsibility')
        case 'usage':
          return this.$t('Unit usage')
        case 'tags':
          return this.$t('Category')
      }

      if (this.reportingLevel === 'building_project') {
        switch (field) {
          case 'external_url':
            return this.$t('ExternalUrl')
          case 'additional_info':
            return this.$t('building_projects.additional_info')
          case 'strategy':
            return this.$t('building_projects.strategy')
          case 'gross_area':
            return this.$t('building_projects.gross_area')
          case 'net_area':
            return this.$t('building_projects.net_area')
          case 'implementation_type':
            return this.$t('building_projects.implementation_type')
          case 'is_allocated':
            return this.$t('building_projects.is_allocated')
          case 'order_system':
            return this.$t('building_projects.order_system')
          case 'estimated_rent':
            return this.$t('building_projects.estimated_rent')
        }
      }

      // Otherwise use the default name
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    getFieldValue (field) {
      switch (field) {
        case 'integration_region':
          return this.reportingLevel === 'site' ? 'integration_region' : field
        case 'region':
          return this.reportingLevel === 'site' ? 'region' : field
        case 'maintenance':
          return 'maintenance'
        case 'alv':
          return 'vat_responsibility'
      }

      if (this.reportingLevel === 'building_project') {
        switch (field) {
          case 'tilausjärjestelma':
            return 'order_system'
          case 'vaistotila':
            return 'temporary_relocation'
          case 'bruttoala':
            return 'gross_area'
          case 'strategia':
            return 'strategy'
          case 'kuvaus':
            return 'description'
        }
      }

      // Otherwise use the default value
      return field
    },
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? this.$t(def.label) : this.$t('Undefined')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
      this.confirmation = false
    },
    showDefaultSearchDialog () {
      this.showDefaultSearch = !this.showDefaultSearch
      this.confirmation = false
    },
    closeViewAndSelectionDialogs () {
      if (this.showDefaultSearch) {
        this.showDefaultSearchDialog()
      }
      if (this.showColumnsFilter) {
        this.showColumnsFilterDialog()
      }
    },
    exportAs (documentType, reportLevel) {
      this.exporting = {}
      this.exporting.documentType = documentType

      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push(reportLevel)
    },
    getUnitHeaders () {     
      var headers = [
        { value: 'site_name', text: this.$t('Site name') },
        { value: 'site_identifier', text: this.$t('Site identifier') },
        { value: 'building_name', text: this.$t('Building name') },
        { value: 'building_code', text: this.$t('Building code') },
        ...this.buildHeaders(this.unitMetadata)
      ]
      headers.filter(
        header => header.text == 'Additional info').forEach(
          (header) => { header.hideOverflow = true }
      )
      headers.filter(
        header => header.text == 'Permission definition').forEach(
          (header) => { header.hideOverflow = true }
      )
      if (headers.find(h => h.value === 'rental_status')) {
        const endHeaders = headers.filter(h => h.value === 'market_rent' || h.value === 'estimated_ti_expense' || h.value === 'clarified_ti_expense')
        headers = headers.filter(h => h.value !== 'rental_status' && h.value !== 'market_rent' && h.value !== 'estimated_ti_expense' && h.value !== 'clarified_ti_expense')
          .concat([  
            { value: 'unit_status', text: this.$t('Unit rental status') },
            { value: 'contract_party', text: this.$t('Contract party') },
            { value: 'validity', text: this.$t('Validity') },
            { value: 'signatureDate', text: this.$t('Contract date'), format: 'Date' },
            { value: 'curPartyStartDate', text: this.$t('Contract start date'), format: 'Date' },
            { value: 'contractFirstPossibleEndDate', text: this.$t('Contract first possible end date'), format: 'Date' },
            { value: 'curPartyEndDate', text: this.$t('Contract end date'), format: 'Date'},
            { value: 'tenantFirstPossibleEndDate', text: this.$t('rental_status.tenant_first_possible_end_date'), format: 'Date' },
            { value: 'tenantNotice', text: this.$t('Agreement notice period (Tenant)') },
            { value: 'landlordFirstPossibleEndDate', text: this.$t('rental_status.landlord_first_possible_end_date'), format: 'Date' },
            { value: 'landlordNotice', text: this.$t('Agreement notice period (Lanlord)') },
            { value: 'huom', text: this.$t('Validity notice') },
            { value: 'contract_note', text: this.$t('Contract details') },
            { value: 'contract_type', text: this.$t('leasing.contract_type') },
            { value: 'industry', text: this.$t('leasing.industry') },            
            { value: 'contract_status', text: this.$t('Contract status') },
            { value: 'capitalRent', text: this.$t('Capital rent') },
            { value: 'maintenanceRent', text: this.$t('Maintenance rent') },
            { value: 'maintenanceRentEqualization', text: this.$t('rental_status.maintenance_rent_equalization') },
            { value: 'changeoverRent', text: this.$t('rental_status.changeover_rent') },
            { value: 'separateAllowances', text: this.$t('rental_status.separate_allowances') },
            { value: 'discounts', text: this.$t('rental_status.discounts') },
            { value: 'openBillsTotal', text: this.$t('rental_status.open_bills_total') },
            { value: 'basicRent', text: this.$t('rental_status.basic_rent') },
            { value: 'rentReviewType', text: this.$t('rental_status.rent_review_type') },
            { value: 'indexType', text: this.$t('rental_status.index_type') },
            { value: 'basicIndexPointNumber', text: this.$t('rental_status.basic_index_point_number') },
            { value: 'reviewMonths', text: this.$t('rental_status.review_months') },
            { value: 'latestIndexYear', text: this.$t('rental_status.latest_index_year') },
            { value: 'latestIndexMonth', text: this.$t('rental_status.latest_index_month') },
            { value: 'latestIndexPointNumber', text: this.$t('rental_status.latest_index_point_number') },
            { value: 'minimumIncrease', text: this.$t('rental_status.minimum_increase'), format: 'Percentage' }          
        ])
        headers = [...headers, ...endHeaders]
      }
      return headers
    },
    getCarparkHeaders () {
      let headers = [
        { value: 'site_name', text: this.$t('Site name') },
        { value: 'site_identifier', text: this.$t('Site identifier') },
        { value: 'building_name', text: this.$t('Building name') },
        { value: 'building_code', text: this.$t('Building code') },
        ...this.buildHeaders(this.carparkMetadata)
      ]
      // If metadata headers has contract value, also add contract headers
      if (headers.find(h => h.value === 'contract')) {
        // Headers from carpark metadata to be ignored. Start/end date will instead refer to contract start and end
        const ignoredHeaders = [
          'start_date',
          'end_date',
          'type'
        ]
        // Get typeHeader to move it elsewhere
        const typeHeader = headers.find(h => h.value === 'type')
        headers = headers.filter(h => !(ignoredHeaders.includes(h.value)))
        const contractHeaders = [  
          { text: this.$t('Contract area'), value: 'contract_area', format: 'Area' },
          { text: this.$t('Contract number'), value: 'contract_number' },
          { text: this.$t('dashboard.vat_responsibility'), value: 'vat_responsibility' },
          { text: this.$t('Tenant'), value: 'tenant' },
          { text: this.$t('Contract party'), value: 'contract_party' },
          { text: this.$t('Validity'), value: 'validity' },
          { text: this.$t('Contract date'), value: 'signature_date', format: 'Date' },
          { text: this.$t('Contract start date'), value: 'start_date', format: 'Date' },
          { text: this.$t('Contract first possible end date'), value: 'first_possible_end_date', format: 'Date' },
          { text: this.$t('Contract end date'), value: 'end_date', format: 'Date' },
          { text: this.$t('rental_status.tenant_first_possible_end_date'), value: 'tenant_first_possible_end_date', format: 'Date' },
          { text: this.$t('Agreement notice period (Tenant)'), value: 'tenant_notice', format: 'Number' },
          { text: this.$t('rental_status.landlord_first_possible_end_date'), value: 'landlord_first_possible_end_date', format: 'Date' },
          { text: this.$t('Agreement notice period (Lanlord)'), value: 'landlord_notice', format: 'Number' },
          { text: this.$t('Validity notice'), value: 'validity_note' },
          { text: this.$t('leasing.contract_type'), value: 'contract_type' },
          { text: this.$t('Industry'), value: 'industry' },
          { text: this.$t('Contract status'), value: 'contract_status' },
          { text: this.$t('Capital rent'), value: 'capital_rent', format: 'Euro' },
          { text: this.$t('Maintenance rent'), value: 'maintenance_rent', format: 'Euro' },
          { text: this.$t('rental_status.maintenance_rent_equalization'), value: 'maintenance_rent_equalization', format: 'Euro' },
          { text: this.$t('rental_status.changeover_rent'), value: 'changeover_rent', format: 'Euro' },
          { text: this.$t('rental_status.separate_allowances'), value: 'separate_allowances', format: 'Euro' },
          { text: this.$t('rental_status.discounts'), value: 'discounts', format: 'Euro' },
          { text: this.$t('BasicRent'), value: 'basic_rent', format: 'Euro' },
          { text: this.$t('rental_status.rent_review_type'), value: 'rent_review_type' },
          { text: this.$t('rental_status.index_type'), value: 'index_type' },
          { text: this.$t('rental_status.basic_index_point_number'), value: 'basic_index_point_number', format: 'Number' },
          { text: this.$t('rental_status.review_months'), value: 'review_months' },
          { text: this.$t('rental_status.latest_index_year'), value: 'latest_index_year', format: 'Number' },
          { text: this.$t('rental_status.latest_index_month'), value: 'latest_index_month', format: 'Number' },
          { text: this.$t('rental_status.latest_index_point_number'), value: 'latest_index_point_number', format: 'Number' },
          { text: this.$t('rental_status.minimum_increase'), value: 'minimum_increase', format: 'Percentage' }
        ]
        // if metadata includes type-header, mix it into contractHeaders
        if (typeHeader) {
          contractHeaders.splice(contractHeaders.findIndex(h => h.value === 'tenant'), 0, typeHeader)
        }
        // Splice contractheaders in place of contractHeader
        const contractHeaderIndex = headers.findIndex(h => h.value === 'contract')
        headers.splice(contractHeaderIndex, 1, ...contractHeaders)
      }

      if(!SHOW_CARPARK_AREA){
        return headers.filter(header => header.value !== "area")
      }

      return headers
    },
    getBuildingProjectHeaders () {
      let headers = [
        { value: 'site_name', text: this.$t('Site name'), format: 'None', sortable: true },
        { value: 'site_identifier', text: this.$t('Site identifier'), format: 'None', sortable: true },
        { value: 'building_name', text: this.$t('Building name'), format: 'None', sortable: true },
        ...this.buildHeaders(this.buildingProjectMetadata),
      ]

      const headerTextMap = {
        "Landlord": this.$t('Owner'),
        "Ownership relation": this.$t('Buildingproject ownership relation'),
        [this.$t('building_projects.additional_info')]: true,
        [this.$t('building_projects.strategy')]: true,
        "Description": true
      }

      headers.forEach(header => {
        if (headerTextMap[header.text]) {
          if (headerTextMap[header.text] === true) {
            header.hideOverflow = true
          } else {
            header.text = headerTextMap[header.text]
          }
        }
      })

      return headers
    },
    getTechnicalValueHeaders () {
      let headers = [
        { value: 'site_identifier', text: this.$t('Site identifier') },
        { value: 'site_name', text: this.$t('Site name') },
        { value: 'site_status', text: this.$t('Site status') },
        { value: 'building_code', text: this.$t('Building code') },
        { value: 'building_identifier', text: this.$t('Building identifier') },
        { value: 'building_name', text: this.$t('Building name') },
        { value: 'life_cycle', text: this.$t('building_life_cycle') },
        { value: 'governance_entity', text: this.$t('Governance entity') },
        { value: 'building_usage', text: this.$t('Building usage') },
        { value: 'building_exact_usage', text: this.$t('Building exact usage') },
        { value: 'building_net_floor_area', text: this.$t('Building net floor area'), format: 'Area' },
        { value: 'completed_year', text: this.$t('Completed year') },
        { value: 'indexIncreasedPrice', text: this.$t('technicalValues.indexIncreasedPrice'), format: 'Money' },
        { value: 'technicalValue', text: this.$t('technicalValues.technicalValue'), format: 'Money' },
        { value: 'adjustedRepairCost', text: this.$t('technicalValues.adjustedRepairCost'), format: 'Money' },
        { value: 'conditionPercentage', text: this.$t('technicalValues.conditionPercentage'), format: 'PercentageFromDecimal' },
        { value: 'repairDebt75', text: this.$t('technicalValues.repairDebt75'), format: 'Money' },
        { value: 'repairNeed60', text: this.$t('technicalValues.repairNeed60'), format: 'Money' },
      ]

      for (let i = 0; i < 10; i++) {
        headers.push({
          value: 'predictedTechnicalValues' + i,
          array: 'predictedTechnicalValues',
          valueIndex: i,
          text: `${this.$t('technicalValues.technicalValue')} ${this.currentDate.getFullYear() + i + 1}`,
          valueFromArray: true,
          format: 'Money',
          sortable: false,
          hideFilter: true
        })
      }

      return headers 
    },
    getTechnicalValueRepairsHeaders () {
      let headers = [
        { value: 'site_identifier', text: this.$t('Site identifier') },
        { value: 'site_name', text: this.$t('Site name') },
        { value: 'building_name', text: this.$t('Building name') },
        { value: 'building_address', text: this.$t('technicalValues.buildingAddress') },
        { value: 'life_cycle', text: this.$t('building_life_cycle') },
        { value: 'building_usage', text: this.$t('Building usage') },
        { value: 'building_exact_usage', text: this.$t('Building exact usage') },
        { value: 'internal_building_number', text: this.$t('Building tunniste') },
        { value: 'building_net_floor_area', text: this.$t('Building net floor area'), format: 'Area' },
        { value: 'repair_year', text: this.$t('repair.repairYear') },
        { value: 'repair_type', text: this.$t('repair.repairType') },
        { value: 'IsTotalRepair', text: this.$t('repair.isTotalRepair'), format: 'Boolean', hideFilter: true, sortable: false}, 
        { value: 'original_repair_cost', text: this.$t('repair.originalRepairCost'), format: 'Money'},
        { value: 'RepairCost', text: this.$t('repair.repairCost'), format: 'Money', hideFilter: true, sortable: false},
        { value: 'originalRepairCostHtm2', text: this.$t('repair.originalRepairCostHtm2'), format: 'MoneyPerSquare', hideFilter: true, sortable: false},
        { value: 'indexIncreasedRepairCost', text: this.$t('repair.indexIncreasedRepairCost'), format: 'Money' , hideFilter: true, sortable: false},
        { value: 'indexIncreasedRepairCostHtm2', text: this.$t('repair.indexIncreasedRepairCostHtm2'), format: 'MoneyPerSquare' , hideFilter: true, sortable: false },
        { value: 'technicalValue', text: this.$t('Technical value'), format: 'Money' , hideFilter: true, sortable: false },
        { value: 'technicalValueHtm2', text: this.$t('Technical value htm2'), format: 'MoneyPerSquare' , hideFilter: true, sortable: false },
        { value: 'yearlyDepracation', text: this.$t('technicalValues.yearlyDepreciation'), format: 'Money', hideFilter: true, sortable: false},
      ]
      return headers 
    },
    async filterSearch (query) {
      if (!this.isLoading && this.usePagination) {
        this.searchValues = JSON.stringify(query)
        this.betweenPageLoad = true
        this.tablePagination.page = 1 // set pagination to first page when filter criterias changes
        await this.getDataPerPage(this.currentDate, this.tablePagination.page - 1, this.tablePagination.itemsPerPage, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        this.betweenPageLoad = false
      }
    },
    addCustomizableDataFieldsForSpaces (spaces) {
      const customFields = this.customSpaceMetadata.items.filter(cf => cf.enabled == true)
      const spacesWithCustomInfo = [...spaces].map(row => {
        row.customfield_data.map(cfd => {
          let customField = customFields.find(cf => cf.id == cfd.idField)
          if(customField) {
            let customFieldValue = customField.customizableDataFieldValues.find(cfv => cfv.id === cfd.idValue)
            row['custom_'+customField.id] = customFieldValue.value
          }
          return row
        })
        return row
      })
      return spacesWithCustomInfo
    },
    async loadCarparks () {
      if (!this.usePagination && this.reportingLevel === 'carpark') {
        this.isLoading = true
        this.carparks = await this.$rambollfmapi.carparks.reports().getAll(this.currentDate)
        this.isLoading = false
      }
    },
    async getExportData () {

      if (this.reportingLevel === 'site') {
        const data = await this.$rambollfmapi.sites.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.siteData(data)
      }

      if (this.reportingLevel === 'estate') {
        const data = await this.$rambollfmapi.estates.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.estateData(data)
      }

      if (this.reportingLevel === 'building') {
        const data = await this.$rambollfmapi.buildings.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.buildingData(data)
      }

      if (this.reportingLevel === 'unit') {
        const data = await this.$rambollfmapi.units.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.unitData(data)
      }

      if (this.reportingLevel === 'space') {
        let data = await this.$rambollfmapi.spaces.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        data = this.addCustomizableDataFieldsForSpaces(data)
        return this.spaceData(data)
      }

      if (this.reportingLevel === 'carpark') {
        const data = await this.$rambollfmapi.carparks.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.carparkData(data)
      }

      if (this.reportingLevel === 'structure') {
        const data = await this.$rambollfmapi.structures.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.structureData(data)
      }

      if (this.reportingLevel === 'building_project') {
        const data = await this.$rambollfmapi.buildingProjects.reports().perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return this.structureData(data)
      }

      if (this.reportingLevel === 'technical_value' && this.hasApplicationPermissionByName('TEKNISET_ARVOT')) {
        const data = await this.$rambollfmapi.technicalValues.reports.perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return data
      }

      if (this.reportingLevel === 'technical_value_calculation_repairs' && this.hasApplicationPermissionByName('TEKNISET_ARVOT')) {
        const data = await this.$rambollfmapi.technicalValueRepairs.reports.perPage(this.currentDate, 0, 1000000, this.searchValues, this.tablePagination.sortBy[0], this.tablePagination.sortDesc[0])
        return data
      }

      return []
    },
    getFieldType (field) {
      if (!field) return null;

      let type = null
      switch (field.name) {
        case "tags":
          type = "chipField"
          break;
      }
      return type
    },
    getFieldOverflow (field) {
      switch (field) {
        case "additional_info":
          return true
      }
    },
  }
}
</script>
<style scoped>
</style>
