<template>
  <div id="serviceDialog">
    <v-dialog
      v-model="uploadDialogVisible"
      persistent
      max-width="550"
    >
      <FileUploadForm
        :document="document"
        :type="'Request Attachment'"
        :mode="uploadMode"
        @cancel="uploadDialogVisible = false"
        @created="created"
      />
    </v-dialog>
    <BaseModal
      @cancel="emit"
    >
      <template #popupButton>
        <v-btn
          large
          icon
          absolute
          right
          style="margin-top: 0.8em; margin-right: 4em;"
          @click="openWindow(); emit()"
        >
          <v-icon>open_in_new</v-icon>
          <span class="d-sr-only">{{ $t('OpenInNewWindow') }}</span>
        </v-btn>
      </template>
      <template #title>
        {{ $t('New service request') }}:
        <br>
        {{ $t(requestTypes[requestType].title) }}
      </template>
      <template #content>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col class="section-textfield">
            <h4 class="text--secondary">
              {{ $t('Service request') }}
            </h4>
          </v-col>
          <v-text-field
            v-model="title"
            :label="$t('Title')"
            required
            number
            counter="80"
            class="form-field"
            :rules="[rules.required, rules.counter80]"
            :hint="$t('Required')"
            persistent-hint
          />
          <v-textarea
            v-model="details"
            class="form-field"
            :label="$t('Description')"
            counter="400"
            :rules="[rules.counter400]"
          />
          <br>
          <v-divider />
          <br>
          <template>
            <v-col class="section-textfield">
              <h4 class="text--secondary">
                {{ $t('Location') }}
              </h4>
            </v-col>
            <!-- SITES -->
            <v-autocomplete
              v-model="selectedSite"
              :disabled="allSites.length === 0"
              clearable
              :label="$t('Site')"
              :items="allSites"
              item-text="name"
              hide-details
              return-object
              :placeholder="$t('Site')"
            />
            <v-spacer />
            <!-- BUILDINGS -->
            <v-autocomplete
              v-model="selectedBuilding"
              :disabled="!selectedSite || allBuildings.length === 0"
              clearable
              :label="$t('Building')"
              :items="allBuildings"
              item-text="building_name"
              hide-details
              return-object
              :placeholder="$t('Building')"
            />
            <!-- FLOORS -->
            <v-autocomplete
              v-model="selectedFloor"
              :disabled="!selectedBuilding || allFloors.length === 0"
              clearable
              :label="$t('Floor')"
              :items="allFloors"
              item-text="floor_name"
              hide-details
              return-object
              :placeholder="$t('Floor')"
            />
            <!-- UNITS -->
            <v-autocomplete
              v-model="selectedUnits"
              :disabled="!selectedFloor || allUnits.length === 0"
              :label="$t('Units')"
              :items="allUnits"
              item-text="unit_code"
              multiple
              clearable
              small-chips
              hide-details
              return-object
              :placeholder="$t('Units')"
            >
              <template #selection="data">
                <v-chip
                  :key="JSON.stringify(data.id)"
                  class="v-chip--select-multi"
                  close
                  @click.stop
                  @click:close="removeUnit(data.item.id)"
                  @input="data.parent.selectItem(data.item)"
                >
                  <!-- {{allUnits[data.index].unit_code}} -->
                  {{ data.item.unit_code }}
                </v-chip>
              </template>
            </v-autocomplete>
            <!-- SPACES -->
            <v-autocomplete
              v-model="selectedSpaces"
              :disabled="!selectedUnits || allSpaces.length === 0"
              :label="$t('Spaces')"
              :items="allSpaces"
              item-text="name"
              multiple
              clearable
              small-chips
              hide-details
              return-object
              :placeholder="$t('Spaces')"
            >
              <template #selection="data">
                <v-chip
                  :key="JSON.stringify(data.id)"
                  class="v-chip--select-multi"
                  close
                  @click.stop
                  @click:close="removeSpace(data.item.id)"
                  @input="data.parent.selectItem(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
            <br>
          </template>
          <div v-if="requestType === 3">
            <v-divider />
            <br>
            <v-col class="section-textfield">
              <h4 class="text--secondary">
                {{ $t('zoan.products_to_order') }}
              </h4>
            </v-col>
            <br>
            <v-checkbox
              v-for="product in zoanProducts"
              :key="product.name"
              v-model="zoanOrder[product.name]"
              class="ma-0 pa-0"
              height="2"
              small
              :label="$t(product.label)"
            />
            <v-divider />
            <br>
            <v-col class="section-textfield">
              <h4 class="text--secondary">
                {{ $t('More info') }}
              </h4>
            </v-col>
            <v-row>
              <v-col cols="6">
                <p class="label-text">
                  {{ $t('zoan.request_schedule') + ":" }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="zoanOrder.request_schedule"
                  type="date"
                  :label="$t('zoan.schedule')"
                />
              </v-col>
            </v-row>
            <v-radio-group
              v-model="zoanOrder.quality"
              row
              class="ma-0 pa-0"
            >
              <template #label>
                <div>{{ $t('zoan.quality') + ":" }}</div>
              </template>
              <v-radio
                :label="$t('zoan.marketing')"
                :value="0"
              />
              <v-radio
                :label="$t('zoan.standard')"
                :value="1"
              />
            </v-radio-group>
            <v-radio-group
              v-model="zoanOrder.furniture"
              row
              class="ma-0 pa-0"
            >
              <template #label>
                <div>{{ $t('zoan.furniture') + ":" }}</div>
              </template>
              <v-radio
                :label="$t('Yes')"
                :value="1"
              />
              <v-radio
                :label="$t('No')"
                :value="0"
              />
            </v-radio-group>
            <v-radio-group
              v-model="zoanOrder.delivery_format"
              row
              class="ma-0 pa-0"
            >
              <template #label>
                <div>{{ $t('zoan.delivery_format') + ":" }}</div>
              </template>
              <v-radio
                v-for="(format, index) in deliveryFormats"
                :key="format"
                :label="format"
                :value="index"
              />
            </v-radio-group>
            <v-textarea
              v-model="zoanOrder.reference_to_invoicing"
              :placeholder="$t('zoan.reference_to_invoicing')"
              counter="400"
              :rules="[rules.counter400]"
            />
          </div>
          <br>
          <v-col v-if="hasApplicationPermissionByName('RAMBOLL_TILAUSDOKUMENTTI') || hasApplicationPermissionByName('DOKUMENTTIEN_HALLINTA')">
            <v-divider />
            <br>
            <v-col class="section-textfield">
              <h4 class="text--secondary">
                {{ $t('Documents') }}
              </h4>
            </v-col>
            <div
              v-for="doc in linkedDocuments"
              :key="doc.idDocument"
            >
              <v-chip
                close
                @input="deleteAttachment(doc)"
              >
                <v-icon left>
                  file_copy
                </v-icon>
                {{ doc.title }}
              </v-chip>
            </div>
            <CdsButton
              type="tertiary"
              @click="uploadDialogVisible = true"
            >
              {{ $t('Attach new document') }}
            </CdsButton>
          </v-col>
        </v-form>
      </template>
      <template #footer>
        <CdsButton
          type="accent"
          :disabled="!valid"
          :loading="saving"
          :loading-text="$t('Loading...')"
          @click="validate"
        >
          {{ $t('Save') }}
        </CdsButton>
      </template>
    </BaseModal>
    <Alert
      :show="showIndicator"
      :result="operationResult"
      :message="indicatorMessage"
    />
  </div>
</template>
<script>
import FileUploadForm from './FileUploadForm'
import Alert from '../components/Alert'
import CdsButton from '../components/CircleDesignSystem/CdsButton.vue'
import helpers from '../helpers'
import { mapGetters, mapState } from 'vuex'
import BaseModal from './general/BaseModal'
export default {
  name: 'ServiceRequestForm',
  components: { FileUploadForm, Alert, BaseModal, CdsButton },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    requestType: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      uploadMode: 'new',
      uploadDialogVisible: false,
      linkedDocuments: [],
      allSites: [],
      allBuildings: [],
      allFloors: [],
      allUnits: [],
      allSpaces: [],
      valid: true,
      selectedSite: null,
      selectedBuilding: null,
      selectedFloor: null,
      selectedUnits: [],
      selectedSpaces: [],
      details: '',
      service: null,
      title: '',
      requestTypes: helpers.requestTypes.types(),
      zoanOrder: {
        floor_plan: false,
        interior_visualization: false,
        exterior_visualization: false,
        presentation_material: false,
        quality: 0,
        request_schedule: null,
        delivery_format: 0,
        furniture: 0,
        reference_to_invoicing: ''
      },
      zoanProducts: [
        { name: 'floor_plan', label: 'zoan.floor_plan' },
        {
          name: 'interior_visualization',
          label: 'zoan.interior_visualization'
        },
        {
          name: 'exterior_visualization',
          label: 'zoan.exterior_visualization'
        },
        { name: 'presentation_material', label: 'zoan.presentation_material' }
      ],
      dateSelection: false,
      saving: false,
      showIndicator: false,
      operationResult: '',
      indicatorMessage: '',
      indicatorRunning: false,
      rules: {
        required: v => !!v || this.$t('Required'),
        counter80: v => v.length <= 80 || this.$t('Maximum length {n} characters', {n: 80}),
        counter400: v => v.length <= 400 || this.$t('Maximum length {n} characters', {n: 400}),
      },
      document: {
        idBuilding: null,
        fileName: '',
        title: '',
        description: '',
        type: '',
        versionDescription: '',
        tags: [],
        idCemetery: null,
        idSection: null,
        idGrave: null
      },
    }
  },
  computed: {
    ...mapGetters('app', ['hasApplicationPermissionByName']),
    ...mapState('app', ['sites']),
    selectedDateAsString () {
      if (this.zoanOrder.request_schedule) {
        const date = new Date(this.zoanOrder.request_schedule)
        return helpers.humanize.date(date.toISOString(), 'date')
      } else {
        return ''
      }
    },
    minimumDate () {
      return new Date().toISOString().substr(0, 10)
    },
    deliveryFormats () {
      return helpers.requestTypes.deliveryFormats()
    }
  },
  watch: {
    isShown: function (shown) {
      this.selectedSite = null
      this.selectedBuilding = null
      this.selectedFloor = null
      this.selectedUnits = []
      this.selectedSpaces = []
      this.linkedDocuments = []
      this.allBuildings = []
      this.allFloors = []
      this.allUnits = []
      this.allSpaces = []
      this.getAllSites()
      this.title = ''
      this.details = ''
      this.zoanOrder = {
        floor_plan: false,
        interior_visualization: false,
        exterior_visualization: false,
        presentation_material: false,
        quality: 0,
        request_schedule: null,
        delivery_format: 0,
        furniture: 0,
        reference_to_invoicing: undefined
      }
    },
    showIndicator: function (value) {
      if (value === true) {
        this.indicatorRunning = true
        setTimeout(() => {
          this.hideIndicator()
        }, 4000)
      }
    },
    selectedSite: function (newSite) {
      this.allBuildings = []
      this.selectedBuilding = null
      if (newSite) {
        this.$rambollfmapi.buildings
          .list({ query: { siteId: newSite.id_site } })
          .then(res => {
            this.allBuildings = res
            if (
              this.$route.name.includes('facilitymanagement') &&
              this.$store.state.app.requestItems.buildings.length === 1
            ) {
              this.selectedBuilding = this.allBuildings.find(
                building =>
                  building.building_code ===
                  this.$store.state.app.requestItems.buildings[0]
              )
            }
          })
      }
    },
    selectedBuilding: function (newBuilding) {
      this.allFloors = []
      this.selectedFloor = null
      if (newBuilding) {
        this.$rambollfmapi.buildings
          .floors(newBuilding.building_code)
          .list()
          .then(res => {
            this.allFloors = res
            if (
              this.$route.name.includes('facilitymanagement') &&
              this.$store.state.app.requestItems.floors.length === 1
            ) {
              this.selectedFloor = this.allFloors.find(
                floor =>
                  floor.id === this.$store.state.app.requestItems.floors[0]
              )
            }
          })
      }
    },
    selectedFloor: function (newFloor) {
      // UNITS
      this.allUnits = []
      this.selectedUnits = []
      if (newFloor) {
        this.$rambollfmapi.floors
          .units(newFloor.id)
          .list()
          .then(res => {
            this.allUnits = res
            if (
              this.$route.name.includes('facilitymanagement') &&
              this.$store.state.app.requestItems.units.length > 0
            ) {
              this.selectedUnits = this.allUnits.filter(unit =>
                this.$store.state.app.requestItems.units.includes(unit.id)
              )
            }
          })
      }
      // SPACES
      this.allSpaces = []
      this.selectedSpaces = []
      if (newFloor) {
        this.$rambollfmapi.floors
          .spaces(newFloor.id)
          .list()
          .then(res => {
            this.allSpaces = res
            if (
              this.$route.name.includes('facilitymanagement') &&
              this.$store.state.app.requestItems.spaces.length > 0
            ) {
              this.selectedSpaces = this.allSpaces.filter(space =>
                this.$store.state.app.requestItems.spaces.includes(space.id)
              )
            }
          })
      }
    }
  },
  mounted () {
    this.getAllSites()
  },
  methods: {
    openWindow () {
      const w = document.getElementById('serviceDialog').clientWidth
      const h = document.getElementById('serviceDialog').clientHeight + 1

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - w) / 2 / systemZoom
      const top = (height - h) / 2 / systemZoom

      const baseURL = window.location.href.replace(this.$route.path, '')

      var extraURL = '/'
      if (this.selectedSite !== undefined && this.selectedSite !== null) {
        extraURL += this.selectedSite.id_site + ';'
        if (
          this.selectedBuilding !== null &&
          this.selectedBuilding !== undefined
        ) {
          extraURL += this.selectedBuilding.id_building + ';'
          if (this.selectedFloor !== null && this.selectedFloor !== undefined) {
            extraURL += this.selectedFloor.id + ';'
            if (
              this.selectedUnits !== null &&
              this.selectedUnits !== undefined &&
              this.selectedUnits !== []
            ) {
              extraURL += 'units:'
              this.selectedUnits.forEach(unit => {
                extraURL += unit.id + ';'
              })
            }
            if (
              this.selectedSpaces !== null &&
              this.selectedSpaces !== undefined &&
              this.selectedSpaces !== []
            ) {
              extraURL += 'spaces:'

              this.selectedSpaces.forEach(space => {
                extraURL += space.id + ';'
              })
            }
          }
        }
      }
      if (this.linkedDocuments.length > 0) {
        var string = 'doc:'
        this.linkedDocuments.forEach(doc => {
          string += doc.idDocument + ';'
        })
        extraURL += string
      }
      if (this.title !== '' || this.details !== '') {
        var details = this.details.split('\n').join(':nl:')
        extraURL += 'title:' + this.title + ';details:' + details + ';'
      }
      if (extraURL === '/') {
        extraURL += 'null'
      }

      window.open(
        `${baseURL}/servicerequestwindow/${this.requestType + extraURL}`,
        `myWindow${new Date()}`,
        `width=${w}, height=${h}, top=${top}, left=${left}`
      )
    },
    hideIndicator () {
      this.showIndicator = false
      this.indicatorRunning = false
    },
    deleteAttachment (doc) {
      this.$rambollfmapi.documents.delete(doc.idDocument).then(res => {
        this.linkedDocuments = this.linkedDocuments.filter(
          x => x.idDocument !== doc.idDocument
        )
      })
    },
    created (doc) {
      this.uploadDialogVisible = false
      this.linkedDocuments.push(doc)
    },
    emit (event) {
      this.$emit('close', false)
    },
    async validate () {
      if (this.$refs.form.validate()) {
        this.saving = true
        const newRequest = {
          id_type: this.requestType,
          title: this.title,
          versions: [
            {
              state: 0,
              comment: this.details
            }
          ]
        }

        if (this.selectedSite) {
          newRequest.sites = [this.selectedSite.id_site]
        }
        if (this.selectedBuilding) {
          newRequest.buildings = [this.selectedBuilding.id_building]
        }
        if (this.selectedFloor) {
          newRequest.floors = [this.selectedFloor.id]
        }
        if (this.selectedUnits.length > 0) {
          newRequest.units = this.selectedUnits.map(unit => unit.id)
        }
        if (this.selectedSpaces.length > 0) {
          newRequest.spaces = this.selectedSpaces.map(space => space.id)
        }
        if (this.linkedDocuments.length > 0) {
          newRequest.documents = this.linkedDocuments.map(doc => doc.idDocument)
        }

        if (
          this.zoanOrder.reference_to_invoicing &&
          this.zoanOrder.reference_to_invoicing.length > 399
        ) {
          this.zoanOrder.reference_to_invoicing = this.zoanOrder.reference_to_invoicing.slice(
            0,
            399
          )
        }
        if (this.requestType === 3) {
          newRequest.versions[0] = {
            ...newRequest.versions[0],
            ...this.zoanOrder
          }
        }
        try {
          await this.$rambollfmapi.support.servicerequest().post(newRequest)
          this.$emit('close', false)
        } catch {
            this.indicatorMessage = this.$t("Error while saving")
            this.operationResult = 'error'
            this.showIndicator = true
        } 
        
        this.saving = false
      }
    },
    removeUnit (unitId) {
      const unitIdx = this.selectedUnits.findIndex(unit => unit.id === unitId)
      this.selectedUnits.splice(unitIdx, 1)
    },
    removeSpace (spaceId) {
      const spaceIdx = this.selectedSpaces.findIndex(space => space.id === spaceId)
      this.selectedSpaces.splice(spaceIdx, 1)
    },
    getAllSites () {
      const idSite = this.$route.params.id_site
      if (idSite) {
        this.allSites = this.sites.filter(s => s.id_site === Number(idSite))
        this.selectedSite = this.allSites.find(
          site => Number(site.id_site) === Number(idSite)
        )
      } else {
        this.allSites = this.sites
      }
    }
  }
}
</script>
<style scoped>
.v-autocomplete {
  margin-top: 1em !important;
  height: auto !important;
}

.label-text {
  font-size: 16px;
  color: #7c7c7c;
  padding-top: 1.5em;
  margin-bottom: 1.5em;
}
</style>
