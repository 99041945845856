!<template>
  <ViewAndSelections
    :contract-level="contractLevel"
    parent-level="contract"
    :loading-as-prop="loading"
    @close-filter="showColumnsFilterDialog"
  >
    <template #buttons>
      <v-row
        class="toolbar dashboard overflow-x-auto ml-1 mr-1"
        :justify="isMobile ? 'start' : 'end'"
        no-gutters
      >
        <v-btn
          small
          outlined
          rounded
          :disabled="loading"
          @click="showColumnsFilterDialog"
        >
          {{ $t('Choose visible columns') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'pdf'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('pdf')"
        >
          {{ $t('Export to PDF') }}
        </v-btn>
        <v-btn
          small
          outlined
          rounded
          :loading="exporting.documentType == 'csv'"
          :disabled="Object.keys(exporting).length > 0 || loading"
          @click="exportAs('csv')"
        >
          {{ $t('Export to spreadsheet') }}
        </v-btn>
        <ImportButton
          v-if="importing"
          :additional-date-format="true"
          :headers="headers"
          :disabled="loading"
          format="windows-1252"
          @dataUpdate="saveFile"
        />
      </v-row>
      <span v-if="dataUpdateDialog">
        <DataUpdateDialog
          :data-update-dialog="dataUpdateDialog"
          :import-success="importSuccess"
          :rental-status-changes="marketRentChanges"
          :saving-failed="savingFailed"
          :errors="errors"
          :is-saving-changes="isSavingChanges"
          :car-parks="importingCarparks"
          @saveChanges="saveChanges"
          @cancel="dataUpdateDialog = false"
          @ok="handleOk"
        />
      </span>
    </template>
    <template
      #table="props"
    >
      <Table
        v-show="!loading"
        :rows="viewData"
        :headers="headers"
        :hide="true"
        :show-filter="showColumnsFilter"
        :document-type="exportAsDocument"
        :pagination.sync="pagination"
        :show-save-selection="true"
        :selected-view-string="props.props.storedView ? props.props.storedView.name : null"
        :stored-views="props.props.storedViews.filter(sv => sv.level === contractLevel)"
        :show-confirm="props.props.confirmation"
        @exported="exporting = {}"
        @select_view="props.props.selectView"
        @save-selection="props.props.saveStoredView"
        @delete-view="props.props.deleteSelectedView"
      />
    </template>
  </ViewAndSelections>
</template>

<script>
import ImportButton from '../ImportButton.vue'
import DataUpdateDialog from '../DataUpdateDialog.vue'
import Table from '../Table.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import helpers from '../../helpers'
import ViewAndSelections from './ViewAndSelections.vue'

export default {
  name: 'MarketRentContractLevel',
  components: {
    ImportButton,
    DataUpdateDialog,
    Table,
    ViewAndSelections
  },
  props: {
    loading: { type: Boolean, default: false },
    rentalStatuses: { type: Array, default: () => [] },
    futureRentalStatuses: { type: Array, default: () => [] },
    carparks: { type: Array, default: () => [] },
    futureCarparkContracts: { type: Array, default: () => []},
    importing: { type: Boolean, default: false },
    contractLevel: { type: String, default: '' }
  },
  emits: ['refreshRentalData', 'refreshCarparkData'],
  data () {
    return {
      showColumnsFilter: false,
      exportAsDocument: [],
      dataUpdateDialog: false,
      isSavingChanges: false,
      savingFailed: false,
      updatingItems: false,
      importSuccess: false,
      marketRentChanges: [],
      errors: { unexisting: [], uneditable: [] },
      exporting: {},
    }
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'definitionLabelById']),
    isMobile () {
      return visualViewport.width <= 760
    },
    headers () {
      if (this.contractLevel === 'carpark_market_rent') {
        return [
          {
            text: this.$t('Site'),
            value: 'siteName',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Cost center'),
            value: 'site_identifier',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Car park ID'),
            value: 'number',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Market rent €/car space'),
            value: 'market_rent',
            format: 'Euro',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Num of parking privileges'),
            value: 'parking_rights',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Identifier'),
            value: 'carParkId',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Market rent added date'),
            value: 'commercial_info_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Vacancy rate assumption'),
            value: 'vacancy_rate_assumption',
            editable: true,
            type: Number,
            allowNull: true,
            rules: [v => v >= 0 && v <= 100]
          },
          {
            text: this.$t('Vacancy rate assumption updated'),
            value: 'vacancy_rate_assumption_updated',
            format: undefined,
            editable: false,
            ignoreHeader: true
          },
          {
            text: this.$t('Car park identifier'),
            value: 'carParkCodeLong',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Carpark.area'),
            value: 'area',
            format: 'Area',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Carpark version end date'),
            value: 'carpark_version_end_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Carpark version start date'),
            value: 'carpark_version_start_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Latest updater'),
            value: 'commercial_info_user',
            editable: false,
            ignoreHeader: true
          },
          {
            text: this.$t('Rent target'),
            value: 'rent_target',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Lease plan'),
            value: 'lease_plan',
            format: undefined,
            editable: true,
            type: undefined
          },
          {
            text: this.$t('Lease plan updated'),
            value: 'lease_plan_updated',
            format: undefined,
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'currentTenantName', 
            text: this.$t('Tenant'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'nextCarParkContractNumber', 
            text: this.$t('Next party contract no.'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'contractNumber', 
            text: this.$t('Contract no.'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'capitalRent', 
            text: this.$t('Capital rent (€/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'capitalRentPerSquare', 
            text: this.$t('Capital rent (€/m2/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'contractType', 
            text: this.$t('leasing.contract_type'),
            format: 'Translation',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'validity', 
            text: this.$t('Validity'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'signature_date', 
            text: this.$t('Contract date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'currentContractStartDate', 
            text: this.$t('Contract start date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'currentContractEndDate', 
            text: this.$t('Contract end date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
        ]
      }
      if (this.contractLevel === 'market_rent') {
        return [
          {
            text: this.$t('Site'),
            value: 'siteName',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Cost center'),
            value: 'site_identifier',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Building code'),
            value: 'buildingCode',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit'),
            value: 'unitName',
            editable: false,
            type: undefined
          },
          {
            text: this.$t('Unit code long'),
            value: 'unit_code_long',
            editable: false,
            type: undefined,
            ignoreHeader: true 
          },
          {
            text: this.$t('Unit area'),
            value: 'area',
            format: 'Area',
            editable: false,
            type: undefined,
            ignoreHeader: true 
          },
          {
            text: this.$t('Unit Status'),
            value: 'unit_status',
            editable: false,
            type: undefined,
            ignoreHeader: true 
          },
          {
            text: this.$t('Market rent'),
            value: 'market_rent',
            format: 'Euro',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Market rent added date'),
            value: 'commercial_info_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Estimated TI Expense'),
            value: 'estimated_ti_expense',
            format: 'MoneyPerSquare',
            editable: false,
            type: Number,
            ignoreHeader: true
          },
          {
            text: this.$t('Clarified TI Expense'),
            value: 'clarified_ti_expense',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Vacancy rate assumption'),
            value: 'vacancy_rate_assumption',
            editable: true,
            type: Number,
            allowNull: true,
            rules: [v => v >= 0 && v <= 100]
          },
          {
            text: this.$t('Vacancy rate assumption updated'),
            value: 'vacancy_rate_assumption_updated',
            format: undefined,
            editable: false,
            ignoreHeader: true
          },
          {
            text: this.$t('Unit version end date'),
            value: 'unit_version_end_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Unit version start date'),
            value: 'unit_version_start_date',
            format: 'Date',
            editable: false,
            type: undefined,
            ignoreHeader: true
          },
          {
            text: this.$t('Latest updater'),
            value: 'commercial_info_user',
            editable: false,
            ignoreHeader: true
          }, 
          {
            text: this.$t('Rent target'),
            value: 'rent_target',
            format: 'MoneyPerSquare',
            editable: true,
            type: Number
          },          
          {
            text: this.$t('List rent apartment'),
            value: 'list_rent_apartment',
            format: 'Euro',
            editable: true,
            type: Number
          },
          {
            text: this.$t('Lease plan'),
            value: 'lease_plan',
            format: undefined,
            editable: true,
            type: undefined
          },
          {
            text: this.$t('Lease plan updated'),
            value: 'lease_plan_updated',
            format: undefined,
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'curPartyName', 
            text: this.$t('Tenant'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'nextPartyContractNumber', 
            text: this.$t('Next party contract no.'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'contractNumber', 
            text: this.$t('Contract no.'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'capitalRent', 
            text: this.$t('Capital rent (€/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'capitalRentPerSquare', 
            text: this.$t('Capital rent (€/m2/mth)'),
            format: 'Euro',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'contractType', 
            text: this.$t('leasing.contract_type'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'validity', 
            text: this.$t('Validity'),
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'signature_date', 
            text: this.$t('Contract date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'curPartyStartDate', 
            text: this.$t('Contract start date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
          { 
            value: 'curPartyEndDate', 
            text: this.$t('Contract end date'),
            format: 'Date',
            editable: false,
            ignoreHeader: true
          },
        ]
      }
      return []
    },
    viewData () {
      if (this.contractLevel === 'carpark_market_rent') {
        return this.getCarparkMarketRentData()
      }
      if (this.contractLevel === 'market_rent') {
        return this.getUnitMarketRentData()
      }
      return []
    },
    pagination () {
      let sortBy = ['buildingCode']
      if (this.contractLevel === 'carpark_market_rent') {
        sortBy = ['siteName']
      }
      return {
        sortDesc: [ false ],
        itemsPerPage: 100,
        sortBy, 
      }
    },
    importingCarparks () {
      return this.contractLevel === 'carpark_market_rent'
    }
  },
  methods: {
    ...mapActions('dashboard', ['updateUnitInformation']),
    exportAs (documentType) {
      this.exporting = {}
      this.exporting.documentType = documentType
      
      this.exportAsDocument = []
      this.exportAsDocument.push(documentType)
      this.exportAsDocument.push('market_rent_contract')
    },
    showColumnsFilterDialog () {
      this.showColumnsFilter = !this.showColumnsFilter
    },
    saveFile (results) {
      this.dataUpdateDialog = true
      this.updatingItems = true
      const data = {
        items: this.viewData,
        headers: this.headers
      }
      // find correct identifier indexes
      let firstIdentifier = null
      let secondIdentifier = null
      if (this.contractLevel === 'market_rent') {
        firstIdentifier = this.headers.findIndex(h => h.value === 'buildingCode')
        secondIdentifier = this.headers.findIndex(h => h.value === 'unitName')
      }
      else {
        firstIdentifier = this.headers.findIndex(h => h.value === 'carParkId')
      }

      const items = helpers.dataTable.updateItems(data, results.data, firstIdentifier, secondIdentifier, this.importingCarparks)
      this.importSuccess = items.success
      this.errors = { unexisting: [], uneditable: [] },
      this.marketRentChanges = []
      if (this.importSuccess) {
        this.handleUpdate(items.items, data.items, data.headers)
      } else {
        this.errors = items.errors
      }
      this.savingFailed = false
      this.isSavingChanges = false
      this.updatingItems = false
    },
    handleUpdate (items, data, headers) {
      const changedReports = []
      // detect changed rows
      data.forEach(report => {
        // building code and unit name is used to identify changed information
        const modifiedItem = items.find(item => {
          if (this.importingCarparks) {
            return (
              item.carParkId === report.carParkId &&
              ((item.market_rent !== null &&
              (Number(String(item.market_rent).replace(',', '.')) !==
                report.market_rent)) ||
              (item.rent_target !== null &&
              (Number(String(item.rent_target).replace(',', '.')) !==
                report.rent_target)) ||
              (item.lease_plan !== null && item.lease_plan !== report.lease_plan) ||
              (item.vacancy_rate_assumption !== null && // check if value is between 0 and 100 and not equal to original value
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) >= 0 &&
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) <= 100 &&
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) !== report.vacancy_rate_assumption) ||
              (item.vacancy_rate_assumption === null &&
                item.vacancy_rate_assumption !== report.vacancy_rate_assumption))
            )
          }
          else {
            return (
              item.buildingCode === report.buildingCode &&
              item.unitName === report.unitName &&
              ((item.market_rent !== null &&
              (Number(String(item.market_rent).replace(',', '.')) !==
                report.market_rent)) ||
              (item.estimated_ti_expense !== null &&
              (Number(String(item.estimated_ti_expense).replace(',', '.')) !==
                report.estimated_ti_expense)) ||
              (item.clarified_ti_expense !== null &&
              (Number(String(item.clarified_ti_expense).replace(',', '.')) !==
                report.clarified_ti_expense)) ||
              (item.rent_target !== null &&
              (Number(String(item.rent_target).replace(',', '.')) !==
              report.rent_target)) ||
              (item.lease_plan !== null && item.lease_plan !== report.lease_plan) ||
              (item.list_rent_apartment !== null && (Number(String(item.list_rent_apartment).replace(',', '.')) !==
              report.list_rent_apartment)) ||
              (item.vacancy_rate_assumption !== null && // check if value is between 0 and 100 and not equal to original value
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) >= 0 &&
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) <= 100 &&
                Number(String(item.vacancy_rate_assumption).replace(',', '.')) !== report.vacancy_rate_assumption) ||
              (item.vacancy_rate_assumption === null &&
                item.vacancy_rate_assumption !== report.vacancy_rate_assumption)) 
            )
          }
        })
        if (modifiedItem) {
          let errorObject = {}
          if (this.importingCarparks) {
            errorObject = {
              siteName: modifiedItem.siteName,
              number: modifiedItem.number
            }
          }
          else {
            errorObject = {
              buildingCode: modifiedItem.buildingCode,
              unitName: modifiedItem.unitName
            }
          }
          let validMarketRent = true
          // validate input fields that they are in correct format
          headers.forEach(header => {
            let isNotCorrect
            if (modifiedItem[header.value]) {
              if (header.type === Number) {
                // export function converts all decimal dots to commas, convert them back
                modifiedItem[header.value] = String(
                  modifiedItem[header.value]
                ).replace(',', '.')
                // ignore NaN notice periods
                isNotCorrect = isNaN(Number(modifiedItem[header.value]))
              }
              if (header.rules) {
                isNotCorrect = header.rules.some(rule => !rule(modifiedItem[header.value]))
              }

              if (isNotCorrect) {
                this.errors.uneditable.push({
                  ...errorObject,
                  value: modifiedItem[header.value],
                  label: header.text
                })
                // return original value
                modifiedItem[header.value] = report[header.value]
                if (this.importingCarParks) {
                  validMarketRent = false
                }
              }
            }
            if (header.type === Number) {
              // export function converts all numbers to strings, convert them back to numbers if needed
              if (header.allowNull && modifiedItem[header.value] === '') {
                // if value is empty and header allows null, set it to null
                modifiedItem[header.value] = null
              } else {
                modifiedItem[header.value] = modifiedItem[header.value]
                  ? Number(modifiedItem[header.value])
                  : report[header.value]
              }
            }
            // if editable header has same value in modifieditem obj and report obj remove 
            // it to not send it as updated value in saveMarketRentChanges method
            if (header.editable && modifiedItem[header.value] === report[header.value]) {
              delete modifiedItem[header.value]
            }
          })
          // Add modified items to changed reports array
          if (validMarketRent) {
            changedReports.push(modifiedItem)
          }          
        }
      })
      this.marketRentChanges = changedReports
    },
    async saveChanges () {
      const time = new Date().toISOString().slice(0, 10)
      this.isSavingChanges = true
      for (const marketRentChange of this.marketRentChanges) {
        const patchObj = []
        if(marketRentChange.market_rent !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/market_rent',
            value: marketRentChange.market_rent
        })
        }
        if(marketRentChange.rent_target !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/rent_target',
            value: marketRentChange.rent_target
          })
        }
        if(marketRentChange.lease_plan !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/lease_plan',
            value: marketRentChange.lease_plan
          })
        }
        if(marketRentChange.vacancy_rate_assumption !== undefined) {
          patchObj.push({
            op: 'replace',
            path: '/vacancy_rate_assumption',
            value: marketRentChange.vacancy_rate_assumption
          })
        }
        if (!this.importingCarparks) {
          if(marketRentChange.estimated_ti_expense !== undefined) {  
            patchObj.push({
              op: 'replace',
              path: '/estimated_ti_expense',
              value: marketRentChange.estimated_ti_expense
            })
          }
          if(marketRentChange.clarified_ti_expense !== undefined) {
            patchObj.push({
              op: 'replace',
              path: '/clarified_ti_expense',
              value: marketRentChange.clarified_ti_expense
            })
          }
          if(marketRentChange.list_rent_apartment !== undefined) {
            patchObj.push({
              op: 'replace',
              path: '/list_rent_apartment',
              value: marketRentChange.list_rent_apartment
            })
          }
        }
        try {
          if (patchObj.length > 0) {
            if (this.importingCarparks) {
              const data = {
                carParkId: marketRentChange.carParkId,
                patch: patchObj
              }
              await this.$rambollfmapi.carparks.marketRents().patch(time, [data])     
            }
            else {
              const data = {
                unitId: marketRentChange.unitId,
                time: time,
                patch: patchObj
              }
              await this.updateUnitInformation(data)
            }
          }     
        } catch {
          this.savingFailed = true
          this.resetChanges()
          this.dataUpdateDialog = true
        }
      }
      if (this.savingFailed === false) {
        this.resetChanges()
      }
      if (this.importingCarparks) {
        this.$emit('refreshCarparkData')
      }
      else {
        this.$emit('refreshRentalData')
      }
    },
    resetChanges () {
      this.isSavingChanges = false
      this.updatingItems = false
      this.dataUpdateDialog = false
      this.marketRentChanges = []
    },
    handleOk () {
      this.dataUpdateDialog = false
    },
    getUnitMarketRentData () {
        const totalAreaByContractNumber = this.rentalStatuses.reduce((acc, curr) => {
        if (curr.contractNumber) {
          if (acc[curr.contractNumber]) {
            acc[curr.contractNumber] += curr.area
          } else {
            acc[curr.contractNumber] = curr.area
          }
        }
        return acc
      }, {})

      // Map future market rent data to objects for fast access
      const futureUnitContracts = Object.fromEntries(this.futureRentalStatuses.map(item => [item.unitId, item.contractNumber]))

      const rentalDefs = this.definitionsByGroupLabel('unit.notrentable')
      const rentableDefs = rentalDefs.filter(x => x.label.indexOf('notrentable') === -1).map(x => x.id)

      return this.rentalStatuses.filter(s => rentableDefs.includes(s.unitStatus)).map(rs => {

        const capitalRent =  totalAreaByContractNumber[rs.contractNumber] ? rs.capitalRent * rs.area / totalAreaByContractNumber[rs.contractNumber] : null
        const unitStatus = this.definitionLabelById(rs.unitStatus)

        return {
          siteName: rs.siteName,
          unitId: rs.unitId,
          unitName: rs.unitName,
          buildingCode: rs.buildingCode,
          site_identifier: rs.site_identifier,
          market_rent: rs.market_rent,
          commercial_info_date: rs.commercial_info_date,
          siteIdentifier: rs.siteId,
          unitIdentifier: rs.unitId,
          estimated_ti_expense: rs.estimated_ti_expense,
          unit_code_long: rs.unit_code_long,
          area: rs.area,
          unit_status: this.$t(unitStatus),
          clarified_ti_expense: rs.clarified_ti_expense,
          vacancy_rate_assumption: rs.vacancy_rate_assumption,
          vacancy_rate_assumption_updated: rs.vacancy_rate_assumption_user && rs.vacancy_rate_assumption_date ? rs.vacancy_rate_assumption_user + ', ' + moment(rs.vacancy_rate_assumption_date).format('DD.MM.YYYY') : '',
          unit_version_end_date: rs.curUnitEndDate,
          unit_version_start_date: rs.curUnitStartDate,
          commercial_info_user: rs.commercial_info_user,
          rent_target: rs.rent_target,
          lease_plan: rs.lease_plan,
          lease_plan_updated: rs.lease_plan_user && rs.lease_plan_date ? rs.lease_plan_user + ', ' + moment(rs.lease_plan_date).format('DD.MM.YYYY') : '',
          curPartyName: rs.curPartyName,
          contractNumber: rs.contractNumber,
          capitalRent: capitalRent,
          capitalRentPerSquare: capitalRent / rs.area,
          contractType: rs.contractType,
          validity: rs.validity,
          signature_date: rs.signature_date,
          curPartyStartDate: rs.curPartyStartDate,
          curPartyEndDate: rs.curPartyEndDate,
          nextPartyContractNumber: futureUnitContracts[rs.unitId] !== rs.contractNumber ? futureUnitContracts[rs.unitId] : null,
          list_rent_apartment: rs.list_rent_apartment
        }
      })
    },
    getCarparkMarketRentData () {
      const totalAreaByContractNumber = this.carparks.reduce((acc, curr) => {
        if (curr.contract_number) {
          if (acc[curr.contract_number]) {
            acc[curr.contract_number] += curr.carpark_area
          } else {
            acc[curr.contract_number] = curr.carpark_area
          }
        }
        return acc
      }, {})

      const futureCarparks = Object.fromEntries(this.futureCarparkContracts.map(item => [item.id_carpark, item.contract_number]))
      
      return this.carparks.map(cp => {
        const capitalRent =  totalAreaByContractNumber[cp.contract_number] ? cp.capital_rent * cp.carpark_area / totalAreaByContractNumber[cp.contract_number] : null
        const carparkArea = cp.rentingtype === 'Single' ? cp.area : null
          return {
            siteName: cp.siteName,
            site_identifier: cp.siteIdentifier,
            number: cp.number,
            market_rent: cp.market_rent_per_car_space,
            parking_rights: cp.num_of_parking_rights,
            carParkCodeLong: cp.carpark_code_long,
            area: carparkArea,
            carParkId: cp.carParkId,
            id_site: cp.idSite,
            commercial_info_date: cp.commercial_info_date,
            carpark_version_end_date: cp.carpark_version_end_date,
            carpark_version_start_date: cp.carpark_version_start_date,
            commercial_info_user: cp.commercial_info_user,
            rent_target: cp.rent_target,
            lease_plan: cp.lease_plan,
            lease_plan_updated: cp.lease_plan_user && cp.lease_plan_date ? cp.lease_plan_user + ', ' + moment(cp.lease_plan_date).format('DD.MM.YYYY') : '',
            vacancy_rate_assumption: cp.vacancy_rate_assumption,
            vacancy_rate_assumption_updated: cp.vacancy_rate_assumption_user && cp.vacancy_rate_assumption_date ? cp.vacancy_rate_assumption_user + ', ' + moment(cp.vacancy_rate_assumption_date).format('DD.MM.YYYY') : '',
            currentTenantName: cp.tenant,
            contractNumber: cp.contract_number,
            capitalRent: capitalRent,
            capitalRentPerSquare: capitalRent / cp.carpark_area,
            contractType: cp.contract_type,
            validity: cp.validity_type,
            signature_date: cp.signature_date,
            currentContractStartDate: cp.contract_start_date,
            currentContractEndDate: cp.contract_end_date,
            nextCarParkContractNumber: futureCarparks[cp.carParkId] !== cp.contract_number ? futureCarparks[cp.carParkId] : null
          }
        })
    }
  }
}
</script>

<style>
</style>